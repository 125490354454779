import config from "../services/configService";
import {branding as asBrand} from "@/tenants/AS/branding";
import {branding as baBrand} from "@/tenants/BA/branding";
import {branding as cfBrand} from "@/tenants/CF/branding";
import {branding as cwBrand} from "@/tenants/CW/branding";
import {branding as ecBrand} from "@/tenants/EC/branding";
import {branding as icBrand} from "@/tenants/IC/branding";
import {branding as lsBrand} from "@/tenants/LS/branding";
import {branding as lwBrand} from "@/tenants/LW/branding";
import {branding as mnBrand} from "@/tenants/MN/branding";
import {branding as msBrand} from "@/tenants/MS/branding";
import {branding as mxBrand} from "@/tenants/MX/branding";
import {branding as rfBrand} from "@/tenants/RF/branding";
import {branding as rmBrand} from "@/tenants/RM/branding";
import {branding as tfBrand} from "@/tenants/TF/branding";
import {branding as npBrand} from "@/tenants/NP/branding";
import { branding as pbBrand } from "@/tenants/PB/branding";
import { branding as fwBrand } from "@/tenants/FW/branding";

const getBrand = () => {
  switch (config.code) {
    case "AS": return asBrand;
    case "BA": return baBrand;
    case "CF": return cfBrand;
    case "CW": return cwBrand;
    case "EC": return ecBrand;
    case "IC": return icBrand;
    case "LS": return lsBrand;
    case "LW": return lwBrand;
    case "MN": return mnBrand;
    case "MS": return msBrand;
    case "MX": return mxBrand;
    case "RF": return rfBrand;
    case "RM": return rmBrand;
    case "TF": return tfBrand;
    case "NP": return npBrand;
    case "PB": return pbBrand;
    case "FW": return fwBrand;
    default:
      throw new Error(`Unknown code ${config.code}`);
  }
};

export const brand = getBrand();