<template>
    <svg id="questions-circular-button" xmlns="http://www.w3.org/2000/svg" width="18.457" height="18.457" viewBox="0 0 18.457 18.457" role="img" aria-label="question">
        <g id="Group_2883" data-name="Group 2883">
            <path id="Path_571" data-name="Path 571" d="M225.759,181.208l-.154.166Z" transform="translate(-218.801 -175.743)" fill="#1d2675"/>
            <path id="Path_572" data-name="Path 572" d="M209.807,155.819a1.605,1.605,0,0,1,.359,1.045,1.565,1.565,0,0,1-.246.83,2.026,2.026,0,0,1-.356.436l-.492.486a3.805,3.805,0,0,0-.915,1.232,3.982,3.982,0,0,0-.209,1.416h1.094a3.023,3.023,0,0,1,.175-1.17,3.453,3.453,0,0,1,.765-.968,8.369,8.369,0,0,0,1.078-1.192,1.891,1.891,0,0,0,.268-1.057,2.33,2.33,0,0,0-.728-1.767,3.045,3.045,0,0,0-4,.117,3.013,3.013,0,0,0-.75,2.135h1.094a2.961,2.961,0,0,1,.252-1.223,1.75,1.75,0,0,1,2.608-.319Z" transform="translate(-199.645 -149.761)" fill="#1d2675"/>
            <path id="Path_573" data-name="Path 573" d="M18.457,9.229a9.229,9.229,0,1,0-9.229,9.229A9.229,9.229,0,0,0,18.457,9.229Zm-17.618,0a8.39,8.39,0,1,1,8.39,8.39A8.389,8.389,0,0,1,.839,9.229Z" fill="#1d2675"/>
            <rect id="Rectangle_3712" data-name="Rectangle 3712" width="1.223" height="1.278" transform="translate(8.279 12.522)" fill="#1d2675"/>
        </g>
    </svg>
</template>

<script>
export default {
  name: "QuestionIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path, rect {
            fill: var(--color--menu-icons);
        }
        &.mobile--menu {
            path {
                fill: var(--color--menu-icons--mobile) !important;
            }
        }
    }
</style>