import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.$appConfig.isBilingual)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['lang__wrapper', _ctx.mobile ? 'lang__wrapper__mobile' : ''])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["lang", { active: _ctx.lang === `en` }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectLang(`en`)))
        }, "EN", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["lang", { active: _ctx.lang === `fr` }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectLang(`fr`)))
        }, "FR", 2)
      ], 2))
    : _createCommentVNode("", true)
}