import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = {
  key: 1,
  class: "messageContainer"
}
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "previewDrop" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "hidden"
}
const _hoisted_8 = {
  key: 2,
  class: "dropdownItems"
}
const _hoisted_9 = ["index", "onClick"]
const _hoisted_10 = { class: "previewDrop" }
const _hoisted_11 = {
  key: 0,
  class: "hidden"
}
const _hoisted_12 = { class: "dropdownItems" }
const _hoisted_13 = ["index", "onClick"]
const _hoisted_14 = {
  for: "mortgage-account-number",
  id: "mortgage-account-number__label"
}
const _hoisted_15 = {
  key: 0,
  class: "info-box setWidth"
}
const _hoisted_16 = { class: "info-imgs" }
const _hoisted_17 = ["src", "alt"]
const _hoisted_18 = ["src", "alt"]
const _hoisted_19 = ["src", "alt"]
const _hoisted_20 = { for: "full-name" }
const _hoisted_21 = { for: "email" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = ["placeholder", "aria-label"]
const _hoisted_24 = {
  key: 3,
  class: "row"
}
const _hoisted_25 = { for: "file-upload" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "captcha" }
const _hoisted_28 = ["disabled"]
const _hoisted_29 = {
  key: 2,
  class: "messageContainer",
  style: {"text-align":"center"}
}
const _hoisted_30 = ["innerHTML"]
const _hoisted_31 = { class: "confirmation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon")!
  const _component_DownArrowIcon = _resolveComponent("DownArrowIcon")!
  const _component_InfoIconPrimary = _resolveComponent("InfoIconPrimary")!
  const _component_AttachmentIcon = _resolveComponent("AttachmentIcon")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider", { open: _ctx.isOpen }])
  }, [
    _createElementVNode("h2", null, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMessage && _ctx.toggleMessage(...args)))
      }, [
        _createVNode(_component_ArrowLeftIcon)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("ContactUsDrawer.NewMessage")), 1)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(this.error), 1))
      : _createCommentVNode("", true),
    (_ctx.initialLoad && !_ctx.confirmation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.fromFireInsurance || _ctx.messageIsTopicFix ? _ctx.$t("Message.Subject") : _ctx.$t("Message.Help")), 1),
          _createElementVNode("div", {
            class: "drop",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.topicDropDown && _ctx.topicDropDown(...args)))
          }, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("p", { class: "dropNotSelected" }, _toDisplayString(_ctx.$t("Message.SelectHelp")), 513), [
                [_vShow, !_ctx.topic]
              ]),
              (_ctx.topic)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.isFrench ? _ctx.topic.frenchText : _ctx.topic.englishText), 1))
                : _createCommentVNode("", true),
              (!_ctx.messageIsTopicFix)
                ? (_openBlock(), _createBlock(_component_DownArrowIcon, {
                    key: 1,
                    class: "black"
                  }))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.topicDropDownShow)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.topicDropDownShow)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
              : _createCommentVNode("", true),
            (_ctx.topicDropDownShow && !_ctx.messageIsTopicFix)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topics, (topic, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: i,
                      index: i,
                      onClick: ($event: any) => (_ctx.topicClick(topic))
                    }, _toDisplayString(_ctx.isFrench ?
                        topic.frenchText : topic.englishText), 9, _hoisted_9))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.loggedIn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "drop",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.mortgageDropDown && _ctx.mortgageDropDown(...args)))
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _withDirectives(_createElementVNode("p", { class: "dropNotSelected" }, _toDisplayString(_ctx.$t("Message.SelectMortgage")), 513), [
                    [_vShow, _ctx.mortgageNumber === '']
                  ]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.mortgageNumber), 1),
                  (!_ctx.messageIsMortgageNumberFix)
                    ? (_openBlock(), _createBlock(_component_DownArrowIcon, {
                        key: 0,
                        class: "black"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("hr", null, null, 512), [
                  [_vShow, !_ctx.mortageDropDownShow]
                ]),
                (_ctx.mortageDropDownShow)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
                  : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("ul", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mortgageSummaries, (mortgage, i) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: i,
                      index: i,
                      onClick: ($event: any) => (_ctx.mortageClick(i))
                    }, _toDisplayString(_ctx.mortgageSummaries[i].mortgageNumber), 9, _hoisted_13))
                  }), 128))
                ], 512), [
                  [_vShow, _ctx.mortageDropDownShow && !_ctx.messageIsMortgageNumberFix]
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.loggedIn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["input-container", { hasValue: _ctx.mortgageNumber.length > 0, active: _ctx.inputs.mortgageNumberActive }])
              }, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("MortgageAccountNumber")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "mortgage-account-number",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mortgageNumber) = $event)),
                  onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addActiveInput('mortgageNumber'))),
                  onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeActiveInput()))
                }, null, 544), [
                  [_vModelText, _ctx.mortgageNumber]
                ]),
                _createElementVNode("div", {
                  id: "mortgage-account-info-icon",
                  class: "input-icon",
                  onMouseenter: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleMortgageAccountInfo())),
                  onMouseleave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.toggleMortgageAccountInfo()))
                }, [
                  _createVNode(_component_InfoIconPrimary)
                ], 32),
                (_ctx.showMortgageAccountInfo)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t("FindMortgageNumber")), 1),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("img", {
                          src: _ctx.letterImage,
                          alt: _ctx.$t('ImageOfLetter'),
                          onError: _cache[8] || (_cache[8] = ($event: any) => (_ctx.imageLoadError($event)))
                        }, null, 40, _hoisted_17),
                        _createElementVNode("img", {
                          src: _ctx.annualImage,
                          alt: _ctx.$t('ImageOfStatement'),
                          onError: _cache[9] || (_cache[9] = ($event: any) => (_ctx.imageLoadError($event)))
                        }, null, 40, _hoisted_18),
                        _createElementVNode("img", {
                          src: _ctx.accountInfoImage,
                          alt: _ctx.$t('ImageOfAccount'),
                          onError: _cache[10] || (_cache[10] = ($event: any) => (_ctx.imageLoadError($event)))
                        }, null, 40, _hoisted_19)
                      ]),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t("SignUpMortgageAccountInfo")), 1)
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          (!_ctx.loggedIn)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["input-container", { hasValue: _ctx.name.length > 0, active: _ctx.inputs.nameActive }])
              }, [
                _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("Name")), 1),
                _withDirectives(_createElementVNode("input", {
                  id: "full-name",
                  type: "text",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.name) = $event)),
                  onFocus: _cache[12] || (_cache[12] = ($event: any) => (_ctx.addActiveInput(`name`))),
                  onBlur: _cache[13] || (_cache[13] = ($event: any) => (_ctx.removeActiveInput()))
                }, null, 544), [
                  [_vModelText, _ctx.name]
                ])
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["input-container", { hasValue: _ctx.email.length > 0, active: _ctx.inputs.emailActive }])
          }, [
            _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("EmailAddress")), 1),
            _withDirectives(_createElementVNode("input", {
              id: "email",
              type: "text",
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.email) = $event)),
              onFocus: _cache[15] || (_cache[15] = ($event: any) => (_ctx.addActiveInput(`email`))),
              onBlur: _cache[16] || (_cache[16] = ($event: any) => (_ctx.removeActiveInput())),
              disabled: _ctx.messageIsTopicFix
            }, null, 40, _hoisted_22), [
              [_vModelText, _ctx.email]
            ])
          ], 2),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.message) = $event)),
            placeholder: _ctx.placeholder,
            maxlength: "2000",
            class: "message-text",
            "aria-label": _ctx.message
          }, null, 8, _hoisted_23), [
            [_vModelText, _ctx.message]
          ]),
          _createElementVNode("p", {
            class: _normalizeClass(["characterLimit", { characterLimitReached: _ctx.message.length === 2000 }])
          }, _toDisplayString(_ctx.$t("Message.CharacterLimit", { characters: this.message.length })), 3),
          (_ctx.loggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("label", _hoisted_25, [
                  _createVNode(_component_AttachmentIcon),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("Message.AttachFile")) + " ", 1),
                  _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fa fa-cloud-upload" }, null, -1))
                ]),
                _createElementVNode("input", {
                  type: "file",
                  id: "file-upload",
                  name: "filename",
                  onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.attachFile($event))),
                  multiple: "",
                  accept: ".jpg, .jpeg, .png, .gif, .rtf, .txt, .docx, .doc, .pdf"
                }, null, 32),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attachments, (n, i) => {
                    return (_openBlock(), _createElementBlock("div", { key: i }, [
                      _createElementVNode("p", null, _toDisplayString(n.name) + " - " + _toDisplayString(_ctx.bytesToSize(n)), 1),
                      _createElementVNode("span", {
                        class: "closeIcon",
                        onClick: ($event: any) => (_ctx.removeAttachment(i))
                      }, [
                        _createVNode(_component_CloseIcon)
                      ], 8, _hoisted_26)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_vue_recaptcha, {
              sitekey: _ctx.$appConfig.siteKey,
              language: _ctx.recaptchaLanguage,
              ref: "recaptcha",
              loadRecaptchaScript: true,
              onVerify: _ctx.onVerify,
              onExpired: _ctx.onExpired
            }, null, 8, ["sitekey", "language", "onVerify", "onExpired"])
          ]),
          (!_ctx.confirmation)
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                onClick: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args))),
                disabled: _ctx.isCsr,
                class: "send button button--primary"
              }, _toDisplayString(_ctx.$t("Message.SendMessage")), 9, _hoisted_28))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Spinner, { key: 5 }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.confirmation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
          _createElementVNode("span", {
            class: "confirmation",
            innerHTML: _ctx.$t('Message.MessageConf')
          }, null, 8, _hoisted_30),
          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('Message.ToYourEmail')), 1),
          _createElementVNode("div", {
            onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.closeAll && _ctx.closeAll(...args))),
            class: "send button button--primary"
          }, _toDisplayString(_ctx.$t("Close")), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}