<template>
    <div class="slider" :class="{open: isOpen}">
        <h2>
            <div @click="toggleContactUs">
                <ArrowLeftIcon />
            </div>
            {{ $t("ContactUsDrawer.newCoversation") }}
        </h2>
        <ul>
            <div class="topBanner">
                <div class="middleSection">
                    <h3>{{ $t("ContactUsDrawer.QuickAnswers") }}</h3>
                    <div class="searchContainer">
                        <SearchIcon class="black" />

                        <input :placeholder="searchTextPlaceHolder" v-model="searched" @keyup.enter="search" />
                        <div class="arrow" @click="search">
                            <ArrowRightIcon />
                        </div>
                    </div>
                    <div>
                        <p>{{ $t("ContactUsDrawer.SearchResults") }}"{{searched}}"</p>
                    </div>
                </div>
                <div class="bottomSection" v-for="(topic, i) in filteredTopics" :key="i" :index="i">
                    <hr />
                    <div>
                        <h4>{{topic.header}}</h4>
                        <p>{{topic.description}}</p>
                    </div>
                </div>
                <div class="noResults" v-if="filteredTopics.length === 0">{{$t("ContactUsDrawer.NoResults")}}</div>
                <p class="leave">{{ $t("ContactUsDrawer.LeaveUs") }}</p>
                <p class="subText">{{ $t("ContactUsDrawer.TypicalReplies") }}</p>
                <div class="button button--primary--white newMessage" @click="toggleMessage">{{ $t("ContactUsDrawer.NewMessage") }}</div>
            </div>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { TranslateResult } from "vue-i18n";

import ArrowLeftIcon from "../Icons/LeftArrowIcon.vue";
import ClockIcon from "../Icons/ClockIcon.vue";
import SearchIcon from "@/components/Icons/SearchIcon.vue";
import ArrowRightIcon from "@/components/Icons/ArrowRightIcon.vue";
import i18n from "@/plugins/i18n";
import { useI18n } from "vue-i18n";
const { t } = i18n.global;

@Component({
  components: {
    ArrowLeftIcon,
    ClockIcon,
    SearchIcon,
    ArrowRightIcon,
  },
})
class ContactUs extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Prop() private isOpen!: boolean;
  @Prop() private toggleContactUs!: () => void;
  @Prop() private toggleMessage!: () => void;

  private topics: [] = [];
  private filteredTopics: { header: string; description: string }[];
  private searched: string;
  private searchTextPlaceHolder: TranslateResult;

  private created() {
    this.topics = [];
    this.filteredTopics = [];
    this.searched = "payment";
    this.searchTextPlaceHolder = t("ContactUsDrawer.SearchForAnswers");
    this.filteredTopics = (this.topics as any).filter((t: { header: string; description: string }) => t.header.toLowerCase().includes("payment"));
  }

  private search() {
    this.filteredTopics = (this.topics as any).filter((t: { header: string; description: string }) =>
      t.header.toLowerCase().includes(this.searched.toLowerCase()),
    );
  }
}
export default toNative(ContactUs)
</script>

<style lang="scss" scoped>


    .main {
        & .slider {
            top: 0px;
        }
    }

    .contact-us {
        width: 448px;
        height: 100vh;
        position: fixed;
        z-index: 200;
        right: -100%;
        transition: 0.5s;
        top: 80px;
        background: white;
        box-shadow: -3px 3px 6px rgba(black, 0.16);
        max-width: 80%;
        max-height: calc(100vh - 80px);
        overflow: auto;
        border-top: 1px solid #eeeeee;
        @media (max-width: $media--ipad) {
            width: 400px;
            max-height: 100vh;
            top: 0;
        }
        &.open {
            right: 0;
        }
        h2 {
            margin: 0;
            font-size: 30px;
            font-weight: var(--font--light);
            display: flex;
            align-items: center;
            padding: 20px 15px;
            border-bottom: 2px solid #eeeeee;
            svg {
                transform: scale(1.35);
                margin-right: 15px;
                cursor: pointer;
            }
        }
        ul {
            list-style: none;

            .topBanner {
                background: var(--color--primary);
                border-radius: 4px;
                margin: 15px 20px 10px 15px;
                padding-bottom: 5px;
                .leave {
                    color: #eeeeee;
                    font-weight: 100;
                    padding: 10px;
                    padding-left: 20px;
                }
                .subText {
                    font-size: 13px;
                    color: #eeeeee;
                    margin-left: 20px;
                    font-weight: 100;
                }

                .newMessage {
                    width: 250px;
                    margin: 20px auto;
                }
            }
            .middleSection {
                background: #f5f6f9;
                padding: 10px;
                padding-left: 20px;
                padding-top: 20px;
                h3 {
                    color: var(--color--primary);
                    font-weight: 500;
                    font-size: 18px;
                }
                p {
                    font-size: 13px;
                    opacity: 0.6;
                    color: #1c293b;
                }
                input {
                    margin-left: 5px;
                }
                .searchContainer {
                    border: 1px;
                    border-color: rgb(221, 221, 221);
                    border-style: solid;
                    border-radius: 5px;
                    display: flex;
                    position: relative;
                    padding: 7px;
                    margin: 15px auto;
                    background: white;

                    p {
                        color: rgb(173, 173, 173);
                        font-size: 15px;
                        font-weight: 100;
                        margin-left: 5px;
                    }
                    input {
                        border: none;
                        width: 100%;
                    }

                    .arrow {
                        background: var(--color--primary);
                        float: right;
                        position: absolute;
                        width: 40px;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        text-align: center;
                        padding: 7px;
                        border-radius: 0px 4px 4px 0px;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.7;
                        }

                        svg {
                            fill: white;
                        }
                    }
                }
            }
            .noResults {
                background: #f5f6f9;
                text-align: center;
                padding: 15px;
            }
            .bottomSection {
                background: #f5f6f9;
                cursor: pointer;

                div {
                    padding: 10px;
                    padding-left: 20px;
                    &:hover {
                        background: var(--color--primary);
                        p,
                        h4 {
                            color: white;
                        }
                    }
                }
                p {
                    font-size: 12px;
                    margin-top: 4px;
                }
                h4 {
                    font-size: 14px;
                    font-weight: 500;
                }
                hr {
                    background-color: #dbdbdb;
                    height: 1px;
                    border-width: 0;
                }
            }
        }
    }
</style>