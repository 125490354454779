import { User } from "@/store/types/user";

const windowAny = window as any;

export interface Config {
  useNewSkipPayment: boolean;
  fileNameMaxLength: number;
  cookieConsentExpiryInDays: number;
  isCalculator: boolean;
  isInfoStatement: boolean;
  isThinkHELOCEnded: boolean;
  publicPath: string;
  code: string;
  frenchUrl: string;
  secondaryUrl: string;
  name: string;
  nameFr: string;
  prefix: string;
  prefixFr: string;
  isBilingual: boolean;
  serviceHours: string;
  serviceHoursFrench: string;
  phoneNumber: string;
  phoneNumberFrench: string;
  emailFrom: string;
  fax: string;
  faxFrench: string;
  email: string;
  emailFrench: string;
  siteKey: string;
  currentUser: User;
  allowArrears: boolean;
  allowChangePaymentFrequency: boolean;
  allowChangePaymentDate: boolean;
  allowLumpSumPayment: boolean;
  allowLumpSumPaymentOnGoing: boolean;
  googleAnalyticsKey: string;
  googleAnalyticsKeyFr: string;
  googleTagManagerKey: string;
  googleTagManagerKeyFr: string;
  facebookPixelId?: string;
  businessName: string;
  businessNameFr: string;
  showLicensing: boolean;
  licensing: string;
  licensingFr: string;
  redirect: {
    oldUrl: string;
    newUrlEn: string;
    newUrlFr: string;
    deadline: Date;
  };
  videoLibrary: {
    launchDate: string;
  };
  getUrl: () => string;
}

const config: Config = {
  publicPath: windowAny.mcap_config.publicPath,
  code: windowAny.mcap_config.code,
  frenchUrl: windowAny.mcap_config.frenchUrl,
  secondaryUrl: windowAny.mcap_config.secondaryUrl,
  emailFrom: windowAny.mcap_config.emailFrom,
  name: windowAny.mcap_config.name,
  nameFr: windowAny.mcap_config.nameFr,
  prefix: windowAny.mcap_config.prefix || "",
  prefixFr: windowAny.mcap_config.prefixFr || "",
  serviceHours: windowAny.mcap_config.serviceHours || "",
  serviceHoursFrench: windowAny.mcap_config.serviceHoursFrench || "",
  phoneNumber: windowAny.mcap_config.phoneNumber || "",
  phoneNumberFrench: windowAny.mcap_config.phoneNumberFrench || "",
  email: windowAny.mcap_config.email || "",
  emailFrench: windowAny.mcap_config.emailFrench || "",
  siteKey: windowAny.mcap_config.siteKey || "",
  fax: windowAny.mcap_config.fax || "",
  faxFrench: windowAny.mcap_config.faxFrench || "",
  isBilingual: windowAny.mcap_config.isBilingual,
  currentUser: windowAny.mcap_config.currentUser,
  allowArrears: windowAny.mcap_config.allowArrears,
  allowChangePaymentFrequency: windowAny.mcap_config.allowChangePaymentFrequency,
  allowChangePaymentDate: windowAny.mcap_config.allowChangePaymentDate,
  allowLumpSumPayment: windowAny.mcap_config.allowLumpSumPayment,
  allowLumpSumPaymentOnGoing: windowAny.mcap_config.allowLumpSumPaymentOnGoing,
  googleAnalyticsKey: windowAny.mcap_config.googleAnalyticsKey,
  googleAnalyticsKeyFr: windowAny.mcap_config.googleAnalyticsKeyFr,
  googleTagManagerKey: windowAny.mcap_config.googleTagManagerKey,
  googleTagManagerKeyFr: windowAny.mcap_config.googleTagManagerKeyFr,
  facebookPixelId: windowAny.mcap_config.facebookPixelId,
  businessName: windowAny.mcap_config.businessName,
  businessNameFr: windowAny.mcap_config.businessNameFr,
  isCalculator: windowAny.mcap_config.isCalculator,
  isInfoStatement: windowAny.mcap_config.isInfoStatement,
  isThinkHELOCEnded: windowAny.mcap_config.isThinkHELOCEnded,
  redirect: windowAny.mcap_config.redirect,
  showLicensing: windowAny.mcap_config.showLicensing,
  licensing: windowAny.mcap_config.licensing,
  licensingFr: windowAny.mcap_config.licensingFr,
  videoLibrary: windowAny.mcap_config.videoLibrary,
  useNewSkipPayment: windowAny.mcap_config.useNewSkipPayment,
  fileNameMaxLength: windowAny.mcap_config.fileNameMaxLength,
  cookieConsentExpiryInDays: windowAny.mcap_config.cookieConsentExpiryInDays,
  getUrl: () => {
    return windowAny.mcap_config.publicPath;
    // return (lang  === 'fr' ? windowAny.mcap_config.frenchUrl : windowAny.mcap_config.secondaryUrl) || windowAny.mcap_config.secondaryUrl;
  }
};

export default config;
