import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#19a0cf" },
    { name: "Green", value: "#00AA44" },
    { name: "Primary", value: "#003057" },
    { name: "Red", value: "#D93759" },
    { name: "Orange", value: "#F2A765" },
    { name: "Grey", value: "#D9D9D6" },
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: false,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: null,
      homeSystem: null,
      terms: null,
      privacy: "https://www.mcap.com/Privacy",
      security: "https://www.mcap.com/Security",
      forms: "https://eclipse.entrez.ca/Home/ContentPage/Forms",
      legal: "https://www.mcap.com/Legal",
      licences: "https://www.mcap.com/Licences",
      access: "https://www.mcap.com/Accessibility",
      moreInfoSAP: "https://eclipse.entrez.ca/Home/ContentPage/FAQ",
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: null,
    },
    fr: {
      homeAppliance: null,
      homeSystem: null,
      privacy: null,
      security: null,
      forms: null,
      legal: null,
      licences: null,
      access: null,
      terms: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
    },
  },

  footerbottom: "",
};
