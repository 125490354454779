import i18n from "@/plugins/i18n";
import { TranslateResult } from "vue-i18n";
import apiService from "./apiService";
import config from "./configService";
import errorService from "./errorService";
import { DateService } from "./dateService";
import { MortgageSummary } from "@/store/types/mortgage";

const { t } = i18n.global;

export interface ValidateMortgage {
  lastName: string;
  bankAccount: string;
  mortgageNumber: string;
  recaptcha: string;
}
class MortgageService {
  public getFrequencyText = (frequency: number, accelerated?: boolean): TranslateResult => {
    switch (frequency) {
      case 1:
        return t("PaymentFrequencies.Annually");
      case 2:
        return t("PaymentFrequencies.SemiAnnually");
      case 12:
        return t("PaymentFrequencies.Monthly");
      case 24:
        return t("PaymentFrequencies.SemiMonthly");
      case 26:
        return accelerated ? t("PaymentFrequencies.BiWeekly-Accelerated") : t("BiWeekly");
      case 52:
        return accelerated ? t("PaymentFrequencies.Weekly-Accelerated") : t("Weekly");
    }
    return "";
  }

  public ValidateMortgage = (currentMortgageAccountNumber: string, mortgageDetails: ValidateMortgage): any => {
    return apiService.POST("registration/validate", [currentMortgageAccountNumber, config.code], mortgageDetails);
  }

  public lineOfCreditWithdraw = (mortgageNumber: string, lang: string, amount: any): any => {
    return apiService.POST("lineOfCredit/withdraw", [mortgageNumber, `${lang}-CA`], amount);
  }

  public skipAPayment = (mortgageNumber: string, lang: string, details: any): any => {
    return apiService.POST("skipAPayment", [mortgageNumber, `${lang}-CA`, config.useNewSkipPayment ? "2" : ""], details);

  }

  public makeSpecialPayment = (mortgageNumber: string, details: any): any => {
    return apiService.POST("specialPayments", [mortgageNumber], details);
  }

  public getSkipAPayment = async (mortgage: MortgageSummary, lang: string): Promise<any> => {
    const response = await apiService.GET("skipAPayment/paymentDetails", [mortgage.mortgageNumber, `${lang}-CA`]);
    if (!response.isSuccess && response.isCatchError) {
      return { isSuccess: false, error: response.error };
    }
    if (response.error) {
      return {
        isSuccess: false, value: this.skipMessage(response, mortgage), error: response.error,
      };
    }
    return { isSuccess: true, value: response };
  }

  public getSkipAPaymentEligibility = async (mortgage: any, lang: string): Promise<any> => {
    const response = await apiService.GET("SkipAPayment/eligibility", [mortgage.mortgageNumber, lang, config.useNewSkipPayment ? "2" : ""]);
    if (!response.error && response.isCatchError) {
      return { isSuccess: false, error: response.error };
    }
    if (response.error) {
      return {
        isSuccess: false, value: this.skipMessage(response, mortgage), error: response.error,

      };
    }
    return { isSuccess: true, value: response };
  }

  public deleteLumpSumPayment = (mortgageNumber: string, relativeRecordNumber: number): any => {
    return apiService.DELETE("lumpsumpayments", [mortgageNumber, String(relativeRecordNumber)]);
  }

  public getLumpSumPayment = (mortgageNumber: string, lang: string): any => {
    return apiService.DELETE("mortgages", [mortgageNumber, `${lang}-CA`]);
  }

  public getLumpSumOneTimeDetails = (mortgageNumber: string): any => {
    return apiService.GET("lumpsumpayments", ["onetime", mortgageNumber]);
  }

  public getLumpSumOnGoingDetails = (mortgageNumber: string, startDate: string, lang: string): any => {
    return apiService.GET("lumpsumpayments", ["ongoing", mortgageNumber, startDate, `${lang}-CA`]);
  }

  public getCurrentLumpSumPayments = (mortgageNumber: string): any => {
    return apiService.GET("lumpsumpayments", [mortgageNumber]);
  }
  public getLumpSumCalculatorScenarioDetails = (mortgageNumber: string, details: any, lang: string): any => {
    return apiService.POST("lumpsumpayments/calculate", [mortgageNumber, lang], details);
  }

  public getLumpSumCalculatorDetails = (mortgageNumber: string, lang: string): any => {
    return apiService.GET("lumpsumpayments/calculatorDetails", [mortgageNumber, lang]);
  }

  public updatePaymentFrequency = (mortgageNumber: string, lang: string, details: any): any => {
    return apiService.PUT("paymentFrequency", [mortgageNumber, `${lang}-CA`], details);
  }

  public updatePaymentDate = (mortgageNumber: string, lang: string, details: any): any => {
    return apiService.PUT("paymentDate", [mortgageNumber, `${lang}-CA`], details);
  }

  public confirmLumpSumPayment = (type: string, mortgageNumber: string, lang: string, details: any): any => {
    return apiService.POST(`lumpsumpayments/${type}`, [mortgageNumber, "confirm", `${lang}-CA`], details);
  }

  public submitLumpSumPayment = (type: string, mortgageNumber: string, details: any): any => {
    return apiService.POST(`lumpsumpayments/${type}`, [mortgageNumber], details);
  }

  public skipAPaymentReport = (startDate: string, endDate: string): any => {
    return apiService.GET("SkipAPayment/report", [startDate, endDate], { responseType: "blob" });
  }

  public skipMessage(response: any, mortgage: any) {
    return errorService.getErrorText(errorService.transformErrorByBrand(response.error), {
      lastSkippedPaymentDate: DateService.formatDate(response.lastSkippedPaymentDate),
      nextEligiblePaymentDate: DateService.formatDate(response.nextEligiblePaymentDate),
      ...mortgage,
      ...config,
    });
  }

  public getMortgageSummaries(lang: string) {
    return apiService.GET("mortgages", [`${lang}-CA`]);
  }

  public getMortgageDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("mortgages", [mortgageNumber, `${lang}-CA`]);
  }

  public getAmortizationDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("amortization", [mortgageNumber, lang]);
  }

  public getPaymentFrequencyChangeDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("paymentFrequency", [mortgageNumber, `${lang}-CA`]);
  }

  public getProspectivePaymentFrequencyChangeDetails(mortgageNumber: string, request: any, lang: string) {
    return apiService.POST("paymentFrequency", ["calculate", mortgageNumber, `${lang}-CA`], request);
  }

  public getPaymentDateChangeDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("paymentDate", [mortgageNumber, `${lang}-CA`]);
  }

  public getProspectivePaymentDateChangeDetails(mortgageNumber: string, lang: string, request: any) {
    return apiService.POST("paymentDate", ["calculate", mortgageNumber, `${lang}-CA`], request);
  }

  public getArrearsPaymentDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("specialPayments", [mortgageNumber, `${lang}-CA`]);
  }

  public getLineOfCreditDetails(mortgageNumber: string, lang: string) {
    return apiService.GET("lineOfCredit", [mortgageNumber, `${lang}-CA`]);
  }

  public requestInfoStatement(mortgageNumber: string, lang: string) {
    return apiService.GET("documents/InfoStatement", [mortgageNumber, `${lang}-CA`]);
  }

  public getInfoStatement(propertyNumber: number, chargeType: string, lang: string) {
    return apiService.GET("mortgages/getInfoStatement", [String(propertyNumber), chargeType, `${lang}-CA`]);
  }

  public getCampaignReport(start: string, end: string, campaignName: string) {
    return apiService.GETFILE("Campaign", ["report", campaignName, start, end]);
  }
  public getHWCampaignType(mortgageNumber: string, lang: string) {
    return apiService.GET("Campaign", ["HWCampaignType", mortgageNumber, lang]);
  }

  public getMortgageActivities(mortgageNumber: string, lang: string, extraParameter?: string[]) {
    const parameters = [mortgageNumber];
    if (extraParameter) {
      extraParameter.forEach((p: string) => {
        parameters.push(p);

      });
    }
    parameters.push(`${lang}-CA`);
    return apiService.GET("activities", parameters);
  }

  public getPropertyTaxPayment(mortgageNumber: string, lang: string, extraParameter?: string[]) {
    const parameters = [mortgageNumber, "payments"];
    if (extraParameter) {
      extraParameter.forEach((p: string) => {
        parameters.push(p);

      });
    }
    parameters.push(`${lang}-CA`);
    return apiService.GET("propertytaxes", parameters);

  }
}

export default new MortgageService();
