import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "EI-banner"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "text",
          innerHTML: _ctx.text
        }, null, 8, _hoisted_2),
        _createElementVNode("div", {
          class: "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createVNode(_component_CloseIcon, { class: "white" })
        ])
      ]))
    : _createCommentVNode("", true)
}