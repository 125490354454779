<template>
    <svg id="error" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <path id="Path_4349" data-name="Path 4349" d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.512,10.512,0,0,0,10.5,0Zm0,20.192A9.692,9.692,0,1,1,20.192,10.5,9.7,9.7,0,0,1,10.5,20.192Z" fill="#1d2675"/>
        <path id="Path_4350" data-name="Path 4350" d="M23.958,16.118a.4.4,0,0,0-.571,0l-3.349,3.349-3.349-3.349a.4.4,0,0,0-.571.571l3.349,3.349-3.349,3.349a.4.4,0,1,0,.571.571l3.349-3.349,3.349,3.349a.4.4,0,0,0,.571-.571l-3.349-3.349,3.349-3.349A.4.4,0,0,0,23.958,16.118Z" transform="translate(-9.538 -9.538)" fill="#1d2675"/>
    </svg>
</template>

<script>
export default {
  name: "CloseIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: var(--color--primary);
        }
        &.red {
            path {
                fill: var(--color--red);
            }
        }
        &.quiz-red {
            height: 20px;
            width: 40px;
            margin-bottom: -4px;
            path {
                fill: red;
            }
        }
        &.white {
            path {
                fill: white;
            }
        }
        &.primary {
            path {
                fill: var(--color--primary);
            }
        }
    }
</style>