import "./dataPreload";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./assets/style.scss";
import { Vue, Inject, toNative } from "vue-facing-decorator";
import { useStore } from "vuex";
import { createApp, provide, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store/index";
import i18n from "./plugins/i18n";
import VueGtag from "vue-gtag";
import mortgageService from "@/services/mortgageService";
import config from "./services/configService";
import { CookieService } from "@/services/cookieService";
import "./plugins/filters";
import money from "v-money3";
import filters from "@/plugins/filters";
import FloatingVue from "floating-vue";
import mortgageServiceMixin from "./plugins/registeredServices";
import VueTheMask from "vue-the-mask";
import { createGtm } from "@gtm-support/vue-gtm";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { createHead } from "@unhead/vue";
import { useHead } from "@unhead/vue";
import metaInfo from "@/plugins/metaTags";
import { brand } from "@/assets/branding";

const app = createApp(App);
export default app;

app.use(money);
app.use(store);
app.use(router);
app.use(i18n);
app.component("VueDatePicker", VueDatePicker);
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: window.innerWidth > 768 ? ["hover", "focus", "click"] : ["click"],
    },
  },
});
app.use(VueTheMask);
app.mixin(mortgageServiceMixin);
const head = createHead();
app.use(head);
useHead(metaInfo);
app.config.globalProperties.$filters = filters;

app.provide("$appConfig", config);

const lang = CookieService.getCookie("lang") ? CookieService.getCookie("lang") : "en";
const googleAnalyticsKey = lang === "en" ? config.googleAnalyticsKey : config.googleAnalyticsKeyFr;
const googleTagManagerKey =
  lang === "en" ? config.googleTagManagerKey : config.googleTagManagerKeyFr;

const cookiesAccepted =
  CookieService.getCookie(CookieService.Cookies.Consent.Name) ===
  CookieService.Cookies.Consent.Values.Accepted;
if (googleAnalyticsKey) {
  try {
    app.use(
      VueGtag,
      {
        config: { id: googleAnalyticsKey },
        enabled: cookiesAccepted,
      },
      router
    );
  } catch (error) {
    console.error(error);
  }
}

if (googleTagManagerKey) {
  try {
    app.use(
      createGtm({
        id: googleTagManagerKey,
        loadScript: true,
        enabled: cookiesAccepted,
        vueRouter: router,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

if (cookiesAccepted) {
  const windowAny = window as any;
  if (config.facebookPixelId && windowAny && windowAny.fbq) {
    windowAny.fbq("init", config.facebookPixelId);
    windowAny.fbq("track", "PageView");
  }
}
app.use(createHead());

router.isReady().then(() => {
  app.mount("#app");
});
