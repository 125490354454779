import apiService from "./apiService";

class SafeguardService {
  public postSafeguardAnalytics = (mortgageNumber: string, lang: string, request): any => {
    return apiService.POST("mortgages/safeguard/analytics", [mortgageNumber, `${lang}-CA`], request);
  }

  public postCancelAnalytics(mortgageNumber: string, lang: string, request) {
    return apiService.POST("mortgages/safeguard/cancel", [mortgageNumber, `${lang}-CA`], request);
  }
}

export default new SafeguardService();
