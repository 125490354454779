import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#609cf5" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#A3A93D" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
  ],
  metaImageUrl: "",
  logoSize: "110px",
  logoSizeFr:"110px",
  FAQCovidDirectLink: true,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: false,
  showLicenceInfoFromCMS: true,
  usefulLink: {
    en: {
      homeAppliance: null,
      homeSystem: null,
      privacy: null,
      security: null,
      forms: "https://radius.entrez.ca/Home/ContentPage/Forms",
      legal: null,
      licences: null,
      terms: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: null,
    },
    fr: {
      homeAppliance: null,
      homeSystem: null,
      privacy: null,
      security: null,
      forms: "https://radius.entrez.ca/Home/ContentPage/Forms",
      legal: null,
      licences: null,
      terms: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: null,
    },
  },

  footerbottom: ""
};
