<template>
    <div class="slider" :class="{ open: isOpen }">
        <h2>
            <div @click="toggleMessage">
                <ArrowLeftIcon />
            </div>
            {{ $t("ContactUsDrawer.NewMessage") }}
        </h2>
        <div v-if="error" class="error">{{ this.error }}</div>
        <div class="messageContainer" v-if="initialLoad && !confirmation">
            <p class="info">{{ fromFireInsurance || messageIsTopicFix ? $t("Message.Subject") : $t("Message.Help") }}</p>
            <div class="drop" @click="topicDropDown">
                <div class="previewDrop">
                    <p class="dropNotSelected" v-show="!topic">{{ $t("Message.SelectHelp") }}</p>
                    <p v-if="topic">{{ isFrench ? topic.frenchText : topic.englishText }}</p>
                    <DownArrowIcon class="black" v-if="!messageIsTopicFix" />
                </div>

                <hr v-if="!topicDropDownShow" />
                <hr class="hidden" v-if="topicDropDownShow" />
                <ul v-if="topicDropDownShow && !messageIsTopicFix" class="dropdownItems">
                    <li v-for="(topic, i) in topics" :key="i" :index="i" @click="topicClick(topic)">{{ isFrench ?
                        topic.frenchText : topic.englishText }}</li>
                </ul>
            </div>

            <div class="drop" @click="mortgageDropDown" v-if="loggedIn">
                <div class="previewDrop">
                    <p class="dropNotSelected" v-show="mortgageNumber === ''">{{ $t("Message.SelectMortgage") }}</p>
                    <p>{{ mortgageNumber }}</p>

                    <DownArrowIcon class="black" v-if="!messageIsMortgageNumberFix" />
                </div>

                <hr v-show="!mortageDropDownShow" />
                <!-- reason: to minimze movement when opening drop down -->
                <hr class="hidden" v-if="mortageDropDownShow" />

                <ul v-show="mortageDropDownShow && !messageIsMortgageNumberFix" class="dropdownItems">
                    <li v-for="(mortgage, i) in mortgageSummaries" :key="i" :index="i" @click="mortageClick(i)">
                        {{ mortgageSummaries[i].mortgageNumber }}</li>
                </ul>
            </div>

            <div class="input-container"
                :class="{ hasValue: mortgageNumber.length > 0, active: inputs.mortgageNumberActive }" v-if="!loggedIn">
                <label for="mortgage-account-number" id="mortgage-account-number__label">{{ $t("MortgageAccountNumber")
                }}</label>
                <input type="text" id="mortgage-account-number" v-model="mortgageNumber"
                    @focus="addActiveInput('mortgageNumber')" @blur="removeActiveInput()" />
                <div id="mortgage-account-info-icon" class="input-icon" @mouseenter="toggleMortgageAccountInfo()"
                    @mouseleave="toggleMortgageAccountInfo()">
                    <InfoIconPrimary />
                </div>
                <div class="info-box setWidth" v-if="showMortgageAccountInfo">
                    <p>{{ $t("FindMortgageNumber") }}</p>
                    <div class="info-imgs">
                        <img :src="letterImage" :alt="$t('ImageOfLetter')" @error="imageLoadError($event)" />
                        <img :src="annualImage" :alt="$t('ImageOfStatement')" @error="imageLoadError($event)" />
                        <img :src="accountInfoImage" :alt="$t('ImageOfAccount')" @error="imageLoadError($event)" />
                    </div>
                    <p>{{ $t("SignUpMortgageAccountInfo") }}</p>
                </div>
            </div>
            <div class="input-container" :class="{ hasValue: name.length > 0, active: inputs.nameActive }" v-if="!loggedIn">
                <label for="full-name">{{ $t("Name") }}</label>
                <input id="full-name" type="text" v-model="name" @focus="addActiveInput(`name`)"
                    @blur="removeActiveInput()" />
            </div>
            <div class="input-container" :class="{ hasValue: email.length > 0, active: inputs.emailActive }">
                <label for="email">{{ $t("EmailAddress") }}</label>
                <input id="email" type="text" v-model="email" @focus="addActiveInput(`email`)" @blur="removeActiveInput()"
                    :disabled="messageIsTopicFix" />
            </div>
            <textarea v-model="message" :placeholder="placeholder" maxlength="2000" class="message-text"
                :aria-label="message" />
            <p class="characterLimit" :class="{ characterLimitReached: message.length === 2000 }">
                {{ $t("Message.CharacterLimit", { characters: this.message.length }) }}</p>

            <div class="row" v-if="loggedIn">
                <label for="file-upload">
                    <AttachmentIcon />
                    {{ $t("Message.AttachFile") }}
                    <i class="fa fa-cloud-upload"></i>
                </label>
                <input type="file" id="file-upload" name="filename" @change="attachFile($event)" multiple
                    accept=".jpg, .jpeg, .png, .gif, .rtf, .txt, .docx, .doc, .pdf" />
                <div>
                    <div v-for="(n, i) in attachments" :key="i">
                        <p>{{ n.name }} - {{ bytesToSize(n) }}</p>
                        <span class="closeIcon" @click="removeAttachment(i)">
                            <CloseIcon />
                        </span>
                    </div>
                </div>
            </div>
            <div class="captcha">
                <vue-recaptcha :sitekey="$appConfig.siteKey" :language="recaptchaLanguage" ref="recaptcha"
                    :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
            </div>
            <button v-if="!confirmation" @click="send" :disabled="isCsr" class="send button button--primary">
                {{ $t("Message.SendMessage") }}</button>
            <Spinner v-if="loading" />
        </div>
        <div class="messageContainer" style="text-align: center;" v-if="confirmation">
            <span class="confirmation" v-html="$t('Message.MessageConf')"></span>
            <span class="confirmation">{{ $t('Message.ToYourEmail') }}</span>
            <div @click="closeAll" class="send button button--primary">{{ $t("Close") }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";

import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";

import { MortgageSummary } from "@/store/types/mortgage";

import ArrowLeftIcon from "../Icons/LeftArrowIcon.vue";
import AttachmentIcon from "../Icons/AttachmentIcon.vue";

import { mapActions } from "vuex";
import { TranslateResult } from "vue-i18n";
import CloseIcon from "../Icons/CloseIcon.vue";
import Spinner from "@/components/Spinner.vue";
import { Action } from "vuex-facing-decorator";
import { CodeValue } from "@/store/types/codeValue";
import errorService from "@/services/errorService";
import { VueRecaptcha } from "vue-recaptcha";
import InfoIconPrimary from "@/components/Icons/InfoIconPrimary.vue";
import userService from "@/services/userService";
import { isMortgageNumberValid } from "@/services/utilService";
import config from "@/services/configService";

const TopicRequiredErrorCode = "BPE7047";
const MortgageNumberRequiredErrorCode = "BPE3019";
const MortgageNumberInvalidErrorCode = "BPE3020";
const MessageRequiredErrorCode = "BPE3065";
const NameRequiredErrorCode = "BPE3066";
const EmailRequiredErrorCode = "BPE3055";
const RecaptchaInvalidErrorCode = "BPE8021";
const AttachmentErrorCode = "BPE3067";

@Component({
  components: {
    ArrowLeftIcon,
    DownArrowIcon,
    AttachmentIcon,
    CloseIcon,
    Spinner,
    VueRecaptcha,
    InfoIconPrimary,
  },
})
class Message extends Vue {

  @Inject() $appConfig: any
  get isCsr(): boolean {
    return !!(this.$store?.state?.user?.isCsr)
  }

  public $store = useStore()
  @Prop() private isOpen!: boolean;
  @Prop() private toggleMessage!: () => void;
  @Prop() private toggleContactUs!: () => void;

  @Action("getMortgageSummaries") private getMortgageSummaries!: () => Promise<any>;
  @Action("getTopics") private getTopics!: () => Promise<any>;
  @Action("setFireInsuruacne") private setFireInsuruacne!: (value: boolean) => Promise<any>;

  private topicDropDownShow: boolean = false;
  private mortageDropDownShow: boolean = false;
  private mortgageNumber: string = "";
  private topic: CodeValue | null = null;
  private message: string = "";
  private attachments: any[] = [];
  private attachmentFileNames: string[] = [];
  private placeholder: TranslateResult | null = null;
  private confirmation: boolean = false;
  private error: string   | null = null;
  private loading: boolean = false;
  private loggedIn: boolean = false;
  private tenantName: string;
  private initialLoad = false;
  private chequeImage: string = "";
  private annualImage: string = "";
  private accountInfoImage: string = "";
  private letterImage: string = "";
  private email: string = "";
  private name: string = "";
  private showMortgageAccountInfo = false;
  private totalFileSize = 0;
  private isUpdateFireInsurance = false;
  private fromFireInsurance = false;
  private inputs: { emailActive: boolean; mortgageNumberActive: boolean; nameActive: boolean } = {
    emailActive: false,
    mortgageNumberActive: false,
    nameActive: false,
  };
  private recaptchaResponse = "";

  get mortgageSummaries(): MortgageSummary[] {
    return this.$store.state.mortgageSummaries;
  }

  get topics(): CodeValue[] {
    return this.$store.state.messageTopics;
  }

  get isFrench(): boolean {
    return this.$store.state.locale === "fr";
  }

  get recaptchaLanguage(): string {
    return this.$store.state.locale === "fr" ? "fr-CA" : "en-GB";
  }

  get messageIsTopicFix(): boolean {
    return this.$store.state.MessageIsTopicFix;
  }

  get messageIsMortgageNumberFix(): boolean {
    return this.$store.state.MessageIsMortgageNumberFix;
  }

  get messageAttachmentMandatory(): boolean {
    return this.$store.state.MessageAttachmentMandatory;
  }

  private created() {
    this.tenantName = this.$appConfig.prefix + this.$store.state.tenantName;
    this.confirmation = false;
    this.placeholder = this.$t("Message.Placeholder");
    this.chequeImage = this.$store.state.locale === "fr" ? `${this.$appConfig.getUrl()}assets/images/cheque_Fr.png` : `${this.$appConfig.getUrl()}assets/images/cheque_En.png`;
    this.annualImage = this.$store.state.locale === "fr" ? `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/annual-fr.png` : `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/annual-en.png`;
    this.accountInfoImage =
            this.$store.state.locale === "fr" ? `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/account-info-fr.png` : `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/account-info-en.png`;
    this.letterImage = this.$store.state.locale === "fr" ? `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/letter-fr.png` : `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/registration/letter-en.png`;
  }

  private initialize() {
    this.showMortgageAccountInfo = false;
    this.topicDropDownShow = false;
    this.mortageDropDownShow = false;
    this.mortgageNumber = "";
    this.topic = null;
    this.message = "";
    this.email = "";
    this.attachments = [];
    this.attachmentFileNames = [];
    this.confirmation = false;
    this.error = "";
    this.loading = false;
    this.recaptchaResponse = "";
    this.name = "";
    this.isUpdateFireInsurance = false;
  }

  private topicDropDown() {
    if (!this.messageIsTopicFix) {
      this.topicDropDownShow = !this.topicDropDownShow;
      this.mortageDropDownShow = false;
    }
  }

  private isMortgageValid(): boolean {
    return !!isMortgageNumberValid(this.mortgageNumber);
  }

  @Watch("isOpen")
  private async onIsOpenChanged(newValue: boolean, oldValue: boolean) {
    this.initialize();
    this.initialLoad = true;
    this.loading = true;
    this.loggedIn = !!this.$store.state.user;
    this.totalFileSize = 0;
    this.fromFireInsurance = false;
    if (newValue) {
      if ((!this.mortgageSummaries || this.mortgageSummaries.length === 0) && this.loggedIn) {
        const response = await this.getMortgageSummaries();
        if (!response.isSuccess) {
          this.error = response.error;
          this.initialLoad = false;
        }
      }
      if (!this.topics || this.topics.length === 0) {
        const response = await this.getTopics();
        if (!response.isSuccess) {
          this.error = response.error;
          this.initialLoad = false;
        }
      }
    }
    if (this.$store.state.MessageTopic !== "") {
      if (this.$store.state.MessageTopic === "Home/Fire Insurance") {
        this.fromFireInsurance = true;
      }
      this.topic = this.topics.find((t: any) => t.englishText === this.$store.state.MessageTopic) || null;
      this.$store.state.MessageTopic = "";
    }
    this.setEmail();
    this.setMortgageNumber();
    this.loading = false;
  }

  private setEmail() {
    if (this.$store.state.user) {
      this.email = this.$store?.state?.user?.email;
    }
  }

  private setMortgageNumber() {
    if (this.$store.state.MessageMortgageNumber !== 0) {
      this.mortgageNumber = this.$store.state.MessageMortgageNumber;
      this.$store.state.MessageMortgageNumber = 0;
      return;
    }
    if (this.mortgageSummaries.length === 1) {
      this.mortgageNumber = this.mortgageSummaries[0].mortgageNumber;
    }
  }

  private mortgageDropDown() {
    if (!this.messageIsMortgageNumberFix) {
      this.mortageDropDownShow = !this.mortageDropDownShow;
      if (this.topicDropDownShow) {
        this.topicDropDownShow = false;
      }
    }
  }

  private closeAll() {
    this.toggleMessage();
    if (this.isUpdateFireInsurance) {
      location.reload();
    }
    // Phase 2 this.toggleContactUs();
  }

  private checkIfFileExits(filename: string) {
    return this.attachmentFileNames.indexOf(filename) !== -1;
  }

  private checkFileType(fileType: string) {
    //  jpg, png, gif, rft, txt, docx, doc, pdf
    const validTypes = [
      "image/png",
      "text/plain",
      "image/jpeg",
      "image/gif",
      "text/rft",
      "text/docx",
      "text/doc",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
      "application/msword",
    ];
    return validTypes.indexOf(fileType) !== -1;
  }

  private areTotalAttachmentsGreaterThanSeven(numberOfAttachments: any) {
    return numberOfAttachments + 1 > 7 ? true : false;
  }

  private isFileSizeZero(fileSize: any) {
    return fileSize <= 0 ? true : false;
  }

  private bytesToSize(file: any) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let unit = 0;
    let fileSize = parseInt(file.size, 10) || 0;
    while (fileSize >= 1024 && ++unit) {
      fileSize = fileSize / 1024;
    }
    return (fileSize.toFixed(fileSize < 10 && unit > 0 ? 1 : 0) + " " + units[unit]);
  }

  private attachFile(e: any) {
    this.confirmation = false;
    this.error = null;
    this.loading = true;
    const attachedFiles = (document.getElementById("file-upload") as any)!.files;

    Array.from(attachedFiles).forEach((file: any) => {
      if (this.verifyFileAttachments(file)) {
        this.totalFileSize = this.totalFileSize + file.size;
        this.attachments.push(file);
        this.attachmentFileNames.push(file.name);
      }
    });

    this.loading = false;
    e.currentTarget.value = null;
  }

  private verifyFileAttachments(file: any) {

    if (this.areTotalAttachmentsGreaterThanSeven(this.attachments.length)) {
      this.error = this.$t("Message.AttachmentError");
      return false;
    }

    if (this.checkIfFileExits(file.name)) {
      this.error = this.$t("Message.DoubleFileError");
      return false;
    }

    if (file.name.length > config.fileNameMaxLength) {
      this.error = this.$t("Message.FileNameTooLong");
      return false;
    }

    if (this.isFileSizeZero(file.size)) {
      this.error = this.$t("Message.EmptyFileError");
      return false;
    }

    if (!this.checkFileType(file.type)) {
      this.error = this.$t("Message.FileTypeError");
      return false;
    }

    if (this.totalFileSize + file.size > 20000000) {
      this.error = this.$t("Message.FileSizeError");
      return false;
    }

    return true;
  }

  private topicClick(topic: CodeValue) {
    this.topic = topic;
    this.confirmation = false;
  }

  private removeAttachment(id: number) {
    this.totalFileSize = this.totalFileSize - this.attachments[id].size;
    delete this.attachments[id];
    delete this.attachmentFileNames[id];
  }
  private mortageClick(index: number) {
    this.mortgageNumber = this.mortgageSummaries[index].mortgageNumber;
    this.confirmation = false;
  }

  private toBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.slice((reader.result as any).indexOf(",") + 1));
      reader.onerror = (error) => reject(error);
    });
  }

  private clearError() {
    this.error = null;
  }

  private showError(error: any, response?: any) {
    if (!error) {
      this.error = null;
    }
    this.error = this.$te(error)
      ? this.$t(error, { ...this.$appConfig, ...response })
      : this.$t(errorService.UnknownError, { ...this.$appConfig, ...response });
  }

  private isValidMessageContent() {

    if (!this.topic) {
      this.showError(TopicRequiredErrorCode);
      return false;
    }

    if (!this.mortgageNumber) {
      this.showError(MortgageNumberRequiredErrorCode);
      return false;
    }

    if (!this.isMortgageValid()) {
      this.showError(MortgageNumberInvalidErrorCode);
      return false;
    }

    if (!this.loggedIn && !this.name) {
      this.showError(NameRequiredErrorCode);
      return false;
    }

    if (!this.email) {
      this.showError(EmailRequiredErrorCode);
      return false;
    }

    if (!this.message) {
      this.showError(MessageRequiredErrorCode);
      return false;
    }

    if (this.messageAttachmentMandatory && this.attachments.length === 0) {
      this.showError(AttachmentErrorCode);
      return false;
    }

    if (!this.recaptchaResponse) {
      this.showError(RecaptchaInvalidErrorCode);
      return false;
    }

    return true;
  }

  private async send() {
    if (!this.isValidMessageContent()) {
      if (!this.error) {
        this.error = this.$t("Message.FieldError");
      }
      return;
    }

    this.clearError();
    const fileData = [];
    for (const attachment of this.attachments) {
      const encodedData = await this.toBase64(attachment);
      fileData.push({ name: attachment.name, type: attachment.type, data: encodedData });
    }
    const payload = {
      topic: this.topic.value,
      email: this.email,
      message: this.message,
      name: this.name,
      files: fileData,
      recaptcha: this.recaptchaResponse,
    };
    try {
      this.loading = true;

      const response = await userService.sendMessage(this.mortgageNumber, this.$store.state.locale, payload);
      this.loading = false;
      if (!response.isSuccess) {
        this.error = errorService.getErrorText(response.error);
        return;
      }
      if (this.topic) {
        this.isUpdateFireInsurance = this.topic.englishText === "Home/Fire Insurance" && this.attachments.length > 0;
        if (this.isUpdateFireInsurance) {
          this.setFireInsuruacne(true);
        }
      }
      //  show a confirmation
      this.confirmation = true;
      //  and clear item
      this.topic = null;
      this.mortgageNumber = "";
      this.message = "";
      this.attachments = [];
      this.onExpired(); // Reset recaptcha
      this.email = "";
      this.name = "";
      this.setMortgageNumber();
      this.setEmail();
    } catch (error) {
      this.error = errorService.getErrorTextByResponse(error);
      this.loading = false;
    }
  }

  private addActiveInput(id: string) {
    this.inputs = {
      emailActive: id === "email",
      mortgageNumberActive: id === "mortgageNumber",
      nameActive: id === "name",
    };
  }

  private removeActiveInput() {
    this.inputs = {
      emailActive: false,
      mortgageNumberActive: false,
      nameActive: false,
    };
  }

  @Watch("topic")
  private onTopicChanged() {
    if (this.topic && this.error === errorService.getErrorText(TopicRequiredErrorCode)) {
      this.clearError();
    }
  }

  @Watch("mortgageNumber")
  private onMortgageNumberChanged() {
    if (this.mortgageNumber && this.error === errorService.getErrorText(MortgageNumberRequiredErrorCode)) {
      this.clearError();
    } else if (this.mortgageNumber && this.isMortgageValid() && this.error === errorService.getErrorText(MortgageNumberInvalidErrorCode)) {
      this.clearError();
    }
  }

  @Watch("message")
  private onMessageChanged() {
    if (this.message && this.error === errorService.getErrorText(MessageRequiredErrorCode)) {
      this.clearError();
    }
  }

  @Watch("name")
  private onNameChanged() {
    if (this.name && this.error === errorService.getErrorText(NameRequiredErrorCode)) {
      this.clearError();
    }
  }

  @Watch("email")
  private onEmailChanged() {
    if (this.email && this.error === errorService.getErrorText(EmailRequiredErrorCode)) {
      this.clearError();
    }
  }

  private onVerify(response: any) {
    this.recaptchaResponse = response;
    if (this.error === errorService.getErrorText("BPE8021")) {
      this.clearError();
    }
  }

  private onExpired() {
    (this.$refs.recaptcha as any).reset();
    this.onVerify(null);
  }

  private toggleMortgageAccountInfo() {
    this.showMortgageAccountInfo = !this.showMortgageAccountInfo;
  }

  private imageLoadError(e: any) {
    e.target.style.display = "none";
  }
}
export default toNative(Message)
</script>


<style lang="scss" scoped>


    .slider {
        width: 448px;
        position: fixed;
        z-index: 200;
        right: -100%;
        transition: 0.5s;
        background: white;
        box-shadow: -3px 3px 6px rgba(black, 0.16);
        max-width: 85%;
        border-top: 1px solid #eeeeee;
        @media (max-width: $media--ipad) {
            width: 400px;
        }
        &.open {
            position: absolute;
            right: 0;
        }
        h2 {
            margin: 0;
            font-size: 30px;
            font-weight: var(--font--light);
            display: flex;
            align-items: center;
            padding: 20px 15px;
            border-bottom: 2px solid #eeeeee;
            svg {
                transform: scale(1.35);
                margin-right: 15px;
                cursor: pointer;
            }
        }
        ul {
            list-style: none;
            // to make the dropdown appear over top
            z-index: 10;
            position: absolute;
            background: white;
            width: 100%;
        }
        svg#error {
            vertical-align: middle;
            height: 15px;
            cursor: pointer;
        }
    }
    textarea {
        height: 150px;
        width: 100%;
        margin: 10px auto;
        border: none;
        resize: none;
    }
    textarea:focus {
        outline-color: rgba(128, 128, 128, 0.644);
    }
    .send {
        width: 220px;
        margin: auto;
        margin-top: 25px;
    }
    label {
        color: var(--color--primary);
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        cursor: pointer;
    }

    .error {
        margin-left: 3px;
        margin-right: 3px;
        width: initial;
    }

    .messageContainer {
        margin: 20px;
        min-height: 100%;
        .info {
            color: black;
            font-weight: bold;
            font-size: 18px;
            margin-left: 5px;
        }
        .captcha {
            margin-top: 20px;
        }
        .row {
            display: flex;
            p {
                margin-left: 10px;
            }
            div {
                width: 250px;
                flex-wrap: wrap;
                display: flex;
                padding: 5px;
            }
        }
        .confirmation {
            text-align: center;
            margin-top: 15px;
            color: var(--color--primary--label);
            font-weight: bold;
            b {
                color: var(--color--primary--label);
                margin-left: 4px;
                margin-right: 4px;
            }
        }
        .characterLimit {
            text-align: right;
        }
        .characterLimitReached {
            color: red;
        }
        .drop {
            margin-bottom: 15px;
        }
        .hidden {
            color: white;
            border: none;
            height: 1.5px;
        }
        .dropdownItems {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        }
        hr {
            opacity: 0.5;
        }
        .input-container {
            margin-top: 30px;
            input {
                margin-top: 10px;
            }
            .input-icon {
                position: absolute;
                right: 10px;
                top: 0px;
                svg {
                    transform: scale(1.35);
                    cursor: pointer;
                }
            }
            .info-box {
                position: absolute;
                top: 34px;
                left: 0;
                right: 0;
                border-radius: 6px;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                box-shadow: 0px 3px 6px rgba(black, 0.1);
                p {
                    color: white;
                    font-size: 13px;
                }

                @media (max-width: 600px) {
                    width: unset;
                }
                .info-imgs {
                    display: flex;
                    flex-direction: column;
                    @media (max-width: 600px) {
                        flex-flow: column;
                        margin-bottom: 0px;
                        overflow: auto;
                        height: 250px;
                    }
                    img {
                        margin-right: 10px;
                        margin-bottom: 5px;
                        margin-top: 5px;
                        width: 250px;
                    }
                }
            }
        }
        .previewDrop {
            display: flex;
            position: relative;
            padding: 5px;
            cursor: pointer;
            .dropNotSelected {
                color: var(--color--primary--label);
            }
            p {
                color: var(--color--primary--label);
                font-weight: 500;
                size: 16px;
            }
            .black {
                position: absolute;
                right: 0;
            }
        }
        li {
            cursor: pointer;
            padding: 5px 25px;
            &:hover {
                background: var(--color--primary);
                color: white;
            }
        }
        textarea {
            height: 150px;
            width: 100%;
            margin: 10px auto;
            padding: 4px;
            resize: none;
            border: 1px solid black;
        }
        textarea:focus {
            outline-color: rgba(128, 128, 128, 0.644);
        }
        .send {
            width: 220px;
            margin: auto;
            margin-top: 25px;
        }
        label {
            color: var(--color--primary--label);
            font-weight: 500;
            font-size: 20px;
            @media(max-width: $media--iphone-plus) {
                font-size: 17px;
            }
        }
        input[type="file"] {
            display: none;
        }
        form {
            div {
                margin: auto;
                width: 300px;
                margin-top: 20px;
            }
        }
    }

</style>

