<template>
    <div class="notifications" :class="{open: isOpen}">
        <h2><div @click="toggleNotifications"><ArrowLeftIcon /></div> {{ $t("Notifications") }}</h2>
        <ul>
            <li v-for="(n, i) in notifications" :key="i">
                <span><ClockIcon class="dark"/> {{ n.date }}</span>
                <p>{{ n.title }}</p>
                <div v-if="n.content" v-html="n.content" class="content"></div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";

import { Notification, User } from "@/store/types/user";

import ArrowLeftIcon from "@/components/Icons/LeftArrowIcon.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";

@Component({
  components: {
    ArrowLeftIcon,
    ClockIcon,
  },
})

class Notifications extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Prop() private isOpen!: boolean;
  @Prop() private toggleNotifications!: () => void;
  @Prop() private user!: User;

  private notifications: Notification[];

  private created() {
    this.notifications = this.user.notifications ? this.user.notifications : [];
  }
}
export default toNative(Notifications)
</script>

<style lang="scss">


    .notifications {
        width: 448px;
        height: 100vh;
        position: fixed;
        z-index: 200;
        right: -100%;
        transition: .5s;
        top: 80px;
        background: white;
        box-shadow: -3px 3px 6px rgba(black, .16);
        max-width: 80%;
        max-height: calc(100vh - 80px);
        overflow: auto;
        border-top: 1px solid #eeeeee;
        @media(max-width: $media--ipad) {
            width: 400px;
            max-height: 100vh;
            top: 0;
        }
        &.open {
            right: 0;
        }
        h2 {
            margin: 0;
            font-size: 30px;
            font-weight: var(--font--light);
            display: flex;
            align-items: center;
            padding: 20px 15px;
            border-bottom: 2px solid #eeeeee;
            svg {
                transform:  scale(1.35);
                margin-right: 15px;
                cursor: pointer;
            }
        }
        ul {
            list-style: none;
            li {
                display: flex;
                flex-flow: column;
                padding: 15px 25px;
                border-bottom: 1px solid #E1E1E1;
                span {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: color-mix(in srgb,var(--color--grey--dark),#0000 25%);
                    font-weight: var(--font--medium);
                    margin-bottom: 8px;
                    svg {
                        transform: scale(1.3);
                        margin-right: 7px;
                    }
                }
                p {
                    font-weight: var(--font--medium);
                    color: black;
                }
                .content {
                    background: var(--color--primary);
                    color: white;
                    border-radius: 4px;
                    margin-top: 10px;
                    padding: 12px 15px;
                    h3 {
                        color: white;
                        font-weight: var(--font--bold);
                        margin-bottom: 15px;
                    }
                    p {
                        color: white;
                        font-size: 14px;
                    }
                    .button {
                        margin-left: auto;
                        width: 150px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
</style>