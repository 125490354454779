import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#1ab1ff" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#1d2675" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
    { name: "Magenta", value: "#ff0098"}
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: true,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: true,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: null,
      privacy: "https://www.merixfinancial.com/privacy-policy",
      security: null,
      forms: null,
      licences: "https://www.merixfinancial.com/about/brokerage-licenses/",
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: null,
    },
    fr: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: null,
      privacy: "https://www.merixfinancial.com/fr/privacy-policy",
      security: null,
      forms: null,
      licences: "https://www.merixfinancial.com/fr/a-propos/licences-de-courtage/",
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: null,
    },
  },

  footerbottom: "",
};
