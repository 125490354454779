<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="8.463" height="14.847" viewBox="0 0 8.463 14.847">
    <g id="arrow-point-to-right" transform="translate(-97.139 0)" opacity="1">
        <path id="Path_4314" data-name="Path 4314" d="M105.3,8.158l-6.384,6.384a1.04,1.04,0,0,1-1.47-1.47l5.649-5.649L97.443,1.775A1.04,1.04,0,0,1,98.914.3L105.3,6.688a1.04,1.04,0,0,1,0,1.47Z"/>
    </g>
    </svg>
</template>

<script>
export default {
  name: "ArrowRightIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        &.white {
            path {
                fill: white;
            }
        }
    }
</style>