<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="6.703" height="3.914" viewBox="0 0 6.703 3.914">
        <path id="Path_10" data-name="Path 10" d="M-6.539-3.75a.542.542,0,0,0-.4.164.542.542,0,0,0-.164.4.542.542,0,0,0,.164.4L-4.148,0a.542.542,0,0,0,.4.164A.542.542,0,0,0-3.352,0L-.563-2.789a.542.542,0,0,0,.164-.4.542.542,0,0,0-.164-.4.542.542,0,0,0-.4-.164Z" transform="translate(7.102 3.75)" fill="#e3e3e4" opacity="1"/>
    </svg>
</template>

<script>
export default {
  name: "DropdownIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: white;
        }
        &.black {
            path {
                fill: var(--mortgage-details-actions-more-text);
            }
        }
        &.dropdown__icon {
            path {
                fill: var(--color--menu-text);
            }
        }
    }
</style>