<template>
    <div v-if="$appConfig.isBilingual"  :class="['lang__wrapper', mobile ? 'lang__wrapper__mobile' : '']">
        <div class="lang" :class="{ active: lang === `en` }" @click="selectLang(`en`)">EN</div>
        <div class="lang" :class="{ active: lang === `fr` }" @click="selectLang(`fr`)">FR</div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { CookieService } from "@/services/cookieService";

@Component({
  components: {},
})
class LanguageToggle extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Prop() private mobile!: boolean;
  private lang: string = "en";

  private created() {
    this.lang = this.$store.state.locale ? this.$store.state.locale : "en";
  }

  private selectLang(lang: string) {
    const frLink: string = this.$appConfig.frenchUrl;
    const mainLink: string = this.$appConfig.secondaryUrl;

    // CookieService.setCookie("lang", lang, { path : this.$appConfig.publicPath });
    CookieService.setCookie("lang", lang);

    if (frLink &&
            mainLink &&
            frLink !== mainLink &&
            !this.$store.state.user) {

      // const referenceAddress: string = `/login?ref=${location.pathname}`;
      // const extraPath = (this.$store.state.user) ? referenceAddress : publicAddress;

      // CookieService.setCookie("lang", lang);

      const publicAddress: string = location.pathname;

      if (lang === "fr") {
          if (publicAddress === "/login") {
              window.open(frLink, "_self");
          } else{
              window.open(frLink + publicAddress, "_self");
          }
        
      } else if (lang === "en") {
        window.open(mainLink + publicAddress, "_self");
      }
    } else {
      if (this.$route.query.lang) {
        window.location.href = window.location.href.split("?")[0];
      } else {
        location.reload();
      }
    }
  }
}
export default toNative(LanguageToggle)
</script>

<style lang="scss">


.lang__wrapper {
    height: 45px;
    width: 85px;
    border-radius: 80px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 3.5px;

    background: #fff;
    // background: var(--color--light--background);

    // @media(max-width: $media--ipad-pro) {
    //     display: none;
    // }

    @media (max-width: $media--iphone) {
        margin-top: 15px;
    }

    &__mobile {
        // -webkit-box-align: center;
        // -webkit-box-pack: justify;
        // justify-content: center;
    }
}

.lang {
    height: 37px;
    width: 37px;
    border-radius: 100%;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    font-weight: var(--font--medium);
    color: var(--color--primary);
    cursor: pointer;

    &:hover {
        border: 1px solid var(--color--primary);
    }

    &.active {
        pointer-events: none;
        cursor: default;
        color: white;
        background: var(--color--primary);
    }
}
</style>