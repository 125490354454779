import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "glossary-tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InforIconPrimary = _resolveComponent("InforIconPrimary")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _withDirectives(_createVNode(_component_InforIconPrimary, {
      class: _normalizeClass({ white: _ctx.white }),
      style: {"outline":"none"},
      id: "product-info-icon"
    }, null, 8, ["class"]), [
      [
        _directive_tooltip,
        _ctx.description,
        void 0,
        { "top-center": true }
      ]
    ])
  ]))
}