import http from "@/services/httpService";
import errorService from "@/services/errorService";

const versionNumber = 1;
export interface Response {
  isSuccess: boolean;
  error?: any;
  value?: any;
  errorValue?: any;
  isCatchError?: boolean;
}

const controllerWithUniqueReturns = ["SkipAPayment/eligibility", "SkipAPayment/report", "skipAPayment/paymentDetails", "accountRecovery/sendUsername", "accountRecovery/sendUsernameAndPasswordReset", "accountRecovery/resetPassword", "Users/sweepstakesURL"];
class APIService {
  public GET = async (controller: string, parameters: string[] , body?: any ): Promise<Response> => {
    try {
      const { data } = await http.get<any>(`/api/v${versionNumber}/${controller}${this.createParameterString(parameters)}`, body);

      if (controllerWithUniqueReturns.indexOf(controller) > -1) {
        return data;
      }
      if (data.error || data.isSuccess === false) {
        return { isSuccess: false, error: data.error };
      }
      return { isSuccess: true, value: data };
    } catch (error) {
      return { isSuccess: false, isCatchError: true, errorValue: error, error: errorService.getErrorTextByResponse(error.response)};
    }
  }

  public GETFILE =  (controller: string, parameters: string[] , body?: any ) => {
    http.get(`/api/v${versionNumber}/${controller}${this.createParameterString(parameters)}`, {
      responseType: "blob",
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = response.headers["content-disposition"].split("filename=")[1].split(";")[0];
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  }

  // this can be removed if we don't do outside calls, as of right now the only one is for the google map street view on mortgage details page
  public customGET = async (url: string, parameters: string[] , body?: any ): Promise<Response> => {
    try {
      const { data } = await http.get<any>(`${url}${this.createParameterString(parameters)}`, body);
      return { isSuccess: true, value: data };
    } catch (error) {
      return { isSuccess: false, error: errorService.getErrorTextByResponse(error.response)};
    }
  }

  public POST = async (controller: string, parameters: string[], body: any, options?: any): Promise<Response> => {
    try {
      const { data } = await http.post<any>(
        `/api/v${versionNumber}/${controller}${this.createParameterString(parameters)}`,
        body,
        options,
      );
      if (data) {
        const isSuccess = data.isSuccess !== undefined ? data.isSuccess : data.errorCode === null;
        if (!isSuccess && controllerWithUniqueReturns.indexOf(controller) > -1) {
          return data;
        }
        if (!isSuccess) {
          return { isSuccess: false, error:  errorService.getErrorText(data.errorCode) , value: data };
        }
      }
      return { isSuccess: true, value: data };
    } catch (error) {
      return { isSuccess: false, isCatchError: true, errorValue: error, error: errorService.getErrorTextByResponse(error.response)};
    }
  }

  // this can be removed if the /impersonate has a api version number
  public customPOST = async (url: string, parameters: string[] , body?: any ): Promise<Response> => {
    try {
      const { data } = await http.post<any>(`${url}${this.createParameterString(parameters)}`, body);
      if (data && !data.isSuccess) {
        return { isSuccess: false, error:  errorService.getErrorText(data.errorCode) };
      }
      return { isSuccess: true, value: data };
    } catch (error) {
      return { isSuccess: false,  isCatchError: true, errorValue: error, error: errorService.getErrorTextByResponse(error.response)};
    }
  }

  public  PUT  = async (controller: string, parameters: string[], body: any, options?: any): Promise<any> => {
    try {
      const { data } = await http.put<any>(
        `/api/v${versionNumber}/${controller}${this.createParameterString(parameters)}`,
        body,
        options,
      );
      if (data && !data.isSuccess ) {
        return { isSuccess: false, error: data.error};
      }
      return { isSuccess: true };
    } catch (error) {
      if (controllerWithUniqueReturns.indexOf(controller) > -1) {
        return { isSuccess: false,  errorValue: error};
      }
      return { isSuccess: false, error: errorService.getResponseError(error.response, controller), errorValue: error};
    }
  }

  public DELETE = async (controller: string, parameters: string[]): Promise<Response> => {
    try {
      await http.delete(`/api/v${versionNumber}/${controller}${this.createParameterString(parameters)}`);
      return { isSuccess: true };
    } catch (error) {
      return { isSuccess: false, error: errorService.getErrorTextByResponse(error.response)};
    }
  }

  public createParameterString(parameters: any) {
    let parmString = "";
    parameters.forEach((param: string) => {
      parmString += "/" + param;
    });
    return parmString;
  }

}

export default new APIService();
