import moment from "moment";
import { createObjectCsvStringifier } from "csv-writer";
import apiService from "./apiService";
import { HomeAppliance, HomeSystem, MLI } from "@/store/mockData/campaignQuiz";
import { QuizData } from "@/store/types/quizData";
import mortgageService from "./mortgageService";

const mortgageNumberRegex = /^(\d{7})\.[0-9a-zA-Z]\s*$/;

const decodeHTML = (html: string) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

const getOfferTypeFromUrl = (url: string) => {
  return url.split("/")[url.split("/").length - 2];
};

export const setPasswordString = (password: any) => {
  let amount = 0;
  amount = amount + (password.length >= 8 ? 1 : 0);
  amount = amount + (hasNumber(password) ? 1 : 0);
  amount = amount + (hasSymbol(password) ? 1 : 0);
  amount = amount + (!hasSpace(password) ? 1 : 0);
  amount = amount + (hasAlphaNumericCharacter(password) ? 1 : 0);
  return amount;
};

const hasNumber = (value: any) => {
  return /\d/.test(value);
};

const hasSymbol = (value: any) => {
  const format = /[!@#$%^&*]/;
  return format.test(value);
};

const hasAlphaNumericCharacter = (value: any) => {
  const format = /[a-zA-Z]/;
  return format.test(value);
};

const hasSpace = (value: any) => {
  return / /.test(value);
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const checkValidUserName = (userName: string) => {
  // no spaces
  // no  =+#%^)(\/?><][}{|;:~`&®"
  const format = /[ `#%^&®()+\=\[\]{};':"\\|,<>\/?~]/;
  if (userName.indexOf(" ") >= 0 || format.test(userName)) {
    return false;
  }
  return true;
};

export const selectOptionFromUtil = (e: any, name: string) => {
  /* Check this */
  document.querySelectorAll("#options .checkbox").forEach((c: any, i: number) => {
    c.classList.remove("active");
    c.parentNode.classList.remove("active");
    if (i + 1 === document.querySelectorAll("#options .checkbox").length) {
      e.currentTarget.classList.add("active");
      e.currentTarget.parentNode.classList.add("active");
      // this.option = name;
    }
  });
};

export const checkForDuplicateSecurityQuestions = (questions: any) => {
  let isDup = false;
  questions.map((q: any, index: any) => {
    const tempIndex = questions.map((e: any) => e.question.questionNumber).indexOf(q.question.questionNumber);
    if (!isDup && tempIndex !== index) {
      isDup = true;
    }
  });
  return isDup;
};

export const isValidAnswer = (answer: string) => {
  if (answer.length < 3 || /^\s|\s$/.test(answer)) {
    return false;
  }
  return true;
};


export const checkIfValidSecurityAnswer = (questions: any) => {
  let isValid = true;
  questions.map((q: any) => {
    if (q.question.question !== "" && !isValidAnswer(q.answer)) {
      isValid = false;
    }
  });
  return isValid;
};

export const getSubCategoryForHelp = (faq: any, searchText: any) => {
  const temp: any[] = [];
  /* Check this */
  faq.forEach((category: any, j: number) => {
    category.subCategories.forEach((subCat: any) => {
      subCat.questions.forEach((q: any, i: number) => {
        if (q.title.toLowerCase().includes(searchText.toLowerCase())) {
          q.fullurl = category.url + "/" + subCat.url + "/" + q.url;
          q.subCatIndex = j;
          q.index = i;
          temp.push(q);
        }
      });
    });
  });
  return temp;
};

export const getDateRanges = (dates: any) => {
  const range: any[] = [];
  let currentPair: { start?: Date; end?: Date };
  dates.forEach((date: any) => {
    const isDateObject = typeof date === "object";
    const momentDate = moment(isDateObject ? date.date : date);
    const yesterday = moment(isDateObject ? date.date : date).subtract(1, "day");
    if (!currentPair || !moment(currentPair.end).isSame(yesterday)) {
      currentPair = {};
      currentPair.start = isDateObject ? date.date : date;
      currentPair.end = isDateObject ? date.date : date;
      range.push(currentPair);
    } else {
      currentPair.end = isDateObject ? date.date : date;
    }
  });
  return range;
};

export const createCSV = (headers: any, mappingObj: any, fileName: any) => {
  const csvStringifier: any = createObjectCsvStringifier({
    header: headers,
  });
  let csvContent = csvStringifier.getHeaderString();
  csvContent += csvStringifier.stringifyRecords(
    mappingObj.map((a: any) => {
      const result: any = {};
      headers.forEach((h: any) => (result[h.id] = h.decorator(a[h.id], a)));
      return result;
    }),
  );
  return csvContent;
};

export const getTenants = (): any => {
  return apiService.GET("tenants", []);
};

export const isMortgageNumberValid = (mortgageNumber: any): any => {
  return mortgageNumber.match(mortgageNumberRegex);
};

export const isValidFullName = (name: string): any => {
  const nameRegex = /^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
  return nameRegex.test(name);
};

export const isValidMortgageDetailsForm = (mortgageAccountNumber: any, bankAccountNumber: any, lastName: any, recaptcha: any): any => {
  if (!mortgageAccountNumber) {
    return ("BPE3019");
  } else if (!isMortgageNumberValid(mortgageAccountNumber)) {
    return ("BPE3020");
  } else if (!bankAccountNumber) {
    return ("BPE7028");
  } else if (!bankAccountNumber.match(/^[0-9]{4}$/)) {
    return ("BPE7030");
  } else if (!lastName) {
    return ("BPE3022");
  } else if (!recaptcha) {
    return ("BPE8021");
  } else {
    return true;
  }
  return false;
};

export const getSubmitQuizParams = (numbers: any, quizResponseQueAns: any): any => {
  const array: any = [];
  numbers.forEach((n: any) => {
    const obj = {
      QuestionNumber: n,
      Answer: quizResponseQueAns[n - 1].options[
        quizResponseQueAns[n - 1].whichClicked
      ].text,
      AnswerId: quizResponseQueAns[n - 1].options[
        quizResponseQueAns[n - 1].whichClicked
      ].id,
    };
    array.push(obj);
  });
  return array;
};

export const UserHasOffer = (type: any, store: any) => {
  let offerType: string;
  if (store.state.offerAds.length === 0) {
    return false;
  }
  if (type === "MLI") {
    offerType = "offerHomeMortgageProtection";
  } else if (type === "HomeSystem" || type === "HomeAppliance") {
    offerType = "offerHomeWarranty";
  } else {
    return false;
  }
  return store.state.offerAds.find((offer: any) => offer.notificationText === offerType) !== undefined;

};

export const isQuizAvailable = (quizAvailableResponse: any) => {
  if (!quizAvailableResponse) {
    return false;
  }
  return quizAvailableResponse.value.hasUserCompletedQuiz !== 1;
};

export const isFormAvailable = (quizAvailableResponse: any) => {
  if (!quizAvailableResponse) {
    return false;
  }
  return quizAvailableResponse.value.hasUserCompletedQuiz === 1 && quizAvailableResponse.value.hasUserCompletedLead !== 1;
};

export const HWGetCorrectQuizForMortgage = async (type: string, mortgageNumber: string, locale: string) => {
  let correctHWType = type;
  let HWEnrollmentCost = 0;
  if (type.toLowerCase() === "homeappliance" || type.toLowerCase() === "homesystem") {
    const HWTypeResponse = await mortgageService.getHWCampaignType(
      mortgageNumber,
      `${locale}-CA`,
    );
    if (!HWTypeResponse.isSuccess) {
      return {correctType : "error"};
    }

    const HWType = HWTypeResponse.value.homeWarrantyType === "HA" ? "HomeAppliance"  : "HomeSystem";
    HWEnrollmentCost = HWTypeResponse.value.enrollmentCost;
    if  (HWType.toLowerCase() !== type.toLowerCase()) {
      correctHWType = HWType;
    }
  }
  return { correctType: correctHWType, enrollmentCost : HWEnrollmentCost };
};

export const  GetQuizData =  async (params: any, next: any, tnCVersion: number, prize: number, locale: string) => {
  let typeDetails = params.type.toLowerCase();
  if (params.type.toLowerCase() === "homesystem" || params.type.toLowerCase() === "homeappliance") {
    typeDetails = await HWGetCorrectQuizForMortgage(typeDetails, params.mortgageNumber, locale);
    if (typeDetails.correctType === "error") {
      next("/");
    }
  }
  let quizData: QuizData;
  if (typeDetails.correctType && typeDetails.correctType.toLowerCase() === "homeappliance") {
    quizData = HomeAppliance;
    quizData.enrollmentCost = typeDetails.enrollmentCost;
  } else if (typeDetails.correctType && typeDetails.correctType.toLowerCase() === "homesystem") {
    quizData = HomeSystem;
    quizData.enrollmentCost = typeDetails.enrollmentCost;
  } else if (typeDetails === "mli") {
    quizData = MLI;
  } else {
    next("/");
    return null;
  }
  quizData.TnCVersion = tnCVersion;
  quizData.prize = prize;
  quizData.mortgageNumber = params.mortgageNumber;
  return quizData;
};

export const getOfferTypeName = (offerAdsName: string): any => {
  let typeName = "";
  switch (offerAdsName) {
    case "offerHomeMortgageProtection":
      typeName = "MLI";
      break;
    case "offerHomeWarranty":
      typeName = "HW";
      break;
    default:
      typeName = offerAdsName;
  }
  return typeName;
};
export const enableBodyScrolling = () => {
  const body = document.body;
  body.style.position = "";
  body.style.height = "auto";
  body.style.overflowY = "auto";
};

export const disableBodyScrolling = () => {
  const body = document.body;
  body.style.position = "fixed";
  body.style.height = "100vh";
  body.style.overflowY = "hidden";
};
