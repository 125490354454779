<template>
  <div>
    <IEBannerMessage v-if="isIE" :closeBanner="closeBanner" :class="{
      bannerTop:
        $route.name === 'Help' ||
        $route.name === 'Sitemap' ||
        $route.name === 'ContactUs',
    }" />
    <header class="header" v-if="user" :class="{ moveDown: isIEBannerShowing && isIE }">
      <div class="left">
        <router-link :to="`/`" class="logo" :class="{ width90: isEclipse || isRMG }" id="header__logo">
          <BrandLogoInverted v-if="isRFA" :style="{ minWidth: logoSize }" />
          <BrandLogo v-else :style="{ minWidth: isFrench? logoSizeFr : logoSize }" />
        </router-link>
        <CsrSearch v-if="isCsr" />
      </div>
      <ul v-if="screenSize > mediaSizes.ipad && user" class="menu">
        <li v-if="isBilingual">
          <LanguageToggle />
        </li>
        <li id="header__menu__item--messages" @click="toggleMessaging" v-if="!isCsr"
          v-tooltip.top-center="$t('ContactUs')">
          <div class="header__menu__item__icon">
            <MailIcon />
          </div>
        </li>
        <li id="header__menu__item--question" v-tooltip.top-center="$t('Assistance')"
          v-if="!isCsr || $appConfig.code == 'MS' || $appConfig.code == 'RM'">
          <div class="icon">
            <router-link :to="`/help`" alt="help center">
              <span class="header__menu__item--question__badge" v-if="showNewVideoBadge">{{
                $t("EducationalVideos.SupportPage.New") }}</span>
              <QuestionIcon style="transform: scale(1.3)" />
              <span style="display: none">{{ $t("HowCanWeHelp") }}</span>
            </router-link>
          </div>
        </li>

        <!-- phase 2 <li
                id="header__menu__item--bell"
                @click="toggleNotifications"
                v-if="!isCsr"
                @mouseenter="showToolTip('notification')"
                @mouseleave="showToolTip('notification')"
            >
                <div class="icon">
                    <BellIcon style="transform: scale(1.25);" />
                </div>
            </li> -->

        <li id="header__menu__item--gear" v-tooltip.top-center="$t('Settings')">
          <div class="icon">
            <router-link :to="`/settings`" alt="$t('Settings')">
              <GearIcon style="transform: scale(1.25)" />
              <span style="display: none">{{ $t("Settings") }}</span>
            </router-link>
          </div>
        </li>
      </ul>
      <div v-if="screenSize > mediaSizes.ipad && user" :class="[{ open: profileIsOpen }, 'profile']" id="header__profile"
        @click="toggleProfileDropdown()">
        <div class="profile__top">
          <div class="profile__image" v-if="user.profileImage">
            <img aria-hidden="true" :src="user.profileImage" alt="Your Profile Picture" />
          </div>
          <div class="profile__text">
            <p>{{ user.firstName }} {{ user.lastName }}</p>
            <p>{{ user.type }}</p>
          </div>
          <DropdownIcon :class="{'dropdown__icon': !isRFA && !isICICI}" />
        </div>
        <ul>
          <li @click="signOut" style="cursor: pointer">{{ $t("SignOut") }}</li>
        </ul>
      </div>
      <div id="menu__toggle" v-if="screenSize <= mediaSizes.ipad" @click="toggleMobileMenu()">
        <MenuToggleIcon />
      </div>
      <div class="menu menu--mobile" v-if="screenSize <= mediaSizes.ipad && user" :class="{ open: mobileMenuIsOpen }">
        <div @click="toggleMobileMenu()" class="header__menu--mobile__close" id="header__menu--mobile__close">
          <LeftArrowIcon />
        </div>

        <div class="header__profile" id="header__profile">
          <div class="profile__top">
            <div class="profile__image" v-if="user.profileImage">
              <img aria-hidden="true" :src="user.profileImage" alt="Your Profile Picture" />
            </div>
            <div class="profile__text">
              <p>{{ user.firstName }} {{ user.lastName }}</p>
              <p>{{ user.type }}</p>
            </div>
          </div>
        </div>
        <ul>
          <li @click="toggleMobileMenu">
            <router-link :to="`/`">
              <HomeIcon class="mobile--menu" style="top: -3px; margin-right: 27px" />
              <p class="home">{{ $t("Home") }}</p>
            </router-link>
          </li>
          <li @click.prevent="openMessaging">
            <a
              class="active-item"
              href=""
              id="header__menu--mobile__item--messages"
            >
              <MailIcon class="mobile--menu" style="transform: scale(1.2); margin-right: 23px" />
              {{ $t("ContactUs") }}
            </a>
          </li>
          <!-- <li @click="toggleNotifications">  -- PHASE 2 PHASE 2 PHASE 2
                    <a href="" id="header__menu--mobile__item--bell">
                        <BellIcon style="transform: scale(1.4); left: 5px;" />
                        {{ $t("Notifications") }}
                    </a>
                </li> -->
          <li @click="toggleMobileMenu">
            <router-link
              :to="`/settings`"
              class="active-item"
              id="header__menu--mobile__item--gear"
            >
              <GearIcon class="mobile--menu" style="transform: scale(1.4)" />
              {{ $t("Settings") }}
            </router-link>
          </li>
          <li @click="toggleMobileMenu">
            <router-link
              :to="`/help`"
              class="active-item"
              id="header__menu--mobile__item--question"
            >
              <QuestionIcon class="mobile--menu" style="transform: scale(1.4)" />
              {{ $t("Help") }}
            </router-link>
          </li>
          <li>
            <a
              @click="signOut"
              class="active-item"
              id="header__menu--mobile__item--"
            >
              <LogoutIcon class="mobile--menu" />
              {{ $t("Logout") }}
            </a>
          </li>
          <li style="justify-content: left" v-if="isBilingual">
            <LanguageToggle />
          </li>
        </ul>
      </div>
    </header>
    <!-- <Notifications :isOpen="notificationsIsOpen" :toggleNotifications="toggleNotifications" :user="user" v-if="user"/> -->
    <!-- phase 2 <ContactUs  :isOpen="contactUsIsOpen" :toggleContactUs="toggleContactUs"  :toggleMessage="toggleMessaging" :user="user" /> -->
    <Message :isOpen="messageIsOpen" :toggleMessage="toggleMessaging" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { mapActions } from "vuex";

import { MediaSizes } from "@/store/types/media";
import { User } from "@/store/types/user";
import IEBannerMessage from "@/components/IEBannerMessage.vue";
import ToolTip from "@/components/ToolTip.vue";

import MailIcon from "@/components/Icons/MailIcon.vue";
import QuestionIcon from "@/components/Icons/QuestionIcon.vue";
import BellIcon from "@/components/Icons/BellIcon.vue";
import GearIcon from "@/components/Icons/GearIcon.vue";
import LogoutIcon from "@/components/Icons/LogoutIcon.vue";
import MenuToggleIcon from "@/components/Icons/MenuToggleIcon.vue";
import HomeIcon from "@/components/Icons/HomeIcon.vue";
import DropdownIcon from "@/components/Icons/DropdownIcon.vue";
import LeftArrowIcon from "@/components/Icons/LeftArrowIcon.vue";
import Notifications from "./Notifications.vue";
import ContactUs from "./ContactUs.vue";
import Message from "./Message.vue";
import BrandLogo from "@/components/BrandLogo.vue";
import BrandLogoInverted from "@/components/BrandLogoInverted.vue";
import ProfileIcon from "@/components/Icons/ProfileIcon.vue";
import LanguageToggle from "@/components/Header/LanguageToggle.vue";

import CsrSearch from "./CSRSearch.vue";


import { Action } from "vuex-facing-decorator";
import { CookieService } from "@/services/cookieService";
import { brand } from "@/assets/branding";

const { logoSize, logoSizeFr } = brand;
@Component({
  components: {
    LanguageToggle,
    MailIcon,
    QuestionIcon,
    BellIcon,
    GearIcon,
    MenuToggleIcon,
    LogoutIcon,
    HomeIcon,
    DropdownIcon,
    LeftArrowIcon,
    Notifications,
    ProfileIcon,
    CsrSearch,
    ContactUs,
    Message,
    BrandLogo,
    BrandLogoInverted,
    IEBannerMessage,
    ToolTip,
  },
})
class Header extends Vue {

  @Inject() $appConfig: any
  get isCsr(): boolean {
    return !!(this.$store?.state?.user?.isCsr)
  }

  public $store = useStore()
  get screenSize(): number {
    return this.$store.state.screenSize;
  }

  get user() {
    return this.$store.state.user;
  }

  get messageIsOpen() {
    window.scrollTo(0, 0);
    return this.$store.state.isContactUsOpen;
  }

  get showNewVideoBadge() {
    return (
      this.isLoggedIn &&
      (this.tenantName === "MCAP" || this.tenantName === "RMG") &&
      this.isVideoLibraryNewlyAdded(90)
    );
  }

  get isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE "); // IE 10 or older => return version number
    const trident = ua.indexOf("Trident/"); // IE 11 => return version number
    if (msie > 0 || trident > 0) {
      return true;
    }
    // other browser
    return false;
  }
  private mobileMenuIsOpen: boolean = false;
  private notificationsIsOpen: boolean = false;
  private contactUsIsOpen: boolean = false;
  private profileIsOpen: boolean = false;
  private tenantName: string = "";
  private tenantNamePrefix: string = "";
  private logoSize: string = logoSize;
  private logoSizeFr: string = logoSizeFr;
  private mediaSizes: MediaSizes;
  private isIEBannerShowing: boolean = true;
  private isBilingual: boolean = false;
  private language: string = "";
  private isLoggedIn: boolean = false;
  private logOutTimeStamp: any = "";
  private isEclipse: boolean = false;
  private isRMG: boolean = false;
  private isRFA: boolean = false;
  private isICICI: boolean = false;

  @Action("logout") private logout!: () => Promise<void>;
  @Action("toggleContactUs") private toggleContactUs!: (
    state: any
  ) => Promise<void>;

  private isVideoLibraryNewlyAdded(rangeInDays: number = 90): boolean {
    const startDateString = this.$appConfig.videoLibrary.launchDate;
    if (startDateString) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const todayDate = new Date();
      const startDate = new Date(startDateString);
      const todayDateUTC = Date.UTC(
        todayDate.getFullYear(),
        todayDate.getMonth(),
        todayDate.getDate()
      );
      const startDateUTC = Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      );
      const distance = Math.floor((todayDateUTC - startDateUTC) / _MS_PER_DAY);
      const result = distance <= rangeInDays;
      return result;
    }
    return false;
  }

  private created() {
    this.mediaSizes = this.$store.state.mediaSizes;
    this.isEclipse = this.$store.state.tenantName
      .toLowerCase()
      .includes("eclipse");
    this.isRMG = this.$store.state.tenantName.toLowerCase().includes("rmg");
    this.isRFA = this.$store.state.tenantName.toLowerCase().includes("rfa");
    this.isICICI = this.$store.state.tenantName.toLowerCase().includes("icici");
    this.mobileMenuIsOpen = false;
    this.notificationsIsOpen = false;
    this.contactUsIsOpen = false;
    this.profileIsOpen = false;
    this.tenantName = this.$store.state.tenantName || "MCAP";
    this.logoSize = logoSize;
    this.logoSizeFr = logoSizeFr;
    this.isBilingual = this.$appConfig.isBilingual;
    this.language = CookieService.getCookie("lang")
      ? CookieService.getCookie("lang")
      : "en";
    this.isLoggedIn = this.$store.state.user !== null;
    this.logOutTimeStamp = setTimeout(() => {
      if (this.isLoggedIn) {
        this.expiredSession();
      }
    }, 1800000);
    this.tenantName = this.$store.state.tenantName || "";
    this.tenantNamePrefix =
      this.$store.state.locale === "fr"
        ? this.$appConfig.prefixFr
        : this.$appConfig.prefix;
  }

  private closeBanner() {
    this.isIEBannerShowing = false;
  }

  private toggleProfileDropdown() {
    this.profileIsOpen = !this.profileIsOpen;
  }

  private toggleMobileMenu() {
    this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
    this.toggleContactUs({ isOpen: false });
  }

  private toggleNotifications() {
    this.notificationsIsOpen = !this.notificationsIsOpen;
  }

  /* phase 2 private toggleContactUs() {
                         this.contactUsIsOpen = !this.contactUsIsOpen;
                     }*/

  private openMessaging() {
    this.toggleMobileMenu();
    this.toggleContactUs({ isOpen: true });
  }

  private toggleMessaging() {
    this.toggleContactUs({ isOpen: !this.messageIsOpen });
  }

  private async signOut() {
    clearInterval(this.logOutTimeStamp);
    await this.logout();
    this.$router.push("/login");
  }

  private async expiredSession() {
    await this.logout();
    this.$router.push("/SessionExpired");
  }

  get isFrench(): boolean {
    return this.$store.state.locale === "fr";
  }
}
export default toNative(Header)
</script>

<style lang="scss" scoped>


.header {
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  background: var(--color--header-background);
  z-index: 9999;
  .logo {
    &.width90 {
      img {
        width: 90px;
      }
    }
    margin-right: auto;
    img {
      width: 110px;
    }
  }
  .left {
    display: flex;
    align-items: center;
  }
  #menu__toggle {
    cursor: pointer;
    margin-left: auto;
  }
  .menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: auto;
    height: 100%;
    li {
      .header__menu__item--question__badge {
        z-index: 1;
        position: absolute;
        top: -10px;
        right: -20px;
        padding: 3px 5px;
        line-height: 12px;
        font-size: 12px;
        background-color: #c30000;
        border-radius: 3px;
        color: #fff;
      }

      padding-right: 22px;
      padding-left: 22px;
      border-right: 1px solid var(--color--border);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:first-child {
        border-left: 1px solid var(--color--border);
      }
      &:last-child {
        margin-right: 22px;
      }
      &.hasNotification {
        padding-right: 30px;
      }
      img {
        width: 30px;
      }
      svg {
        transform: scale(1.15);
      }
      .notification {
        background: var(--color--notification);
        position: absolute;
        top: -15px;
        right: -20px;
        color: white;
        width: 17px;
        height: 17px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: var(--font--medium);
        &--mobile {
          right: initial;
          left: 32px;
          top: -10px;
        }
      }
    }
    &--mobile {
      top: 0;
      bottom: 0;
      position: fixed;
      right: -100%;
      background: var(--color--menu--mobile);
      z-index: 200;
      width: 400px;
      padding: 15px;
      display: block;
      transition: 0.5s;
      max-width: 80%;
      &.open {
        right: 0;
      }
      &__close {
        width: 32px;
        margin-top: 15px;
        cursor: pointer;
        margin-bottom: 30px;
      }
      .profile {
        &__top {
          margin-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 30px;
        }
        &__text {
          p {
            &:first-child {
              font-size: 22px;
              color: var(--color--header-text--mobile);
            }
          }
        }
      }
      ul {
        list-style: none;
        width: 100%;
        li {
          margin-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px;
          width: 100%;
          &:last-child {
            margin-bottom: 0px;
          }
          a {
            color: var(--color--menu-text);
            text-decoration: none;
            font-size: 22px;
            font-weight: var(--font--light);
            display: flex;
            align-items: center;
            width: 100%;
            svg {
              margin-right: 30px;
              transform: scale(1.1);
            }
            &.active-item {
              font-weight: var(--font--medium);
            }
          }
        }
      }
    }
  }
  .profile {
    &__top {
      padding-right: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        transform: scale(1.25);
      }
    }
    &__image {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      border-radius: 100%;
      padding: 2px;
      background: white;
      box-shadow: var(--shadow--normal);
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    &__text {
      margin-right: 35px;
      p {
        color: var(--color--header-text);
        &:first-child {
          font-size: 18px;
          font-weight: var(--font--medium);
          margin-bottom: 5px;
        }
      }
    }
    ul {
      list-style: none;
      display: none;
      li {
        font-weight: var(--font--medium);
        color: color-mix(in srgb,var(--color--primary),#0000 20%);
        text-transform: uppercase;
      }
    }
    &.open {
      top: 30px;
      box-shadow: var(--shadow--normal);
      z-index: 2;
      background: var(--color--header-background);
      padding: 7px 10px;
      margin-left: -20px;
      .profile__top {
        margin-bottom: 20px;
      }
      ul {
        display: block;
        li {
          color: var(--color--header-text);
        }
      }
      .dropdown__icon {
        transform: rotate(180deg) scale(1.25);
      }
    }
  }
  .info-box {
    position: absolute;
    z-index: 2000;
    top: 60px;
  }
  .home {
    color: var(--color--menu-text);
    font-size: 22px;
    font-weight: 500;
    &.active-item {
      font-weight: var(--font--medium);
    }
  }
}
.moveDown {
  top: 30px;
}
</style>
