<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="9.287" viewBox="0 0 24 9.287" role="img" aria-label="left arrow" >
        <g id="Group_862" data-name="Group 862" transform="translate(0 0)">
            <g id="Group_861" data-name="Group 861">
            <path id="Path_800" data-name="Path 800" d="M173.9,111.336l-4.033-3.98a.663.663,0,0,0-.931.945l2.882,2.844H150.763a.663.663,0,0,0,0,1.327H171.82l-2.882,2.844a.663.663,0,1,0,.931.945l4.033-3.98a.663.663,0,0,0,0-.945Z" transform="translate(-150.1 -107.165)" fill="#5a5b68"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: "BellIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        transform: rotate(180deg) scale(1.15);
        path {
            fill: #5A5B68;
        }
        &.dark {
            path {
                fill: black;
            }
        }
    }
</style>