import store from "@/store/index";

const englishFormatter = new Intl.NumberFormat("en-CA", {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "CAD",
});

const frenchFormatter = new Intl.NumberFormat("fr-CA", {
  minimumFractionDigits: 2,
  style: "currency",
  currency: "CAD",
});



export const setLanguage =  () => {
  const currentFormatter = store.state.locale === "fr" ? frenchFormatter : englishFormatter;
};

export const format = (value: number) => {
  const currentFormatter = store && store.state.locale === "fr" ? frenchFormatter : englishFormatter;
  return currentFormatter.format(value).replace("CA", "").trim();
};

export const getMoneyOptions = () => {
  const lang = store.state.locale;
  return {
    decimal: lang === "fr" ? "," : ".",
    thousands: lang === "fr" ? " " : ",",
    prefix: lang === "fr" ? "" : "$",
    suffix: lang === "fr" ? " $" : "",
    precision: 2,
    masked: false,
  };
};
