export default {
  "RefinanceApplicationLinksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de refinancement"])},
  "RenewalNotification": {
    "RenewalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning for an early renewal / transfer / refinance / porting of your mortgage?  We are here to take you through our attractive renewal rates and hassle free process. Call our service center at "])},
    "DueRenewalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage is due for renewal!"])},
    "DueRenewalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are here to take you through our attractive renewal rates and hassle free process. Call our service center at  "])},
    "ServicingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday through Friday"])},
    "EmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or email at "])},
    "Dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
  },
  "EducationalVideos": {
    "SupportPage": {
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
      "SupportCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de services"])},
      "NeedAssistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
      "ExploreTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez notre nouvelle vidéothèque."])},
      "ExploreDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gagnez du temps et découvrez-en plus sur les sujets hypothécaires qui vous importent."])},
      "VideoLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéothèque"])},
      "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau"])},
      "FAQTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous désirez des réponses aux questions les plus fréquentes?"])},
      "FAQDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez la catégorie qui décrit le mieux le type d'aide dont vous avez besoin, ou entrez un mot-clé dans le champ de recherche pour limiter les résultats."])},
      "FAQSearchBoxPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En quoi pouvons-nous vous aider?"])}
    },
    "VideoLibrary": {
      "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
      "ViewVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la vidéo"])}
    },
    "PopUp": {
      "ViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez les nouvelles vidéos de notre Centre de services!"])},
      "ViewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre nouvelle vidéothèque offre du contenu qui vous permet d’en savoir plus à propos de votre prêt hypothécaire."])},
      "ViewLinkTextOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur l'icône "])},
      "ViewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de services"])},
      "ViewLinkTextTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pour voir les vidéos."])}
    }
  },
  "WatchVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visionner la vidéo"])},
  "WelcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rebienvenue"])},
  "TodayIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui, c'est le"])},
  "NextPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
  "NextPaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
  "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de renouvellement"])},
  "Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
  "MaxMonthlyAllowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du maximum mensuel permis"])},
  "DaysLeftToMakePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours restants pour effectuer le paiement"])},
  "IWantTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux"])},
  "MakeAnExtraPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser mon prêt hypothécaire"])},
  "IncreaseMyPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmenter mon paiement "])},
  "ChangePaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la fréquence des paiements"])},
  "ManageArrearsPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les paiements pour arrérages"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus"])},
  "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos de nous"])},
  "UsefulLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens Utiles"])},
  "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous joindre"])},
  "EmailUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
  "Accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accessibilité"])},
  "Glossary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossaire"])},
  "System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes résidentiels"])},
  "Appliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils ménagers (Alberta seulement)"])},
  "MCAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCAP"])},
  "Forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
  "MortgageServicingCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de services hypothécaires"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C. P. 351, succursale C"])},
  "Address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchener, ON N2G 3Y9"])},
  "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécopieur"])},
  "PropertyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt foncier"])},
  "Tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt foncier"])},
  "HoldAPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différer un paiement"])},
  "ChangeBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le compte bancaire"])},
  "UpdateFireInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'assurance-incendie"])},
  "WaysToMakeRenewalEasier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["façons pour renouveler simplement"])},
  "NewDocumentsToReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nouveau(x) document(s) à consulter"])},
  "PrintYourAnnualStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimez votre déclaration annuelle/lettre d'ajustement fiscal"])},
  "FireInsuranceAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte d'assurance incendie"])},
  "NoNewActivityLastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune nouvelle activité depuis votre dernière connexion"])},
  "ManageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des détails"])},
  "AnnualStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé annuel"])},
  "NextPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain paiement"])},
  "Frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
  "MLI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MLI"])},
  "ApprovedAccessGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès autorisé donné"])},
  "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du versement régulier"])},
  "Term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terme"])},
  "HomeWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie résidentielle"])},
  "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
  "MortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro hypothécaire"])},
  "3rdPartyConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentement de tierce partie"])},
  "PayYourMortgageOffFaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursez votre prêt hypothécaire plus rapidement"])},
  "PayYourMortgageOffFasterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites jusqu'à 20 000 ", "$", " en paiements supplémentaires et réduisez de 8 mois votre prêt hypothécaire"])},
  "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "Legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis juridiques"])},
  "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité"])},
  "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
  "Licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis"])},
  "MortgageCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculateur d'hypothèque"])},
  "SiteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan du site"])},
  "AccessibilityatMCAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'accessibilité chez MCAP"])},
  "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer la séance"])},
  "MortgageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du prêt hypothécaire"])},
  "LineCreditDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de marge de crédit"])},
  "MortgageDetailsasat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du prêt hypothécaire en date du"])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
  "PropertyTaxDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte de taxes"])},
  "Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "AmortizationSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barème d'amortissement"])},
  "StartingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde initial"])},
  "CurrentBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel"])},
  "AvailableCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit disponible"])},
  "CreditLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de crédit"])},
  "BalancePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde payé"])},
  "PrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du capital"])},
  "VariableRate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Variable: Prime ", _interpolate(_named("plus")), _interpolate(_named("offset")), "%)"])},
  "PromotionalDiscountRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion de taux d'escompte"])},
  "PromotionalExpiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration de la promotion"])},
  "MaturityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de maturité"])},
  "MaturityDatetip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’échéance"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "PropertyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de la propriété"])},
  "RequestInformationStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un relevé d'information"])},
  "ComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bientôt disponible"])},
  "PaymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échéance"])},
  "YourMortgageElementsListedBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prêt hypothécaire est composé des éléments ci-dessous"])},
  "YourLineOfCreditElementsListedBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ligne de crédit est constituée de ou des éléments ci-dessous."])},
  "TermsOfTheProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalités du produit. Section Produit"])},
  "PrincipalAndInterestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital et intérêts"])},
  "InterestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt Amount"])},
  "HomeMortgageProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection hypothécaire résidentielle"])},
  "OtherAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre montant"])},
  "PaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence des paiements"])},
  "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement restant"])},
  "FireInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance Incendie"])},
  "LevelOfAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau d'autorisation"])},
  "On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
  "BankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compte bancaire"])},
  "Bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compte"])},
  "At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à"])},
  "RequestBankAccountChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander une modification du compte bancaire"])},
  "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain"])},
  "PaidOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursé"])},
  "LegalWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recours légaux"])},
  "Discharged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quittances obtenue"])},
  "PendingPO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement en cours"])},
  "PendingRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellement en cours"])},
  "TaxAccountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte foncièr"])},
  "TaxEstimateAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimée du montant de taxes"])},
  "InterestAdjustmentDateTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une date de paiement des intérêts seulement, généralement avant la date du premier paiement courant, si la date que votre hypothèque commence (ou de prochain paiement) de correspond pas aux dates de paiements demandées. La date de rajustement des intérêts est la date à laquelle le montant pour rajustement des intérêts est exigible, et sera débité de votre compte."])},
  "TaxAccountBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du compte d'impôt"])},
  "PropertyTaxRollNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro matricule"])},
  "SchoolTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes scolaires <br/> (si vous êtes résident du Québec)"])},
  "LastBillPaidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier paiement"])},
  "NextPropertyTaxDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochaine date d'échéance de l'impôt foncier"])},
  "PropertyTaxParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous cherchez nous envoyer votre compte de taxes municipale, une facture de taxes supplémentaire ou votre compte de taxes scolaire? Vous pouvez nous l'envoyer par courriel au <a class='link' href='mailto:taxes", "@", "serviceprets.ca'>taxes", "@", "serviceprets.ca</a> ou par télécopieur au 1-866-370-0660. Veuillez nous laisser deux jours ouvrables pour traiter votre demande."])},
  "ToSeeHowLongYourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour savoir pendant combien de temps vos documents seront visibles"])},
  "DocumentRequired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Si le document que vous cherchez n'est plus visible <a href='", _interpolate(_named("url")), "Contact' class='link' id='contact-us'>communiquez avec nous</a>."])},
  "ThinkHELOCNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour ce qui concerne votre marge de crédit, contactez-nous à l'adresse suivante 1 877 343 0883 or <a href='mailto:helocrequest", "@", "thinkfinancial.ca' class='link' id='contact-us'>helocrequest", "@", "thinkfinancial.ca</a>."])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquez ici."])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "WelcomeBackLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon Retour, Veuillez Vous Brancher à Votre Compte"])},
  "Lendwise_LoginAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous ci-dessous si vous avez obtenu votre prêt hypothécaire depuis le 1er mai 2023. Si vous avez obtenu votre prêt hypothécaire avant le 1er mai 2023, connectez-vous <a href='https://lendwise.mymortgagedetails.com/iPad_Site_Logon.html?brandid=CW_LW_Fq&languageid=fq' class='link' id='lnkLoginAlert' target='_blank'>ici</a>.<br /><br />Vous n’êtes pas certain de la date à laquelle vous avez obtenu votre prêt hypothécaire? <a href='https://www.merixfinancial.com/fr/contact/' class='link' id='lnkContactUs' target='_blank'>Communiquez avec nous</a> et il nous fera plaisir de vous assister."])},
  "MERIX_LoginAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous ci-dessous si vous avez obtenu votre prêt hypothécaire depuis le 1er mai 2023. Si vous avez obtenu votre prêt hypothécaire avant le 1er mai 2023, connectez-vous <a href='https://merix.mymortgagedetails.com/iPad_Site_Logon.html?brandid=CW_MX_Fq&languageid=fq' class='link' id='lnkLoginAlert' target='_blank'>ici</a>.<br /><br />Vous n’êtes pas certain de la date à laquelle vous avez obtenu votre prêt hypothécaire? <a href='https://www.merixfinancial.com/fr/contact/' class='link' id='lnkContactUs' target='_blank'>Communiquez avec nous</a> et il nous fera plaisir de vous assister."])},
  "Lendwise_SignUpAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ci-dessous si vous avez obtenu votre prêt hypothécaire depuis le 1er mai 2023. Si vous avez obtenu votre prêt hypothécaire avant le 1er mai 2023, inscrivez-vous <a href='https://lendwise.mymortgagedetails.com/iPad_Site_SignUp.html?brandid=CW_LW_Fq&languageid=fq' class='link' id='contact-us' target='_blank'>ici</a>.<br /><br />Vous n’êtes pas certain de la date à laquelle vous avez obtenu votre prêt hypothécaire? <a href='https://www.merixfinancial.com/fr/contact/' class='link' id='lnkContactUs' target='_blank'>Communiquez avec nous</a> et il nous fera plaisir de vous assister."])},
  "MERIX_SignUpAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ci-dessous si vous avez obtenu votre prêt hypothécaire depuis le 1er mai 2023. Si vous avez obtenu votre prêt hypothécaire avant le 1er mai 2023, inscrivez-vous <a href='https://merix.mymortgagedetails.com/iPad_Site_Logon.html?brandid=CW_MX_Fq&languageid=fq' class='link' id='contact-us' target='_blank'>ici</a>.<br /><br />Vous n’êtes pas certain de la date à laquelle vous avez obtenu votre prêt hypothécaire? <a href='https://www.merixfinancial.com/fr/contact/' class='link' id='lnkContactUs' target='_blank'>Communiquez avec nous</a> et il nous fera plaisir de vous assister."])},
  "ChargeTypeAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre numéro d'hypothèque ou le numéro de référence du prêteur ci-dessous suivi de .1"])},
  "RememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenez-vous de moi"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
  "IncludesDeferredPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprend un paiement différés et des frais de"])},
  "InterestAdjustmentAmountTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant pour rajustement des intérêts (MRI)"])},
  "RemainingLumpSumAvailableTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un montant d’argent supplémentaire porté au solde du capital, généralement limité à un montant maximal par année d’anniversaire, selon votre entente hypothécaire. Ces paiements peuvent être effectués lors de chaque date d’exigibilité de paiement, pourvu qu’il y ait un paiement minimal de 100,00 ", "$", ". Faire un paiement forfaitaire entraîne une diminution du solde du capital dû et de l’amortissement du prêt. Le montant du paiement courant demeure le même. Les paiements forfaitaires peuvent être effectués une fois ou peuvent être récurrents, c’est-à-dire qu’ils surviennent à chaque date de paiement courant."])},
  "MortgagePermissionsStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut des permissions hypothécaires"])},
  "PastDueBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde en souffrance"])},
  "RemainingLumpSumAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement forfaitaire restant"])},
  "AllActivityUpcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les activités à venir"])},
  "ActivityHistorical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des activités"])},
  "Last12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 derniers mois"])},
  "Next12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 prochains mois"])},
  "EarlyRenewalOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre de renouvellement anticipé"])},
  "FinalDemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettre de demande finale"])},
  "FireInsuranceLapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance incendie échue"])},
  "CostOfBorrowingDisclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût d'emprunt"])},
  "HomeWarrantySetUpLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettre de mise en place de la garantie habitation"])},
  "HomeWarrantyPayingPeriodLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettre relative à la période de paiement de la garantie habitation"])},
  "HomeAccountStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé de compte à domicile"])},
  "AssumptionStmnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration d'hypothèse"])},
  "PrivilegePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement forfaitaire"])},
  "AllInTheLast12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout au long des 12 derniers mois"])},
  "AllInTheLast18Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout au long des 18 derniers mois"])},
  "AllInTheLast3Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout au long des trois dernières années"])},
  "AllInTheLast2Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout au long des deux dernières années"])},
  "MostRecentInTheLast2Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plus récentes au cours des deux dernières années"])},
  "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune"])},
  "DocumentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document"])},
  "Visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité"])},
  "ListOfEligibleCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des correspondances éligibles"])},
  "TaxEstimateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant prévu des impôts fonciers annuels à payer pour l’année de taxe suivante. Cette estimation est un calcul approximatif fondé sur plusieurs facteurs incluant les paiements et les estimés précédents et les renseignements fournis par votre municipalité. Ce nombre est cependant souvent entre 5% et 10% plus élevé que le montant de taxes payé pour l'année courante et tout surplus sera appliqué contre les hausses de taxes futures."])},
  "TaxBalanceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permet de réduire la portion du capital et des intérêts d’un paiement hypothécaire. Cela entraînerait une augmentation de l’amortissement, ce qui provoquerait une augmentation du nombre d’années nécessaires pour rembourser le prêt hypothécaire."])},
  "ListOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de"])},
  "Past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passé"])},
  "12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois"])},
  "ActivityTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 mois"])},
  "PropertyTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes Municipales"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "HavingSigningIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème de connexion?"])},
  "HelpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
  "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrire"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalités d'utilisation"])},
  "SignUpNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous Pour Gérer Votre Prêt Hypothécaire"])},
  "SignUpMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer ci-dessous les détails exactement tels qu'ils apparaissent sur vos documents hypothécaires. Votre adresse courriel sera utilisée pour vérifier votre compte."])},
  "SignUpMortgageAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format est de 7 chiffres suivi d'un point puis d'un chiffre. Ex. : 9999999.1"])},
  "SignUpBankAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les 4 derniers chiffres du compte bancaire à partir duquel les paiements hypothécaires sont effectués."])},
  "LastNameFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille complet."])},
  "AccountVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de compte"])},
  "AccountVerificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de vérifier que vous êtes bien le propriétaire de cette hypothèque, un code de vérification vous sera envoyé à:"])},
  "AccountDetailsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer les informations ci-dessous pour compléter votre inscription. Lors de votre prochaine connexion, utilisez le nom d'utilisateur et le mot de passe que vous entrez ci-dessous pour accéder à votre compte."])},
  "EnterYourVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre code de vérification"])},
  "Verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "DidntReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu de code? <a href='#' class='link' id='new-code-link'> Cliquez ici</a> pour réessayer."])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "NewCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un nouveau code"])},
  "HavingTrouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez du mal à vérifier ?"])},
  "HavingTroubleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On dirait qu'il va falloir faire ça à l'ancienne. Laissez-nous vous aider."])},
  "HavingTroubleContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous au numéro de téléphone ci-dessous et l'un de nos représentants du service clientèle vous aidera à vérifier."])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "BackToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'Accueil"])},
  "ChooseBorrowerMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes vérifié. Il est maintenant temps de choisir comment vous allez vous connecter à votre compte. Choisissez une méthode de connexion ci-dessous."])},
  "ChooseBorrower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un emprunteur"])},
  "SignInUsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous en utilisant"])},
  "UsernameAndPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur et mot de passe"])},
  "EasySocialLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion facile par médias sociaux"])},
  "MortgageAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte hypothécaire"])},
  "BankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte bancaire"])},
  "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "PasswordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe"])},
  "MobilePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
  "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique"])},
  "ConfirmEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du courriel"])},
  "MatchingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les adresses électroniques ne correspondent pas"])},
  "InvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique invalide."])},
  "NumericCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractère numérique"])},
  "MinimumCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 caractères minimums et doit"])},
  "SpecialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères spéciaux !, ", "@", ", #, ", "$", ", %, ^, &, *"])},
  "NoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'espaces"])},
  "SecureYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurisez votre compte"])},
  "SecureYourAccountMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité, veuillez créer trois questions de sécurité avec des réponses que vous seul connaissez. La réponse doit comporter un minimum de 3 lettres."])},
  "SecureYourAccountMessageSecurityTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité, veuillez créer trois questions de sécurité avec des réponses que vous seul connaissez. Vos réponses doivent comporter au moins 3 lettres."])},
  "ExpiredSecurityQuestionsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos questions et réponses de sécurité ont expiré. Veuillez sécuriser votre compte avec de nouvelles questions et réponses que vous seul connaissez, au cas où vous oublieriez votre nom d'utilisateur ou votre mot de passe."])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "SelectQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une question"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
  "Of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
  "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
  "ContentsTermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et Conditions"])},
  "ContentsTermsConditionsSubText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du Concours"])},
  "TermsConditionsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez lire les conditions décrites ci-dessous et cliquer sur J'accepte pour compléter votre inscription."])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer"])},
  "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte"])},
  "IAgreeToThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les"])},
  "IAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les"])},
  "BrowserUpdateText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["AVIS : Vous utilisez un navigateur qui ne sera pas compatible avec notre site Web. Veuillez mettre à jour votre navigateur vers la dernière version afin que vous puissiez profiter des avantages de l'utilisation de ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), "."])},
  "MetaData": {
    "Login": {
      "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Connexion au Portail des propriétaires ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), " ", "|", " ", _interpolate(_named("tenant"))])},
      "Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gérez votre prêt hypothécaire ", _interpolate(_named("tenant")), " avec ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), ". Branchez-vous à  ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), " pour accéder aux détails de votre prêt hypothécaire, faire des paiements forfaitaires, voir votre taxe foncière et plus!"])},
      "Title-MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au Portail des propriétaires MonMCAP ", "|", " MCAP"])},
      "Title-MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au portail client MonMERIX ", "|", " MERIX"])},
      "Description-MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ou branchez-vous au portail client MonMERIX pour accéder à votre prêt hypothécaire MERIX dès aujourd’hui!"])},
      "Title-LW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au portail client Lendwise ", "|", " Lendwise"])},
      "Description-LW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous ou branchez-vous au portail client Lendwise pour optimiser la gestion de votre prêt hypothécaire."])},
      "Title-NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au portail client MonNPX ", "|", " NPX"])},
      "Description-NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez le solde et les détails de paiement de votre prêt hypothécaire NPX en tout temps en vous inscrivant ou en vous connectant au portail client MonNPX."])},
      "Title-PB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion au portail client de MonPeoplesBank ", "|", " Peoples Bank"])},
      "Description-PB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez le solde et les détails de paiement de votre prêt hypothécaire Peoples Bank et plus en vous inscrivant ou en vous connectant au portail client."])}
    }
  },
  "PasswordResetCompleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe complète"])},
  "PasswordResetCompleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été réinitialisé.  Veuillez vous connecter avec votre nouveau mot de passe."])},
  "PasswordResetHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
  "Payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
  "PrincipalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital payé"])},
  "InterestPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêts payés"])},
  "TotalPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des paiements et intérêts"])},
  "P&I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C+I"])},
  "ScheduledPaymentsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les détails dans le tableau ci-dessous évaluent vos paiements prévus d'aujourd'hui à la date d'échéance <strong style='font-size: inherit;'>", _interpolate(_named("date")), "</strong>"])},
  "ScheduledPaymentsMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>Ce barème d'amortissement montre les détails attendus du capital et des intérêts pour chaque paiement, de la date de votre prochain paiement jusqu'à l'échéance du prêt. Ce barème d'amortissement prévoit que tous les paiements sont faits à temps. Veuillez noter que les informations suivantes incluront toute somme forfaitaire et versement supplémentaire prévu en dehors de votre versement hypothécaire régulier.</p>"])},
  "PropertyTaxesMessage2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>Payer l'impôt foncier annuel à votre municipalité est obligatoire pour tous les propriétaires.</p><p style='margin-bottom: 15px;'>Nous pouvons vous aider à gérer cette importante dépense annuelle en ajoutant une portion d'impôt foncier à vos paiements hypothécaires courants. Nous prélevons une portion supplémentaire pour couvrir l'estimé annuel d'impôt foncier, et payons la municipalité en votre nom. Et saviez-vous que ce service est gratuit?</p><p style='margin-bottom: 15px;'>Un souci de moins! Pour plus de détails, appelez-nous au ", _interpolate(_named("frenchPhone")), ".</p>"])},
  "YourMortgageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos renseignements hypothécaires"])},
  "TodaysBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde en date d'aujourd'hui"])},
  "PaymentsUntilRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements d'ici le renouvellement"])},
  "CurrentRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux actuel"])},
  "DetailsAtRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails au renouvellement"])},
  "ClosingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde de clôture"])},
  "PrinciplePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital payé"])},
  "TotalAmountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total payé"])},
  "RemainingAmortizationRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement restant au renouvellement"])},
  "OpeningPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital initial"])},
  "PIPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement capital et intérêts"])},
  "DetailsAsAtMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails à maturité"])},
  "ClosingPrincipalToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermeture du capital à l'échéance"])},
  "PaymentsScheduledToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements prévus à maturité"])},
  "AppliedToPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porté au capital"])},
  "AppliedToInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porté aux intérêts"])},
  "TotalPaymentsUpToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements totaux à maturité"])},
  "RemainingAmortizationAtMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement restant à maturité"])},
  "AsOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dès Le"])},
  "BalanceAtRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde au renouvellement"])},
  "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restant"])},
  "Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ans"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
  "AccountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails du compte"])},
  "AlphaNumericCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractère alpha"])},
  "DocumentDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison de documents "])},
  "SelectDeliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner le mode de livraison des documents"])},
  "ElectronicCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communications de la voie électronique"])},
  "Username6Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d’utilisateur doit être un minimum de 6 caractères en longueur et peut contenir aucune espace"])},
  "UsernameSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucunes des caractères spéciaux suivants: =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
  "UsernameSpecialCharatersError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur ne peut pas contenir d'espaces ni aucun des caractères spéciaux suivants: =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
  "PasswordStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solidité de mot de passe"])},
  "PasswordsMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe correspondent."])},
  "Weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
  "Okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
  "NoEnoughFundsWithdraw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n'avez pas suffisamment de fonds disponibles pour effectuer un retrait. Le montant minimum de retrait requis est de ", _interpolate(_named("minimumAmount")), ". Veuillez noter qu'il faut compter un jour ouvrable pour que les paiements soient crédités sur votre compte."])},
  "Strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fort"])},
  "PaymentRenewalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous remboursez votre prêt hypothécaire avant la fin du terme en cours, les paiements indiqués correspondent à la date du dernier paiement de votre terme en cours."])},
  "PaymentAmountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant du paiement indiqué est compose du principal et des intérêts et ne comprend pas l'impôt foncier, l'assurance hypothécaire ou la garantie habitation (le cas échéant)."])},
  "ActivityType-P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement préautorisé"])},
  "ActivityType-LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait assisté"])},
  "ActivityType-AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
  "ActivityType-A2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait progressif"])},
  "RenewalInDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de renouvellement"])},
  "NumberOfMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " mois"])},
  "NumberOfMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " mois"])},
  "NumberOfYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ans"])},
  "NumberOfYear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " an"])},
  "NumberOfYearsAndMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ans ", _interpolate(_named("months")), " mois"])},
  "NumberOfYearAndMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " an ", _interpolate(_named("months")), " mois"])},
  "NumberOfYearsAndMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ans ", _interpolate(_named("months")), " mois"])},
  "NumberOfYearAndMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " an ", _interpolate(_named("months")), " mois"])},
  "Electronic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électronique"])},
  "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courrier (Poste)"])},
  "SkipPaperLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne me montrer pas cette alerte la prochaine fois"])},
  "ActivityHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Principal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
    "Interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêts"])},
    "PropertyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes Municipales"])},
    "HomeMortgageProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection hypothécaire résidentielle"])},
    "OtherAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre montant"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "Reversed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renversé"])}
  },
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
  "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
  "Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifications"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "ActivityPdfHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Activité hypothécaire ", _interpolate(_named("mortgageNumber")), "\n ", _interpolate(_named("filterType")), ": ", _interpolate(_named("filterDuration"))])},
  "ActivityReversedTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "ActivityReversedFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "Refinance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refinancez"])},
  "ActivitiesNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune activité n'a été trouvée pour cette période."])},
  "MortgageActivityFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité hypothécaire"])},
  "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "LoginPageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - Une Meilleure Façon De Gérer Votre Prêt Hypothécaire"])},
  "RagisterMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer ci-dessous les détails exactement tels qu'ils apparaissent sur vos documents hypothécaires. Votre adresse courriel sera utilisée pour vérifier votre compte."])},
  "ElectronicCommMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément aux modalités énoncées dans votre lettre d'engagement hypothécaire, nous pouvons vous envoyer des courriels au sujet de votre compte hypothécaire."])},
  "ElectronicCommAccept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'aimerais recevoir des messages relatifs au cycle de vie de mon prêt hypothécaire de ", _interpolate(_named("tenant")), "."])},
  "Understand": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Je comprends qu'en m'inscrivant ici, je consens à recevoir des messages de marketing par courriel de ", _interpolate(_named("tenant")), ". Les messages de marketing peuvent inclure, sans s'y limiter, des produits, des services et des promotions liés à la propriété et aux prêts hypothécaires. Vous pouvez vous désabonner à tout moment."])},
  "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])},
  "LostUsernameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez perdu votre mot de passe ou votre nom d'utilisateur ?"])},
  "LostUsernameOrPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne vous inquiétez pas.  Nous pouvons vous aider à récupérer rapidement les détails de votre compte et à vous reconnecter immédiatement."])},
  "SelectOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une option"])},
  "RememberPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne me souviens pas de mon mot de passe"])},
  "RememberPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ne vous inquiétez pas, nous pouvons vous aider. Votre sécurité et votre vie privée sont importantes pour nous. Au cours de la prochaine étape, nous vous poserons quelques questions sur votre prêt avant de réinitialiser votre mot de passe.</p>"])},
  "RememberUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne me souviens pas de mon nom d'utilisateur"])},
  "RememberUsernameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne me souviens pas de mon nom d'utilisateur et de mon mot de passe"])},
  "SocialNotWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion par médias sociaux ne fonctionne pas"])},
  "SocialNotWorkingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>Puisque nous n'avons aucun contrôle sur votre connexion aux médias sociaux, nous réinitialiserons votre compte. Votre sécurité et votre confidentialité sont essentielles pour nous. À l'étape suivante, nous vous poserons des questions concernant votre prêt hypothécaire pour réinitialiser votre compte. </p><p>Une fois votre compte réinitialisé, vous devrez associer à nouveau votre connexion aux médias sociaux, ou vous devrez opter pour un nom d'utilisateur avec mot de passe.</p>"])},
  "StartRecovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la récupération"])},
  "ChooseVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous devons vérifier votre compte."])},
  "ChooseVerifyAccountMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de vérifier les détails de votre compte, nous devons vous envoyer un code à 6 chiffres. Sélectionnez une méthode ci-dessous pour continuer."])},
  "VerifyMeUsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez-moi en utilisant  "])},
  "SendAnSMS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Envoyez un SMS à ", _interpolate(_named("number"))])},
  "SendAnEmail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Envoyez un courriel à ", _interpolate(_named("email"))])},
  "AnswerSecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre aux questions de sécurité"])},
  "NoneOfAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas accès à aucun des éléments ci-dessus."])},
  "TermInYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("term")), " ans"])},
  "PaymentFrequencies": {
    "Annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuellement"])},
    "SemiAnnually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-annuellement"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuel"])},
    "SemiMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la quinzaine"])},
    "BiWeekly-Accelerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bihebdomadaire accéléré"])},
    "Weekly-Accelerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire Accéléré"])}
  },
  "BiWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bihebdomadaire"])},
  "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
  "NeedAssistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide?"])},
  "Assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide"])},
  "NotificationAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes de notification"])},
  "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez un nouveau mot de passe"])},
  "ConfirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du nouveau mot de passe"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrire"])},
  "ChooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un nouveau mot de passe"])},
  "ChooseNewPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre vérification a été effectuée avec succès. Il est maintenant temps de choisir un nouveau mot de passe."])},
  "VerifyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de vérifier que vous êtes bien le propriétaire de cette hypothèque, un code de vérification sera envoyé à votre appareil mobile :"])},
  "SecurityQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question de sécurité"])},
  "AccountRecoveryComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recouvrement de compte terminé"])},
  "PasswordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été modifié."])},
  "GoDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à mon tableau de bord"])},
  "NoneOfAboveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout n'est pas perdu, vous êtes entre de bonnes mains. Contactez-nous au numéro de téléphone ci-dessous et l'un de nos représentants du service à la clientèle vous aidera à récupérer votre compte."])},
  "PropertyTaxTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 mois"])},
  "PropertyTaxHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du compte d'impôt"])}
  },
  "PropertyTaxPaymentsPdfHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement de l'impôt foncier sur les hypothèques"])},
  "PropertyTaxPaymentsFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt foncier"])},
  "PropertyTaxPaymentType-P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement préautorisé"])},
  "PropertyTaxesNotCollected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n’êtes actuellement pas abonné au service de taxes foncières et scolaire offert par ", _interpolate(_named("tenant")), " pour le prêt sélectionné. Pour obtenir plus d’information concernant ce service gratuit, veuillez contacter un de nos spécialistes qualifiés entre ", _interpolate(_named("hours")), " au ", _interpolate(_named("phone")), "."])},
  "PropertyTaxPaymentsNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun paiement d'impôt foncier trouvé pour cette période"])},
  "PropertyTaxesChargeType7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre taxe foncière est perçue sur votre ligne de crédit. Vous trouverez les informations relatives à votre taxe foncière dans la section 'Détails de votre hypothèque' de ce segment de votre hypothèque"])},
  "PropertyTaxActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité passée"])},
  "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "DocumentsRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois"])},
  "DocumentsNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document pour cette période n'a été trouvé."])},
  "AmortizationHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "PrincipalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital payé"])},
    "InterestPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêts payés"])},
    "TotalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payé"])},
    "PrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde du capital"])}
  },
  "MortgageAmortizationFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement des hypothèques"])},
  "AmortizationPdfHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Calendrier d'amortissement des hypothèques ", _interpolate(_named("mortgageNumber")), "\n ", _interpolate(_named("filterDuration"))])},
  "AmortizationTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois"])},
  "TaxPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement Des Taxes"])},
  "InterestAdjustmentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de rajustement des intérêts"])},
  "FirstNewPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première date du nouveau paiement"])},
  "NewTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles modalités"])},
  "IADAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["montant de la date d'ajustement des intérêts"])},
  "CurrentTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalités actuelles"])},
  "PleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remarque"])},
  "TotalPaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total du paiement"])},
  "ForMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour le numéro hypothécaire"])},
  "YourNewMaturityDateIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre date de renouvellement est le"])},
  "YourNewTotalLoanPaymentIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total de votre nouveau paiement"])},
  "IncurMidtermLoanChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devrez payer des frais de modification du prêt avant échéance de"])},
  "InterestAdjustmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et une somme pour rajustement des intérêts de"])},
  "FirstFullNewPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première date de nouveau paiement"])},
  "AcceptChangeFreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cochez cette case si vous acceptez les coûts associés à la facturation (frais ou intérêts)"])},
  "YouHaveChosenTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez choisi de"])},
  "YouHaveRequestedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez demandé"])},
  "ChangeYourPaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["changer votre <span style='font-weight: 500; font-size: 13px;'>fréquence de paiement</span> pour"])},
  "ChangeYourPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier votre <span style='font-weight: 500; font-size: 13px;'>date de paiement</span> à"])},
  "ChangePaymentFrequencyFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les paiements hypothécaires sont effectués à rebours (et non en avance, comme pour un loyer). Lorsque vous effectuez un changement à la date de paiement, notez que votre prochain paiement hypothécaire courant doit être effectué comme prévu pour terminer le calendrier de paiement en vigueur. Si vous avez un souci concernant votre prochain paiement hypothécaire courant, appelez-nous dès que possible au ", _interpolate(_named("phoneNumberFrench")), " (du lundi au vendredi, entre ", _interpolate(_named("serviceHoursFrench")), ", heure locale)."])},
  "SelectFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la fréquence"])},
  "ViewActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir l'activité"])},
  "MortgageActionSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la fréquence de paiement"])},
  "MortgageActionSuccessDateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la date de paiement"])},
  "MortgageActionSuccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été soumise avec succès."])},
  "MortgageActionSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désirez-vous voir vos prochains paiements et l'activité à votre compte?"])},
  "Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé "])},
  "ChangePaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la date de paiement"])},
  "ChangePaymentDateFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les paiements hypothécaires sont effectués à rebours (et non en avance, comme pour un loyer). Lorsque vous effectuez un changement à la date de paiement, notez que votre prochain paiement hypothécaire courant doit être effectué comme prévu pour terminer le calendrier de paiement en vigueur. Si vous avez un souci concernant votre prochain paiement hypothécaire courant, appelez-nous dès que possible au ", _interpolate(_named("phoneNumberFrench")), " (du lundi au vendredi, entre ", _interpolate(_named("serviceHoursFrench")), ", heure locale)."])},
  "ActivityDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail d'activité"])},
  "MaxExtraPaymentFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ce paiement sera prélevé de votre compte bancaire en même temps que votre versement hypothécaire courant<br/> de <strong style='font-size: inherit;'>", _interpolate(_named("value")), "</strong>."])},
  "MaxExtraPaymentFooter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez note que les institutions bancaires peuvent geler les fonds récemment déposés pendant 3 à 5 jours ouvrables. Si votre versement hypothécaire courant prévu n'est pas traité en raison d'un gel de fonds par votre institution bancaire, des frais pour paiement retourné vous seront facturés."])},
  "RemainingPastDueFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque : Pour des raisons de sécurité, les renseignements de votre compte bancaire ne seront affichés qu'en partie."])},
  "RemainingPastDueFooter2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["***Pour demander tout changement  à un paiement arriéré, téléphonez-nous au ", _interpolate(_named("phoneNumber")), ". Nous demandons un préavis de 4 jours ouvrables pour tout changement."])},
  "SelectRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez ou entrez un montant ci-dessous"])},
  "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
  "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
  "OneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique"])},
  "Ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récurrent"])},
  "StartingOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["À compter du <strong>", _interpolate(_named("date")), "</strong>"])},
  "EndingOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jusqu'au <strong>", _interpolate(_named("date")), "</strong>"])},
  "LSPPRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total restant des paiements forfaitaires"])},
  "NewPrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau solde"])},
  "AmountDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Due"])},
  "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Du Paiement"])},
  "ManagePastDueBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le solde dû"])},
  "MakeAPastDuePaymentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer un <strong style='font-size: inherit;'>paiement en retard</strong> de"])},
  "RemainingPastDueOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restant <strong style='font-size: inherit;'>souffrance</strong> de"])},
  "AfterThisPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après <strong style='font-size: inherit;'> ce paiement</strong>"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "MonthEndOptions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun changement"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29em jour du mois"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30em jour du mois"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du mois"])}
  },
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du traitement de votre demande"])},
  "BestOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la meilleure option pour moi?"])},
  "IncreaseMyPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmenter mes paiements"])},
  "IncreaseMyPaymentsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement majoré sera appliqué directement sur le capital et vous aidera à rembourser votre prêt hypothécaire plus rapidement. Pour réduire vos paiements à une date ultérieure, vous pouvez encourir des frais de changement de prêt à moyen terme."])},
  "MakeOneTimeLumpSumPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer un paiement forfaitaire unique?"])},
  "SetUpRecurringLumpSumPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établir des paiements forfaitaires récurrents"])},
  "PayDownMyMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser mon prêt hypothécaire"])},
  "MarsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette fonction n'est pas disponible en ce moment. Veuillez essayer de nouveau après ", _interpolate(_named("time")), " HE."])},
  "WhatsInItForMeContent": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu'est-ce qui m'est offert?"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez deux façons de rembourser votre prêt hypothécaire plus rapidement : en effectuant des paiements supplémentaires ou en augmentant le montant de vos paiements."])},
    "LumpSumPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements forfaitaires"])},
    "PaymentIncrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augmentation de paiement"])},
    "LumpSumItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuez un paiement supplémentaire unique ou établissez des paiements récurrents"])},
    "LumpSumItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paiements forfaitaires sont portés directement à votre solde dû"])},
    "LumpSumItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de 100 ", "$", " par paiement"])},
    "LumpSumItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement doit être effectué lors d'une date de paiement courant prévue"])},
    "LumpSumItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulez sans frais tout paiement futur prévu"])},
    "PaymentIncreaseItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affecte tous les paiements à venir"])},
    "PaymentIncreaseItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant du paiement supplémentaire est porté directement à votre solde dû"])},
    "PaymentIncreaseItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les augmentations de paiement sont immobilisées à votre date d'anniversaire hypothécaire"])},
    "PaymentIncreaseItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulez sans frais les augmentations de paiement avant qu'ils ne soient immobilisés"])},
    "PaymentIncreaseItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulez les augmentations de paiement après leur immobilisation moyennant des frais"])}
  },
  "PrincipleInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital et intérêts"])},
  "YourTotalNewPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant total de votre nouveau paiement sera de"])},
  "DateSelectedMonthEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date que vous avez choisie est le dernier jour du mois pour certains mois, mais pas pour tous. Voulez-vous que votre date de paiement soit définitivement changée a :"])},
  "Shadowing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Observation ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
  "CsrSearchHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de client à l'aide du nom d'utilisateur / WebClientId"])},
  "WithdrawFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait de fonds"])},
  "DailyWithdrawalLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de retrait quotidienne"])},
  "DailyWithdrawalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait quotidien disponible"])},
  "WeeklyWithdrawalLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de retrait hebdomadaire"])},
  "WeeklyWithdrawalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait hebdomadaire disponible"])},
  "WithdrawFundsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le montant que vous souhaitez retirer. Vous avez la possibilité de revoir les détails avant de vérifier votre demande."])},
  "WithdrawFundsFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*L'heure limite pendant le jour ouvrable est 22h00 heure de l'est du lundi au vendredi."])},
  "WithdrawFundsFooter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le paiement mensuel de votre marge de crédit tombe dans les 5 jours ouvrables suivant ce retrait, vous allez voir un montant d'intérêt additionnel retiré de votre compte en même temps que le paiement mensuel. Ce montant additionnel représente les intérêts exigible sur le montant du retrait complété aujourd'hui."])},
  "WithdrawFooter3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<br/><br/>Si vous avez besoin de l'argent plus rapidement, des frais de virement direct de 25 ", "$", " s'appliqueront et les fonds peuvent être déposés le même ou le jour ouvrable suivant.<br/><br/>Veuillez annuler cette transaction et nous contacter au ", _interpolate(_named("phoneNumber")), " (", _interpolate(_named("serviceHours")), ").<br/><br/>*** La banque Tangerine ne permet pas les virements. ***<br/>"])},
  "ToWithdrawFundsOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Prélever des fonds </strong>de"])},
  "BalanceAfterWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde après retrait"])},
  "UsingMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation du numéro d'hypothèque"])},
  "RequestedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandé le"])},
  "ToBeDepositedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A déposer sur le"])},
  "MakePaymentOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Effectuez un <strong style='font-size: inherit;'>", _interpolate(_named("type")), "</strong> paiement supplémentaire unique de"])},
  "DayOfWithdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour de la demande de retrait*"])},
  "FundsDeposited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds déposés dans votre compte bancaire"])},
  "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
  "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mardi"])},
  "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercredi"])},
  "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeudi"])},
  "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendredi"])},
  "SaturdaySunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samedi ou Dimanche"])},
  "StatutoryHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours fériés"])},
  "StatutoryHolidaysExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les demandes relatives aux jours fériés seront déposées deux jours ouvrables après le jour férié par example: Lundi férié = Jeudi"])},
  "NoOfPendingPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de paiements en traitement"])},
  "ManageLumpSumPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les paiements forfaitaires"])},
  "ManageLumpSumPaymentsNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les demandes de paiement sont envoyées à votre institution bancaire 3 jours ouvrables avant la date d'échéance du paiement. Si vous devez annuler un paiement supplémentaire que vous avez établi avec nous, cette demande devra être effectuée au moins 3 jours ouvrables avant la date de paiement visée. Si vous établissez une demande maintenant et que la date de paiement demandée est dans moins de 3 jours, sachez que vous pouvez uniquement l'annuler aujourd'hui si une telle annulation était nécessaire."])},
  "ConfirmCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain(e) de vouloir annuler ce paiement?"])},
  "SpecialPaymentsNotAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La fonction de gestion des arriérés n'est pas disponible actuellement. Veuillez nous appeler au ", _interpolate(_named("phoneNumberFrench")), "."])},
  "VerificationCodeResendFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a eu une erreur lors de l'envoi de votre code de vérification, veuillez réessayer."])},
  "SendCodeToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer mon code de vérification sur mon téléphone portable"])},
  "WebClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant web de client"])},
  "SelectBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une marque…"])},
  "ViewPortalBorrower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le portail de l'emprunteur"])},
  "WithdrawInputError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez entrer un nombre entre ", _interpolate(_named("min")), " et ", _interpolate(_named("max"))])},
  "AgentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion de l'agent"])},
  "ChangeSites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de site"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "SkipPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de Soutien aux Paiements"])},
  "IndicateReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer la raison pour votre demande"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
  "ReasonQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle est la principale raison de votre demande d'aide au paiement?"])},
  "HouseholdIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Renseignements sur le ménage</strong><br/> <span style='font-size: inherit;'>(Revenue mensuel total du ménage courant - net, après déductions)</span>"])},
  "OtherSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre source de revenu"])},
  "NumberDependents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Personnes à charge</strong><br/> <span style='font-size: inherit;'>(Nombre d'occupants de moins de 18 ans)</span>"])},
  "RRSPRIFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REER/FERR"])},
  "StockBonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions/obligations"])},
  "TermDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôts à terme"])},
  "OtherProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres propriétés"])},
  "Savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épargnes (Choisissez tout applicable)"])},
  "Occupancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status d’Occupation"])},
  "OwnerOccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
  "Rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux"])},
  "NumberOccupantsUnder18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Occupants</strong><br/> <span>(Nombre d’occupants 18 ans et plus)</span>"])},
  "Occupant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupant"])},
  "SkipPaymentIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre programme 'Sauter un paiement' vous permet de différer un paiement hypothécaire. Nous vous demandons maintenant de fournir les informations suivantes aussi précisément que possible afin d'évaluer votre admissibilité."])},
  "SkipPaymentIntroText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous avez des questions supplémentaires, veuillez <a href='", _interpolate(_named("SAPClickHerelink")), "' class='link'>cliquer ici</a>."])},
  "SkipPaymentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En raison de notre obligation de préavis de 5 jours ouvrables, seules les dates ci-dessous peuvent faire l'objet d'un Saut de paiement."])},
  "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])},
  "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "FullTimeEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emploi à temps plein"])},
  "PartTimeEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emploi à temps partiel"])},
  "ContractEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emploi contractuel"])},
  "SelfEmployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travailleur autonome"])},
  "EmploymentInsuranceCerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestations d’assurance-emploi (PAU)"])},
  "RentalIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus locatifs"])},
  "PensionDisabilityIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu de pension/d’invalidité"])},
  "CurrentSourceIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source actuelle de revenus"])},
  "EmploymentIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d’emploi"])},
  "EstimateTimeToReturnToWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps estimé pour retourner au travail"])},
  "TotalSavings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Économies totales"])},
  "SkipPaymentBecause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Une aide au paiement</strong> parce que"])},
  "MakeAdditionalPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer un paiement supplémentaire"])},
  "SkipPaymentOtherOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles sont les autres options que vous avez envisagées pour vous aider avec votre situation? (Sélectionnez toutes les réponses qui s'appliquent.)"])},
  "ManagePayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les paiements"])},
  "GetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer"])},
  "CovidUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour COVID-19 : Saut de Paiement"])},
  "AvailableDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements non effectués"])},
  "PasswordResetSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous avons envoyé un courriel qui vous permettra de réinitialiser votre mot de passe."])},
  "YourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom"])},
  "UsernameRetrievalSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous vous avons envoyé un courriel ", _interpolate(_named("maskedEmail")), " avec votre nom d'utilisateur."])},
  "OnGoingPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continuel - ", _interpolate(_named("frequency"))])},
  "OneTimePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une seule fois"])},
  "MatchingPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne sont pas identiques"])},
  "VerificationCodeIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de vérification que vous avez saisi est incorrect"])},
  "VerificationCodeIncorrectLast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de vérification que vous avez saisi est incorrect ou périmé. Il vous reste une chance."])},
  "ChangeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le nom d'utilisateur"])},
  "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
  "CurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le mot de passe actuel"])},
  "ChangeEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'adresse courriel"])},
  "ChangeMailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'adresse postale"])},
  "ChangePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le numéro de téléphone"])},
  "ChangeSecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les questions de sécurité"])},
  "ViewUserAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir l'entente d'utilisation"])},
  "ChangeMortgagePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le permissions hypothécaires"])},
  "SecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question de sécurité"])},
  "DocumentsDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison des document"])},
  "InformationStatement": {
    "Mortgagor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothécaire"])},
    "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hypothécaire"])},
    "StatementOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉCLARATION DE COMPTE - au"])},
    "StatmentPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette déclaration est faite à titre d'information ou d'hypothèse et"])},
    "PayoutPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ne doit pas être utilisé à des fins de paiement."])},
    "MortgageAccount": {
      "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte d'hypothèque"])},
      "PrinciplaeBalanceDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Solde du capital après ", _interpolate(_named("nextPaymentDate")), " le versement"])},
      "InterestDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêts dus"])},
      "OtherCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres frais/autres honoraires"])},
      "PropertyTaxAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de taxe foncière"])},
      "TotalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])}
    },
    "TotalAmountWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si le montant total est payé après le ", _interpolate(_named("nextPaymentDate")), ", ajoutez des intérêts de ", _interpolate(_named("dailyInterest")), " ", "$", " par jour."])},
    "particulars": {
      "header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Détails du prêt actuel ", "@", " ", _interpolate(_named("nextPaymentDate"))])},
      "InterestRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'intérêt"])},
      "MaturityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de maturité"])},
      "Biweekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements bi-hebdomadaires"])},
      "BledPnI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- capital et intérêts mélangés"])},
      "TaxPortion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Portion de la taxe"])},
      "MortgageProtecetion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Protection hypothécaire résidentielle"])},
      "TotalInstalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Versement total"])}
    },
    "StatementOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si cette déclaration est faite à titre d'information uniquement, le plan de prélèvements automatiques ne sera pas annulé."])},
    "StatementTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si cette déclaration est faite à des fins de prise en charge, l'acheteur doit être approuvé. La déclaration est basée sur tous les paiements, jusqu'à la ", _interpolate(_named("nextPaymentDate")), " incluse, qui sont honoré par votre banque. Si vous payez par 'chèque pré-autorisé', ce plan sera annulé après le versement de la ", _interpolate(_named("nextPaymentDate")), "."])},
    "ThankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
    "MCAPSC": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantName")), " Entreprise"])}
  },
  "LinkAnotherMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lier une autre hypothèque"])},
  "ContactUsDrawer": {
    "newCoversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une nouvelle adresse courriel"])},
    "LeaveUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez-nous un message"])},
    "TypicalReplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe répond généralement dans les 24 heures"])},
    "NewMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous joindre"])},
    "QuickAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez des réponses rapidement"])},
    "SearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche pour"])},
    "SearchForAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des réponses"])},
    "NoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])}
  },
  "Message": {
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux de l'aide pour"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
    "SelectHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez ce pour quoi vous avez besoin d'aide"])},
    "SelectMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre hypothèque"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez votre message ici"])},
    "AttachFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attacher un fichier"])},
    "SendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "AttachmentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La limite des pièces jointes est de 7 fichiers"])},
    "FileTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier non supporté"])},
    "EmptyFileError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier vide"])},
    "FileSizeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille totale ne doit pas dépasser 20 MO"])},
    "DoubleFileError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier déjà existant"])},
    "MessageConf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci. <br/><br/>Vous recevrez une réponse dans les 2 jours "])},
    "ToYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ouvrables à votre adresse couriel."])},
    "CheckSpam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous de vérifier si notre réponse se trouve dans vos dossiers de courrier indésirable ou de spam."])},
    "FieldError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un sujet, un numéro de prêt hypothécaire et écrire un message."])},
    "CharacterLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("characters")), " Maximun de 2000 caractères"])},
    "FileTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Seuls les fichiers jpg, png, gif, rtfs, txt, docx, doc, docs, pdf sont pris en accepté)."])},
    "FileNameTooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés d'avoir rencontré un problème avec votre téléversement. Le nom du document dépasse 100 caractères. Veuillez renommer le fichier et réessayer de le téléverser"])}
  },
  "ChangeUserName": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le nom d'utilisateur"])},
    "CurrentUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur actuel"])},
    "ConfirmCurrentUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le nom d'utilisateur actuel"])},
    "NewUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau nom d'utilisateur"])},
    "ConfirmNewUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du nouveau nom d'utilisateur"])},
    "noMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les noms d'utilisateur ne correspondent pas"])},
    "notValidUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur actuel est erroné."])},
    "SameAsCurrentUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouveau nom d'utilisateur doit être différent de votre nom d'utilisateur actuel"])},
    "UsernameAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur existe déjà"])},
    "SettingChangeSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le nom d'utilisateur"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur a été modifié avec succès"])}
    },
    "hints": {
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune espace"])},
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de caractères spéciaux =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum de 6 caractères"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les noms des utilisateurs correspondent"])}
    }
  },
  "AssociatedCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montants à retirer"])},
  "SignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "SearchHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherchez dans notre centre d'aide"])},
  "ContactUsVia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contactez-nous par <a href='#' class='link'>courrier électronique</a> ou appelez-nous directement au ", _interpolate(_named("phoneNumber"))])},
  "PersonalTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'une petite touche personnelle ?"])},
  "HowCanWeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment pouvons-nous vous aider ?"])},
  "HelpCats": {
    "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander du <br/> financement hypothécaire"])},
    "Statements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance <br/> et relevés"])},
    "Discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quittances"])},
    "Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options libre-service"])},
    "Taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes foncières"])},
    "Renewals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renouvellements <br/> et Conversions"])},
    "Special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan spécial <br/> de paiements"])},
    "COVID-19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID-19"])}
  },
  "ChangePasswordSucessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été modifié avec succès."])},
  "PasswordsDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vos mots de passe ne correspondent pas, veuillez vous assurer que votre mot de passe est le même"])},
  "electronicComm": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication électroniques"])},
    "TransactionMesg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformément aux modalités énoncées dans votre lettre d'engagement hypothécaire, nous pouvons vous envoyer des courriels au sujet de votre compte hypothécaire."])},
    "Agree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J'aimerais recevoir des messages relatifs au cycle de vie de mon prêt hypothécaire de ", _interpolate(_named("tenant")), "."])},
    "Understand": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" Je comprends qu'en m'inscrivant ici, je consens à recevoir des messages de marketing par courriel de ", _interpolate(_named("tenant")), ". Les messages de marketing peuvent inclure, sans s'y limiter, des produits, des services et des promotions liés à la propriété et aux prêts hypothécaires. Vous pouvez vous désabonner à tout moment."])},
    "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sûr de ne plus vouloir recevoir de communications électroniques ?"])},
    "SuccessAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez accepté de recevoir des communications électroniques."])},
    "SuccessDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accepté de recevoir des communications électroniques."])}
  },
  "RelatedQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions liées"])},
  "WasThisAnswerHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette réponse a-t-elle été utile?"])},
  "CantFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne trouvez pas la réponse que vous recherchez?"])},
  "StillNeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI vous n'arrivez toujours pas à trouver la bonne réponse à votre question, nous pouvons vous aider."])},
  "ContactUsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiquez avec nous au numéro de téléphone ci-dessous et un représentant du service à la clientèle vous aidera à trouver les réponses à vos questions."])},
  "EmailUsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posez-nous vos questions par courriel et un représentant communiquera avec vous pour vous répondre."])},
  "ChangeEmail": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'adresse courriel"])},
    "CurrentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse courriel actuelle:"])},
    "NewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une nouvelle adresse courriel"])},
    "ConfirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de la nouvelle adresse courriel"])},
    "ErrorNotValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique non valide"])},
    "SameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de changer a la même adresse électronique au dossier"])},
    "ErrorMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les adresses électroniques ne correspondent pas"])}
  },
  "Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères"])},
  "IncorrectAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse incorrecte à la question de sécurité."])},
  "IncorrectAnswerstipHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez du mal à répondre à vos questions?"])},
  "IncorrectAnswerstip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre réponse originale comportait-elle des espaces?"])},
  "IncorrectAnswerstip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous ajouté un espace par erreur?"])},
  "IncorrectAnswerstip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous confirmé que les réponses que vous avez entrées ci-dessous correspondent exactement à vos réponses initiales?"])},
  "SecurityQuestionsSettings": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions de sécurité"])},
    "SelectAQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une question"])},
    "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
    "SecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions de sécurité"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été complétée et vous serez automatiquement déconnecté.  Lors de votre prochaine connexion, vous serez invité à répondre à vos nouvelles questions de sécurité."])},
    "ErrorDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions en double"])},
    "shortAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse doit comporter un minimum de 3 lettres et ne doit contenir aucun espace."])}
  },
  "OccupantsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer un nombre valide d'occupants de votre maison âgés de plus de 18 ans"])},
  "DocDeliver": {
    "note2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En sélectionnant « Électronique », tous les documents hypothécaires seront ajoutés à ", _interpolate(_named("tenant")), ". Une alerte courriel sera envoyée à <b><u>", _interpolate(_named("userEmail")), "</u></b> lorsqu’un nouveau document sera disponible pour consultation."])},
    "paperless": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenant")), " peut choisir de fournir certains documents hypothécaires par la poste en plus de les fournir par voie électronique."])},
    "paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous changez votre préférence pour recevoir tous les documents relatifs à l'hypothèque par courrier postal. Vous pouvez modifier cette préférence à tout moment"])},
    "AnnualStatements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevé annuel"])},
    "RenewalDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents de renouvellement"])},
    "MortgageDefaultNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis de défaut de paiement hypothécaire"])},
    "confirmSubPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir opter pour les communications électroniques?"])},
    "confirmSubMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sûr de vouloir passer au courrier postal?"])}
  },
  "CreditAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit disponible"])},
  "CreditUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit utilisé"])},
  "6MonthConvertibleTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un produit à taux fermé, avec des frais et des pénalités pour mettre fin à l'avance.  Ce produit vous permet de passer à l'un de nos produits à taux fixe ou variable sans frais."])},
  "VariableTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un produit à taux variable sur 5 ans.  Les conditions tarifaires standard s'appliquent."])},
  "FixedTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit d'un produit à taux fixe sur 5 ans.  Les conditions tarifaires standard s'appliquent."])},
  "ValueFlexTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prêt hypothécaire ValueFlex offre un taux très réduit, mais il est assorti d'une restriction de vente. Cela signifie que la seule façon de mettre fin à votre prêt hypothécaire avant la date de renouvellement est de vendre la propriété à une personne qui n'est pas un membre de la famille. Examinez vos documents originaux ou contactez-nous pour obtenir de plus amples informations sur les conditions de votre prêt hypothécaire."])},
  "LowRateTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prêt hypothécaire de base à taux réduit offre un taux très réduit, mais il est assorti de restrictions et de pénalités. Votre prêt hypothécaire n'est pas admissible à un renouvellement anticipé et le fait de mettre fin au prêt avant la date de renouvellement entraînera une pénalité d'au moins 3 % du solde restant. Examinez vos documents originaux ou contactez-nous pour obtenir de plus amples informations sur les conditions de votre prêt hypothécaire."])},
  "MortgageLifeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance vie hypothécaire"])},
  "DisabilityInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance invalidité"])},
  "SourceOfFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source des fonds"])},
  "SourceOfFundsHelp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avant de procéder au changement demandé, nous vous demandons de préciser d'où proviennent les fonds pour votre paiement anticipé. Par exemple, vos épargnes, vos investissements, la vente d'une propriété etc. ", _interpolate(_named("name")), " est mandaté d'obtenir cette information afin d'agir conformément au Régime canadien de lutte contre le blanchiment d'argent et à la Loi sur le recyclage des produits de la criminalité et le financement des activités terroristes du Canada et ses règlements."])},
  "FindMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où trouver votre numéro de compte hypothécaire:"])},
  "SmallEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de fin doit être après la date de début"])},
  "ErrorCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe actuel est erroné"])},
  "SameAsCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau mot de passe doit être différent du mot de passe actuel."])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
  "LsppCountdown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez effectuer des paiements forfaitaires de ", _interpolate(_named("total")), " par année civile à n'importe quelle date de paiement à venir.  Il vous reste ", _interpolate(_named("remaining")), " pour cette année civile."])},
  "ImageOfCheque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image du chèque"])},
  "ImageOfLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de la lettre"])},
  "ImageOfStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image du relevé annuel"])},
  "ImageOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image des infos du compte"])},
  "ImageOfInfoStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image du Relevés d'information"])},
  "SkipPaymentOverLimitWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour déterminer vos options d’aide au paiement, veuillez remplir le formulaire de demande qui suit."])},
  "SkipPaymentOverLimitConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cadre de notre programme d’aide au paiement, nous prendrons en charge le paiement de vos impôts fonciers pendant au moins un an. Pour permettre ceci, votre paiement hypothécaire sera ajusté et augmenté pour inclure une portion de taxe. Pour évaluer les options d’aide au paiement qui s’offrent à vous, MCAP commandera une évaluation de votre propriété. Les frais jusqu’à 150 ", "$", " plus taxes seront imputés au compte de taxe établie. <br/><br/> <strong>Il est important de noter que vos paiements réguliers devraient continuer pendant que nous terminons notre examen.</strong>"])},
  "SkipAPaymentReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport de saut de paiement"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
  "SubmissionSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumission réussie"])},
  "SkipPaymentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des frais supplémentaires peuvent apparaître dans le délai de paiement sauté que vous avez"])},
  "ChangeEmailSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le courriel"])},
  "ChangeEmailSuccessSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre courriel a été modifié avec succès"])},
  "BorrowerRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un emprunteur"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
  "ServiceFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de service"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
  "CreditorInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance Vie et Invalidité Hypothécaire"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "AreYouPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous prêts pour l’inattendu?"])},
  "MLIText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'assurance Vie et Invalidité Hypothécaire peut rembourser le solde de votre hypothèque jusqu'à concurrence de ", _interpolate(_named("balance")), " dans le cas d'une réclamation en cas de décès, et jusqu'à 4 000 ", "$", " par mois pendant deux ans dans le cas d'une réclamation en cas d'invalidité."])},
  "3in4Canadians": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 3 Canadiens sur 4 "])},
  "3in4CanadainsSecondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estiment qu'ils auraient de la difficulté à payer leurs dépenses quotidiennes si leur partenaire décédait. "])},
  "50PercentChance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Canadiens ont 50% de chances"])},
  "50PercentChanceSecondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de devenir invalides et incapables de travailler pendant plus de 90 jours."])},
  "MLILeadInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voyons comment vous pouvez vous protéger et protéger votre famille."])},
  "MLILeadInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous aimeriez en savoir plus sur l'Assurance Vie et Invalidité Hypothécaire? L'un de nos agents d'assurance agréés vous contactera au moment qui vous conviendra le mieux."])},
  "FullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Complet Prénom et Nom"])},
  "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de Téléphone"])},
  "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de Contact Préféré  (Heure de l'Est)"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des commentaires ou des questions supplémentaires?"])},
  "ReturnToForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])},
  "MLIFormCancelPopUpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous annulez avant de soumettre, vous ne participerez pas au concours."])},
  "MLIFormCancelPopUpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-Vous Sûr?"])},
  "MLIFormSubmitConfirmTextSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez demandé à être rappelé, l'un de nos agents agréés vous contactera dans un délai de 1 à 2 jour ouvrable."])},
  "MLIFormSubmitConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été inscrit au concours pour avoir complété le Sondage Vie et Invalidité Hypothécaire."])},
  "ProvideFullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer votre nom complet."])},
  "ProvidePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer le numéro de téléphone auquel vous souhaitez être contacté."])},
  "MLIFormError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Il manque des champs obligatoires, veuillez les mettre à jour avant de les soumettre."])},
  "SunLifeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='font-size: 13px'>Sun Life du Canada, compagnie d'assurance-vie est l’assureur de ce produit et fait partie du groupe d’entreprises Sun Life. Pour communiquer avec l’assureur, composez le 1-877-271-8713 ou visitez le <a target='_blank' style='font-size: 13px' href='https://www.sunlife.ca/fr/' id='sunlife'>www.sunlife.ca.</a></p>"])},
  "CharactersLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractères De Longueur"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
  "fourOutOfThreeLeaves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quatre feuilles sur trois"])},
  "ThreeCalendarCrossedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trois calendriers barrés"])},
  "MortgageActivityAriaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez plus de détails sur l'activité hypothécaire (PDF)"])},
  "UserAgreementChangedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Notre entente d'utilisation a récemment été modifié.  Cliquez sur \"J'accepte\" pour prendre connaissance de la modification et accéder à ", _interpolate(_named("prefix")), _interpolate(_named("tenant"))])},
  "AccountLockedError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte est verrouillé. Veuillez attendre jusqu'à ", _interpolate(_named("date")), " et cliquez sur \"Problèmes de connexion\" pour récupérer votre nom d'utilisateur et réinitialiser votre mot de passe."])},
  "Safeguard": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de relevé de compte"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour mieux servir nos propriétaires, veuillez nous signaler la raison pour laquelle vous demandez un relevé de compte. Sélectionnez toutes les réponses qui s’appliquent."])},
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour aller de l’avant avec votre demande de relevé de compte, cliquez sur 'Continuer'."])},
    "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez toutes les réponses qui s’appliquent."])},
    "Options": {
      "Selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendre votre propriété"])},
      "Purchasing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter une propriété"])},
      "Transferring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer votre prêt hypothécaire"])},
      "Consolidationg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consolider vos dettes"])},
      "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie d'investir"])},
      "Renovations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planifier des rénovations"])},
      "Financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification financière"])},
      "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune de ces réponses"])}
    }
  },
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu du processus"])},
  "InfoStatementHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un relevé d’information fournit une vue actuelle de l'état de votre prêt hypothécaire à la date de la demande."])},
  "InfoStatementTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que contient un relevé ‘dinformation ?"])},
  "InfoStatementImportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails importants"])},
  "InfoStatementImportDetailsBody1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le relevé d’information se retrouve dans la sections Documents de votre portail ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), "."])},
  "InfoStatementImportDetailsBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seulement les renseignements les plus récents seront visibles."])},
  "InfoStatementImportDetailsBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre relevé peut être disponible dans un délai de 5 à 10 minutes, mais cela peut prendre jusqu'à 2 heures."])},
  "InfoStatementImportDetailsBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois qu'une demande est faite, vous ne pouvez pas faire une autre demande pendant 24 heures."])},
  "SubmitRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre la demande"])},
  "ThankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci!"])},
  "InfoStatementConfirmationHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez prévoir jusqu'à 2 heures pour que votre demande soit traitée."])},
  "InfoStatementConfirmationDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez être connecté à votre compte ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), " pour récupérer le document."])},
  "InfoStatementConfirmationBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour accéder à votre relevé d’information:"])},
  "InfoStatementConfirmationBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le bouton \"Détails hypothécaires\" sur la page de renvoi"])},
  "InfoStatementConfirmationBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allez dans l'onglet \"Documents\""])},
  "InfoStatementConfirmationBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur le lien «Relevé d’information»"])},
  "InfoStatementConfirmationBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le coin supérieur gauche de votre navigateur, cliquez sur \"Fichier\" puis sélectionnez \"Imprimer\""])},
  "InfoStatementErrorHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il semble y avoir un problème répondre à votre demande en ligne. Veuillez nous contacter au ", _interpolate(_named("phone")), " de 8h00 et 19h00 et un de nos représentants du service à la clientèle sera heureux de vous accueillir!"])},
  "InfoSecurityQuestionNewDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous êtes authentifié avec succès grâce à votre nom d'utilisateur et votre mot de passe. Pour plus de sécurité, veuillez répondre à la question ci-dessous."])},
  "QuizHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Testez vos connaissances en matière d’Assurance Vie et Invalidité Hypothécaire et courez la chance de gagner ", _interpolate(_named("prize")), "!"])},
  "QuizMissingAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On dirait que vous avez oublié cette question. Veuillez fournir votre réponse pour continuer."])},
  "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-Vous Sûr?"])},
  "QuizGoBackPrimaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous fermez le questionnaire avant de l'avoir terminé, vous ne serez pas inscrit au concours.<br/><br/>Vous pouvez reprendre le questionnaire à tout moment pendant la durée du concours pour y participer. Cliquez retourner pour le terminer maintenant."])},
  "ReturnToQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner"])},
  "WithoutTandCPrimaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas accepté les Termes et Conditions, vous devez «Accepter» pour participer au concours."])},
  "AcceptTermsAnsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "withoutTandCError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez accepter les Termes et Conditions pour poursuivre."])},
  "Quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
  "HomeWarrantyQuizSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Répondez au questionnaire sur le Plan d’entretien pour ", _interpolate(_named("type")), " et courez la chance de gagner ", _interpolate(_named("prize")), "!"])},
  "HomeWarrantyQuiz": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plan d’entretien pour ", _interpolate(_named("type"))])},
  "HomeAppliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électroménagers"])},
  "HomeSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes Résidentiels"])},
  "HomeCoverage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Plan d’entretien pour ", _interpolate(_named("type"))])},
  "HomeBenefit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avantages de notre Plan d’entretien pour ", _interpolate(_named("type"))])},
  "PrepareForUnexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparez-vous à l’inattendu et profitez des 3 PREMIERS MOIS à nos frais!"])},
  "ServiceOfferHeading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre jusqu’à "])},
  "ServiceOfferHeading1ColouredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 000 ", "$", " en couverture annuelle"])},
  "ServiceOfferHeading1Ending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([",  ce qui vous protège contre les dépenses et les factures de réparation inattendues!"])},
  "ServiceOfferHeading2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Couvre la réparation et le remplacement de vos ", _interpolate(_named("type")), " en cas de bris"])},
  "ServiceOfferHeading3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donne un accès direct à un réseau de réparateurs professionnels qualifiés"])},
  "EligibleToEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez souscrire au plan dès aujourd’hui."])},
  "EnrollmentCostHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous ne paierez que ", _interpolate(_named("cost")), " ", "$", " par mois, plus taxes"])},
  "Enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscrire"])},
  "Decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
  "RentedTandCHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je confirme qu’aucune portion de ma propriété n’est louée ni utilisée à des fins commerciales"])},
  "TandCHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["du Plan d’entretien pour ", _interpolate(_named("type")), " et j'accepte également les "])},
  "TandCSubHeading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’accepte les "])},
  "TandCSubHeading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalités "])},
  "SurveyContestTandCHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du Concours de sondage"])},
  "SurveyContestTandC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du Concours de sondage "])},
  "EnrollHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Souscrivez au Plan d’entretien pour ", _interpolate(_named("type")), " dès aujourd’hui et profitez des 3 premiers mois à nos frais!"])},
  "DeclineWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il s’agit d’une offre de durée limitée. Si vous refusez la couverture, il y a une période d’attente de six mois avant que vous soyez admissible au Plan d’entretien pour ", _interpolate(_named("type")), ".<br><br>Sélectionnez Fermer pour modifier votre décision d’inscription. En sélectionnant Continuer, vous ne serez pas inscrit à la couverture, mais vous serez inscrit au concours du Plan d’entretien pour ", _interpolate(_named("type")), "."])},
  "ThankYouAcceptEnrollTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre demande."])},
  "ThankYouAcceptEnrollText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une fois traitée, votre couverture apparaîtra sur votre page ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), " et vous recevrez une confirmation de souscription par courrier."])},
  "ConfirmEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, j’aimerais souscrire au Plan"])},
  "DeclineEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non merci"])},
  "InvalidPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone invalide."])},
  "HomeServiceCoverageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Protection Résidentielle"])},
  "HomeServiceCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Protection Résidentielle"])},
  "TandCDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez cocher le(s) case(s) suivante(s) pour continuer."])},
  "MultipleProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous possédez plusieurs propriétés. Veuillez sélectionner ci-dessous votre résidence principale."])},
  "FireInsuranceUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Requise: Veuillez Mettre à Jour Votre "])},
  "FireInsuranceDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos documents d’assurance incendie ont été "])},
  "Received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reçus."])},
  "FireHalfPt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style='display: inline-block;list-style: disc inside;padding: 12px;text-align: left;'><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'>Vous avez modifié votre police d’assurance existante.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'> Vous avez changé de fournisseur d’assurance.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'>Votre couverture d’assurance est arrivée à échéance.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'> Vos primes d’assurance ne sont pas à jour.</li></ul>"])},
  "FireFullPt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style='display: inline-block;list-style: disc inside;padding: 12px;text-align: left;'><li style='color: #5a5b68;font-size: 18px;font-weight: 500; text-indent: -1.5em;  padding: 5px;padding-left: 2em; '> Des frais seront facturés si nous ne recevons pas de preuve de couverture à la date indiquée dans votre lettre d’assurance-incendie.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;  text-indent: -1.5em; padding: 5px;padding-left: 2em; '>  La nouvelle police doit inclure l’adresse de la propriété et le(s) propriétaire(s) sur l’hypothèque.</li></ul>"])},
  "FireHalfTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span  style='color: #5a5b68;font-size: 18px;font-weight: 500;'>Votre police d’assurance incendie a été annulé en raison de l’un des changements ci-dessous dans votre couverture:</span>"])},
  "FireFullTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span  style='color: #5a5b68;font-size: 18px;font-weight: 500;'>En raison des modifications apportés à votre police d’assurance, des mesures sont nécessaires pour mettre à jour votre hypothèque. Veuillez cliquer sur Mettre à Jour pour nous fournir une preuve de votre couverture la plus récente.<br/><br/>Remarque:</span>"])},
  "MoreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de Détails"])},
  "WhatToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon à Savoir!"])},
  "UpdatePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à Jour"])},
  "FireReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons reçu vos documents. Veuillez prévoir jusqu’à 48 heures ouvrables pour que l’alerte soit supprimé."])},
  "LSPPCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculateur de Paiement Forfaitaire"])},
  "LSPPCalculatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour voir combien d’intérêts vous pouvez économiser et à quelle vitesse vous pouvez rembourser votre hypothèque, sélectionnez le scénario de paiement de votre choix ci-dessous."])},
  "PPPScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scénario de Paiement Forfaitaire"])},
  "EnterValuesLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplissez les valeurs à gauche pour calculer un scénario de paiement forfaitaire."])},
  "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
  "NewProjectAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel Amortissement Projeté"])},
  "InterestSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt Économisé"])},
  "TotalPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Total du Paiement"])},
  "PayOffFaster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Remboursez votre hypothèque ", _interpolate(_named("time")), " rapidement"])},
  "OverYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sur ", _interpolate(_named("years"))])},
  "TotalNumberOfPayments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total de ", _interpolate(_named("number")), " paiements effectués ", _interpolate(_named("frequency"))])},
  "TotalNumberOfPaymentsOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total de ", _interpolate(_named("number")), " paiement"])},
  "ApplyPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer le Paiement"])},
  "CompareScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les Scénarios"])},
  "ExitComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la Comparaison"])},
  "ScenarioNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scénario ", _interpolate(_named("number"))])},
  "PPPCompareScenario": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scénario de Paiement Forfaitaire ", _interpolate(_named("number"))])},
  "LSPF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence de paiement forfaitaire"])},
  "EnterValuesAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplissez les valeurs ci-dessous pour calculer un scénario de paiement forfaitaire."])},
  "ProjectedAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement Prévu"])},
  "ProjectedAmortizationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représente le temps réel qu’il faudra pour rembourser l’hypothèque au complet, en fonction du solde actuel, du taux d’intérêt, du montant et la fréquence des paiements et de tout changement de date future de la fréquence des versements et des paiements forfaitaires prévus."])},
  "LSPPCalc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer un Scénario de Remboursement"])},
  "FasterScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est le scénario le plus rapide!"])},
  "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION"])},
  "RedirectMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p style=\"padding: 10px 0\">L’adresse du site Web <b>", _interpolate(_named("oldUrl")), "</b> a été modifiée.</p><p style=\"padding: 10px 0\">Vous devrez mettre à jour vos favoris, vos signets et les liens pour l’écran d’accueil a <b>", _interpolate(_named("newUrl")), "</b> avant le <b>", _interpolate(_named("deadline")), "</b>.</p><p style=\"padding: 10px 0\">Votre nom d’utilisateur et mot de passe ne seront pas affectés par ce changement.</p>"])},
  "SessionExpiredHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée"])},
  "SessionExpiredSubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité et la protection de vos données personnelles, votre session a expiré après une période d'inactivité."])},
  "Conversion": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir à un Taux Fixe"])},
    "AlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer-moi une offre de taux d'intérêt fixe"])},
    "Alert1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez le meilleur taux fixe sur 5 ans disponible."])},
    "Alert2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'apporterons aucun changement à votre prêt hypothécaire jusqu'à ce que l'offre nous soit retournée, signée par tous les propriétaires."])},
    "Alert3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois l'offre signée soumise, vous recevrez une confirmation avec plus de détails par courriel électronique."])},
    "SuccessSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été soumise."])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un courriel électronique détaillant comment convertir à un taux fixe.  L'offre sera disponible dans les 2 jours ouvrables."])}
  },
  "Signatory": {
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
    "VerificationMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de vérification"])},
    "TextMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message texte"])},
    "MethodTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des frais de SMS peuvent s'appliquer. Veuillez vérifier auprès de votre fournisseur de services."])},
    "MobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre numéro de téléphone cellulaire ici."])},
    "EmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse courriel ici."])},
    "Actions": {
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réenvoyer"])}
    },
    "Errors": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs"])},
      "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone mobile à 10 chiffres."])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format d'adresse courriel n'est pas valide."])},
      "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez enregistrer/fermer tous les formulaires avant de continuer."])},
      "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
      "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone mobile canadien valide."])}
    }
  },
  "Renewal": {
    "Actions": {
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "GoBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to Homepage"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "UpdateResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update / Resend"])},
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])}
    },
    "Menu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renew My Mortgage"])},
      "RenewalIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renewal Introduction"])},
      "RatesAndProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Rates and Products"])},
      "ManageMyRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Manage My Renewal"])}
    },
    "Banner": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started on your mortgage renewal today!"])},
      "Message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["FR-Your mortgage will be up for renewal on <b>", _interpolate(_named("date")), "</b>. "])},
      "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at your renewal options and renew online."])}
    },
    "Introduction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renewal Introduction"])},
      "NextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Review Renewal Options"])},
      "Support": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Videos to Help You Along Your Homeowner Journey"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "InfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Why renew with MCAP?"])},
        "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renewing your mortgage is <b>free</b>!"])},
        "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-You do not need to re-qualify to renew your mortgage with MCAP. That means <b>no credit check</b> or <b>documents to submit</b>"])},
        "InfoNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-<b>Complete the entire process online</b> using the self-serve portal, or get assistance from yor personal Renewal Account Manager"])},
        "InfoNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-You're <b>guaranteed the best rates</b> available an can personalize your mortgage with additional products and features"])},
        "Footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Let's start your mortgage renewal!</br>Our goal is to make the renewal process as simple as possible. Using the self-serve mortgage renewal feature, you'll be able to compare mortgage options and the best solution to fit your needs."])}
      },
      "Compare": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Compare Rates\" to see your current variable rate and payment amount compared to today's rate options."])}
      },
      "Start": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Lock-In?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a lock-in offer is free of charge. Select \"Compare Rates\", choose a fixed rate option that suits you, and click \"Continue\". To request a Lock-in offer a valid email and mobile number will be required."])}
      }
    },
    "Product": {
      "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "PrincipalAndInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal and Interest"])},
      "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
      "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
      "PaymentAmountTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all charges that make up your total payment e.g. principal, interest, property tax, and other products if applicable."])},
      "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Date"])},
      "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amortization"])},
      "RemainingAmortizationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Represents the actual time it will take to pay off the mortgage in full, based on the current principal balance, interest rate, payment amount and payment frequency."])},
      "CurrentProductDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Product Details"])},
      "RenewalOfferDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in Offer Details"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
      "CancelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Cancel Lock-in Offer\" and you will remain in your current variable rate product."])},
      "CancelRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Lock-in Offer"])},
      "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])},
      "SigningStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Status"])},
      "SignaturePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Pending"])},
      "SigningCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Completed"])},
      "SigningArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Archived"])}
    },
    "CompareRates": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Start Your Mortgage Renewal"])},
      "Filter": {
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Fixed"])},
        "Adjustable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Adjustable"])},
        "1YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-1yr Fixed"])},
        "2YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-2yr Fixed"])},
        "3YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-3yr Fixed"])},
        "4YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-4yr Fixed"])},
        "5YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-5yr Fixed"])},
        "5YearValueFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-5yr Value Flex Fixed / 5yr Low Rate Basic"])},
        "5YearValueVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-5yr Value Flex Variable/5yr Low Rate Basic"])},
        "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Show All"])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Mortgage Options and Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Choosing your next mortgage is all about finding what works for you. Take a look at the different mortgage products and rates to find the one that best suits your needs."])},
        "ViewAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Options"])},
        "HideAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a rate to continue."])},
        "ChangeNoticeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Change as of November 1st, 2023"])},
        "ChangeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <a href='https://www.mcap.com/residential-mortgages/products/our-mortgages/fusion-mortgage' target='_blank'>Here</a> for details on the changes that will occur when renewing your MCAP Fusion mortgage."])}
      }
    },
    "Warranty": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Protect Your Investment"])},
      "VideoSection": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Home Service Plan: Protect your home with affordable coverage"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Watch the video below to see how the Home Appliance Service Plan can save you unexpected repairs of a costly home appliance."])}
      },
      "Benefits": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Did You Know?"])},
        "Sections": {
          "Dishwasher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-The life expectancy of a dishwasher is 9 years"])},
          "WashingMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-The life expectancy of a washing machine is 10 years"])},
          "Appliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-30 -40% of appliances require repairs in the first 5 years"])}
        },
        "Note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-1. Reflects average data from claims experiences for appliance repairs of the provider of the MCAP Home Appliance Service Plan."])},
        "Note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-2. Consumer Reports, 'By the numbers how long will your appliances last?' 2009"])}
      },
      "Eligibility": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Eligibility"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-No portion of your home can be used for rental or commercial purposes and coverage must be active for 30-days prior to making a claim. To read the complete list of Terms and Conditions for the Home Appliance Service Plan. "])},
        "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Click here"])}
      },
      "ServiceFees": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Service Fees"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-No portion of your home can be used for rental or commercial purposes and coverage must be active for 30-days prior to making a claim. To read the complete list of Terms and Conditions for the Home Appliance Service Plan. "])}
      },
      "Offer": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-When you enroll you will get the first 3 months of membership fees at no cost!"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Select \"Continue\" to begin enrollment and be sure your Home Appliances are protected."])}
      },
      "Options": {
        "Coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Add coverage to your mortgage"])},
        "Skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Skip for now"])},
        "ErrorNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-"])},
        "ErrorMandatoryAttest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-"])}
      }
    },
    "Support": {
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
      "ContactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-800-265-2624"])}
    },
    "StepsBar": {
      "Rates": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Select Your New Mortgage & Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Warranty": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Add a Home Service Plan"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-A warranty to protect one of your biggest investments."])}
      },
      "Appliances": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Add a Home Service Plan"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-A warranty to protect one of your biggest investments."])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Confirm your contact information to receive and sign your renewal documents."])}
      },
      "Summary": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renewal Summary & Confirmation"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Review and submit your mortgage renewal."])}
      }
    },
    "ConfirmRate": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Lock-in Your New Rate"])},
      "DateAndRate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Effective Date and Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change one of your selections above, select \"Back\" to go to the previous screen."])}
      }
    },
    "Signature": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Lock-in Your New Rate"])},
      "Sign": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Receiving Your Electronic Documents"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-To complete your mortgage renewal, we'll need you to sign your mortgage documents electronically. Please confirm the contact information below, or click update to make any changes."])},
        "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will email you a notification within 1 hour when the lock-in offer is ready to be signed."])}
      },
      "Errors": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
        "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a 10-digit mobile number."])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a correct email address."])},
        "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Save/Close all forms before continuing."])},
        "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
        "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Canadian mobile number."])}
      }
    },
    "Roles": {
      "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeowner"])},
      "Guarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantor"])}
    },
    "Summary": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Renewal Summary & Confirmation"])},
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Your New Mortgage & Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Your mortgage renewal at a glance."])}
      },
      "Warranty": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Protect Your Investment"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-The Home Service Plan will be added to your mortgage after the renewal date."])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Please review contact information."])}
      },
      "Confirm": {
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Error while processing your request"])}
      }
    },
    "Success": {
      "Request": {
        "Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Your Mortgage Renewal Offer is being created!"])},
        "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Your mortgage renewal documents will be sent to your email shortly."])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR-Once all the documents have been signed by the required homeowners, we will begin processing your renewal."])}
      }
    },
    "PostOffer": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage My Lock-in"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you online support to help you manage your lock-in offer and electronic signature experience. Managing your account has never been easier. See how we can help below."])},
      "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the offer is not signed by all homeowners and guarantors by the effective date, it will be cancelled and no changes will occur."])},
      "HelpOptions": {
        "One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To resend the link, update the mobile number or email address click \"Update / Resend\"."])},
        "Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To find out who has signed the offer, review the signing status on the \"Lock-in Offer Details\" section of this page."])},
        "Three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cancel the offer, select \"Cancel Lock-in Offer\".  Please note rates are subject to change."])}
      }
    },
    "PostOfferOld": {
      "DescriptionOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have made the decision to lock-in your rate and your new payment of <strong>", _interpolate(_named("paymentAmount")), "</strong> starts on <strong>", _interpolate(_named("paymentDate")), "</strong>. Your fixed rate principal and interest payments will remain the same for the duration of the term you selected. Variable rate principal and interest payments are subject to change."])},
      "DescriptionTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The new rate on your account will be updated the day after <strong>", _interpolate(_named("effectiveDate")), "</strong> and within 2 business days, a confirmation letter will be available in the \"Documents\" section."])},
      "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Should you decide to change your mind, you have until <strong>", _interpolate(_named("effectiveDate")), "</strong> to cancel the offer. Select \"Cancel Lock-in Offer\" and no changes will be made."])}
    },
    "Cancel": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel?"])},
      "CancelAtSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose \"Continue\" to cancel, all rates displayed are subject to change."])},
      "CancelRenewalRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cancel, the Lock-in process will have to be started again and you may be subject to a higher rate."])},
      "ContinueToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"<strong>Continue</strong>\" to cancel"])}
    },
    "Resend": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email to sign document"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Information"])}
    },
    "Restriction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Product - Review Details"])},
      "IAcknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I acknowledge"])},
      "MS": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCAP Value Flex"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to transfer or refinance this product outside of MCAP until its renewal date."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance or early renew within MCAP to another Value Flex Product at anytime."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mortgage can only be paid out upon the sale of the property to a non-family member.  Regular pre-payment penalties may apply."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "RM": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMG Low Rate Basic"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying out this mortgage early will result in a penalty which is the greater of 3% of the outstanding balance or an intertest rate differential penalty."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early renewal options are not available with this product."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mortgage cannot be assumed to avoid a penalty."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance this mortgage with RMG into another Low Rate Basic product."])}
      }
    },
    "Notification": {
      "ClickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
      "DownloadFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in offer is available, please "])},
      "DownloadFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to download and print - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "PendingFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in request pending, "])},
      "PendingFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to upload the signed document - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "DocumentProcessed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lock-in document has been processed – Effective date is set for ", _interpolate(_named("effectiveDate"))])}
    }
  },
  "LockIn": {
    "Actions": {
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauver"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
      "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisi"])},
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "GoBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d'accueil"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "UpdateResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier / Réenvoyer"])},
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réenvoyer"])}
    },
    "Menu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options pour une demande de passer à un taux fixe"])},
      "VideosAndSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéos et Soutien"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les taux"])},
      "ManageMyLockIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer mon passage à un taux fixe"])}
    },
    "Product": {
      "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
      "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
      "PrincipalAndInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital et intérêts"])},
      "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de paiement"])},
      "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])},
      "PaymentAmountTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprend tous les montant qui composent votre paiement total, par ex. capital, intérêts, impôt foncier et autres produits, le cas échéant."])},
      "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de renouvellement"])},
      "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement restant"])},
      "RemainingAmortizationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représente la durée réelle nécessaire pour rembourser le prêt hypothécaire en totalité, selon le solde de capital, le taux d’intérêt, le montant et la fréquence des paiements actuels."])},
      "CurrentProductDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du produit actuel"])},
      "LockInOfferDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'offre"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les taux"])},
      "CancelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez \"Annuler l'offre de verrouillage\" pour préserver votre produit à taux variable actuel."])},
      "CancelLockIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'offre de verrouillage"])},
      "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'entrée en vigueur"])},
      "SigningStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la signature"])},
      "SignaturePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature en attente"])},
      "SigningCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature complétée"])},
      "SigningArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Archived"])}
    },
    "CompareRates": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparaison de taux"])},
      "Date": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une date"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une date dans les 30 prochains jours, selon votre fréquence de paiement actuelle, en sélectionnant le calendrier."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'entrée en vigueur ci-dessous est la date la plus rapprochée disponible."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En changeant la date d'entrée en vigueur, l'information de paiement ci-dessous sera modifiée en conséquence."])},
        "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la date d'entrée en vigueur"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une date pour continuer."])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les taux"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour convertir votre prêt sans pénalité, vous devez choisir une nouvelle durée de prêt égale ou supérieure à la durée restante de votre prêt actuel. Vos options sont affichées ci-dessous, y compris le meilleur taux disponible."])},
        "ViewAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'options"])},
        "HideAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner un taux pour continuer."])},
        "ChangeNoticeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement important à partir du 1er novembre 2023"])},
        "ChangeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez <a href='https://www.mcap.com/fr/residentiel/produits/nos-prets-hypothecaires/pret-fusion' target='_blank'>ici</a> pour plus de détails sur les changements qui surviendront lors de la conversion de votre prêt hypothécaire Fusion de MCAP."])}
      },
      "ReadyToLockIn": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous désirez bloquer votre nouveau taux?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI vous désirez aller de l'avant avec le blocage du taux que vous avez choisi, cliquez sur «Continuer» pour passer aux étapes suivantes."])}
      }
    },
    "Support": {
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soutien"])},
      "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiquez avec nous"])},
      "ContactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-888-811-2529"])}
    },
    "StepsBar": {
      "Confirm": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la date d'entrée en vigueur et le taux"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements de signature électronique"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournissez un courriel et un numéro de téléphone mobile valides pour recevoir et signer votre offre."])}
      },
      "Summary": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez que tous vos renseignements sont correctes."])}
      }
    },
    "ConfirmRate": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes pour bloquer votre nouveau taux"])},
      "DateAndRate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la date d'entrée en vigueur et le taux"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour modifier l'un de vos choix ci-dessus, cliquez sur «Retour» pour revenir à la page précédente."])}
      }
    },
    "Introduction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
      "Support": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus sur les taux variables"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en savoir plus sur les taux variables, visionnez les vidéos ci-dessous."])},
        "InfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce qu'il faut savoir"])},
        "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour convertir votre prêt sans pénalité, vous devez choisir une nouvelle durée de prêt égale ou supérieure à la durée restante de votre prêt actuel."])},
        "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour connaître les taux et les durées que vous pouvez convertir en sans pénalité, sélectionnez « Comparer les taux »."])}
      },
      "Compare": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les taux"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez «Comparer les taux» pour comparer votre taux variable actuel et votre montant payé avec les taux offerts actuellement en vigueur."])}
      },
      "Start": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous désirez bloquer votre taux?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création d'une offre de blocage de taux est sans frais. Sélectionnez «Comparer les taux», choisissez une option à taux fixe qui vous convient, puis cliquez sur «Continuer». Pour demander une offre de blocage, un courriel et un numéro de téléphone mobile valides sont requis."])}
      }
    },
    "Signature": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes pour bloquer votre nouveau taux"])},
      "Sign": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer des documents par voie électronique"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de remplir et signer l'offre, nous enverrons un lien par courriel à tous les propriétaires et garants mentionnés ci-dessous pour que chacun puisse accéder au document. Chaque personne devra confirmer son identité à l'aide d'un numéro de téléphone mobile."])},
        "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous enverrons une notification par courriel dans un délai d'une heure lorsque l'offre de passer à un taux fixe sera prête à être signée."])}
      },
      "Review": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification des renseignements"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier chaque numéro de téléphone mobile et chaque adresse électronique ci-dessous. Sélectionnez «Modifier» pour ajouter ou modifier les informations."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations que vous fournissez ci-dessous ne seront utilisées que pour cette transaction."])}
      },
      "Errors": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs"])},
        "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone mobile à 10 chiffres."])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format d'adresse courriel n'est pas valide."])},
        "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez enregistrer/fermer tous les formulaires avant de continuer."])},
        "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
        "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de téléphone mobile canadien valide."])}
      }
    },
    "Roles": {
      "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
      "Guarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garant"])}
    },
    "Summary": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes pour bloquer votre nouveau taux"])},
      "Date": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'entrée en vigueur"])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux sélectionné"])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de signature électronique"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier le courriel et le numéro de téléphone mobile que vous avez fournis. Pour modifier vos coordonnées, cliquez sur «Changer» pour modifier l'information."])}
      },
      "Confirm": {
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur «Confirmer» pour terminer votre demande."])},
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du traitement de votre demande"])}
      }
    },
    "Success": {
      "Request": {
        "Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande soumise"])},
        "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est en cours de traitement."])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier ci-dessous «L'information importante» et «Étapes suivantes»."])}
      },
      "Information": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information importante"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'entrée en vigueur de votre passage à un taux fixe sera mentionnée sur l'offre et le taux sera garanti jusqu'à cette date."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour finaliser l'offre, elle doit être signée par tous les propriétaires et garants."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'offre n'est pas signée par tous les propriétaires et garants avant la date d'entrée en vigueur, elle sera annulée et aucun changement ne sera effectué."])}
      },
      "NextSteps": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étapes suivantes"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réception de votre offre et des instructions de signature électronique peut prendre jusqu'à 1 heure."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les personnes figurant au prêt hypothécaire recevront le courriel d'instructions."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette notification par courriel sera envoyée depuis l'adresse <", _interpolate(_named("email")), ">."])},
        "DescriptionFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous n'apporterons aucun changement avant que l'offre n'ait été signée par toutes les personnes figurant au prêt hypothécaire."])}
      }
    },
    "PostOffer": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer mon passage à un nouveau taux"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous proposons une assistance en ligne pour vous aider à gérer votre offre de passer à un taux fixe et votre expérience de signature électronique. La gestion de votre compte n'a jamais été aussi simple. Découvrez comment nous pouvons vous aider ci-dessous."])},
      "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'offre n'est pas signée par tous les propriétaires et garants avant la date d'entrée en vigueur, elle sera annulée et aucun changement ne sera effectué."])},
      "HelpOptions": {
        "One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour renvoyer le lien ou mettre à jour le numéro de téléphone cellulaire ou l'adresse courriel, cliquez sur \"Réenvoyer\"."])},
        "Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour savoir qui a signé l'offre, vérifiez le statut de la signature dans la section des \"Détails de l'offre\" de passer à un nouveau taux de cette page."])},
        "Three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour annuler l'offre, sélectionnez \"Annuler l'offre de verrouillage\". Veuillez noter que les taux sont susceptibles d'être modifiés."])}
      }
    },
    "PostOfferOld": {
      "DescriptionOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez pris la décision de bloquer votre taux et votre nouveau paiement de <strong>", _interpolate(_named("paymentAmount")), "</strong> commence le <strong>", _interpolate(_named("paymentDate")), "</strong>. Vos paiements de capital et d'intérêts à taux fixe resteront les mêmes pour la durée du terme que vous avez choisi. Les paiements de capital et d'intérêts à taux variable sont sujets à changement."])},
      "DescriptionTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le nouveau taux sur votre compte sera mis à jour le jour suivant <strong>", _interpolate(_named("effectiveDate")), "</strong> et dans les 2 jours ouvrables, une lettre de confirmation sera disponible dans la section \"Documents\"."])},
      "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si vous décidez de changer d'avis, vous avez jusqu'au <strong>", _interpolate(_named("effectiveDate")), "</strong> pour annuler l'offre. Sélectionnez \"Annuler l'offre de verrouillage\" et aucun changement ne sera effectué."])}
    },
    "Cancel": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désirez-vous annuler le changement?"])},
      "CancelAtSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous \"continuez\" avec l’annulation, tous les taux affichés pourraient être modifiés."])},
      "CancelLockInRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annulation fera en sorte que le processus de verrouillage devra reprendre du début ; les taux peuvent alors être modifiés."])},
      "ContinueToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez \"<strong>Continuer</strong>\" pour annuler."])}
    },
    "Resend": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renvoyer le courriel pour signer le document"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les informations"])}
    },
    "Restriction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit restreint - Confirmation des détails"])},
      "IAcknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’accepte"])},
      "MS": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt Écono-Flex de MCAP"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne serez pas en mesure d’effectuer un transfert ou un refinancement pour ce produit hors de MCAP, et ce, d’ici la date de renouvellement."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourriez effectuer un refinancement ou un renouvellement anticipé avec MCAP vers un autre produit Écono-Flex, et ce, en tout temps."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce prêt hypothécaire peut uniquement être remboursé advenant la vente de la propriété à une personne non apparentée. Les pénalités de remboursement courantes peuvent s’appliquer."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "RM": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêt Essentiel à bas taux de RMG"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le remboursement de ce prêt hypothécaire entraînera une pénalité correspondant au montant le plus élevé entre 3 % du solde dû ou le différentiel des taux d’intérêt."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les options de renouvellement anticipé ne sont pas offertes pour ce produit."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prêt hypothécaire ne peut être pris en charge pour éviter les pénalités."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez refinancer ce prêt hypothécaire avec RMG vers un autre produit Essentiel à bas taux."])}
      }
    },
    "Notification": {
      "ClickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquer ici"])},
      "DownloadFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre de passage à un taux fixe est disponible, veuillez "])},
      "DownloadFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["pour télécharger et imprimer - Signatures requises avant le ", _interpolate(_named("effectiveDate")), "."])},
      "PendingFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de passage à un taux fixe en attente, "])},
      "PendingFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["pour télécharger le document signé - Signatures requises avant le ", _interpolate(_named("effectiveDate")), "."])},
      "DocumentProcessed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre offre de passer à un taux fixe a été traité - La date d'entrée en vigueur est le ", _interpolate(_named("effectiveDate"))])}
    }
  },
  "AcceptAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter tout"])},
  "RejectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser tout"])},
  "PaymentAssistance": {
    "Landing": {
      "VideoCard": {
        "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez-en plus sur l'aide au paiement"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous traversez des difficultés financières et avez besoin d'aide avec vos paiements hypothécaires? Nous pouvons vous aider! Découvrez-en plus sur les demandes d'aide au paiement et voyez comment cela pourrait vous convenir."])}
      },
      "Acknowledge": {
        "NeedToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'aide au paiement"])},
        "Acknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation du processus"])},
        "AcknowledgementCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les conditions susmentionnées pour que ma demande soit traitée"])}
      },
      "ApplyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander Maintenant"])}
    },
    "Cancel": {
      "Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous procéder?"])},
      "CancelContentFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous annulez le tout, vos renseignements ne seront pas soumis et vos réponses seront perdues."])},
      "CancelContentSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez « Annuler » pour quitter ou « Continuer » pour poursuivre votre demande d'aide au paiement."])},
      "CancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "ContinueButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])}
    },
    "Confirmation": {
      "ConfirmationHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre demande"])},
      "ConfirmationSubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements importants"])},
      "ConfirmationPointer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un courriel de confirmation avec un formulaire de revenus et dépenses et des instructions supplémentaires dans les 15 minutes.  Le tout pourrait être redirigé dans votre dossier de pourriels."])},
      "ConfirmationPointer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le formulaire des revenus et dépenses et tous les documents à l'appui doivent être remplis en entier et retournés par courriel afin d’évaluer votre demande."])},
      "ConfirmationPointer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter qu'aucun changement ne sera apporté à vos paiements hypothécaires tant que votre demande n'aura pas été examinée et approuvée."])}
    },
    "Error": {
      "ErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreurs"])},
      "NonSelectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez faire une sélection pour continuer."])},
      "MainReasonEmptyOtherError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer la raison principale de votre demande dans la case prévue à cet effet"])},
      "OtherOptionsEmptyOtherError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer les autres options que vous avez envisagées dans la case prévue à cet effet."])}
    }
  },
  "NoSAPDataError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR - No Payment Assistance submissions found for this date range."])},
  "BPE0001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur demandé est déjà enregistré. Veuillez choisir un autre nom d'utilisateur."])},
  "BPE0003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ou plusieurs champs d'inscription ne correspondent pas aux données du système."])},
  "BPE0004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ou plusieurs champs d'inscription ne correspondent pas aux données du système."])},
  "BPE0006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prêt hypothécaire n'est pas admissible à l'inscription. Pour obtenir de l'aide, consultez l'onglet Nous joindre pour les détails."])},
  "BPE0008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'inscription de client pour tous les emprunteurs admissibles a déjà été effectuée. Veuillez cliquer sur le bouton Annuler, et entrez le nom d'utilisateur et le mot de passe que vous avez déjà créés. Pour récupérer votre nom d'utilisateur ou votre mot de passe, suivez les liens Nom d'utilisateur ou Mot de passe oublié."])},
  "BPE0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur n'a pas complété la validation d'inscription."])},
  "BPE3008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un nom d'utilisateur"])},
  "BPE3013": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre compte est verrouillé. Veuillez attendre jusqu'à ", _interpolate(_named("date")), " et cliquez sur \"Problèmes de connexion\" pour récupérer votre nom d'utilisateur et réinitialiser votre mot de passe."])},
  "BPE3019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le numéro de compte hypothécaire"])},
  "BPE3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de compte hypothécaire valide (9999999.1)."])},
  "BPE3022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir le champ Nom de famille."])},
  "BPE3027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le mot de passe"])},
  "BPE3034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La première question de sécurité est requise"])},
  "BPE3035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse a la première question de sécurité est requise"])},
  "BPE3036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La deuxième question de sécurité est requise"])},
  "BPE3037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse a la deuxième question de sécurité est requise"])},
  "BPE3038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La troisième question de sécurité est requise"])},
  "BPE3039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réponse a la troisième question de sécurité est requise"])},
  "BPE3041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le nouveau mot de passe est le même que l'ancien mot de passe"])},
  "BPE3042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou mot de passe n'a pas été trouvé"])},
  "BPE3044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite (Veuillez réessayer?)"])},
  "BPE3055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une adresse courriel valide."])},
  "BPE3065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer les détails de votre demande."])},
  "BPE3066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre nom complet."])},
  "BPE3067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir le fichier à télécharger."])},
  "BPE4085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les documents téléchargés dépassent la limite maximum de 50 MB"])},
  "BPE4088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les documents téléchargés dépassent la limite de 7 documents maximum"])},
  "BPE5058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas éligible au paiement forfaitaire selon les conventions de votre prêt hypothécaire."])},
  "BPE5061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun renseignement n'est disponible."])},
  "BPE5064": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comme votre hypothèque accuse actuellement un retard, nous sommes dans l'impossibilité de traiter votre demande.  Veuillez nous contacter au ", _interpolate(_named("phoneNumberFrench")), "  de ", _interpolate(_named("serviceHoursFrench")), " et un de nos représentants du service à la clientèle  sera heureux de vous accueillir."])},
  "BPE5065": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous n'êtes pas actuellement éligible au paiement forfaitaire. Pour en savoir plus veuillez communiquer avec l'un de nos spécialistes du service à la clientèle au ", _interpolate(_named("phoneNumberFrench")), " entre ", _interpolate(_named("serviceHoursFrench")), "."])},
  "BPE5066": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il semble y avoir un problème répondre à votre demande en ligne.  Veuillez nous contacter au ", _interpolate(_named("phoneNumberFrench")), " de ", _interpolate(_named("serviceHoursFrench")), " et un de nos représentants du service à la clientèle  sera heureux de vous accueillir!"])},
  "BPE5067": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nous avons des difficultés à traiter votre demande.  Si vous avez déjà un paiement forfaitaire en cours, vous pouvez l'annuler en allant sur 'Gérer les paiements forfaitaires' dans le menu libre-service et en essayant à nouveau.  Sinon, veuillez nous contacter à ", _interpolate(_named("phoneNumberFrench")), " entre les heures de ", _interpolate(_named("serviceHoursFrench")), "."])},
  "BPE7015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La longueur minimale du mot de passe doit être de huit caractères sans espace et contenir des caractères numériques, alpha et spéciaux."])},
  "BPE7028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer les quatre (4) derniers chiffres de votre numéro de compte bancaire."])},
  "BPE7030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer un numéro de compte bancaire valide."])},
  "BPE7033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un dernier essai dans le cadre de la procédure d'inscription avant que le tout ne soit verrouillé pour le numéro hypothécaire entré. Veuillez réessayer."])},
  "BPE7044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vous reste une tentative! Ne verrouillez pas votre compte, cliquez sur \"Problème de connexion?\" pour réinitialiser votre mot de passe ou récupérer votre nom d'utilisateur"])},
  "BPE7047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un sujet."])},
  "BPE7071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prêt hypothécaire n’est plus actif. Les modifications pour prêt en cours ne sont pas permises."])},
  "BPE7072": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette modification ne peut pas être effectuée car une modification liée au paiement est déjà en cours.Veuillez nous appeler au ", _interpolate(_named("phoneNumber")), " entre 8h00 et 20h00 du lundi au vendredi pour obtenir de l'assistance supplémentaire."])},
  "BPE7073": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il semble que vous sautiez un paiement ou qu’il y a une retenue sur un paiement à venir. Ce faisant, nous ne sommes pas en mesure de traiter votre demande. Veuillez communiquer avec nous au ", _interpolate(_named("phoneNumberFrench")), ", de ", _interpolate(_named("serviceHoursFrench")), ", et l’un de nos représentants au service à la clientèle sera heureux de vous aider!"])},
  "BPE7074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le type de prêt hypothécaire que vous avez, aucune autre fréquence de paiement n’est permise."])},
  "BPE7076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe temporaire que vous avez utilisé est expiré."])},
  "BPE7077": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il semble y avoir un problème répondre à votre demande en ligne.  Veuillez nous contacter au ", _interpolate(_named("phoneNumberFrench")), " de ", _interpolate(_named("serviceHoursFrench")), " et un de nos représentants du service à la clientèle  sera heureux de vous accueillir!"])},
  "BPE7078": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alors que continuons à aider les propriétaires de résidence à traverser la COVID-19, il se peut que nous ayons des options qui répondent mieux à vos besoins financiers. Si vous désirez faire une demande pour sauter votre prochain paiement hypothécaire, ou si vous voulez discuter des options qui s'offrent à vous concernant votre prochain paiement, veuillez communiquer avec nous au ", _interpolate(_named("phoneNumberFrench")), ", de ", _interpolate(_named("serviceHoursFrench")), "."])},
  "BPE8001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur doit accepter le contrat d'utilisation"])},
  "BPE8002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de répondre à des questions de sécurité"])},
  "BPE8003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nécessite la création des questions de sécurité"])},
  "BPE8004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les réponses aux questions de sécurité sont erronées"])},
  "BPE8005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les réponses aux questions de sécurité sont fausses, il reste 1 tentative"])},
  "BPE8006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe temporaire, nouveau mot de passe requis"])},
  "BPE8007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe n'est pas valide"])},
  "BPE8009": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Notre site est actuellement hors ligne pour un entretien régulier. Veuillez réessayer plus tard ou nous contacter au ", _interpolate(_named("phoneNumberFrench")), " entre les heures de ", _interpolate(_named("serviceHoursFrench")), " du lundi au vendredi. Nous vous prions de nous excuser pour tout inconvénient."])},
  "BPE8010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code d'activation a expiré.  Vous pouvez envoyer un nouveau code en utilisant le lien ci-dessus."])},
  "BPE8011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone mobile invalide; entrez seulement un numéro à 10 chiffres"])},
  "BPE8012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien que vous utilisez pour réinitialiser votre mot de passe a expiré. Veuillez relancer le processus de récupération si vous rencontrez encore des problèmes pour vous connecter."])},
  "BPE8013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre jeton pour la réinitialisation du mot de passe est invalide."])},
  "BPE8015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de fin est requise"])},
  "BPE8016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de début est requise"])},
  "BPE8017": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b style='font-weight:bolder'>Nous avons déjà reçu votre demande de saut-de-paiement.<br/><br/>Nous vous prions de ne pas nous appeler/envoyé un courriel. Vous pouvez soumettre une autre demande le ", _interpolate(_named("nextEligiblePaymentDate")), ".<br/><br/>Le dernier paiement sauté lors de votre demande précédente était le ", _interpolate(_named("lastSkippedPaymentDate")), ".</b><br/><br/>Veuillez noter que nous exigeons un minimum de 5 jours ouvrables d’avis pour modifier un paiement à vernir.<br/><br/> Un saut-de-paiement s’agit de l’ajout de la portion d’intérêt d’un paiement au solde de l’hypothèque. Toutes autres charges seront toujours prélevés lors de votre date de paiement prévu."])},
  "BPE8018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La description de la source de fond doit être entre 5 et 50 caractères."])},
  "BPE8020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nécessite la création des questions de sécurité car les vôtres ont expiré"])},
  "BPE8021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier le reCAPTCHA"])},
  "BPE8022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez transféré un type de fichier invalide; veuillez consulter la liste des types compatibles ci-dessous."])},
  "BPE8023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert de fichier non supporté présentement."])},
  "BPE8024": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le message texte n'a pas été envoyé au numéro de téléphone portable. Veuillez réessayer plus tard ou nous contacter au ", _interpolate(_named("phoneNumber")), " entre les heures de ", _interpolate(_named("serviceHours")), " du lundi au vendredi. Désolé pour l’ inconvénient"])},
  "BPE8025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur et/ou le mot de passe est incorrect"])},
  "BPE8026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom d'utilisateur et/ou le mot de passe est incorrect. Utilisez l'icône de l'œil pour vérifier votre mot de passe et réessayez."])},
  "BPE8027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des difficultés à vous connecter?"])},
  "BPE8028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez encore une tentative avant de verrouiller votre compte pendant 30 minutes. Cliquez sur \"Problèmes de connexion\" maintenant."])},
  "BPE8029": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette modification ne peut pas être effectuée car une modification liée au paiement est déjà en cours.Veuillez nous appeler au ", _interpolate(_named("phoneNumber")), " entre 8h00 et 20h00 du lundi au vendredi pour obtenir de l'assistance supplémentaire."])},
  "BPE8031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés d'avoir rencontré un problème avec votre téléversement. Le nom du document dépasse 100 caractères. Veuillez renommer le fichier et réessayer de le téléverser"])},
  "BPS0008": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez dépassé le nombre maximal d'essais d'inscription. La procédure d'inscription pour le numéro hypothécaire entré est maintenant verrouillée jusqu'au ", _interpolate(_named("lockoutExpirationLocal")), "."])},
  "LOC6123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant demandé dépasse le montant disponible"])},
  "USR3095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique invalide."])},
  "USR4057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un barème d'amortissement ne peut être produit présentement pour votre prêt hypothécaire. Cela pourrait provenir du fait qu'un renouvellement est en cours, ou que le prêt hypothécaire a déjà été remboursé."])},
  "USR4060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de paiement introuvable pour suppression"])},
  "USR4061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour incompatible de date et d'heure de la source de paiement"])},
  "USR4062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La source de paiement ne peut être supprimée"])},
  "USR5052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune date disponible pour les paiements en cours dans l'année en cours"])},
  "USR5083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme des paiements ne peut dépasser le maximum permis en paiements extraordinaires"])},
  "USR5088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme des paiements ne peut dépasser le maximum permis par anniversaire en paiements extraordinaires"])},
  "USR5095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement forfaitaire courant non permis avant le changement au cycle de paiement en cours"])},
  "USR5096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de fonds requise pour ce(s) paiement(s)"])},
  "USR5097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La source de fonds doit contenir au moins 5 caractères"])},
  "USR5098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selon le type de prêt hypothécaire que vous avez, vous ne pouvez pas effectuer de paiements supplémentaires"])},
  "USR5099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source de fonds non requise et sera ignorée"])},
  "USR5100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement forfaitaire ne peut être traité"])}
}