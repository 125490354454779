<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="13.665" height="12.726" viewBox="0 0 13.665 12.726">
    <g id="link_2_" data-name="link (2)" transform="translate(0 -16.6)">
        <g id="Group_3199" data-name="Group 3199" transform="translate(0 16.6)">
        <path id="Path_4412" data-name="Path 4412" d="M7.223,188.627a.381.381,0,0,0,0,.541,1.59,1.59,0,0,1,0,2.247L3.968,194.67a1.59,1.59,0,0,1-2.247,0l-.49-.49a1.59,1.59,0,0,1,0-2.247l3.255-3.255a.382.382,0,0,0-.541-.541L.691,191.393a2.351,2.351,0,0,0,0,3.329l.49.49a2.358,2.358,0,0,0,3.329,0l3.255-3.255a2.358,2.358,0,0,0,0-3.329A.381.381,0,0,0,7.223,188.627Z" transform="translate(0 -183.173)" fill="#e3e3e4"/>
        <path id="Path_4413" data-name="Path 4413" d="M192.061,17.777l-.49-.49a2.358,2.358,0,0,0-3.329,0l-3.255,3.255a2.358,2.358,0,0,0,0,3.329.382.382,0,1,0,.541-.541,1.59,1.59,0,0,1,0-2.247l3.255-3.255a1.59,1.59,0,0,1,2.247,0l.49.49a1.59,1.59,0,0,1,0,2.247l-3.255,3.255a.384.384,0,0,0,.269.654.373.373,0,0,0,.269-.113l3.255-3.255a2.354,2.354,0,0,0,0-3.329Z" transform="translate(-179.083 -16.6)" fill="#e3e3e4"/>
        </g>
    </g>
    </svg>
</template>

<script>
export default {
  name: "AttachmentIcon",
};
</script>

<style lang="scss" scoped>

svg {
  path {
    fill: #afafb6;
  }
  &.dark {
    path {
      fill: black;
    }
  }
}
</style>
