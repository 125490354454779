import { createRouter, createWebHistory } from "vue-router"


import store from "./store/index";
import { CookieService } from "@/services/cookieService";
import userService from "@/services/userService";
import { QuizData } from "@/store/types/quizData";
import {
  isQuizAvailable,
  UserHasOffer,
  GetQuizData,
  isFormAvailable,
} from "@/services/utilService";

// Vue.use(Router);
declare let mcap_config: any;
const assertValidConfig = (next: any) => {
  if (!(window as any).mcap_config || !(window as any).mcap_config.code) {
    next("Unavailable");
    return false;
  }
  return true;
};

const validConfigGuard = (to: any, from: any, next: any) => {
  if (assertValidConfig(next)) {
    next();
  }
};

const guard = (to: any, from: any, next: any) => {
  if (!assertValidConfig(next)) {
    return;
  }
  if (
    store.state.user &&
    store.state.user !== null &&
    (store.state.user.isCsr || store.state.user.isSap) &&
    !store.state.user.isImpersonating
  ) {
    next("/impersonate");
  } else if (store.state.user) {
    next();
  } else {
    next("/login");
  }
};

const guardWithRedirect = (to: any, from: any, next: any) => {
  if (!assertValidConfig(next)) {
    return;
  }
  if (
    store.state.user &&
    store.state.user !== null &&
    (store.state.user.isCsr || store.state.user.isSap) &&
    !store.state.user.isImpersonating
  ) {
    next("/impersonate");
  } else if (store.state.user) {
    next();
  } else {
    if (to?.query?.lang === "fr") {
      CookieService.setCookie("lang", "fr");
    }
    if (to?.query?.lang === "en") {
      CookieService.setCookie("lang", "en");
    }
    next(`/login?ref=${to.fullPath.toString()}`);
  }
};

const isCsr = (to: any, from: any, next: any) => {
  if (!assertValidConfig(next)) {
    return;
  }
  if (
    store.state.user &&
    store.state.user !== null &&
    (store.state.user.isCsr || store.state.user.isSap)
  ) {
    CookieService.setCookie("lang", "en");
    next();
  } else {
    next("/");
  }
};

const isAuth = (to: any, from: any, next: any) => {
  if (!assertValidConfig(next)) {
    return;
  }
  if (store.state.user && store.state.user !== null) {
    next("/");
  } else {
    next();
  }
};

const checkLeadIsAvailable = async (
  quizAvailableResponse: any,
  offerType: any
) => {
  if (store.state.offerAds.length === 0) {
    await store.dispatch("getMortgageSummaries");
  }
  return (
    quizAvailableResponse &&
    quizAvailableResponse.value.hasUserCompletedQuiz === 1 &&
    quizAvailableResponse.value.hasUserCompletedLead !== 1 &&
    store.state.offerAds.length > 0 &&
    store.state.offerAds.find(
      (offer: any) => offer.name === getCampaignTypeOffer(offerType)
    ) !== undefined
  );
};
const isQuiz = async (to: any, from: any, next: any) => {
  if (!store.state.user) {
    next("/login");
  }
  if (store.state.offerAds.length === 0) {
    await store.dispatch("getMortgageSummaries");
  }
  const type =
    to.params.type === "HomeSystem" || to.params.type === "HomeAppliance"
      ? "HW"
      : to.params.type;
  const quizAvailableResponse = await userService.isQuizAvailable(type);
  if (!quizAvailableResponse.isSuccess) {
    next("/");
    return;
  }
  if (type === "HW" && to.params.mortgageNumber === undefined) {
    next("/");
    return;
  }
  const quizData: QuizData | null = await GetQuizData(
    to.params,
    next,
    quizAvailableResponse.value.tnCVersion,
    quizAvailableResponse.value.prize,
    store.state.locale
  );
  if (
    isQuizAvailable(quizAvailableResponse) &&
    UserHasOffer(to.params.type, store)
  ) {
    store.dispatch("updateQuizData", quizData);
    next();
  } else if (UserHasOffer(to.params.type, store) && quizData) {
    store.dispatch("updateQuizData", quizData);
    next({
      name: quizData.redirect,
      params: {
        type: to.params.type,
        mortgageNumber: to.params.mortgageNumber,
      },
    });
  } else {
    next("/");
  }
};
const isEnrollment = async (to: any, from: any, next: any) => {
  if (!store.state.user) {
    next("/login");
  }
  if (store.state.offerAds.length === 0) {
    await store.dispatch("getMortgageSummaries");
  }
  const type =
    to.params.type === "HomeSystem" || to.params.type === "HomeAppliance"
      ? "HW"
      : to.params.type;
  const quizAvailableResponse = await userService.isQuizAvailable(type);
  if (!quizAvailableResponse.isSuccess) {
    next("/");
    return;
  }
  const quizData: QuizData | null = await GetQuizData(
    to.params,
    next,
    quizAvailableResponse.value.tnCVersion,
    quizAvailableResponse.value.prize,
    store.state.locale
  );
  if (
    isFormAvailable(quizAvailableResponse) &&
    UserHasOffer(to.params.type, store)
  ) {
    store.dispatch("updateQuizData", quizData);
    next();
  } else {
    next("/");
  }
};
const isMLILeadGeneration = async (to: any, from: any, next: any) => {
  if (!store.state.user) {
    next("/login");
  }
  if (store.state.offerAds.length === 0) {
    await store.dispatch("getMortgageSummaries");
  }
  const quizAvailableResponse = await userService.isQuizAvailable("MLI");
  const quizData: QuizData | null = await GetQuizData(
    { type: "MLI" },
    next,
    quizAvailableResponse.value.tnCVersion,
    quizAvailableResponse.value.prize,
    store.state.locale
  );
  if (isFormAvailable(quizAvailableResponse) && UserHasOffer("MLI", store)) {
    store.dispatch("updateQuizData", quizData);
    next();
  } else {
    next("/");
  }
};

function tenantPrefix(configCode: string) {
  if (configCode === "MS") {
    configCode = "/mymcap";
  }
  if (configCode === "RM") {
    configCode = "/myrmg";
  }
  return configCode;
}

export default createRouter({
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
  history: createWebHistory(mcap_config.publicPath),
  routes: [
    {
      path: "/unavailable",
      name: "Unavailable",
      beforeEnter: (to, from, next) => {
        if ((window as any).mcap_config && (window as any).mcap_config.code) {
          next("/");
        } else {
          next();
        }
      },
      component:  () => import("./views/MaintenancePage.vue"),
    },
    {
      path: "/",
      name: "Landing",
      beforeEnter: guard,
      component: () => import("./views/Landing/index.vue"),
    },
    {
      path: "/mortgage/:id/:view?/:option?",
      name: "MortgageDetails",
      beforeEnter: guard,
      component: () => import("./views/MortgageDetails/index.vue"),
    },
    {
      path: "/contact",
      name: "ContactUs",
      beforeEnter: validConfigGuard,
      component: () => import("./views/Help/index.vue"),
      alias: ["/Home/ContactUs", "/Home/ContactUsForm"],
    },
    {
      path: "/login/:ref?",
      name: "Login",
      beforeEnter: isAuth,
      component: () => import("@/views/Login/index.vue"),
      alias: [
        "/Home/Index",
        "/Account/AccountInfoAction",
        "/Account/ForgotUserName",
        "/Account/ForgotPassword",
        "/Home/ContentPage/Forms",
      ],
    },
    {
      path: "/glossary",
      name: "Glossary",
      component:  () => import("./views/Glossary/index.vue"),
      alias: ["/Home/Glossary"],
    },
    {
      path: "/passwordReset",
      name: "PasswordReset",
      beforeEnter: isAuth,
      component: () => import("@/views/Login/index.vue"),
      props: (route) => ({ passwordResetCode: route.query.code }),
    },
    {
      path: "/impersonate",
      name: "Impersonation",
      beforeEnter: isCsr,
      component:  () => import("./views/Impersonation/index.vue"),
    },
    {
      path: "/settings/:type?",
      name: "Settings",
      beforeEnter: guardWithRedirect,
      component: () => import("./views/Settings/index.vue"),
    },
    {
      path: "/help/video-library",
      name: "VideoLibrary",
      beforeEnter: guardWithRedirect,
      component: () => import("./views/VideoLibrary/index.vue"),
    },
    {
      path: "/help/video-library/video/:id?",
      name: "Video",
      beforeEnter: guardWithRedirect,
      component: () => import("./views/VideoLibrary/Video/index.vue"),
    },
    {
      path: "/help/:cat?/:question?/:answer?",
      name: "Help",
      beforeEnter: validConfigGuard,
      component: () => import("./views/Help/index.vue"),
      alias: ["/Home/ContentPage/FAQ"],
    },
    {
      path: "/Content/ContentDocument/HomeAppliance",
      name: "HomeAppliance",
      beforeEnter: (to: any, from: any, next: any)=> {
        const lang = CookieService.getCookie("lang")
          ? CookieService.getCookie("lang")
          : "en";
        window.location.href =
          tenantPrefix(mcap_config.code) + "/file/HomeApplianceNov2022/" + lang;
      },
      component: null,
    },
    {
      path: "/Content/ContentDocument/HomeSystem",
      name: "HomeSystem",
      beforeEnter(to: any, from: any, next: any) {
        const lang = CookieService.getCookie("lang")
          ? CookieService.getCookie("lang")
          : "en";
        window.location.href =
          tenantPrefix(mcap_config.code) + "/file/HomeSystemNov2022/" + lang;
      },
      component: null,
    },
    {
      path: "/Content/ContentDocument/:key",
      name: "GenericContent",
      beforeEnter(to: any, from: any, next: any) {
        const lang = CookieService.getCookie("lang")
          ? CookieService.getCookie("lang")
          : "en";
        window.location.href =
           `${tenantPrefix(mcap_config.code)}/file/${to.params.key}/${lang}`;
      },
      component: null,
    },
    {
      path: "/Sitemap",
      name: "Sitemap",
      component: () => import("./views/sitemap/index.vue"),
    },
    {
      path: "/Quiz/:type/:mortgageNumber?",
      name: "Quiz",
      beforeEnter: isQuiz,
      component: () => import("./views/Quiz/index.vue"),
    },
    {
      path: "/Enrollment/:type/:mortgageNumber",
      name: "Enrollment",
      beforeEnter: isEnrollment,
      component: () => import("./views/Campaigns/HomeWarrentyEnrollment.vue"),
    },
    {
      path: "/MLIForm",
      name: "MLIForm",
      beforeEnter: isMLILeadGeneration,
      component: () => import("./views/Campaigns/MLILeadGenerationForm.vue"),
    },
    {
      path: "/SessionExpired",
      name: "SessionExpired",
      component: () => import("./components/SessionExpired.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      beforeEnter(to: any, from: any, next: any) {
        console.log(`Wildcard.  Redirecting to ${mcap_config.publicPath}`);
        window.location.href = mcap_config.publicPath;
      },
      component: null,
    },
  ],
});
function getCampaignTypeOffer(offerType: any) {
  throw new Error("Function not implemented.");
}
