const mortgageServiceMixin = {
  beforeCreate() {
    const options = this.$options;
    if (options.mortgageService) {
      this.$mortgageService = options.mortgageService;
    } else if (options.parent && options.parent.$mortgageService) {
      this.$mortgageService = options.parent.$mortgageService;
    }
  },
}

export default mortgageServiceMixin