<template>
    <div class="row">
        <div class="menu-search">
            <div class="row" @click="toggleDropdown">
                <SearchIcon class="black" />
                <p>{{ $t("CsrSearchHeader") }}</p>
                <DownArrowIcon class="black" />
            </div>
            <div class="menu" v-if="showDropdown">
                <div class="top" @click="toggleDropdown">
                    <SearchIcon class="black" />
                    <p>{{ $t("CsrSearchHeader") }}</p>
                    <DownArrowIcon class="black" />
                </div>
                <div class="content">
                    <div class="row">
                        <div class="input-container" :class="{ hasValue: username.length > 0 }">
                            <label for="username" id="username__label">{{ $t("Username") }}</label>
                            <input type="text" id="username" @focus="addActiveInput($event)"
                                @blur="removeActiveInput($event)" v-model.trim="username" />
                        </div>
                    </div>
                    <div class="input-container" :class="{ hasValue: webClientId.length > 0 }">
                        <label for="webClientId" id="webClientId__label">{{ $t("WebClientId") }}</label>
                        <input type="text" id="webClientId" @focus="addActiveInput($event)"
                            @blur="removeActiveInput($event)" v-model.trim="webClientId" />
                    </div>
                    <button class="button button--primary" @click="impersonate()">{{ $t("Continue") }}</button>
                </div>
            </div>
        </div>
        <div class="shadowing">
            <p>{{ $t("Shadowing", user) }}</p>
            <button class="button button--delete" @click="cancel()">{{ $t("Cancel") }}</button>
        </div>


    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { TranslateResult } from "vue-i18n";
import { Action } from "vuex-facing-decorator";
import errorService from "@/services/errorService";

import SearchIcon from "@/components/Icons/SearchIcon.vue";
import DownArrowIcon from "@/components/Icons/DownArrowIcon.vue";
import { User } from "@/store/types/user";
import { Tenant } from "@/store/types/tenant";
import { CookieService } from "@/services/cookieService";
import userService from "@/services/userService";

@Component({
  components: {
    SearchIcon,
    DownArrowIcon,
  },
})
class CsrSearch extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Action("getTenants") protected getTenants!: () => Promise<void>;
  @Action("setUser") protected setUser!: (user: User) => Promise<void>;
  @Action("logout") protected logout!: () => Promise<void>;

  private error: string   | null = null;
  private loading: boolean = false;
  private webClientId: string = "";
  private username: string = "";
  private tenant: Tenant | null = null;
  private showDropdown: boolean = false;
  private language: string = "";
  private isBilingual: boolean = false;

  get user(): User {
    return this.$store.state.user;
  }

  get tenantDropdownValue() {
    return this.tenant != null ? this.tenant.name : this.$t("SelectBrand");
  }

  get tenants() {
    return this.$store.state.tenants;
  }

  private toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  private addActiveInput(e: any) {
    const parent = e.currentTarget.parentNode;
    parent.classList.add("active");
  }

  private removeActiveInput(e: any) {
    const parent = e.currentTarget.parentNode;
    parent.classList.remove("active");
  }

  private async created() {
    this.webClientId = "";
    this.username = "";
    this.loading = false;
    this.tenant = null;
    await this.getTenants();
    this.showDropdown = false;
    this.language = CookieService.getCookie("lang") ? CookieService.getCookie("lang") : "en";
    this.isBilingual = this.$appConfig.isBilingual;
  }

  private cancel() {
    window.location.href = this.$appConfig.publicPath + "csr";
  }

  private async impersonate() {
    this.error = null;
    this.loading = true;
    const response = await userService.impersonate({
      brand: this.$appConfig.code,
      username: this.username,
      webClientId: this.webClientId ? +this.webClientId : undefined,
    });

    if (response.isSuccess) {
      await this.setUser(response.value);
      this.loading = false;
      window.location.reload();
    } else {
      if (response.errorValue.response.status === 403) {
        window.location.href = this.$appConfig.publicPath + "csr";
        return;
      }
      this.error = response.error;
    }
  }
}
export default toNative(CsrSearch)
</script>

<style lang="scss">


.row {
    display: flex;
    align-items: center;
}

.menu-search {
    margin-left: 50px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    width: 500px;

    &>.row {
        width: 100%;
        display: flex;
        align-items: center;
    }

    p {
        margin-left: 10px;
        margin-right: 30px;
        user-select: none;
    }

    svg {
        &:last-child {
            margin-left: auto;
        }
    }

    &:hover {
        border-bottom: 1px solid var(--color--primary);

        &>p {
            color: var(--color--primary);
        }
    }

    .menu {
        position: absolute;
        left: -20px;
        top: -20px;
        background: white;
        padding: 20px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        width: calc(100% + 40px);

        .row {
            display: flex;
            align-items: center;

            .input-container {
                flex: 1;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        .top {
            display: flex;
            align-items: center;
            margin-bottom: 30px;

            p {
                margin-left: 10px;
                margin-right: 30px;
                user-select: none;
            }
        }
    }

    @media(max-width: $media--ipad-pro) {
        display: none;
    }
}

.shadowing {
    display: flex;
    align-items: center;
    border-left: 1px solid #ccc;
    padding-left: 25px;
    margin-left: 25px;

    p {
        font-weight: var(--font--medium);
    }

    .button {
        width: 100px;
        min-height: 34px;
        margin-left: 30px;
    }

    @media(max-width: $media--iphone-plus) {
        padding-left: 15px;
        margin-left: 15px;

        .button {
            margin-left: 0px;
            margin-right: 12px;
        }
    }

    @media(max-width: $media--galaxy-S5) {
        padding-left: 7px;
        margin-left: 7px;
        display: block;

        p {
            font-size: 15px;
        }
    }

}
</style>