<template>
    <footer id="footer" :class="{ 'FAQfooter': isFAQ }">
        <div class="row" :class="{ 'staticLicence' : brandshowLicenceInfoFromCMS }">
            <div class="about">
                <h3>{{ $t("AboutUs") }}</h3>
                <ul>
                    <li>{{ language === 'fr' ? $appConfig.businessNameFr : $appConfig.businessName }}</li>
                    <li>{{ $t("MortgageServicingCentre") }}</li>
                    <li>{{ $t("Address") }}</li>
                    <li>{{ $t("Address2") }}</li>
                </ul>
            </div>
            <div v-if="language === 'en'" class="about middle">
                <h3>{{ $t("UsefulLinks") }}</h3>
                <ul class="menu">
                    <li v-if="usefulLink.en.legal" tabindex="0">
                        <a :href="usefulLink.en.legal" :alt="$t('Legal')" target="_blank" id="footer__item--legal">
                            {{
                            $t("Legal")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.en.privacy">
                        <a :href="usefulLink.en.privacy" :alt="$t('Privacy')" target="_blank" id="footer__item--privacy">
                            {{
                            $t("Privacy")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.en.security">
                        <a :href="usefulLink.en.security" :alt="$t('Security')" target="_blank"
                           id="footer__item--security">{{ $t("Security") }}</a>
                    </li>
                    <li v-if="usefulLink.en.access">
                        <a :href="usefulLink.en.access" :alt="$t('Accessibility')" target="_blank"
                           id="footer__item--access">{{ $t("Accessibility") }}</a>
                    </li>
                    <li v-if="usefulLink.en.homeSystem">
                        <a :href="systemLink" :alt="$t('System')" target="_blank" id="footer__item--system">
                            {{
 $t("System")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.en.homeAppliance">
                        <a :href="applianceLink" :alt="$t('Appliance')" target="_blank" id="footer__item--Appliance">
                            {{
                            $t("Appliance")
                            }}
                        </a>
                    </li>
                    <li v-if="($appConfig.code === 'MS') && usefulLink.en.finmo">
                        <a :href="usefulLink.en.finmo" :alt="$t('RefinanceApplicationLinksTitle')" target="_blank"
                           id="footer__item--access">{{ $t("RefinanceApplicationLinksTitle") }}</a>
                    </li>
                    <li v-if="usefulLink.en.licences">
                        <a :href="usefulLink.en.licences" :alt="$t('Licences')" target="_blank"
                           id="footer__item--licences">{{ $t("Licences") }}</a>
                    </li>
                    <li>
                        <router-link :to="`/Glossary`" :alt="$t('Licences')"> {{ $t("Glossary") }} </router-link>
                    </li>
                    <li v-if="usefulLink.en.terms">
                        <a :href="usefulLink.en.terms" :alt="$t('terms')" target="_blank" id="footer__item--access">
                            {{
                            $t("terms")
                            }}
                        </a>
                    </li>
                    <li>
                        <router-link :to="`/SiteMap`" :alt="$t('SiteMap')"> {{ $t("SiteMap") }} </router-link>
                    </li>
                    <li v-if="usefulLink.en.mortgageCalculators">
                        <a :href="usefulLink.en.mortgageCalculators" :alt="$t('MortgageCalculators')" target="_blank"
                           id="footer__item--access">{{ $t("MortgageCalculators") }}</a>
                    </li>
                    <li v-if="usefulLink.en.complaintsLink">
                        <a :href="usefulLink.en.complaintsLink" :alt="$t('ComplaintResolution')" target="_blank"
                           id="footer__item--access">  {{ ($appConfig.code === 'FW') ? $t("FileaComplaint") : $t("ComplaintResolution") }} </a>
                    </li>
                    <li v-if="($appConfig.code === 'RM') && usefulLink.en.finmo">
                        <a :href="usefulLink.en.finmo" :alt="$t('RefinanceApplicationLinksTitle')" target="_blank"
                           id="footer__item--access">{{ $t("RefinanceApplicationLinksTitle") }}</a>
                    </li>
                </ul>
            </div>
            <div v-if="language === 'fr'" class="about middle">
                <h3>{{ $t("UsefulLinks") }}</h3>
                <ul class="menu">
                    <li v-if="usefulLink.fr.legal" tabindex="0">
                        <a :href="usefulLink.fr.legal" :alt="$t('Legal')" target="_blank" id="footer__item--legal">
                            {{
                            $t("Legal")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.fr.privacy">
                        <a :href="usefulLink.fr.privacy" :alt="$t('Privacy')" target="_blank" id="footer__item--privacy">
                            {{
                            $t("Privacy")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.fr.security">
                        <a :href="usefulLink.fr.security" :alt="$t('Security')" target="_blank"
                           id="footer__item--security">{{ $t("Security") }}</a>
                    </li>
                    <li v-if="usefulLink.fr.access">
                        <a :href="usefulLink.fr.access" :alt="$t('Accessibility')" target="_blank"
                           id="footer__item--access">{{ $t("Accessibility") }}</a>
                    </li>
                    <li v-if="usefulLink.fr.homeSystem">
                        <a :href="systemLink" :alt="$t('System')" target="_blank" id="footer__item--system">
                            {{
 $t("System")
                            }}
                        </a>
                    </li>
                    <li v-if="usefulLink.fr.homeAppliance">
                        <a :href="applianceLink" :alt="$t('Appliance')" target="_blank" id="footer__item--Appliance">
                            {{
                            $t("Appliance")
                            }}
                        </a>
                    </li>
                    <li v-if="($appConfig.code === 'MS') && usefulLink.fr.finmo">
                        <a :href="usefulLink.fr.finmo" :alt="$t('RefinanceApplicationLinksTitle')" target="_blank"
                           id="footer__item--access">{{ $t("RefinanceApplicationLinksTitle") }}</a>
                    </li>
                    <li v-if="usefulLink.fr.licences">
                        <a :href="usefulLink.fr.licences" :alt="$t('Licences')" target="_blank"
                           id="footer__item--licences">{{ $t("Licences") }}</a>
                    </li>
                    <li v-if="usefulLink.fr.access">
                        <a :href="usefulLink.fr.access" :alt="$t('Accessibility')" target="_blank"
                           id="footer__item--access">{{ $t("Accessibility") }}</a>
                    </li>
                    <li>
                        <router-link :to="`/Glossary`" :alt="$t('Glossary')"> {{ $t("Glossary") }} </router-link>
                    </li>
                    <li>
                        <router-link :to="`/SiteMap`" :alt="$t('SiteMap')"> {{ $t("SiteMap") }} </router-link>
                    </li>
                    <li v-if="usefulLink.fr.mortgageCalculators">
                        <a :href="usefulLink.en.mortgageCalculators" :alt="$t('MortgageCalculators')" target="_blank"
                           id="footer__item--access">{{ $t("MortgageCalculators") }}</a>
                    </li>
                </ul>
            </div>
            <div v-if="brandshowLicenceInfoFromCMS" class="about">
                <h3 v-if="licenceInfoFromCMS?.caption">{{ licenceInfoFromCMS?.caption }}</h3>
                <ul>
                    <li v-if="licenceInfoFromCMS?.line1">{{ licenceInfoFromCMS?.line1 }}</li>
                    <li v-if="licenceInfoFromCMS?.line2">{{ licenceInfoFromCMS?.line2 }}</li>
                    <li v-if="licenceInfoFromCMS?.line3">{{ licenceInfoFromCMS?.line3 }}</li>
                </ul>
            </div>
            <div v-if="language === 'en'" class="about">
                <h3>{{ $t("ContactUs") }}</h3>
                <ul>
                    <li>
                        <a class="icon" alt="english phone number">
                            <PhoneIcon class="space white" aria-hidden="true" />
                            {{ $appConfig.phoneNumber }} ({{ $t("English") }})
                        </a>
                    </li>
                    <li v-if="$appConfig.phoneNumberFrench">
                        <a class="icon" alt="french phone number">
                            <PhoneIcon class="space white" aria-hidden="true" />
                            {{ $appConfig.phoneNumberFrench }} ({{ $t("French") }})
                        </a>
                    </li>
                    <li class="icon" v-if="$appConfig.fax">
                        <FaxIcon class="space" aria-hidden="true" />
                        {{ $appConfig.fax }} ({{ $t("Fax") }})
                    </li>
                    <li>
                        <router-link :to="{ name: 'ContactUs' }" class="icon" id="footer__item--mail" :alt="$t('EmailUs')"
                                     v-if="$route.name !== 'ContactUs'">
                            <EmailIcon class="space" aria-hidden="true" />
                            {{ $t("EmailUs") }}
                        </router-link>
                        <a href="#" class="icon" id="footer__item--mail" :alt="$t('EmailUs')"
                           v-if="$route.name === 'ContactUs'" @click.prevent="onContactUsClick">
                            <EmailIcon class="space" aria-hidden="true" />
                            {{ $t("EmailUs") }}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="language === 'fr'" class="about">
                <h3>{{ $t("ContactUs") }}</h3>
                <ul>
                    <li>
                        <a class="icon" alt="french phone number">
                            <PhoneIcon class="space white" aria-hidden="true" />
                            {{ $appConfig.phoneNumberFrench }} ({{ $t("French") }})
                        </a>
                    </li>
                    <li v-if="$appConfig.phoneNumber">
                        <a class="icon" alt="english phone number">
                            <PhoneIcon class="space white" aria-hidden="true" />
                            {{ $appConfig.phoneNumber }} ({{ $t("English") }})
                        </a>
                    </li>
                    <li class="icon" v-if="$appConfig.faxFrench" alt="fax">
                        <FaxIcon class="space" aria-hidden="true" />
                        {{ $appConfig.faxFrench }} ({{ $t("Fax") }})
                    </li>
                    <li>
                        <router-link :to="{ name: 'ContactUs' }" class="icon" :alt="$t('EmailUs')" id="footer__item--mail"
                                     v-if="$route.name !== 'ContactUs'">
                            <EmailIcon class="space" aria-hidden="true" />
                            {{ $t("EmailUs") }}
                        </router-link>
                        <a href="#" class="icon" id="footer__item--mail" :alt="$t('EmailUs')"
                           v-if="$route.name === 'ContactUs'" @click.prevent="onContactUsClick">
                            <EmailIcon class="space" aria-hidden="true" />
                            {{ $t("EmailUs") }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footerbottom" v-if="$appConfig.code == 'AS'">{{ footerbottom }}</div>
    </footer>
</template>
<script lang="ts">
    import { Component, Prop, Vue, Inject, toNative } from "vue-facing-decorator"
    import { useStore } from "vuex";
    import PhoneIcon from "@/components/Icons/PhoneIcon.vue";
    import FaxIcon from "@/components/Icons/FaxIcon.vue";
    import LinkedinIcon from "@/components/Icons/LinkedinIcon.vue";
    import EmailIcon from "@/components/Icons/EmailIcon.vue";
    import { Action } from "vuex-facing-decorator";
    import { brand } from "@/assets/branding";
    import { UsefulLink } from "@/assets/brandingModel";
    import { LicenceContent } from "@/store/types/content";
    

    const { usefulLink, footerbottom, hasHomeSystem, hasHomeAppliance, showLicenceInfoFromCMS } = brand;

    @Component({
        components: {
            PhoneIcon,
            FaxIcon,
            LinkedinIcon,
            EmailIcon,
        },
    })
    class Footer extends Vue {

        @Inject() $appConfig: any


        public $store = useStore()
        @Prop() public isFAQ!: boolean;
        @Action("toggleContactUs") private toggleContactUs!: (state: any) => Promise<void>;
        @Action("getLicenceInfo") private getLicenceInfo!: () => Promise<void>;
        private usefulLink: { en: UsefulLink, fr: UsefulLink };
        private language: string;
        private footerbottom: string;
        private systemLink: string = "";
        private applianceLink: string = "";

        private brandHasHomeSystem: boolean = hasHomeSystem;
        private brandHasHomeAppliance: boolean = hasHomeAppliance;
        private brandshowLicenceInfoFromCMS: boolean = showLicenceInfoFromCMS;
        private licenceInfoFromCMS : LicenceContent | null =  null;


        private created() {
            this.footerbottom = footerbottom;
            this.usefulLink = usefulLink;
            this.language = this.$store.state.locale ? this.$store.state.locale : "en";
            this.systemLink = `${this.$appConfig.publicPath}file/HomeSystemNov2022/${this.language}`;
            this.applianceLink = `${this.$appConfig.publicPath}file/HomeApplianceNov2022/${this.language}`;
            if (this.brandshowLicenceInfoFromCMS) {
                this.fetchLicenceInfofromCMS();            
            }
        }

        private onContactUsClick() {
            if (this.$route.name === "ContactUs") {
                window.scrollTo(0, 0);
                this.toggleContactUs({ isOpen: true });
            } else {
                this.$router.push({ name: "ContactUs" });
            }
        }
                
        private async fetchLicenceInfofromCMS() {
            if (!this.$store.state.licenceInfoFromCMS)
            {        
                await this.getLicenceInfo();        
            }    
            this.licenceInfoFromCMS =  this.$store.state.licenceInfoFromCMS;
        }

    }
    export default toNative(Footer)
</script>
<style lang="scss" scoped>

    .staticLicence {
        // this is a 4 column layout to show Licence as a different column.
        .about {
            @media (max-width: 950px) {
                width: 100%;
                padding: 0;
                margin-bottom: 15px;
            }
            width: 23%;
            padding: 0 10px;

            &.middle {
                @media (max-width: 950px) {
                    width: 100%;
                }
                width: 23%;
            }
        }
    }

    .about {
        // width: calc((100% * 1 / 3));
        width: 28%;
        padding: 0 60px;

        &.middle {
            width: 44%;
        }

        &:first-child {
            padding: 0;
        }

        &:last-child {
            padding: 0;
        }
    }

    ul {
        list-style: none;
    }

    .space {
        margin-right: 10px;
    }

    .menu {
        // display: inline-grid;
        // grid-template-rows: repeat(5, auto);
        // grid-auto-flow: column;


        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: calc((1.2em + 8px)*5);
        // transform: translateZ(0);
        // column-count: 3;
        // width: calc((100% * 1 / 2));
        @media (max-width: 950px) {
            max-height: calc((1.2em + 8px)*6);
        }

        @media(max-width: $media--iphone-plus) {
            max-height: unset;
            // width: calc((100% * 0.8));
        }
    }

    .icon {
        display: flex;
        align-items: center;
    }

    li {
        margin-bottom: 10px;
        font-size: 14px;
        color: var(--footer-text-color);
        font-weight: 500;
    }

    .row {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        // margin-left: 50px;
    }

    @media (max-width: 950px) {
        .row {
            display: flex;
            flex-wrap: wrap;
        }

        .about {
            width: 100%;
            padding: 0;
            margin-bottom: 15px;

            &.middle {
                width: 100%;
            }
        }

        #communication {
            width: 100%;
        }

        h3 {
            padding-top: 10px;
        }
    }

    .menu {
        li {
            max-width: calc(100% / 3);

            @media (max-width: 950px) {
                max-width: 100%;
            }
        }
    }


    .menu,
    .about {
        a {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            font-size: 14px;
            color: var(--footer-text-color);
            text-decoration: none;
            font-weight: 500;
        }
    }

    .aTag {
        font-weight: 500;
    }

    h3 {
        padding-bottom: 15px;
        color: var(--footer-text-color);
        font-weight: var(--font--bold);
    }

    .footerbottom {
        font-size: 16px;
        color: var(--footer-text-color);
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    footer {
        background-color: var(--footer-color);
        padding: 20px;
    }

    .FAQfooter {
        min-height: 25vh;
        padding: 1em;
        line-height: normal;
    }
</style>

