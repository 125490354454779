<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.957" height="24.522" viewBox="0 0 20.957 24.522">
        <path id="home_2_" data-name="home (2)" d="M38.373,12.161a.594.594,0,0,0-.594.594V23.334H31.408V16.693a.594.594,0,0,0-.594-.594H26.163a.594.594,0,0,0-.594.594v5.265a.594.594,0,1,0,1.188,0V17.287H30.22v6.047H19.2V8.8l9.291-7.446L38,8.978a.594.594,0,1,0,.743-.927L28.86.13a.594.594,0,0,0-.743,0L18.232,8.052a.594.594,0,0,0-.222.463V23.927a.594.594,0,0,0,.594.594h19.77a.594.594,0,0,0,.594-.594V12.755A.594.594,0,0,0,38.373,12.161Z" transform="translate(-18.01 0.001)" fill="#838eab"/>
    </svg>
</template>

<script>
export default {
  name: "HomeIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: var(--color--menu-icons);
        }
        &.mobile--menu {
            path {
                fill: var(--color--menu-icons--mobile) !important;
            }
        }
        &.white {
            path {
                fill: white !important;
            }
        }
    }
</style>