import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "about" }
const _hoisted_2 = {
  key: 0,
  class: "about middle"
}
const _hoisted_3 = { class: "menu" }
const _hoisted_4 = {
  key: 0,
  tabindex: "0"
}
const _hoisted_5 = ["href", "alt"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["href", "alt"]
const _hoisted_8 = { key: 2 }
const _hoisted_9 = ["href", "alt"]
const _hoisted_10 = { key: 3 }
const _hoisted_11 = ["href", "alt"]
const _hoisted_12 = { key: 4 }
const _hoisted_13 = ["href", "alt"]
const _hoisted_14 = { key: 5 }
const _hoisted_15 = ["href", "alt"]
const _hoisted_16 = { key: 6 }
const _hoisted_17 = ["href", "alt"]
const _hoisted_18 = { key: 7 }
const _hoisted_19 = ["href", "alt"]
const _hoisted_20 = { key: 8 }
const _hoisted_21 = ["href", "alt"]
const _hoisted_22 = { key: 9 }
const _hoisted_23 = ["href", "alt"]
const _hoisted_24 = { key: 10 }
const _hoisted_25 = ["href", "alt"]
const _hoisted_26 = { key: 11 }
const _hoisted_27 = ["href", "alt"]
const _hoisted_28 = {
  key: 1,
  class: "about middle"
}
const _hoisted_29 = { class: "menu" }
const _hoisted_30 = {
  key: 0,
  tabindex: "0"
}
const _hoisted_31 = ["href", "alt"]
const _hoisted_32 = { key: 1 }
const _hoisted_33 = ["href", "alt"]
const _hoisted_34 = { key: 2 }
const _hoisted_35 = ["href", "alt"]
const _hoisted_36 = { key: 3 }
const _hoisted_37 = ["href", "alt"]
const _hoisted_38 = { key: 4 }
const _hoisted_39 = ["href", "alt"]
const _hoisted_40 = { key: 5 }
const _hoisted_41 = ["href", "alt"]
const _hoisted_42 = { key: 6 }
const _hoisted_43 = ["href", "alt"]
const _hoisted_44 = { key: 7 }
const _hoisted_45 = ["href", "alt"]
const _hoisted_46 = { key: 8 }
const _hoisted_47 = ["href", "alt"]
const _hoisted_48 = { key: 9 }
const _hoisted_49 = ["href", "alt"]
const _hoisted_50 = {
  key: 2,
  class: "about"
}
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = { key: 1 }
const _hoisted_54 = { key: 2 }
const _hoisted_55 = {
  key: 3,
  class: "about"
}
const _hoisted_56 = {
  class: "icon",
  alt: "english phone number"
}
const _hoisted_57 = { key: 0 }
const _hoisted_58 = {
  class: "icon",
  alt: "french phone number"
}
const _hoisted_59 = {
  key: 1,
  class: "icon"
}
const _hoisted_60 = ["alt"]
const _hoisted_61 = {
  key: 4,
  class: "about"
}
const _hoisted_62 = {
  class: "icon",
  alt: "french phone number"
}
const _hoisted_63 = { key: 0 }
const _hoisted_64 = {
  class: "icon",
  alt: "english phone number"
}
const _hoisted_65 = {
  key: 1,
  class: "icon",
  alt: "fax"
}
const _hoisted_66 = ["alt"]
const _hoisted_67 = {
  key: 0,
  class: "footerbottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PhoneIcon = _resolveComponent("PhoneIcon")!
  const _component_FaxIcon = _resolveComponent("FaxIcon")!
  const _component_EmailIcon = _resolveComponent("EmailIcon")!

  return (_openBlock(), _createElementBlock("footer", {
    id: "footer",
    class: _normalizeClass({ 'FAQfooter': _ctx.isFAQ })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["row", { 'staticLicence' : _ctx.brandshowLicenceInfoFromCMS }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("AboutUs")), 1),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, _toDisplayString(_ctx.language === 'fr' ? _ctx.$appConfig.businessNameFr : _ctx.$appConfig.businessName), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t("MortgageServicingCentre")), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t("Address")), 1),
          _createElementVNode("li", null, _toDisplayString(_ctx.$t("Address2")), 1)
        ])
      ]),
      (_ctx.language === 'en')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("UsefulLinks")), 1),
            _createElementVNode("ul", _hoisted_3, [
              (_ctx.usefulLink.en.legal)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.legal,
                      alt: _ctx.$t('Legal'),
                      target: "_blank",
                      id: "footer__item--legal"
                    }, _toDisplayString(_ctx.$t("Legal")), 9, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.privacy)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.privacy,
                      alt: _ctx.$t('Privacy'),
                      target: "_blank",
                      id: "footer__item--privacy"
                    }, _toDisplayString(_ctx.$t("Privacy")), 9, _hoisted_7)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.security)
                ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.security,
                      alt: _ctx.$t('Security'),
                      target: "_blank",
                      id: "footer__item--security"
                    }, _toDisplayString(_ctx.$t("Security")), 9, _hoisted_9)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.access)
                ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.access,
                      alt: _ctx.$t('Accessibility'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("Accessibility")), 9, _hoisted_11)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.homeSystem)
                ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                    _createElementVNode("a", {
                      href: _ctx.systemLink,
                      alt: _ctx.$t('System'),
                      target: "_blank",
                      id: "footer__item--system"
                    }, _toDisplayString(_ctx.$t("System")), 9, _hoisted_13)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.homeAppliance)
                ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                    _createElementVNode("a", {
                      href: _ctx.applianceLink,
                      alt: _ctx.$t('Appliance'),
                      target: "_blank",
                      id: "footer__item--Appliance"
                    }, _toDisplayString(_ctx.$t("Appliance")), 9, _hoisted_15)
                  ]))
                : _createCommentVNode("", true),
              ((_ctx.$appConfig.code === 'MS') && _ctx.usefulLink.en.finmo)
                ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.finmo,
                      alt: _ctx.$t('RefinanceApplicationLinksTitle'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("RefinanceApplicationLinksTitle")), 9, _hoisted_17)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.licences)
                ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.licences,
                      alt: _ctx.$t('Licences'),
                      target: "_blank",
                      id: "footer__item--licences"
                    }, _toDisplayString(_ctx.$t("Licences")), 9, _hoisted_19)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: `/Glossary`,
                  alt: _ctx.$t('Licences')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Glossary")), 1)
                  ]),
                  _: 1
                }, 8, ["alt"])
              ]),
              (_ctx.usefulLink.en.terms)
                ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.terms,
                      alt: _ctx.$t('terms'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("terms")), 9, _hoisted_21)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: `/SiteMap`,
                  alt: _ctx.$t('SiteMap')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("SiteMap")), 1)
                  ]),
                  _: 1
                }, 8, ["alt"])
              ]),
              (_ctx.usefulLink.en.mortgageCalculators)
                ? (_openBlock(), _createElementBlock("li", _hoisted_22, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.mortgageCalculators,
                      alt: _ctx.$t('MortgageCalculators'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("MortgageCalculators")), 9, _hoisted_23)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.en.complaintsLink)
                ? (_openBlock(), _createElementBlock("li", _hoisted_24, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.complaintsLink,
                      alt: _ctx.$t('ComplaintResolution'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString((_ctx.$appConfig.code === 'FW') ? _ctx.$t("FileaComplaint") : _ctx.$t("ComplaintResolution")), 9, _hoisted_25)
                  ]))
                : _createCommentVNode("", true),
              ((_ctx.$appConfig.code === 'RM') && _ctx.usefulLink.en.finmo)
                ? (_openBlock(), _createElementBlock("li", _hoisted_26, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.finmo,
                      alt: _ctx.$t('RefinanceApplicationLinksTitle'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("RefinanceApplicationLinksTitle")), 9, _hoisted_27)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.language === 'fr')
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("UsefulLinks")), 1),
            _createElementVNode("ul", _hoisted_29, [
              (_ctx.usefulLink.fr.legal)
                ? (_openBlock(), _createElementBlock("li", _hoisted_30, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.legal,
                      alt: _ctx.$t('Legal'),
                      target: "_blank",
                      id: "footer__item--legal"
                    }, _toDisplayString(_ctx.$t("Legal")), 9, _hoisted_31)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.privacy)
                ? (_openBlock(), _createElementBlock("li", _hoisted_32, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.privacy,
                      alt: _ctx.$t('Privacy'),
                      target: "_blank",
                      id: "footer__item--privacy"
                    }, _toDisplayString(_ctx.$t("Privacy")), 9, _hoisted_33)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.security)
                ? (_openBlock(), _createElementBlock("li", _hoisted_34, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.security,
                      alt: _ctx.$t('Security'),
                      target: "_blank",
                      id: "footer__item--security"
                    }, _toDisplayString(_ctx.$t("Security")), 9, _hoisted_35)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.access)
                ? (_openBlock(), _createElementBlock("li", _hoisted_36, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.access,
                      alt: _ctx.$t('Accessibility'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("Accessibility")), 9, _hoisted_37)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.homeSystem)
                ? (_openBlock(), _createElementBlock("li", _hoisted_38, [
                    _createElementVNode("a", {
                      href: _ctx.systemLink,
                      alt: _ctx.$t('System'),
                      target: "_blank",
                      id: "footer__item--system"
                    }, _toDisplayString(_ctx.$t("System")), 9, _hoisted_39)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.homeAppliance)
                ? (_openBlock(), _createElementBlock("li", _hoisted_40, [
                    _createElementVNode("a", {
                      href: _ctx.applianceLink,
                      alt: _ctx.$t('Appliance'),
                      target: "_blank",
                      id: "footer__item--Appliance"
                    }, _toDisplayString(_ctx.$t("Appliance")), 9, _hoisted_41)
                  ]))
                : _createCommentVNode("", true),
              ((_ctx.$appConfig.code === 'MS') && _ctx.usefulLink.fr.finmo)
                ? (_openBlock(), _createElementBlock("li", _hoisted_42, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.finmo,
                      alt: _ctx.$t('RefinanceApplicationLinksTitle'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("RefinanceApplicationLinksTitle")), 9, _hoisted_43)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.licences)
                ? (_openBlock(), _createElementBlock("li", _hoisted_44, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.licences,
                      alt: _ctx.$t('Licences'),
                      target: "_blank",
                      id: "footer__item--licences"
                    }, _toDisplayString(_ctx.$t("Licences")), 9, _hoisted_45)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.usefulLink.fr.access)
                ? (_openBlock(), _createElementBlock("li", _hoisted_46, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.fr.access,
                      alt: _ctx.$t('Accessibility'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("Accessibility")), 9, _hoisted_47)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: `/Glossary`,
                  alt: _ctx.$t('Glossary')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("Glossary")), 1)
                  ]),
                  _: 1
                }, 8, ["alt"])
              ]),
              _createElementVNode("li", null, [
                _createVNode(_component_router_link, {
                  to: `/SiteMap`,
                  alt: _ctx.$t('SiteMap')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("SiteMap")), 1)
                  ]),
                  _: 1
                }, 8, ["alt"])
              ]),
              (_ctx.usefulLink.fr.mortgageCalculators)
                ? (_openBlock(), _createElementBlock("li", _hoisted_48, [
                    _createElementVNode("a", {
                      href: _ctx.usefulLink.en.mortgageCalculators,
                      alt: _ctx.$t('MortgageCalculators'),
                      target: "_blank",
                      id: "footer__item--access"
                    }, _toDisplayString(_ctx.$t("MortgageCalculators")), 9, _hoisted_49)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.brandshowLicenceInfoFromCMS)
        ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
            (_ctx.licenceInfoFromCMS?.caption)
              ? (_openBlock(), _createElementBlock("h3", _hoisted_51, _toDisplayString(_ctx.licenceInfoFromCMS?.caption), 1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", null, [
              (_ctx.licenceInfoFromCMS?.line1)
                ? (_openBlock(), _createElementBlock("li", _hoisted_52, _toDisplayString(_ctx.licenceInfoFromCMS?.line1), 1))
                : _createCommentVNode("", true),
              (_ctx.licenceInfoFromCMS?.line2)
                ? (_openBlock(), _createElementBlock("li", _hoisted_53, _toDisplayString(_ctx.licenceInfoFromCMS?.line2), 1))
                : _createCommentVNode("", true),
              (_ctx.licenceInfoFromCMS?.line3)
                ? (_openBlock(), _createElementBlock("li", _hoisted_54, _toDisplayString(_ctx.licenceInfoFromCMS?.line3), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.language === 'en')
        ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("ContactUs")), 1),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createElementVNode("a", _hoisted_56, [
                  _createVNode(_component_PhoneIcon, {
                    class: "space white",
                    "aria-hidden": "true"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.phoneNumber) + " (" + _toDisplayString(_ctx.$t("English")) + ") ", 1)
                ])
              ]),
              (_ctx.$appConfig.phoneNumberFrench)
                ? (_openBlock(), _createElementBlock("li", _hoisted_57, [
                    _createElementVNode("a", _hoisted_58, [
                      _createVNode(_component_PhoneIcon, {
                        class: "space white",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.phoneNumberFrench) + " (" + _toDisplayString(_ctx.$t("French")) + ") ", 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.$appConfig.fax)
                ? (_openBlock(), _createElementBlock("li", _hoisted_59, [
                    _createVNode(_component_FaxIcon, {
                      class: "space",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.fax) + " (" + _toDisplayString(_ctx.$t("Fax")) + ") ", 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                (_ctx.$route.name !== 'ContactUs')
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: 'ContactUs' },
                      class: "icon",
                      id: "footer__item--mail",
                      alt: _ctx.$t('EmailUs')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EmailIcon, {
                          class: "space",
                          "aria-hidden": "true"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("EmailUs")), 1)
                      ]),
                      _: 1
                    }, 8, ["alt"]))
                  : _createCommentVNode("", true),
                (_ctx.$route.name === 'ContactUs')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: "#",
                      class: "icon",
                      id: "footer__item--mail",
                      alt: _ctx.$t('EmailUs'),
                      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onContactUsClick && _ctx.onContactUsClick(...args)), ["prevent"]))
                    }, [
                      _createVNode(_component_EmailIcon, {
                        class: "space",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("EmailUs")), 1)
                    ], 8, _hoisted_60))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.language === 'fr')
        ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("ContactUs")), 1),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createElementVNode("a", _hoisted_62, [
                  _createVNode(_component_PhoneIcon, {
                    class: "space white",
                    "aria-hidden": "true"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.phoneNumberFrench) + " (" + _toDisplayString(_ctx.$t("French")) + ") ", 1)
                ])
              ]),
              (_ctx.$appConfig.phoneNumber)
                ? (_openBlock(), _createElementBlock("li", _hoisted_63, [
                    _createElementVNode("a", _hoisted_64, [
                      _createVNode(_component_PhoneIcon, {
                        class: "space white",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.phoneNumber) + " (" + _toDisplayString(_ctx.$t("English")) + ") ", 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.$appConfig.faxFrench)
                ? (_openBlock(), _createElementBlock("li", _hoisted_65, [
                    _createVNode(_component_FaxIcon, {
                      class: "space",
                      "aria-hidden": "true"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$appConfig.faxFrench) + " (" + _toDisplayString(_ctx.$t("Fax")) + ") ", 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                (_ctx.$route.name !== 'ContactUs')
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: 'ContactUs' },
                      class: "icon",
                      alt: _ctx.$t('EmailUs'),
                      id: "footer__item--mail"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EmailIcon, {
                          class: "space",
                          "aria-hidden": "true"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("EmailUs")), 1)
                      ]),
                      _: 1
                    }, 8, ["alt"]))
                  : _createCommentVNode("", true),
                (_ctx.$route.name === 'ContactUs')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: "#",
                      class: "icon",
                      id: "footer__item--mail",
                      alt: _ctx.$t('EmailUs'),
                      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onContactUsClick && _ctx.onContactUsClick(...args)), ["prevent"]))
                    }, [
                      _createVNode(_component_EmailIcon, {
                        class: "space",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("EmailUs")), 1)
                    ], 8, _hoisted_66))
                  : _createCommentVNode("", true)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.$appConfig.code == 'AS')
      ? (_openBlock(), _createElementBlock("div", _hoisted_67, _toDisplayString(_ctx.footerbottom), 1))
      : _createCommentVNode("", true)
  ], 2))
}