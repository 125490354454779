import { QuizData } from "../types/quizData";

export const HomeAppliance: QuizData = {
  TnCVersion: 0,
  prize: 0,
  type: "HomeAppliance",
  title: "HomeWarrantyQuiz",
  subTitle: "HomeWarrantyQuizSubTitle",
  redirect: "Enrollment",
  questionsToSave: [5],
  enrollmentCost : 0,
};
export const HomeSystem: QuizData = {
  TnCVersion: 0,
  prize: 0,
  type: "HomeSystem",
  title: "HomeWarrantyQuiz",
  subTitle: "HomeWarrantyQuizSubTitle",
  redirect: "Enrollment",
  questionsToSave: [5],
  enrollmentCost : 0,
};
export const MLI: QuizData = {
  TnCVersion: 0,
  prize: 0,
  type: "MLI",
  title: "CreditorInsurance",
  subTitle: "QuizHeading",
  redirect: "MLIForm",
  questionsToSave: [5, 6],
  enrollmentCost : 0,
};
