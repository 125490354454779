export default {
  "RefinanceApplicationLinksTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refinance Application"])},
  "RenewalNotification": {
    "RenewalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning for an early renewal / transfer / refinance / porting of your mortgage?  We are here to take you through our attractive renewal rates and hassle free process. Call our service center at "])},
    "DueRenewalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage is due for renewal!"])},
    "DueRenewalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are here to take you through our attractive renewal rates and hassle free process. Call our service center at  "])},
    "ServicingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday through Friday"])},
    "EmailMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or email at "])},
    "Dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])}
  },
  "EducationalVideos": {
    "SupportPage": {
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "SupportCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Centre"])},
      "NeedAssistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need assistance?"])},
      "ExploreTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore our new video library."])},
      "ExploreDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time and learn about the most common mortgage topics important to you."])},
      "VideoLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Library"])},
      "New": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
      "FAQTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking for answers to frequently asked questions?"])},
      "FAQDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a category that best describes what you need assistance with, or enter a key word in the search field to narrow down results."])},
      "FAQSearchBoxPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can we help you with?"])}
    },
    "VideoLibrary": {
      "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "ViewVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Video"])}
    },
    "PopUp": {
      "ViewTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View our new videos in your Support Centre!"])},
      "ViewDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our new video library provides content to help you learn more about your mortgage."])},
      "ViewLinkTextOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the "])},
      "ViewLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Centre"])},
      "ViewLinkTextTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" icon to view your videos."])}
    }
  },
  "WatchVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch Video"])},
  "WelcomeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back"])},
  "TodayIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today is"])},
  "NextPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
  "NextPaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
  "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Date"])},
  "Days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "MaxMonthlyAllowance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of max monthly allowance"])},
  "DaysLeftToMakePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days left to make payment"])},
  "IWantTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to"])},
  "MakeAnExtraPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Down My Mortgage"])},
  "IncreaseMyPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase My Payment"])},
  "ChangePaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Payment Frequency"])},
  "ManageArrearsPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Arrears Payments"])},
  "More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
  "AboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "UsefulLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Links"])},
  "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "EmailUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Us"])},
  "Accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility"])},
  "Glossary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glossary"])},
  "System": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home System"])},
  "Appliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Appliance (Alberta Only)"])},
  "MCAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCAP"])},
  "Forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forms"])},
  "MortgageServicingCentre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Servicing Centre"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.O. Box 351 STN C"])},
  "Address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kitchener, ON N2G 3Y9"])},
  "Fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "PropertyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
  "Tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
  "HoldAPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hold-A-Payment"])},
  "ChangeBankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Bank Account"])},
  "UpdateFireInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Fire Insurance"])},
  "WaysToMakeRenewalEasier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ways to make renewal easier"])},
  "NewDocumentsToReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new document(s) to review"])},
  "PrintYourAnnualStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Your Annual Statement/Tax Adjustment letter"])},
  "FireInsuranceAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Insurance Alert"])},
  "NoNewActivityLastLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new activity since your last login"])},
  "ManageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Details"])},
  "AnnualStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Statement"])},
  "NextPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Payment"])},
  "Frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequency"])},
  "MLI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MLI"])},
  "ApprovedAccessGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved Access Given"])},
  "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
  "Term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
  "HomeWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Warranty"])},
  "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "MortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Number"])},
  "3rdPartyConsent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd Party Consent"])},
  "PayYourMortgageOffFaster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay off your mortgage faster."])},
  "PayYourMortgageOffFasterText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make additional payments of up to ", "$", "20,000 and reduce 8 months off your mortgage."])},
  "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
  "Legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
  "Privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "Licences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
  "MortgageCalculators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Calculators"])},
  "ComplaintResolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint Resolution"])},
  "FileaComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File a complaint"])},
  "SiteMap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Map"])},
  "AccessibilityatMCAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibility at MCAP"])},
  "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "MortgageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Details"])},
  "LineCreditDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Of Credit Details"])},
  "MortgageDetailsasat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Details as at"])},
  "Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
  "PropertyTaxDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax Details"])},
  "Documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
  "AmortizationSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortization Schedule"])},
  "StartingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting Balance"])},
  "CurrentBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Balance"])},
  "AvailableCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Credit"])},
  "CreditLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Limit"])},
  "BalancePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Paid"])},
  "PrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Balance"])},
  "VariableRate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Variable: Prime ", _interpolate(_named("plus")), _interpolate(_named("offset")), "%)"])},
  "PromotionalDiscountRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Discount Rate"])},
  "PromotionalExpiryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Expiry Date"])},
  "MaturityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity Date"])},
  "MaturityDatetip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity Date"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "PropertyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Address"])},
  "RequestInformationStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Information Statement"])},
  "ComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
  "PaymentDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due"])},
  "YourMortgageElementsListedBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage payment is made up of the element(s) listed below."])},
  "YourLineOfCreditElementsListedBelow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your line of credit is made up of the element(s) below."])},
  "TermsOfTheProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of the product. Product section"])},
  "PrincipalAndInterestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal and Interest"])},
  "InterestAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Amount"])},
  "HomeMortgageProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Mortgage Protection"])},
  "OtherAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Amount"])},
  "PaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Frequency"])},
  "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amortization"])},
  "FireInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Insurance"])},
  "LevelOfAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level Of Authority"])},
  "On": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On"])},
  "From": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
  "BankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account"])},
  "Bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "At": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At"])},
  "RequestBankAccountChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Bank Account Change"])},
  "Upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
  "PaidOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid Out"])},
  "LegalWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Work"])},
  "Discharged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharged"])},
  "PendingPO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending PO"])},
  "PendingRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Renewal"])},
  "TaxAccountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Account Details"])},
  "InterestAdjustmentDateTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A payment date of interest only, typically before the first regular payment date, if the date your mortgage starts (or next payment date) does not correspond with the requested payment due dates.<br>The interest adjustment date is the date the interest adjustment amount is due and will be debited from your account."])},
  "TaxEstimateAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Estimate Amount"])},
  "TaxAccountBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Account Balance"])},
  "PropertyTaxRollNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax Roll Number"])},
  "SchoolTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Taxes Collected <br/> (Residents Of Quebec Only)"])},
  "LastBillPaidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Bill Paid Date"])},
  "NextPropertyTaxDueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Property Tax Due Date"])},
  "PropertyTaxParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking to send your first property tax bill, an additional tax bill, or for residents of Quebec your annual school tax bill? You can email it to us at <a class='link' href='mailto:taxinfo", "@", "mortgageservicing.ca'>taxinfo", "@", "mortgageservicing.ca</a> or fax it to 1-866-370-0660. Please allow 2 business days for us to process any documents sent."])},
  "ToSeeHowLongYourDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see how long your documents will be visible for,"])},
  "DocumentRequired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the document you require is no longer visible, please <a href='", _interpolate(_named("url")), "contact' class='link' id='contact-us'>Contact Us</a>."])},
  "ThinkHELOCNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To manage your line of credit, contact us at 1 877 343 0883 or <a href='mailto:helocrequest", "@", "thinkfinancial.ca' class='link' id='contact-us'>helocrequest", "@", "thinkfinancial.ca</a>."])},
  "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Here."])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
  "WelcomeBackLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back, Please Login To Your Account"])},
  "Lendwise_LoginAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in below if you obtained your mortgage on or after May 1, 2023. If you obtained your mortgage before May 1, 2023, log in <a href='https://lendwise.mymortgagedetails.com' class='link' id='lnkLoginAlert' target='_blank'>here</a>.<br /><br />Not sure when you obtained your mortgage? <a href='https://www.merixfinancial.com/contact/' class='link' id='lnkContactUs' target='_blank'>Contact us</a> and we’ll be happy to help."])},
  "MERIX_LoginAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in below if you obtained your mortgage on or after May 1, 2023. If you obtained your mortgage before May 1, 2023, log in <a href='https://merix.mymortgagedetails.com/' class='link' id='lnkLoginAlert' target='_blank'>here</a>.<br /><br />Not sure when you obtained your mortgage? <a href='https://www.merixfinancial.com/contact/' class='link' id='lnkContactUs' target='_blank'>Contact us</a> and we’ll be happy to help."])},
  "Lendwise_SignUpAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register below if you obtained your mortgage on or after May 1, 2023. If you obtained your mortgage before May 1, 2023, register <a href='https://lendwise.mymortgagedetails.com/iPad_Site_SignUp.html?brandid=CW_LW_En&languageid=en' class='link' id='lnkRegisterAlert' target='_blank'>here</a>.<br /><br />Not sure when you obtained your mortgage? <a href='https://www.merixfinancial.com/contact/' class='link' id='lnkContactUs' target='_blank'>Contact us</a> and we’ll be happy to help."])},
  "MERIX_SignUpAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register below if you obtained your mortgage on or after May 1, 2023. If you obtained your mortgage before May 1, 2023, register <a href='https://merix.mymortgagedetails.com/iPad_Site_SignUp.html?brandid=CW_MX_En&languageid=en' class='link' id='lnkRegisterAlert' target='_blank'>here</a>.<br /><br />Not sure when you obtained your mortgage? <a href='https://www.merixfinancial.com/contact/' class='link' id='lnkContactUs' target='_blank'>Contact us</a> and we’ll be happy to help."])},
  "ChargeTypeAdditionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your mortgage number or lender reference number below followed by .1"])},
  "RememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "Last12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 Months"])},
  "InterestAdjustmentAmountTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Adjustment Amount (IAA)"])},
  "IncludesDeferredPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes Deferred Payment and Charges of"])},
  "MortgagePermissionsStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Permissions Status"])},
  "PastDueBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Due Balance"])},
  "RemainingLumpSumAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Lump Sum Privilege Payment"])},
  "RemainingLumpSumAvailableTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An additional amount of money applied towards the principal balance, usually restricted to a maximum amount per anniversary year, based on the mortgage agreement. These payments can be made on any payment due date, provided there is a minimum payment amount of ", "$", "100.00. Making a lump sum payment results in a decrease to the outstanding principal balance and remaining amortization on the mortgage. The regular payment amount remains the same. Lump sum payments can be made once or on-going, meaning they happen on every regular payment date."])},
  "AllActivityUpcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activity Upcoming"])},
  "ActivityHistorical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Historical"])},
  "Last12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 months"])},
  "Next12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next 12 months"])},
  "EarlyRenewalOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early Renewal Offer"])},
  "FinalDemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Demand"])},
  "FireInsuranceLapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Insurance Lapse"])},
  "CostOfBorrowingDisclosure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Of Borrowing Disclosure"])},
  "HomeWarrantySetUpLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Warranty Set-up Letter"])},
  "HomeWarrantyPayingPeriodLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Warranty Paying Period Letter"])},
  "HomeAccountStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Account Statement"])},
  "AssumptionStmnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumption Statement"])},
  "PrivilegePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privilege Payment"])},
  "AllInTheLast12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All In The Last 12 Months"])},
  "AllInTheLast18Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All In The Last 18 Months"])},
  "AllInTheLast3Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All In The Last 3 Years"])},
  "AllInTheLast2Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All In The Last 2 Years"])},
  "MostRecentInTheLast2Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Recent In The Last 2 Years"])},
  "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "DocumentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type"])},
  "Visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility"])},
  "ListOfEligibleCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Of Eligible Correspondence"])},
  "TaxEstimateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected amount of annual property taxes to be due for the upcoming tax year. This estimate is an approximate calculation based on several factors including previous bill payments and estimates, as well as information provided by your municipality. It is common for this figure to be 2-5% higher than the actual amount paid last year to account for increases on future taxes charged by your municipality"])},
  "TaxBalanceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount of funds currently available in your tax account to be used to pay upcoming tax bills from your municipality. A negative number indicates a credit balance, meaning there are available funds in your tax account to be applied to the next property tax installment due. A positive number indicates a shortfall, meaning that you are in the process of paying us back for property taxes paid on your behalf."])},
  "ListOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Of"])},
  "Past": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past"])},
  "12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Months"])},
  "ActivityTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Months"])},
  "PropertyTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Taxes"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "HavingSigningIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble signing in?"])},
  "HelpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
  "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "SignUpNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up To Start Managing Your Mortgage Now"])},
  "SignUpMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the details below exactly as they are shown in your mortgage documents provided to you. Your email address will be used to verify your account."])},
  "SignUpMortgageAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format is 7 digits followed by decimal point and one digit. Ex: 9999999.1"])},
  "SignUpBankAccountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 4 digits of the bank account your mortgage payment is drawn from."])},
  "LastNameFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name in full."])},
  "AccountVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Verification"])},
  "AccountVerificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to verify you are the owner of this mortgage a verification code will be sent to:"])},
  "AccountDetailsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the details below to complete your registration. On your next login, use the username and password you enter below to access your account."])},
  "EnterYourVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your verification code"])},
  "Verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "DidntReceive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn't receive a code? <a href='#' class='link' id='new-code-link'>Click here</a> to try again."])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "NewCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We sent you a new code."])},
  "HavingTrouble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble verifying?"])},
  "HavingTroubleMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like we're going to have to do this the old fashioned way. Let us help."])},
  "HavingTroubleContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us at the below phone number and one of our customer service representatives will help you get verified."])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "BackToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
  "ChooseBorrowerMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're verified. Now it's time to select how you will sign in to your account. Choose a sign in method below."])},
  "ChooseBorrower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a Borrower"])},
  "SignInUsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In Using"])},
  "UsernameAndPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username and Password"])},
  "EasySocialLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Social Login"])},
  "MortgageAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Account Number"])},
  "BankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Account Number"])},
  "LastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "PasswordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "MobilePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone Number"])},
  "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
  "ConfirmEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Email Address"])},
  "MatchingEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email addresses do not match."])},
  "InvalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address."])},
  "NumericCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numeric character"])},
  "MinimumCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 8 characters and must"])},
  "SpecialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special characters !,", "@", ",#,", "$", ",%,^,&,*"])},
  "NoSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Spaces"])},
  "SecureYourAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure your account"])},
  "SecureYourAccountMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security purposes please complete three security questions with answers only you would know. Your answers must be a minimum of 3 letters."])},
  "SecureYourAccountMessageSecurityTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security purposes please complete three security questions with answers only you would know. Your answers must be a minimum of 3 letters."])},
  "ExpiredSecurityQuestionsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your security questions and answers have expired. Please secure your account with new questions and answers only you would know, in the event you forget your username or password."])},
  "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
  "SelectQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select A Question"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "Of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of"])},
  "TermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "ContentsTermsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "TermsConditionsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please read through the terms outlined below and click I agree to complete your registration."])},
  "Print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])},
  "IAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I Agree"])},
  "IAgreeToThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Survey Contest"])},
  "IAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
  "BrowserUpdateText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["NOTICE: You are using a browser that will not be compatible with our website. Please update your browser to the latest version so you can enjoy the benefits of using ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), "."])},
  "MetaData": {
    "Login": {
      "Title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("prefix")), _interpolate(_named("tenant")), " Homeowner Portal Login ", "|", " ", _interpolate(_named("tenant"))])},
      "Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manage your ", _interpolate(_named("tenant")), " mortgage with ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), ". Login to ", _interpolate(_named("prefix")), _interpolate(_named("tenant")), " to access your mortgage details, make lump sum payments, review your property tax, and more!"])},
      "Title-MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyMCAP Homeowner Portal Login ", "|", " MCAP"])},
      "Title-MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyMERIX Customer Portal Login ", "|", " MERIX Financial"])},
      "Description-MX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up or log in to the MyMERIX customer portal to access your MERIX Financial mortgage today!"])},
      "Title-LW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lendwise Customer Portal Login ", "|", " Lendwise"])},
      "Description-LW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up or log in to the Lendwise customer portal to experience the better way to manage your mortgage."])},
      "Title-NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyNPX Customer Portal Login ", "|", " NPX"])},
      "Description-NP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access your NPX mortgage balance and payment details anytime, anywhere by registering or logging in to the MyNPX customer portal."])},
      "Title-PB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyPeoplesBank Portal Login ", "|", " Peoples Bank"])},
      "Description-PB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View your Peoples Bank mortgage balance, payment details and more information by registering or logging in to the customer portal."])}
    }
  },
  "PasswordResetCompleteHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset Complete"])},
  "PasswordResetCompleteBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been reset.  Please login with your new password."])},
  "PasswordResetHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Reset"])},
  "Payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
  "PrincipalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Paid"])},
  "InterestPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Paid"])},
  "TotalPI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total P&I"])},
  "P&I": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P&I"])},
  "ScheduledPaymentsMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Details in the table below project your scheduled payments from now until your maturity date <strong style='font-size: inherit;'>", _interpolate(_named("date")), "</strong>"])},
  "ScheduledPaymentsMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>This amortization schedule shows the expected breakdown between principal and interest of each payment from the next payment date to the maturity date. This amortization schedule assumes that all payments are made on the payment due dates.  We have projected the amount of the scheduled payments using your payment particulars currently in place on your mortgage.</p><p style='margin-bottom: 15px;'>Changes to your mortgage balance will cause the payment amount to be different than shown. Please note, the following information will include any lump sum or additional scheduled payments outside of your regular mortgage payment.</p>"])},
  "PropertyTaxesMessage2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>Paying your annual municipal property tax bill is mandatory for all Homeowners.</p><p style='margin-bottom: 15px;'>We can help ease the burden of a large yearly expense, through adding the tax portion along with your regular mortgage payments. We collect the additional portion for your estimated annual property tax bill and pay the municipality on your behalf. Did we tell you this service is free?</p><p style='margin-bottom: 15px;'>One less thing to worry about, and for further details please contact us today at ", _interpolate(_named("phone")), ".</p>"])},
  "YourMortgageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Mortgage Details"])},
  "TodaysBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today’s Balance"])},
  "PaymentsUntilRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Until Renewal"])},
  "CurrentRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Rate"])},
  "DetailsAtRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details at Renewal"])},
  "ClosingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Balance"])},
  "PrinciplePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Paid"])},
  "TotalAmountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount Paid"])},
  "RemainingAmortizationRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amortization at Renewal"])},
  "OpeningPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Principal"])},
  "PIPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P & I Payment"])},
  "DetailsAsAtMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details As At Maturity"])},
  "ClosingPrincipalToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Principal To Maturity"])},
  "PaymentsScheduledToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Scheduled To Maturity"])},
  "AppliedToPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied To Principal"])},
  "AppliedToInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied To Interest"])},
  "TotalPaymentsUpToMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payments Up To Maturity"])},
  "RemainingAmortizationAtMaturity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RemainingAmortizationAtMaturity"])},
  "AsOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Of"])},
  "BalanceAtRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance At Renewal"])},
  "Remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining"])},
  "Years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
  "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
  "AccountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Details"])},
  "AlphaNumericCharacter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpha character"])},
  "DocumentDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Delivery"])},
  "SelectDeliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Document Delivery Method"])},
  "ElectronicCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic Communications"])},
  "Username6Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The User Name must be at least 6 characters long"])},
  "UsernameSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot contain spaces or any of the following special characters: =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
  "UsernameSpecialCharatersError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username cannot contain spaces or any of the following special characters: =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
  "PasswordStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Strength"])},
  "PasswordsMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords Match"])},
  "Weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weak"])},
  "Okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
  "NoEnoughFundsWithdraw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You do not have enough funds available to make a withdraw. The minimum withdraw amount required is ", _interpolate(_named("minimumAmount")), ". Please note payments will take 1 business day to post to your account."])},
  "Strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strong"])},
  "PaymentRenewalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are paying off  your mortgage prior to the end of the current term, payments reflected are to the date of the last payment in your current term."])},
  "PaymentAmountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment amount shown consists of principal and interest and does not include, property tax, mortgage insurance or Home Warranty (if applicable)."])},
  "ActivityType-P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-authorized payment"])},
  "ActivityType-LC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assisted draw"])},
  "ActivityType-AW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
  "ActivityType-A2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress draw"])},
  "RenewalInDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Time"])},
  "NumberOfMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " Month"])},
  "NumberOfMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("months")), " Months"])},
  "NumberOfYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Years"])},
  "NumberOfYear": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Year"])},
  "NumberOfYearsAndMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Years ", _interpolate(_named("months")), " Months"])},
  "NumberOfYearAndMonths": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Year ", _interpolate(_named("months")), " Months"])},
  "NumberOfYearsAndMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Years ", _interpolate(_named("months")), " Month"])},
  "NumberOfYearAndMonth": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " Year ", _interpolate(_named("months")), " Month"])},
  "Electronic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic"])},
  "Mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail"])},
  "SkipPaperLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show me next time"])},
  "ActivityHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Principal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
    "Interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
    "PropertyTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
    "HomeMortgageProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Mortgage Protection"])},
    "OtherAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Amount"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Reversed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversed"])}
  },
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
  "Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "ActivityReversedTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "ActivityReversedFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Refinance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refinance"])},
  "ActivityPdfHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mortgage Activity ", _interpolate(_named("mortgageNumber")), "\n ", _interpolate(_named("filterType")), ": ", _interpolate(_named("filterDuration"))])},
  "ActivitiesNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Activities for this time period were found."])},
  "MortgageActivityFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Activity"])},
  "ForgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
  "LoginPageMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - The Better Way To Manage Your Mortgage"])},
  "RagisterMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the details below exactly as they are shown in your mortgage documents provided to you. Your email address will be used to verify your account"])},
  "ElectronicCommMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per the terms outlined in your mortgage commitment letter, we may email you concerning your mortgage account."])},
  "ElectronicCommAccept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I would like to receive messages related to my mortgage lifecycle from ", _interpolate(_named("tenant")), "."])},
  "Understand": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I understand by registering here, I am providing consent to receive marketing messages from ", _interpolate(_named("tenant")), " by email. Marketing messages may include, but not limited to, home and mortgage-related products, services, and promotions. You may unsubscribe at any time."])},
  "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "LostUsernameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost your password or username?"])},
  "LostUsernameOrPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry.  We can help you recover your account details quickly and get you logged back in."])},
  "SelectOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option"])},
  "RememberPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't remember my password"])},
  "RememberPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Don't worry, we can help. Your security and privacy is important to us. On the next step we will ask you a few questions about your mortgage before resetting your password.</p>"])},
  "RememberUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't remember my username"])},
  "RememberUsernameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't remember my username and password"])},
  "SocialNotWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My social login is not working"])},
  "SocialNotWorkingMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='margin-bottom: 15px;'>Since we have no control over your social login, we will proceed to reset your account. Your security and privacy is important to us. On the next step we will ask you a few questions about your mortgage before resetting your account.</p><p>After your account has been reset, you will have to choice to re-link your social login or choose to use a username and password.</p>"])},
  "StartRecovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Recovery"])},
  "ChooseVerifyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need to verify your account."])},
  "ChooseVerifyAccountMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to verify your account details, we need to send you a 6 digit code. Select a method below to continue."])},
  "VerifyMeUsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify me using"])},
  "SendAnSMS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send an SMS to ", _interpolate(_named("number"))])},
  "SendAnEmail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send an email to ", _interpolate(_named("email"))])},
  "AnswerSecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer security questions"])},
  "NoneOfAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have access to any of the above."])},
  "TermInYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("term")), " Years"])},
  "PaymentFrequencies": {
    "Annually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annually"])},
    "SemiAnnually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-Annually"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "SemiMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-Monthly"])},
    "BiWeekly-Accelerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerated Bi-Weekly"])},
    "Weekly-Accelerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerated Weekly"])}
  },
  "BiWeekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-Weekly"])},
  "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
  "NeedAssistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need assistance?"])},
  "Assistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need assistance"])},
  "NotificationAlerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Alerts"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "NewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "ConfirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "ChooseNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a new password"])},
  "ChooseNewPasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your verification completed successfully. Now it's time to choose a new password."])},
  "VerifyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to verify you are the owner of this mortgage at verification code will be sent to your mobile device:"])},
  "SecurityQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question"])},
  "AccountRecoveryComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Recovery Complete"])},
  "PasswordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password was changed."])},
  "GoDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to my dashboard"])},
  "NoneOfAboveMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All is not lost, you're in good hands. Contact us at the below phone number and one of our customer service representatives will help you complete your account recovery."])},
  "PropertyTaxTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Months"])},
  "PropertyTaxHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "Balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Account Balance"])}
  },
  "PropertyTaxPaymentsPdfHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Property Tax Payments"])},
  "PropertyTaxPaymentsFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
  "PropertyTaxPaymentType-P1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-authorized payment"])},
  "PropertyTaxesNotCollected": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You currently do not subscribe to ", _interpolate(_named("tenant")), "’s property tax service for the mortgage you have selected. For further details on this complimentary service, speak to one of our qualified specialists today at ", _interpolate(_named("phone")), " between the hours of ", _interpolate(_named("hours")), "."])},
  "PropertyTaxesChargeType7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your property tax is collected on your Line of Credit. You will find your property tax information under your mortgage detail section of that segment of your mortgage"])},
  "PropertyTaxPaymentsNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Property Tax Payments for this time period were found."])},
  "PropertyTaxActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Activity"])},
  "Other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "DocumentsRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Months"])},
  "DocumentsNoneFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Documents for this time period were found."])},
  "AmortizationHeaders": {
    "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "PrincipalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Paid"])},
    "InterestPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Paid"])},
    "TotalPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Paid"])},
    "PrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal Balance"])}
  },
  "FirstNewPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First New Payment Date"])},
  "NewTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Terms"])},
  "MortgageAmortizationFileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Amortization"])},
  "AmortizationPdfHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mortgage Amortization Schedule ", _interpolate(_named("mortgageNumber")), "\n ", _interpolate(_named("filterDuration"))])},
  "AmortizationTypeRecent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Months"])},
  "TaxPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Payment"])},
  "InterestAdjustmentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Adjustment Date"])},
  "IADAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IAD Amount"])},
  "CurrentTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Terms"])},
  "PleaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Note"])},
  "TotalPaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payment Amount"])},
  "ForMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Mortgage Number"])},
  "YourNewMaturityDateIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your New Renewal Date Is"])},
  "YourNewTotalLoanPaymentIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your New Total Loan Payment Is"])},
  "IncurMidtermLoanChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Will Incur A Mid-term Loan Change Fee Of"])},
  "InterestAdjustmentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And An Interest Adjustment Amount For"])},
  "FirstFullNewPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Full New Payment Date"])},
  "AcceptChangeFreq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check box to accept the billing of related charges (fees and/or interest)."])},
  "YouHaveChosenTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have chosen to"])},
  "YouHaveRequestedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have requested"])},
  "ChangeYourPaymentFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Your <span style='font-weight: 500; font-size: 13px;'>Payment Frequency</span> To"])},
  "ChangeYourPaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Your <span style='font-weight: 500; font-size: 13px;'>Payment Date</span> To"])},
  "ChangePaymentFrequencyFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mortgage payments pay backward (not forward like rent). When making a payment date change, please note that your next regular mortgage payment needs to be made as scheduled to finish the current payment schedule. If you are concerned about making your next regular mortgage payment, please call us as soon as possible at ", _interpolate(_named("phoneNumber")), " (", _interpolate(_named("serviceHours")), " local time Monday to Friday)."])},
  "SelectFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Frequency"])},
  "ViewActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Activity"])},
  "MortgageActionSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Payment Frequency"])},
  "MortgageActionSuccessDateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Payment Date"])},
  "MortgageActionSuccessSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been submitted successfully."])},
  "MortgageActionSuccessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to view your upcoming payments and account activity?"])},
  "Used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used"])},
  "ChangePaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Payment Date"])},
  "ChangePaymentDateFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mortgage payments pay backward (not forward like rent). When making a payment date change, please note that your next regular mortgage payment needs to be made as scheduled to finish the current payment schedule. If you are concerned about making your next regular mortgage payment, please call us as soon as possible at ", _interpolate(_named("phoneNumber")), " (", _interpolate(_named("serviceHours")), " local time Monday to Friday)."])},
  "ActivityDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Detail"])},
  "MaxExtraPaymentFooter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This payment will be withdrawn from your bank account, along with your regular mortgage<br/> installment of <strong style='font-size: inherit;'>", _interpolate(_named("value")), "</strong>."])},
  "MaxExtraPaymentFooter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be advised that banks can place a hold on recently deposited funds for 3-5 business days. If your regularly scheduled mortgage installment does not clear due to funds held by your bank, a Returned Payment Fee will be charged."])},
  "RemainingPastDueFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: For security, your bank account information will only be partially displayed."])},
  "RemainingPastDueFooter2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["***Should any changes be required for an arrears payment, please contact us at ", _interpolate(_named("phoneNumber")), ". We require 4 business days notice to make a change."])},
  "SelectRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select or type an amount below"])},
  "EndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "StartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "OneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One time"])},
  "Ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
  "StartingOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Starting On <strong>", _interpolate(_named("date")), "</strong>"])},
  "EndingOn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ending On <strong>", _interpolate(_named("date")), "</strong>"])},
  "LSPPRemaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LSPP Total Remaining"])},
  "NewPrincipalBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Principal Balance"])},
  "AmountDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])},
  "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
  "ManagePastDueBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Past Due Balance"])},
  "MakeAPastDuePaymentOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make A <strong style='font-size: inherit;'>Past Due Payment</strong> Of"])},
  "RemainingPastDueOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining <strong style='font-size: inherit;'>Past Due</strong> Of"])},
  "AfterThisPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After <strong style='font-size: inherit;'>This Payment</strong>"])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "MonthEndOptions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No change"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29th Day of the Month"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30th Day of the Month"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month End"])}
  },
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while processing your request"])},
  "BestOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's The Best Option For Me?"])},
  "IncreaseMyPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase My Payments"])},
  "IncreaseMyPaymentsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The increased payment will be applied directly to principle and will help you pay down your mortgage faster. To reduce your payments at a later date, you may incur a mid-term loan change fee."])},
  "MakeOneTimeLumpSumPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a One-Time Lump Sum Payment"])},
  "SetUpRecurringLumpSumPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set Up Recurring Lump Sum Payment"])},
  "PayDownMyMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Down My Mortgage"])},
  "MarsDown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This function is not currently available. Please try again after ", _interpolate(_named("time")), " ET"])},
  "WhatsInItForMeContent": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What's In It For Me?"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have two ways to pay down your mortgage faster: making extra payments and increasing your payment amount."])},
    "LumpSumPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump Sum Payments"])},
    "PaymentIncrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Increase"])},
    "LumpSumItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an extra payment one time or set up ongoing payments"])},
    "LumpSumItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump sum payments are applied directly to your outstanding balance"])},
    "LumpSumItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum ", "$", "100 per payment"])},
    "LumpSumItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment must be made on a regularly scheduled payment date"])},
    "LumpSumItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel future scheduled payments with no fees"])},
    "PaymentIncreaseItem1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affects all future payments"])},
    "PaymentIncreaseItem2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra payment amount is applied directly to your outstanding balance"])},
    "PaymentIncreaseItem3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment increases lock in on your mortgage anniversary date"])},
    "PaymentIncreaseItem4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment increases before they lock in with no fees"])},
    "PaymentIncreaseItem5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment increases after they lock in for a fee"])}
  },
  "PrincipleInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principle and Interest"])},
  "YourTotalNewPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your total new payment amount will be"])},
  "DateSelectedMonthEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The date you selected is the last day of the month for some months, but not all. Do you want your payment date permanently changed to:"])},
  "Shadowing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Shadowing ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName"))])},
  "CsrSearchHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for client using Username / WebClientId"])},
  "WithdrawFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw Funds"])},
  "DailyWithdrawalLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Withdrawal Limit"])},
  "DailyWithdrawalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily Withdrawal Available"])},
  "WeeklyWithdrawalLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Withdrawal Limit"])},
  "WeeklyWithdrawalAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly Withdrawal Available"])},
  "WithdrawFundsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the amount you would like to withdraw and select Continue. You will be able to review the details before completing your request."])},
  "WithdrawFundsFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The business day cutoff time for withdrawals is 10:00 pm EST, Monday through Friday"])},
  "WithdrawFundsFooter2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your monthly line of credit payment is within 5 business days of this withdrawal, you will see an additional interest payment withdrawn from your account along with your next monthly line of credit payment. This additional amount represents the interest due on the balance withdrawn today."])},
  "WithdrawFooter3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<br/><br/>If you need the money faster a ", "$", "25 direct wire fee will apply and funds may be deposited within the same or next business day.<br><br>Please cancel this transaction and contact us at ", _interpolate(_named("phoneNumber")), " (", _interpolate(_named("serviceHours")), ").<br/><br/>*** Tangerine bank does not permit direct wires. ***<br/>"])},
  "ToWithdrawFundsOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Withdraw Funds</strong> Of"])},
  "BalanceAfterWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance After Withdrawal"])},
  "UsingMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using Mortgage Number"])},
  "RequestedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested On"])},
  "ToBeDepositedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Be Deposited On"])},
  "MakePaymentOf": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make A <strong style='font-size: inherit;'>", _interpolate(_named("type")), "</strong> Additional Payment Of"])},
  "DayOfWithdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day of Withdrawal Request*"])},
  "FundsDeposited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funds deposited into your bank account"])},
  "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
  "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
  "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
  "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
  "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
  "SaturdaySunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday or Sunday"])},
  "StatutoryHolidays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory Holidays"])},
  "StatutoryHolidaysExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory holiday requests will be deposited 2 business days following the holiday Example: Holiday Monday = Thursday"])},
  "NoOfPendingPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. Of Pending Payments"])},
  "ManageLumpSumPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Lump Sum Payments"])},
  "ManageLumpSumPaymentsNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All payments requests are sent to your bank 3 business days prior to the actual payment date. If you need to cancel an additional payment you have set up with us, the request needs to be made at least 3 business days before the requested payment date. If you are setting up a request now and it is less than 3 days prior to the requested payment date, please be aware that you may only cancel it today if a cancellation becomes necessary."])},
  "ConfirmCancelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this payment?"])},
  "SpecialPaymentsNotAllowed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Manage Past Due Balance feature is not currently available. Please call us at ", _interpolate(_named("phoneNumber")), "."])},
  "VerificationCodeResendFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error resending your verification code, please try again."])},
  "SendCodeToMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send my verification code to my mobile phone"])},
  "WebClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Client Id"])},
  "SelectBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Brand..."])},
  "ViewPortalBorrower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Portal for Borrower"])},
  "WithdrawInputError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please enter a number between ", _interpolate(_named("min")), " and ", _interpolate(_named("max"))])},
  "AgentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent Login"])},
  "ChangeSites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Sites"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "SkipPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Assistance"])},
  "IndicateReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate the reason for your request"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "ReasonQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the main reason for your payment assistance request?"])},
  "HouseholdIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Household Information</strong><br/> <span style='font-size: 14px;'>(Total current monthly household income - net after deductions)</span>"])},
  "OtherSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other source of income"])},
  "NumberDependents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Dependents</strong><br/> <span style='font-size: 14px;'>(Number under the age of 18)</span>"])},
  "RRSPRIFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RRSP/RIFF"])},
  "StockBonds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock/Bonds"])},
  "TermDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term Deposit"])},
  "OtherProperty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Property"])},
  "Savings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savings (choose all applicable)"])},
  "Occupancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupancy"])},
  "OwnerOccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Occupied"])},
  "Rental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental"])},
  "Both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both"])},
  "NumberOccupantsUnder18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong class='primary'>Occupants</strong><br/> <span style='font-size: 14px;'>(Number of occupants age 18 and over)</span>"])},
  "Occupant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupant"])},
  "SkipPaymentIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Payment assistance program allows you to defer a mortgage payment. We now request you please provide the information that follows as accurately as possible in order to assess your eligibility."])},
  "SkipPaymentIntroText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Should you have any additional questions please <a href='", _interpolate(_named("SAPClickHerelink")), "' class='link'>click here</a>."])},
  "SkipPaymentConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to our 5 business day's notice requirement, only the dates below are eligible for payment assistance."])},
  "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])},
  "FirstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "FullTimeEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Time Employment"])},
  "PartTimeEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part-Time Employment"])},
  "ContractEmployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract Employment"])},
  "SelfEmployed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Employed"])},
  "EmploymentInsuranceCerb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Insurance (EI)/Cerb"])},
  "RentalIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Income"])},
  "PensionDisabilityIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension/Disability Income"])},
  "CurrentSourceIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Source of Income"])},
  "EmploymentIndustry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment Industry"])},
  "EstimateTimeToReturnToWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate time to return to work"])},
  "TotalSavings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Savings"])},
  "SkipPaymentBecause": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Payment Assistance</strong> because"])},
  "SkipPaymentOtherOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What other options have you considered to assist with your situation? (select all that apply)"])},
  "MakeAdditionalPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make Additional Payment"])},
  "ManagePayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Payments"])},
  "CovidUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID-19 Update: Skip-a-Payment"])},
  "GetStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
  "AvailableDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Being Skipped"])},
  "PasswordResetSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have sent you an email that will allow you to reset your password."])},
  "YourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
  "UsernameRetrievalSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have sent you an email to ", _interpolate(_named("maskedEmail")), " with your username."])},
  "OnGoingPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ongoing - ", _interpolate(_named("frequency"))])},
  "OneTimePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time"])},
  "MatchingPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match"])},
  "VerificationCodeIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification code that you entered is incorrect"])},
  "VerificationCodeIncorrectLast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification code that you entered is incorrect or expired. You have one chance remaining."])},
  "InformationStatement": {
    "Mortgagor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgagor"])},
    "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage"])},
    "StatementOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STATEMENT OF ACCOUNT - as at "])},
    "StatmentPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Statement is for information or assumption purposes and"])},
    "PayoutPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["must not be used for payout purposes."])},
    "MortgageAccount": {
      "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Account"])},
      "PrinciplaeBalanceDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Principal balance after ", _interpolate(_named("nextPaymentDate")), " installment"])},
      "InterestDue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest due"])},
      "OtherCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other charges/other fees"])},
      "PropertyTaxAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property tax account"])},
      "TotalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])}
    },
    "TotalAmountWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If the total amount is paid after ", _interpolate(_named("nextPaymentDate")), ", add interest of ", "$", _interpolate(_named("dailyInterest")), " per day"])},
    "particulars": {
      "header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current Loan Particulars ", "@", " ", _interpolate(_named("nextPaymentDate"))])},
      "InterestRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Rate"])},
      "MaturityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maturity Date"])},
      "Biweekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bi-weekly Payments"])},
      "BledPnI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Blended P & I"])},
      "TaxPortion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Tax portion"])},
      "MortgageProtecetion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Home Mortgage Protection"])},
      "TotalInstalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Total Installment"])}
    },
    "StatementOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this Statement is for information purposes only, the pre-authorized chequing plan will not be canceled."])},
    "StatementTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If this Statement is for Assumption purposes, the purchaser needs to be approved. The statement is based on all payments, up to and including the ", _interpolate(_named("nextPaymentDate")), " installment being honored by your bank. If you are paying by 'Pre-Authorized Checking', this plan will be canceled after the ", _interpolate(_named("nextPaymentDate")), " installment."])},
    "ThankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You"])},
    "MCAPSC": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantName")), " Service Corporation"])}
  },
  "ChangeUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Username"])},
  "ChangePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
  "CurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "ChangeEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email Address"])},
  "ChangeMailingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Mailing Address"])},
  "ChangePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Phone Number"])},
  "ChangeSecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Security Questions"])},
  "ViewUserAgreement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View User Agreement"])},
  "ChangeMortgagePermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Mortgage Permissions"])},
  "SecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Questions"])},
  "DocumentsDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Delivery"])},
  "LinkAnotherMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Another Mortgage"])},
  "ContactUsDrawer": {
    "newCoversation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Email"])},
    "LeaveUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us a message"])},
    "TypicalReplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our team typically replies in 1 day"])},
    "NewMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "QuickAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find answers quickly"])},
    "SearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results for"])},
    "SearchForAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for answers"])},
    "NoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])}
  },
  "Message": {
    "Help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want help with"])},
    "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "SelectHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select what you need help with"])},
    "SelectMortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your mortgage"])},
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type your message here"])},
    "AttachFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach a file"])},
    "SendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
    "AttachmentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The attachment limit is 7 files"])},
    "FileTypeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type not supported"])},
    "EmptyFileError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File is empty"])},
    "FileSizeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total size cannot exceed 20 MB"])},
    "DoubleFileError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File already exists"])},
    "MessageConf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Thank you. <br/><br/>You will receive a reply within 2 business days "])},
    "ToYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to your email address on file."])},
    "CheckSpam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please be sure to check for our response in your junk or spam folders."])},
    "FieldError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a topic, a mortgage number and write a message."])},
    "CharacterLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("characters")), " of 2000 max characters"])},
    "FileTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Only jpg, png, gif, rtf, txt, docx, doc, docs, pdf files are supported.)"])},
    "FileNameTooLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry there has been an issue with your upload. The document name exceeds 100 characters. Please rename the file and try your upload again"])}
  },
  "ChangeUserName": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Username"])},
    "CurrentUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Username"])},
    "ConfirmCurrentUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Current Username"])},
    "NewUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Username"])},
    "ConfirmNewUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Username"])},
    "noMatchError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Usernames don't match"])},
    "notValidUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current username is wrong."])},
    "SameAsCurrentUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new username must be different from your current username"])},
    "UsernameAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username already exists"])},
    "SettingChangeSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Username"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Username was Changed successfully"])}
    },
    "hints": {
      "space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Spaces"])},
      "special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Special Characters =+#%^)(\\/?><][", "}", "{", "|", ";:~`&®"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 6 Characters"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users Names Match"])}
    }
  },
  "AssociatedCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amounts To Be Withdrawn"])},
  "SignIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "SearchHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search our help centre"])},
  "ContactUsVia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contact us via <a href='#' class='link'>Email</a> or call us directly at ", _interpolate(_named("phoneNumber"))])},
  "ContactUsVia": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Contact us via <a href='#' class='link'>Email</a> or call us directly at ", _interpolate(_named("phoneNumber"))])},
  "PersonalTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need a little personal touch?"])},
  "HowCanWeHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help?"])},
  "HelpCats": {
    "Apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applying for<br/> Mortgage Financing"])},
    "Statements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence<br/> & Statements"])},
    "Discharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discharges"])},
    "Changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Changes"])},
    "Taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Taxes"])},
    "Renewals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewals<br/> & Conversions"])},
    "Special": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Payment<br/> Arrangements"])},
    "COVID-19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID-19"])}
  },
  "ChangePasswordSucessText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password was successfully changed."])},
  "electronicComm": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic Communication"])},
    "TransactionMesg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per the terms outlined in your mortgage commitment letter, we may email you concerning your mortgage account."])},
    "Agree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I would like to receive messages related to my mortgage lifecycle from ", _interpolate(_named("tenant")), "."])},
    "Understand": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I understand by registering here, I am providing consent to receive marketing messages from ", _interpolate(_named("tenant")), " by email. Marketing messages may include, but not limited to, home and mortgage-related products, services, and promotions. You may unsubscribe at any time."])},
    "Confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to stop getting electronic communications?"])},
    "SuccessAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have agreed to receive electronic communications."])},
    "SuccessDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not agreed to receive electronic communications."])}
  },
  "PasswordsDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your passwords do not match, please make sure your password is the same"])},
  "RelatedQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related Questions"])},
  "WasThisAnswerHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was this answer helpful?"])},
  "CantFind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find the answers you're looking for?"])},
  "StillNeedHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you still can't find the right answers to your questions, we're here to help."])},
  "ContactUsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us at the below phone number and one of our customer service representatives will help you get the right answers to your questions."])},
  "EmailUsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email us with all your questions and a representative will reply back with the answers you are looking for."])},
  "ChangeEmail": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email Address"])},
    "CurrentEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Email Address:"])},
    "NewEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Email Address:"])},
    "ConfirmEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Email Address:"])},
    "ErrorNotValidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid email address"])},
    "SameEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't change to the same email address on file"])},
    "ErrorMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email addresses don't match"])}
  },
  "Characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters"])},
  "IncorrectAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect security question answer."])},
  "IncorrectAnswerstipHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble with your answers?"])},
  "IncorrectAnswerstip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did your original answer have spaces?"])},
  "IncorrectAnswerstip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you add a space in error?"])},
  "IncorrectAnswerstip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you confirm the answers you have entered below exactly match your original answers?"])},
  "SecurityQuestionsSettings": {
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Questions"])},
    "SelectAQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a question"])},
    "Question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
    "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
    "SecurityQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Questions"])},
    "Success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been completed and you will be automatically logged out.  On your next login you will be prompted to answer your new security questions."])},
    "ErrorDuplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Questions"])},
    "shortAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer must be a minimum of 3 letters and must not contain any spaces."])}
  },
  "OccupantsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid number of occupants in your household over the age of 18"])},
  "DocDeliver": {
    "note2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By selecting “electronic”, all mortgage documents will be added to ", _interpolate(_named("tenant")), ". An email alert will be sent to <b><u>", _interpolate(_named("userEmail")), "</u></b> when a new document is available for viewing."])},
    "paperless": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenant")), " may elect to provide certain mortgage documents by mail in addition to providing them electronically."])},
    "paper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are changing your preference to receive all mortgage related documentation by regular mail. You may change this preference at any time"])},
    "AnnualStatements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Statements"])},
    "RenewalDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal documents"])},
    "MortgageDefaultNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage default notifications"])},
    "confirmSubPaperless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to switch to electronic?"])},
    "confirmSubMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to switch to mail?"])}
  },
  "CreditAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Available"])},
  "CreditUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Used"])},
  "6MonthConvertibleTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a closed rate product with fees and penalties to end early.  This product allows you to switch to any of our fixed or variable rate products without cost."])},
  "VariableTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a 5yr variable rate product.  Standard charge terms apply."])},
  "FixedTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a 5yr fixed rate product.  Standard charge terms apply."])},
  "ValueFlexTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ValueFlex Mortgage provides a deeply discounted rate but comes with a sale-only restriction. This means the only way to end your mortgage before the renewal date is to sell the property to someone who is not a family member. Review your original documents or contact us for more information about the terms of your mortgage."])},
  "LowRateTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Low Rate Basic Mortgage provides a deeply discounted rate but comes with restrictions and penalties. Your mortgage is not eligible for early renewal and ending the mortgage before the renewal date will result in a penalty of at least 3% of your remaining balance. Review your original documents or contact us for more information about the terms of your mortgage."])},
  "MortgageLifeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Life Insurance"])},
  "DisabilityInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disability Insurance"])},
  "SourceOfFunds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source Of Funds"])},
  "SourceOfFundsHelp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Before your privilege payment can be processed, you are required to declare the origin of the funds being used for this transaction. Example of this may include, 'savings from income earned', 'liquidation of investments', 'sale of other property', etc. ", _interpolate(_named("name")), " is required to collect this information in order to comply with Canada's anti-money laundering and anti-terrorist financing legislation – Proceeds of Crime (Money Laundering) and Terrorist Financing Act of Canada and its associated regulations."])},
  "FindMortgageNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to find your Mortgage Account Number:"])},
  "SmallEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date must be after start date"])},
  "ErrorCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password is wrong"])},
  "SameAsCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password must be different than current password."])},
  "French": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
  "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "LsppCountdown": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can make ", _interpolate(_named("total")), " lump sum payments per calendar year on any payment date upcoming.  You have ", _interpolate(_named("remaining")), " remaining for this calendar year."])},
  "ImageOfCheque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Cheque"])},
  "ImageOfLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Letter"])},
  "ImageOfStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Annual Statement"])},
  "ImageOfAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Account Info"])},
  "ImageOfInfoStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image of Info Statement"])},
  "SkipPaymentOverLimitWarningMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" To determine your payment assistance options, please complete the application form that follows."])},
  "SkipPaymentOverLimitConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of our payment assistance program, we will assume payment of your property taxes for a minimum of one year.  To allow for this, your mortgage payment will be adjusted and increased to include a tax portion. To assess the payment assistance options available to you, MCAP will order a property evaluation. The cost of up to ", "$", "150.00 plus tax will be charged to the tax account set up on your mortgage.<br/><br/><strong>It is important to note your regular scheduled payments are expected to continue while we complete our review.</strong>"])},
  "SkipAPaymentReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip A Payment report"])},
  "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeowner"])},
  "SubmissionSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submission Successful"])},
  "SkipPaymentWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional charges can appear within the skipped payment period you have requested.  Please review the upcoming activity screen prior to each skipped payment for additional charges that may apply."])},
  "ChangeEmailSuccessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Email Address"])},
  "ChangeEmailSuccessSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address was changed successfully"])},
  "BorrowerRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select one of the borrowers."])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "ServiceFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Fees"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fees"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "CreditorInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Life & Disability Insurance"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "AreYouPrepared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you prepared for the unexpected?"])},
  "MLIText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mortgage Life & Disability Insurance can pay off your mortgage balance up to ", _interpolate(_named("balance")), " in the case of a life claim, and up to ", "$", "4,000 per month for two years in the case of a disability claim."])},
  "3in4Canadians": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 in 4 Canadians"])},
  "3in4CanadainsSecondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feel they would have difficulty paying everyday expenses if their partner were to pass away."])},
  "50PercentChance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canadians have a 50% chance"])},
  "50PercentChanceSecondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of becoming disabled and unable to work for 90+ days."])},
  "MLILeadInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's talk about how we can protect you and your family."])},
  "MLILeadInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to know more about Mortgage Life & Disability Insurance?  One of our licensed insurance agents will reach out at a time that is convenient for you."])},
  "FullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
  "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "TimeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred Contact Time (Eastern Time)"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any additional comments or questions?"])},
  "ReturnToForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
  "MLIFormCancelPopUpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cancel before submitting, you will not be entered into the contest"])},
  "MLIFormCancelPopUpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure?"])},
  "MLIFormSubmitConfirmTextSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have requested a call back, one of our licensed agents will contact you within 1 to 2 business days."])},
  "MLIFormSubmitConfirmText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been entered into the Mortgage Life & Disability Insurance Survey Contest."])},
  "ProvideFullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your full name."])},
  "ProvidePhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the phone number you wish to be contacted at."])},
  "MLIFormError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are required fields missing, please update prior to submitting."])},
  "SunLifeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='font-size: 13px'>Sun Life Assurance Company of Canada is the insurer of this product and is a member of Sun Life group of companies. To contact the Insurer, call 1-877-271-8713 or visit <a target='_blank' style='font-size: 13px' href='https://www.sunlife.ca/en/' id='sunlife'>www.sunlife.ca.</a></p>"])},
  "CharactersLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters Left"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "fourOutOfThreeLeaves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["four out of three leaves"])},
  "ThreeCalendarCrossedOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["three calendar crossed out"])},
  "MortgageActivityAriaLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get more details on Mortgage Activity (PDF)"])},
  "UserAgreementChangedMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Our User Agreement has recently changed.  Click \"I agree\" to acknowledge the change and access ", _interpolate(_named("prefix")), _interpolate(_named("tenant"))])},
  "AccountLockedError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are locked out of your account.  Please wait until ", _interpolate(_named("date")), " then click \"Having trouble signing in\" to recover your username and reset your password."])},
  "Safeguard": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Statement Request"])},
    "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To better serve our homeowners, please let us know the reason you are requesting an information statement. Select all that apply."])},
    "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete your information statement request select 'Continue'."])},
    "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select all that apply."])},
    "Options": {
      "Selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selling your home"])},
      "Purchasing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchasing a home"])},
      "Transferring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferring your mortgage"])},
      "Consolidationg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consolidating debt"])},
      "Invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looking to invest"])},
      "Renovations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning for renovations"])},
      "Financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial planning"])},
      "None": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of the above"])}
    }
  },
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "InfoStatementHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mortgage information statement provides a current view of the status of your mortgage as of the date of the request."])},
  "InfoStatementTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does an information statement contain?"])},
  "InfoStatementImportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Details"])},
  "InfoStatementImportDetailsBody1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The information statement will be found in the Documents section of your My", _interpolate(_named("tenant")), " portal."])},
  "InfoStatementImportDetailsBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only your most current information statement will be visible."])},
  "InfoStatementImportDetailsBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your statement may be available within 5-10 minutes however it could take up to 2 hours."])},
  "InfoStatementImportDetailsBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once a request is made you cannot make another request for 24 hours."])},
  "SubmitRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Request"])},
  "ThankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You!"])},
  "InfoStatementConfirmationHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please allow up to 2 hours for your request to be processed."])},
  "InfoStatementConfirmationDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are required to be logged in to your My", _interpolate(_named("tenant")), " account to retrieve the document."])},
  "InfoStatementConfirmationBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access your information statement:"])},
  "InfoStatementConfirmationBody1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"Mortgage Details\" button from the landing page"])},
  "InfoStatementConfirmationBody2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to the \"Documents\" tab"])},
  "InfoStatementConfirmationBody3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the \"Information Statement\" link"])},
  "InfoStatementConfirmationBody4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the top left corner of your Browser click \"File\" then select \"Print\""])},
  "InfoStatementErrorHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There appears to be an issue fulfilling your request online. Please contact us at ", _interpolate(_named("phone")), " between the hours of 8AM to 7PM and one of our Customer Service Representatives would be happy to assist you!"])},
  "InfoSecurityQuestionNewDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully authenticated with your username and password. For further security purposes please answer the question below."])},
  "QuizHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Test your Mortgage Life & Disability Insurance knowledge for a chance to win ", _interpolate(_named("prize")), "!"])},
  "QuizMissingAns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like you forgot this question. Please provide your answer to proceed."])},
  "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure?"])},
  "QuizGoBackPrimaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you end the quiz before completing it, you will not be entered into the contest.<br/><br/>You can take the quiz again at any time during the contest period to be entered."])},
  "ReturnToQuiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Now"])},
  "WithoutTandCPrimaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By selecting 'Continue', you will not be entered into the contest. Click 'Accept' to agree to the Terms and Conditions to be entered."])},
  "AcceptTermsAnsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "withoutTandCError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept the Terms and Conditions to proceed."])},
  "Quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
  "HomeWarrantyQuizSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Take the ", _interpolate(_named("type")), " Service Plan quiz for a chance to win ", _interpolate(_named("prize")), "!"])},
  "HomeWarrantyQuiz": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " Service Plan"])},
  "HomeAppliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Appliance"])},
  "HomeSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home System"])},
  "HomeCoverage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("type")), " Service Plan"])},
  "HomeBenefit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Benefits of our ", _interpolate(_named("type")), " Service Plan"])},
  "PrepareForUnexpected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepare for the unexpected and don't pay for the FIRST 3 MONTHS! "])},
  "ServiceOfferHeading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provides up to "])},
  "ServiceOfferHeading1ColouredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$", "10,000 of coverage annually"])},
  "ServiceOfferHeading1Ending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", which protects against expensive and unforeseen repair bills!"])},
  "ServiceOfferHeading2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Covers the repair and replacement of your ", _interpolate(_named("type")), " if they stop working."])},
  "ServiceOfferHeading3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gives you direct access to a network of qualified service professionals."])},
  "EligibleToEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are eligible to enroll in the plan today."])},
  "EnrollmentCostHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All you pay is ", "$", _interpolate(_named("cost")), " per month plus applicable taxes"])},
  "Enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enroll"])},
  "Decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
  "RentedTandCHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm that no portion of my property is rented or used for commercial purposes"])},
  "TandCHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I agree to the ", _interpolate(_named("type")), " Service Plan "])},
  "TandCSubHeading1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "TandCSubHeading2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "SurveyContestTandCHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the Survey Contest "])},
  "SurveyContestTandC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and I also agree to the Survey Contest "])},
  "EnrollHeading": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enroll in ", _interpolate(_named("type")), " Service Plan coverage today, you don't pay for the first 3 months!"])},
  "DeclineWarning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is a limited time offer. If you decline the coverage, there is a 6-month waiting period before you will be eligible to enroll in the ", _interpolate(_named("type")), " Service Plan.<br><br>Select Close to change your enrollment decision. By selecting Continue you will not be enrolled for coverage but you will be entered into the ", _interpolate(_named("type")), " Service plan contest."])},
  "ThankYouAcceptEnrollTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your application."])},
  "ThankYouAcceptEnrollText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Once it has been processed, your coverage will appear on your My", _interpolate(_named("tenant")), " homepage and you will receive confirmation of enrollment by mail."])},
  "ConfirmEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I would like to enroll"])},
  "DeclineEnroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thanks"])},
  "InvalidPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number."])},
  "HomeServiceCoverageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Service Plan Coverage"])},
  "HomeServiceCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Service Plan"])},
  "TandCDisclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the following box(es) to proceed."])},
  "MultipleProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have multiple properties. Please select your primary residence below."])},
  "FireInsuranceUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Required:  Please Update Your"])},
  "FireInsuranceDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Fire Insurance documents have been "])},
  "Received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["received."])},
  "FireHalfPt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style='display: inline-block;list-style: disc inside;padding: 12px;text-align: left;'><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'>You have changed your existing insurance policy.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'> You have moved to a new insurance provider.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'> Your existing insurance coverage has expired.</li><li style='color: #5a5b68;font-size: 18px;font-weight: 500;text-indent: -1.5em;padding-left: 2em;'> Your insurance premiums are not up to date.</li></ul>"])},
  "FireHalfTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span  style='color: #5a5b68;font-size: 18px;font-weight: 500;'>Your Fire Insurance policy lapsed due to one of the below changes in your coverage.</span>"])},
  "FireFullPt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul style='display: inline-block;list-style: disc inside;padding: 12px;text-align: left;'><li style='color: #5a5b68;text-indent: -1.5em;font-size: 18px;font-weight: 500;    padding: 5px;padding-left: 2em;'>A fee will be charged if we do not receive proof of coverage by the date specified in your Fire Insurance letter.</li><li style='color: #5a5b68;text-indent: -1.5em;font-size: 18px;font-weight: 500;    padding: 5px;padding-left: 2em;'>The new policy must include the property address and homeowner(s) on the mortgage.</li></ul>"])},
  "FireFullTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span  style='color: #5a5b68;font-size: 18px;font-weight: 500;'>Due to changes in your insurance policy, action is required to update your mortgage. Please click on Update Policy to provide us with proof of your most recent coverage.<br/><br/> Please note:</span>"])},
  "MoreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Details"])},
  "WhatToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What You Need To know!"])},
  "UpdatePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Policy"])},
  "FireReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have received your documents.  Please allow up to 48 business hours for the alert to be removed."])},
  "LSPPCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump Sum Privilege Payment Calculator"])},
  "LSPPCalculatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see out how much interest you can save and how much faster you can pay off your mortgage, select the payment scenario of your choice below."])},
  "PPPScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump Sum Privilege Payment Scenario"])},
  "EnterValuesLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the values on the left to calculate a lump sum payment scenario."])},
  "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])},
  "NewProjectAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Projected Amortization"])},
  "InterestSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest Saved"])},
  "TotalPayAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payment Amount"])},
  "PayOffFaster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay your mortgage off ", _interpolate(_named("time")), " faster"])},
  "OverYears": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Over ", _interpolate(_named("years"))])},
  "TotalNumberOfPayments": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total of ", _interpolate(_named("number")), " payments occurring ", _interpolate(_named("frequency"))])},
  "TotalNumberOfPaymentsOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total of ", _interpolate(_named("number")), " payment"])},
  "ApplyPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Payment"])},
  "CompareScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Scenarios"])},
  "ExitComparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit Comparison"])},
  "ScenarioNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scenario ", _interpolate(_named("number"))])},
  "PPPCompareScenario": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lump Sum Privilege Payment Scenario ", _interpolate(_named("number"))])},
  "LSPF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump Sum Payment Frequency"])},
  "EnterValuesAbove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the above values to calculate a lump sum payment scenario."])},
  "ProjectedAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projected Amortization"])},
  "ProjectedAmortizationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Represents the actual time it will take to pay off the mortgage in full, based on the current principal balance, interest rate, payment amount and payment frequency and any future date changes to payment frequency and scheduled lump sum payments."])},
  "LSPPCalc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate a Pay Down Scenario"])},
  "FasterScenario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the faster scenario"])},
  "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTENTION"])},
  "RedirectMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p style=\"padding: 10px 0\">The website address to ", _interpolate(_named("oldUrl")), " has been changed.</p> <p style=\"padding: 10px 0\">You will need to update your favourites, bookmarks and home screen links to ", _interpolate(_named("newUrl")), " by ", _interpolate(_named("deadline")), ".</p><p style=\"padding: 10px 0\">Your Username and Password will not be impacted by this change.</p>"])},
  "SessionExpiredHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session Expired"])},
  "SessionExpiredSubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons and the protection of your personal data, your session timed out after a period of inactivity."])},
  "Conversion": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in to a Fixed Rate"])},
    "AlertTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send me an offer to lock-in my rate"])},
    "Alert1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive the best available 5 year fixed rate."])},
    "Alert2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will not make any changes to your mortgage until the offer is returned to us, signed by all homeowners."])},
    "Alert3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once submitted, you will receive an email confirmation with further details."])},
    "SuccessSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been submitted."])},
    "SuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with instructions for completing your lock-in. The offer will be available within 2 business days."])}
  },
  "Signatory": {
    "MobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Number"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "VerificationMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification Method"])},
    "TextMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text Message"])},
    "MethodTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS fees may apply. Please check with your service provider."])},
    "MobilePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter mobile number here."])},
    "EmailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email address here."])},
    "Actions": {
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])}
    },
    "Errors": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
      "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a 10-digit mobile number."])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a correct email address."])},
      "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Save/Close all forms before continuing."])},
      "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
      "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Canadian mobile number."])}
    }
  },
  "Renewal": {
    "Actions": {
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deselect"])},
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "GoBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to Homepage"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "UpdateResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update / Resend"])},
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])}
    },
    "Menu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew My Mortgage"])},
      "RenewalIntroduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Introduction"])},
      "RatesAndProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rates and Products"])},
      "ManageMyRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage My Renewal"])}
    },
    "Banner": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get started on your mortgage renewal today!"])},
      "Message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your mortgage will be up for renewal on <b>", _interpolate(_named("date")), "</b>. "])},
      "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at your renewal options and renew online."])}
    },
    "Introduction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewing Your Mortgage"])},
      "NextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Renewal Options"])},
      "Support": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos to Help You Along Your Homeowner Journey"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "InfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why renew with MCAP?"])},
        "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewing your mortgage is <b>free</b>!"])},
        "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not need to re-qualify to renew your mortgage with MCAP. That means <b>no credit check</b> or <b>documents to submit</b>"])},
        "InfoNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Complete the entire process online</b> using the self-serve portal, or get assistance from yor personal Renewal Account Manager"])},
        "InfoNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're <b>guaranteed the best rates</b> available an can personalize your mortgage with additional products and features"])},
        "Footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's start your mortgage renewal!</br>Our goal is to make the renewal process as simple as possible. Using the self-serve mortgage renewal feature, you'll be able to compare mortgage options and the best solution to fit your needs."])}
      },
      "Compare": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Compare Rates\" to see your current variable rate and payment amount compared to today's rate options."])}
      },
      "Start": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Lock-In?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a renewal offer is free of charge. Select \"Compare Rates\", choose a fixed rate option that suits you, and click \"Continue\". To request a Renewal offer a valid email and mobile number will be required."])}
      }
    },
    "Product": {
      "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "PrincipalAndInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal and Interest"])},
      "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
      "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
      "PaymentAmountTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all charges that make up your total payment e.g. principal, interest, property tax, and other products if applicable."])},
      "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Date"])},
      "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amortization"])},
      "RemainingAmortizationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Represents the actual time it will take to pay off the mortgage in full, based on the current principal balance, interest rate, payment amount and payment frequency."])},
      "CurrentProductDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Product"])},
      "RenewalOfferDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Offer Details"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
      "CancelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Cancel Renewal Offer\" and you will remain in your current variable rate product."])},
      "CancelRenewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Renewal Offer"])},
      "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])},
      "SigningStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Status"])},
      "SignaturePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Pending"])},
      "SigningCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Completed"])},
      "SigningArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Archived"])}
    },
    "Warranty": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect Your Investment"])},
      "ErrorMessage-HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option for Home Service to continue."])},
      "ErrorMessage-HS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option for Home Warranty to continue."])},
      "VideoSection": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Service Plan: Protect your home with affordable coverage"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch the video below to see how the Home Appliance Service Plan can save you unexpected repairs of a costly home appliance."])}
      },
      "Benefits": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did You Know?"])},
        "Sections": {
          "Dishwasher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The <b>life expectancy</b> of a dishwasher is <b>9 years</b>"])},
          "WashingMachine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The <b>life expectancy</b> of a washing machine is <b>10 years</b>"])},
          "Appliances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>30 -40%</b> of appliances require repairs in the first <b>5 years</b>"])}
        },
        "Note1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Reflects average data from claims experiences for appliance repairs of the provider of the MCAP Home Appliance Service Plan."])},
        "Note2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Consumer Reports, 'By the numbers how long will your appliances last?' 2009"])}
      },
      "Eligibility": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eligibility"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No portion of your home can be used for rental or commercial purposes and coverage must be active for 30-days prior to making a claim. To read the complete list of Terms and Conditions for the Home Appliance Service Plan. "])},
        "Link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])}
      },
      "ServiceFees": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Fees"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No portion of your home can be used for rental or commercial purposes and coverage must be active for 30-days prior to making a claim. To read the complete list of Terms and Conditions for the Home Appliance Service Plan. "])}
      },
      "Offer": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you enroll you will get the first 3 months of membership fees at no cost!"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Continue\" to begin enrollment and be sure your Home Appliances are protected."])}
      },
      "Options": {
        "Coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add coverage to your mortgage"])},
        "Skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip for now"])},
        "ErrorNotSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "ErrorMandatoryAttest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    },
    "CompareRates": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Your Mortgage Renewal"])},
      "Filter": {
        "Fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
        "Adjustable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustable"])},
        "1YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1yr Fixed"])},
        "2YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2yr Fixed"])},
        "3YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3yr Fixed"])},
        "4YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4yr Fixed"])},
        "5YearFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5yr Fixed"])},
        "5YearValueFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5yr Value Flex Fixed / 5yr Low Rate Basic"])},
        "5YearValueVariable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5yr Value Flex Variable/5yr Low Rate Basic"])},
        "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show All"])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Options and Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing your next mortgage is all about finding what works for you. Take a look at the different mortgage products and rates to find the one that best suits your needs."])},
        "ViewAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Options"])},
        "HideAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a rate to continue."])},
        "ChangeNoticeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Change as of November 1st, 2023"])},
        "ChangeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <a href='https://www.mcap.com/residential-mortgages/products/our-mortgages/fusion-mortgage' target='_blank'>Here</a> for details on the changes that will occur when renewing your MCAP Fusion mortgage."])}
      },
      "FeaturesAndBenefits": {
        "AdjustableRate": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustable Rate: Features and Benefits"])},
          "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can lock-in to a fixed rate without penalty"])},
          "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase payments 20% per year"])},
          "InfoNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump sum payments 20% per year"])},
          "InfoNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portable to a new property upon qualification"])},
          "InfoNote5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumable upon requalifying"])}
        },
        "FixedRate": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Rate: Features and Benefits"])},
          "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase payments 20% per year"])},
          "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump sum payments 20% per year"])},
          "InfoNote3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portable to a new property upon qualification"])},
          "InfoNote4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumable upon requalifying"])}
        }
      }
    },
    "Support": {
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
      "ContactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-800-265-2624"])}
    },
    "StepsBar": {
      "Rates": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Your New Mortgage & Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Warranty": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Home Service Plan"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A warranty to protect one of your biggest investments."])}
      },
      "Appliances": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a Home Service Plan"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A warranty to protect one of your biggest investments."])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your contact information to receive and sign your renewal documents."])}
      },
      "Summary": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Summary & Confirmation"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review and submit your mortgage renewal."])}
      }
    },
    "ConfirmRate": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Renewal Your New Rate"])},
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your New Mortgage & Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage renewal at a glance."])}
      }
    },
    "Signature": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Renewal Your New Rate"])},
      "Sign": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiving Your Electronic Documents"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete your mortgage renewal, we'll need you to sign your mortgage documents electronically. Please confirm the contact information below, or click update to make any changes."])},
        "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will email you a notification within 1 hour when the renewal offer is ready to be signed."])}
      },
      "Errors": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
        "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a 10-digit mobile number."])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a correct email address."])},
        "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Save/Close all forms before continuing."])},
        "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
        "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Canadian mobile number."])}
      }
    },
    "Roles": {
      "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeowner"])},
      "Guarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantor"])}
    },
    "Summary": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Summary & Confirmation"])},
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your New Mortgage & Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage renewal at a glance."])}
      },
      "Warranty": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect Your Investment"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Home Service Plan will be added to your mortgage after the renewal date."])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review contact information."])}
      },
      "Confirm": {
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while processing your request"])}
      }
    },
    "Success": {
      "Request": {
        "Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Mortgage Renewal Offer is being created!"])},
        "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage renewal documents will be sent to your email shortly."])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once all the documents have been signed by the required homeowners, we will begin processing your renewal."])}
      }
    },
    "PostOffer": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage My Renewal"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you online support to help you manage your renewal offer and electronic signature experience. Managing your account has never been easier. See how we can help below."])},
      "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the offer is not signed by all homeowners and guarantors by the effective date, it will be cancelled and no changes will occur."])},
      "HelpOptions": {
        "One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To resend the link, update the mobile number or email address click \"Update / Resend\"."])},
        "Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To find out who has signed the offer, review the signing status on the \"Renewal Offer Details\" section of this page."])},
        "Three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cancel the offer, select \"Cancel Renewal Offer\".  Please note rates are subject to change."])}
      }
    },
    "PostOfferOld": {
      "DescriptionOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have made the decision to renewal your rate and your new payment of <strong>", _interpolate(_named("paymentAmount")), "</strong> starts on <strong>", _interpolate(_named("paymentDate")), "</strong>. Your fixed rate principal and interest payments will remain the same for the duration of the term you selected. Variable rate principal and interest payments are subject to change."])},
      "DescriptionTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The new rate on your account will be updated the day after <strong>", _interpolate(_named("effectiveDate")), "</strong> and within 2 business days, a confirmation letter will be available in the \"Documents\" section."])},
      "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Should you decide to change your mind, you have until <strong>", _interpolate(_named("effectiveDate")), "</strong> to cancel the offer. Select \"Cancel Renewal Offer\" and no changes will be made."])}
    },
    "Cancel": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel?"])},
      "CancelAtSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose \"Continue\" to cancel, all rates displayed are subject to change."])},
      "CancelRenewalRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cancel, the Renewal process will have to be started again and you may be subject to a higher rate."])},
      "ContinueToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"<strong>Continue</strong>\" to cancel"])}
    },
    "Resend": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email to sign document"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Information"])}
    },
    "Restriction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Product - Review Details"])},
      "IAcknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I acknowledge"])},
      "MS": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCAP Value Flex"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to transfer or refinance this product outside of MCAP until its renewal date."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance or early renew within MCAP to another Value Flex Product at anytime."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mortgage can only be paid out upon the sale of the property to a non-family member.  Regular pre-payment penalties may apply."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "RM": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMG Low Rate Basic"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying out this mortgage early will result in a penalty which is the greater of 3% of the outstanding balance or an intertest rate differential penalty."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early renewal options are not available with this product."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mortgage cannot be assumed to avoid a penalty."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance this mortgage with RMG into another Low Rate Basic product."])}
      }
    },
    "Notification": {
      "ClickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
      "DownloadFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal offer is available, please "])},
      "DownloadFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to download and print - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "PendingFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal request pending, "])},
      "PendingFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to upload the signed document - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "DocumentProcessed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Renewal document has been processed – Effective date is set for ", _interpolate(_named("effectiveDate"))])}
    }
  },
  "ProtectInvestment": {
    "Menu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect Your Investment"])}
    },
    "Landing": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect Your Investment"])},
      "SectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect Your Investment"])},
      "SectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a variety of available products that can help to keep your investment safe."])},
      "Features": {
        "FeatureOne": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property tax payments"])},
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We can take care of property tax payments on your behalf"])}
        },
        "FeatureTwo": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home system service plan"])},
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty your major home systems from breakdowns"])}
        },
        "FeatureThree": {
          "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage Life Insurance and Disability"])},
          "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protect your family with life insurance and disability coverage"])}
        }
      },
      "Actions": {
        "Covered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covered"])},
        "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
        "Pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
      }
    },
    "PropertyTax": {
      "Main": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1"])}
      },
      "Alternative": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2"])}
      },
      "Confirmation": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])}
      }
    },
    "MLI": {
      "Main": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4"])}
      },
      "Confirmation": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5"])}
      }
    }
  },
  "LockIn": {
    "Actions": {
      "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
      "Select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "Selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
      "GoBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to Homepage"])},
      "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
      "UpdateResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update / Resend"])},
      "Resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])}
    },
    "Menu": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in Offer Information"])},
      "VideosAndSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos and Support"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
      "ManageMyLockIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage My Lock-in"])}
    },
    "Introduction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More"])},
      "Support": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn About Variable Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are looking to learn more about variable rates, watch the videos below for more details."])},
        "InfoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What You Need to Know"])},
        "InfoNote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To lock-in without a penalty, you must choose a new mortgage term equal to, or longer than what is remaining in your current term."])},
        "InfoNote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To see the rates and terms you are eligible to lock-in to without a penalty, select Compare Rates."])}
      },
      "Compare": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Compare Rates\" to see your current variable rate and payment amount compared to today's rate options."])}
      },
      "Start": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to Lock-In?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating a lock-in offer is free of charge. Select \"Compare Rates\", choose a fixed rate option that suits you, and click \"Continue\". To request a Lock-in offer a valid email and mobile number will be required."])}
      }
    },
    "Product": {
      "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "PrincipalAndInterest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal and Interest"])},
      "PaymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
      "PaymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Amount"])},
      "PaymentAmountTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Includes all charges that make up your total payment e.g. principal, interest, property tax, and other products if applicable."])},
      "RenewalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Date"])},
      "RemainingAmortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amortization"])},
      "RemainingAmortizationTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Represents the actual time it will take to pay off the mortgage in full, based on the current principal balance, interest rate, payment amount and payment frequency."])},
      "CurrentProductDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Product Details"])},
      "LockInOfferDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in Offer Details"])},
      "CompareRates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
      "CancelDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select \"Cancel Lock-in Offer\" and you will remain in your current variable rate product."])},
      "CancelLockIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Lock-in Offer"])},
      "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])},
      "SigningStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Status"])},
      "SignaturePending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Pending"])},
      "SigningCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Completed"])},
      "SigningArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Archived"])}
    },
    "CompareRates": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate Comparison"])},
      "Date": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Date"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a date within the next 30 days based on your current payment frequency by selecting the calendar."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The effective date below is the closest payment date available."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When changing the effective date, the payment information below will adjust accordingly."])},
        "EffectiveDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Effective Date"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a date to continue."])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Rates"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To lock-in without a penalty, you must choose a term equal to, or greater than the time left in your current term. Your options are displayed below, including the best available rate."])},
        "ViewAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Options"])},
        "HideAllOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
        "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a rate to continue."])},
        "ChangeNoticeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Change as of November 1st, 2023"])},
        "ChangeNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click <a href='https://www.mcap.com/residential-mortgages/products/our-mortgages/fusion-mortgage' target='_blank'>Here</a> for details on the changes that will occur when locking-in your MCAP Fusion mortgage."])}
      },
      "ReadyToLockIn": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready To Lock-in Your New Rate?"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like to lock-in the rate you have selected, click \"Continue\" to proceed to the next step."])}
      }
    },
    "Support": {
      "Support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
      "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
      "ContactNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-800-265-2624"])}
    },
    "StepsBar": {
      "Confirm": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Effective Date and Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide an email and mobile number to receive and sign your offer."])}
      },
      "Summary": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary Confirmation"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure all your information is correct."])}
      }
    },
    "ConfirmRate": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Lock-in Your New Rate"])},
      "DateAndRate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Effective Date and Rate"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To change one of your selections above, select \"Back\" to go to the previous screen."])}
      }
    },
    "Signature": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Lock-in Your New Rate"])},
      "Sign": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signing Documents Electronically"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete and sign the offer, we will email a link to each owner and guarantor listed below to access the document. Each person will be required to confirm their identity using a mobile number."])},
        "Notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will email you a notification within 1 hour when the lock-in offer is ready to be signed."])}
      },
      "Review": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Information"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review each mobile number and email address below. Select \"Update\" to add or change the information."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information you provide below will only be used for this transaction."])}
      },
      "Errors": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errors"])},
        "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a 10-digit mobile number."])},
        "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a correct email address."])},
        "Unsaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Save/Close all forms before continuing."])},
        "Required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
        "Invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Canadian mobile number."])}
      }
    },
    "Roles": {
      "Homeowner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homeowner"])},
      "Guarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantor"])}
    },
    "Summary": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steps to Lock-in Your New Rate"])},
      "Date": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Date"])}
      },
      "Rate": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Rate"])}
      },
      "Signature": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-signature Details"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review the email and mobile number you have provided. To change the contact information, select \"Change\" to edit the information."])}
      },
      "Confirm": {
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"Confirm\" to complete your request."])},
        "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error while processing your request"])}
      }
    },
    "Success": {
      "Request": {
        "Submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Submitted"])},
        "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request is in process."])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review the \"Important Information\" and \"Next Steps\" details below."])}
      },
      "Information": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Information"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The effective date of your lock-in will be indicated on the offer and the rate is guaranteed until that date."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete your offer, it must be signed by all homeowners and guarantors."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the offer is not signed by all homeowners and guarantors by the effective date, it will be cancelled and no changes will occur."])}
      },
      "NextSteps": {
        "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Steps"])},
        "DescriptionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It can take up to 1 hour to receive your offer and electronic signing instructions."])},
        "DescriptionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone on the mortgage will receive the email instructions."])},
        "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The email notification will come from <", _interpolate(_named("email")), ">."])},
        "DescriptionFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will not make any changes until the offer is signed by everyone on the mortgage."])}
      }
    },
    "PostOffer": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage My Lock-in"])},
      "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer you online support to help you manage your lock-in offer and electronic signature experience. Managing your account has never been easier. See how we can help below."])},
      "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the offer is not signed by all homeowners and guarantors by the effective date, it will be cancelled and no changes will occur."])},
      "HelpOptions": {
        "One": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To resend the link, update the mobile number or email address click \"Update / Resend\"."])},
        "Two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To find out who has signed the offer, review the signing status on the \"Lock-in Offer Details\" section of this page."])},
        "Three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To cancel the offer, select \"Cancel Lock-in Offer\".  Please note rates are subject to change."])}
      }
    },
    "PostOfferOld": {
      "DescriptionOne": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have made the decision to lock-in your rate and your new payment of <strong>", _interpolate(_named("paymentAmount")), "</strong> starts on <strong>", _interpolate(_named("paymentDate")), "</strong>. Your fixed rate principal and interest payments will remain the same for the duration of the term you selected. Variable rate principal and interest payments are subject to change."])},
      "DescriptionTwo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The new rate on your account will be updated the day after <strong>", _interpolate(_named("effectiveDate")), "</strong> and within 2 business days, a confirmation letter will be available in the \"Documents\" section."])},
      "DescriptionThree": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Should you decide to change your mind, you have until <strong>", _interpolate(_named("effectiveDate")), "</strong> to cancel the offer. Select \"Cancel Lock-in Offer\" and no changes will be made."])}
    },
    "Cancel": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel?"])},
      "CancelAtSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose \"Continue\" to cancel, all rates displayed are subject to change."])},
      "CancelLockInRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cancel, the Lock-in process will have to be started again and you may be subject to a higher rate."])},
      "ContinueToCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click \"<strong>Continue</strong>\" to cancel"])}
    },
    "Resend": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email to sign document"])},
      "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Information"])}
    },
    "Restriction": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricted Product - Review Details"])},
      "IAcknowledge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I acknowledge"])},
      "MS": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCAP Value Flex"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will not be able to transfer or refinance this product outside of MCAP until its renewal date."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance or early renew within MCAP to another Value Flex Product at anytime."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mortgage can only be paid out upon the sale of the property to a non-family member.  Regular pre-payment penalties may apply."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "RM": {
        "ValueFlex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RMG Low Rate Basic"])},
        "Text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paying out this mortgage early will result in a penalty which is the greater of 3% of the outstanding balance or an intertest rate differential penalty."])},
        "Text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Early renewal options are not available with this product."])},
        "Text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mortgage cannot be assumed to avoid a penalty."])},
        "Text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may refinance this mortgage with RMG into another Low Rate Basic product."])}
      }
    },
    "Notification": {
      "ClickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
      "DownloadFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in offer is available, please "])},
      "DownloadFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to download and print - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "PendingFileText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lock-in request pending, "])},
      "PendingFileText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["to upload the signed document - Signatures required by ", _interpolate(_named("effectiveDate"))])},
      "DocumentProcessed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lock-in document has been processed – Effective date is set for ", _interpolate(_named("effectiveDate"))])}
    }
  },
  "AcceptAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept All"])},
  "RejectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject All"])},
  "PaymentAssistance": {
    "Landing": {
      "VideoCard": {
        "SubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn About Payment Assistance"])},
        "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you experiencing financial hardship and require assistance with your mortgage payments? We can help! Learn about what applying for payment assistance can do for you."])}
      },
      "Acknowledge": {
        "NeedToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What You Need to Know Before Applying"])},
        "Acknowledgement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acknowledgement of Process"])},
        "AcknowledgementCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the above conditions to proceed with my application"])}
      },
      "ApplyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Now"])}
    },
    "Cancel": {
      "Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure?"])},
      "CancelContentFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should you cancel, your information will not be submitted, and your answers will be lost."])},
      "CancelContentSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select ‘Cancel’ to exit or ‘Continue’ to proceed with your Payment Assistance request."])},
      "CancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "ContinueButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
    },
    "Confirmation": {
      "ConfirmationHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You For Your Submission"])},
      "ConfirmationSubHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Information"])},
      "ConfirmationPointer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email confirmation with an income and expense form with further instructions within 15 minutes.  It may go to your junk or spam folders."])},
      "ConfirmationPointer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The income and expense form and all supporting documents must be completed in full and returned by email to evaluate your request."])},
      "ConfirmationPointer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Please Note that no changes to your mortgage payments will be made until your application is reviewed and approved."])}
    },
    "Error": {
      "ErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "NonSelectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a selection to continue"])},
      "MainReasonEmptyOtherError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type the main reason for your request, in the box provided"])},
      "OtherOptionsEmptyOtherError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please type what other options you have considered, in the box provided"])}
    }
  },
  "NoSAPDataError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Payment Assistance submissions found for this date range."])},
  "BPE0001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested User Name is already registered. Please choose another User Name."])},
  "BPE0003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more registration fields do not match data on system."])},
  "BPE0004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more registration fields do not match data on system."])},
  "BPE0006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mortgage is not eligible to be registered. For assistance, please see the Contact Us tab for details."])},
  "BPE0008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All eligible customers on title for this mortgage are already registered. Please click the Cancel button and then enter the User Name and Password you have already set up. To retrieve your User Name or Password, follow the Forgot my User Name or Password links."])},
  "BPE0011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User has not completed enrollment validation"])},
  "BPE3008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a Username"])},
  "BPE3013": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are locked out of your account.  Please wait until ", _interpolate(_named("date")), " and click \"Having trouble signing in\" to recover your username and reset your password."])},
  "BPE3019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Mortgage Account Number."])},
  "BPE3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid Mortgage Account Number (9999999.1)."])},
  "BPE3022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Last Name."])},
  "BPE3027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Password"])},
  "BPE3034": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question 1 is required"])},
  "BPE3035": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question Answer 1 is required"])},
  "BPE3036": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question 2 is required"])},
  "BPE3037": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question Answer 2 is required"])},
  "BPE3038": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question 3 is required"])},
  "BPE3039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question Answer 3 is required"])},
  "BPE3041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password is the same as the old password"])},
  "BPE3042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username or password not found"])},
  "BPE3044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown Error (try again?)"])},
  "BPE3055": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid Email address."])},
  "BPE3065": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the details of your request."])},
  "BPE3066": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name."])},
  "BPE3067": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a file to upload."])},
  "BPE4085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded files has exceeded maximum upload limit of 50 MB"])},
  "BPE4088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded files has exceeded maximum upload limit of 7 files"])},
  "BPE5058": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the type of mortgage you have, you are not eligible to make any additional payments"])},
  "BPE5061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Records Available."])},
  "BPE5064": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As your mortgage currently has an outstanding payment balance, we are unable to process your request. Please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " and one of our Customer Service Representatives would be happy to assist you!"])},
  "BPE5065": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["At this time, you are not eligible to make any additional payments online. Please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " and one of our Customer Service Representatives would be happy to assist you!"])},
  "BPE5066": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There appears to be an issue fulfilling your request online.  Please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " and one of our Customer Service Representatives would be happy to assist you!"])},
  "BPE5067": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We are having trouble processing your request.  If you have an existing ongoing lump sum payment in place you can cancel the ongoing lump sum payment by going to “Manage Lump Sum Payments” from the self-serve menu and try again.  Otherwise please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), "."])},
  "BPE7015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum password length must be eight characters have no spaces and contain numeric, alpha and special characters."])},
  "BPE7028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the last four (4) digits of your Bank Account Number."])},
  "BPE7030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter valid Bank Account Number."])},
  "BPE7033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one more attempt before registration process is locked for the mortgage number entered. Please try again."])},
  "BPE7044": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one more attempt left! Don’t lock your account, click ‘Having Trouble Signing in’ to reset your password or retrieve your username"])},
  "BPE7047": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select topic."])},
  "BPE7071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mortgage is no longer active.  Mid-term loan changes are not permitted"])},
  "BPE7072": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This change cannot be completed because there is already a payment-related change in progress. Please call us at ", _interpolate(_named("phoneNumber")), " between 8am - 8pm Monday to Friday for further support."])},
  "BPE7073": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["It appears you are skipping a payment or have a hold on an upcoming payment.  As a result we are unable to process your request. Please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " and one of our Customer Service Representatives would be happy to assist you!"])},
  "BPE7074": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the type of mortgage you have, no other payment frequencies are permitted."])},
  "BPE7076": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Password expired."])},
  "BPE7077": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There appears to be an issue fulfilling your request online.  Please contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " and one of our Customer Service Representatives would be happy to assist you!"])},
  "BPE7078": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["As we continue to support homeowners through COVID-19, we may have options to best suit your financial needs. If you are requesting to skip your next mortgage payment, or need to discuss other options for help with an upcoming payment, please contact our office at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), "."])},
  "BPE8001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User needs to accept the user agreement"])},
  "BPE8002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to answer security questions"])},
  "BPE8003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to create security questions"])},
  "BPE8004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question Answers are wrong"])},
  "BPE8005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Question Answers are wrong, 1 attempt left"])},
  "BPE8006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Password, new password required"])},
  "BPE8007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password is not valid"])},
  "BPE8009": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Our site is currently off-line for regular maintenance. Please try again later or contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " from Monday to Friday. We apologize for any inconvenience."])},
  "BPE8010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your activation code has expired.  You can send a new code using the link above."])},
  "BPE8011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Mobile Phone Number, it should be 10 numbers only."])},
  "BPE8012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link you are using to reset your password has expired. Please restart the recovery process if you are still experiencing issues logging in."])},
  "BPE8013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password reset token is invalid."])},
  "BPE8015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date is required"])},
  "BPE8016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date is required"])},
  "BPE8017": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<b style='font-weight:bolder'>We already received your Skip-A-Payment request.<br/><br/>Please do not call/email. You can submit another request on ", _interpolate(_named("nextEligiblePaymentDate")), ".<br/><br/>The last skipped payment date from your previous submission was ", _interpolate(_named("lastSkippedPaymentDate")), ".</b><br/><br/>Please keep in mind, we require 5 business days’ notice to make modifications to upcoming payments.<br/><br/>When skipping a payment, this refers to the principal and interest portion and all other charges will still be withdrawn on your payment date."])},
  "BPE8018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source of funds needs to be between 5 and 50 characters."])},
  "BPE8020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need to create security questions because yours have expired"])},
  "BPE8021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify reCAPTCHA"])},
  "BPE8022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have uploaded an invalid file type, please consult the list of available types found below."])},
  "BPE8023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File upload is not supported at this time."])},
  "BPE8024": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Text message failed to send to mobile phone number. Please try again later or contact us at ", _interpolate(_named("phoneNumber")), " between the hours of ", _interpolate(_named("serviceHours")), " from Monday to Friday. Sorry for any inconvenience!"])},
  "BPE8025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username and/or password is incorrect"])},
  "BPE8026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username and/or password is incorrect.  Use the eye icon to check your password and try again."])},
  "BPE8027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having trouble signing in?"])},
  "BPE8028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have one more attempt before you lock your account for 30 minutes. Click \"Having trouble signing in\" now."])},
  "BPE8029": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This change cannot be completed because there is already a payment-related change in progress. Please call us at ", _interpolate(_named("phoneNumber")), " between 8am - 8pm Monday to Friday for further support."])},
  "BPE8031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry there has been an issue with your upload. The document name exceeds 100 characters. Please rename the file and try your upload again"])},
  "BPS0008": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have exceeded the maximum number of registration attempts. The registration process is now locked until ", _interpolate(_named("lockoutExpirationLocal")), " for the mortgage number entered."])},
  "LOC6123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The requested amount exceeds the amount that is available"])},
  "USR3095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email address."])},
  "USR4057": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An amortization schedule cannot currently be produced for your mortgage  This could be because a renewal is in progress or the mortgage has already been paid off."])},
  "USR4060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment source not found for deletion"])},
  "USR4061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment source update date and time mismatch"])},
  "USR4062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment source cannot be deleted"])},
  "USR5052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available dates for ongoing payments in current year"])},
  "USR5083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of all payments cannot exceed max privilege amount"])},
  "USR5088": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments cannot run beyond privilege anniversary"])},
  "USR5095": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Lump sum payment not permitted before pending payment cycle change"])},
  "USR5096": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source of funds required for this payment(s)"])},
  "USR5097": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source of funds must be a minimum of 5 characters"])},
  "USR5098": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the type of mortgage you have, you are not eligible to make additional payments"])},
  "USR5099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source of funds not required and will be ignored"])},
  "USR5100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lump sum payment cannot be processed"])},
  "USR7100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active renewal already exists"])}
}