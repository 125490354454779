<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="25.891" height="18.406" viewBox="0 0 25.891 18.406">
        <g id="mail_2_" data-name="mail (2)" transform="translate(0 -4.482)">
            <g id="Group_2293" data-name="Group 2293" transform="translate(4.51 13.356)">
            <path id="Path_4228" data-name="Path 4228" d="M24.076,20.453a.413.413,0,0,1-.286-.114l-4.8-4.507a.417.417,0,1,1,.572-.608l4.8,4.507a.417.417,0,0,1-.286.721Z" transform="translate(-7.623 -15.111)" fill="#1d2675"/>
            <path id="Path_4229" data-name="Path 4229" d="M5.819,20.454a.417.417,0,0,1-.286-.721l4.805-4.507a.417.417,0,1,1,.572.608L6.1,20.34A.413.413,0,0,1,5.819,20.454Z" transform="translate(-5.402 -15.112)" fill="#1d2675"/>
            </g>
            <path id="Path_4230" data-name="Path 4230" d="M23.8,22.888H2.087A2.09,2.09,0,0,1,0,20.8V6.569A2.09,2.09,0,0,1,2.087,4.482H23.8a2.09,2.09,0,0,1,2.087,2.087V20.8A2.09,2.09,0,0,1,23.8,22.888ZM2.087,5.317A1.254,1.254,0,0,0,.835,6.569V20.8a1.254,1.254,0,0,0,1.252,1.252H23.8A1.254,1.254,0,0,0,25.056,20.8V6.569A1.254,1.254,0,0,0,23.8,5.317Z" fill="#1d2675"/>
            <path id="Path_4231" data-name="Path 4231" d="M13.054,15.914a2.335,2.335,0,0,1-1.533-.553L.8,6.01a.417.417,0,1,1,.549-.629l10.72,9.35a1.562,1.562,0,0,0,1.968,0L24.744,5.4a.418.418,0,1,1,.549.629L14.587,15.361A2.333,2.333,0,0,1,13.054,15.914Z" transform="translate(-0.109 -0.132)" fill="#1d2675"/>
        </g>
    </svg>
</template>

<script>
export default {
  name: "MailIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: var(--color--menu-icons);
        }
        &.mobile--menu {
            path {
                fill: var(--color--menu-icons--mobile) !important;
            }
        }
        &.hasNotification {
            path {
                fill: var(--color--notification);
            }
        }
        &.primary {
            path {
                fill: var(--color--primary--label);
            }
        }
    }
</style>