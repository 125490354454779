import i18n from "@/plugins/i18n";
import store from "@/store/index";
import { TranslateResult } from "vue-i18n";
import config from "@/services/configService";
import { AxiosResponse } from "axios";
import { startTime, endTime } from "@/assets/isMarsDown";
const { t, te } = i18n.global;


const  controllersWithMoreErrors = [
  "paymentFrequency",
  "paymentDate",
];

class ErrorService {
  public readonly UnknownError = "BPE5066";
  public getErrorText(errorCode: string, data?: any): TranslateResult {
    return t(this.getErrorCode(errorCode), { ...config, ...data });
  }

  public getErrorCode(errorCode: string) {
    return te(errorCode)
      ? errorCode
      : this.UnknownError;
  }

  public getMarsDownErrorMessage() {
    const now = new Date();
    const nowHour = now.getHours();
    let errorTime: string;

    const isPresetDownTime = startTime < endTime
      ? startTime <= nowHour && endTime >= nowHour // ex: 14:00 to 16:00
      : startTime <= nowHour || endTime >= nowHour; // ex: 22:00 to 05:00

    if (!isPresetDownTime) {
      now.setTime(now.getTime() + 1 * 60 * 60 * 1000);  // Add an hour to current time
      now.setTime(now.getTime() - now.getMinutes() * 60 * 1000);  // Set minutes to 0
      errorTime = this.toLocaleTime(now);
    } else {
      now.setHours(endTime);
      errorTime = this.toLocaleTime(now);
    }
    return  t("MarsDown", { time: errorTime });
  }

  public getErrorTextByResponse(axiosResponse: AxiosResponse): TranslateResult {
    if (!axiosResponse?.status && (axiosResponse as any)?.response) {
      // Probably passed in a straight exception
      axiosResponse = (axiosResponse as any)?.response;
    }
    if (axiosResponse?.status === 422 && (axiosResponse?.data.error || (axiosResponse?.data.errorCodes && axiosResponse?.data.errorCodes.length > 0))) {
      return this.getErrorText(axiosResponse?.data.error || axiosResponse?.data.errorCodes[0], axiosResponse?.data);
    }
    if (axiosResponse?.status === 422 && axiosResponse?.data.errorCode) {
      return this.getErrorText(axiosResponse?.data.errorCode, axiosResponse?.data);
    }
    if (axiosResponse?.status === 400) {
      const firstError = axiosResponse?.data[Object.keys(axiosResponse?.data)[0]];
      if (firstError) {
        return this.getErrorText(firstError);
      }
    }
    return t(this.UnknownError, { ...config, ...axiosResponse?.data });
  }

  public getErrorKeyByResponse(axiosResponse: AxiosResponse): string {
    if (axiosResponse?.status === 422 && (axiosResponse?.data.error || (axiosResponse?.data.errorCodes && axiosResponse?.data.errorCodes.length > 0))) {
      return this.getErrorCode(axiosResponse?.data.error || axiosResponse?.data.errorCodes[0]);
    }
    if (axiosResponse?.status === 422 && axiosResponse?.data.errorCode) {
      return this.getErrorCode(axiosResponse?.data.errorCode);
    }
    if (axiosResponse?.status === 400) {
      const firstError = axiosResponse?.data[Object.keys(axiosResponse?.data)[0]];
      if (firstError) {
        return this.getErrorCode(firstError);
      }
    }
    return this.UnknownError;
  }

  public  getResponseError(error: any, controller: string): string   {
    if (controllersWithMoreErrors.indexOf(controller) > -1) {
      if (error && error?.response && error?.response?.status === 422 && error?.response?.data && error?.response?.data?.errorCode ) {
        return error?.response.data.errorCode;
      } else {
        return "BPE5066";
      }
    } else {
      return  this.getErrorTextByResponse(error?.response);
    }
  }

  // used for SAP, RFA uses a different error message
  public transformErrorByBrand(error: string): string {
    return error;
  }

  private toLocaleTime(time: Date): string {
    if (store.state.locale === "fr") {
      return time.getHours() + " h " + "00";
    } else {
      return (time.getHours() % 12 || 12) + ":00 " + (time.getHours() > 11 ? "p.m." : "a.m.");
    }
  }
}

export default new ErrorService();
