<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <path id="clock" d="M15,8a7,7,0,1,0,7,7A7,7,0,0,0,15,8Zm1.612,9.882-2.49-1.809a.341.341,0,0,1-.138-.274v-4.75a.34.34,0,0,1,.339-.339h1.355a.34.34,0,0,1,.339.339v3.887l1.792,1.3a.339.339,0,0,1,.073.474l-.8,1.1A.341.341,0,0,1,16.612,17.882Z" transform="translate(-8 -8)" fill="#e3e3e4" opacity="0.5"/>
    </svg>
</template>

<script>
export default {
  name: "ClockIcon",
};
</script>

<style lang="scss" scoped>
    svg {
        &.dark {
            path {
                fill: #767E89;
                opacity: .5;
            }
        }
    }
</style>