<template >
    <img :src="imgSrc" height="600" :alt="`${$store.state.tenantName} Logo`" :data-tentant="$store.state.tenantName" />
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
@Component({})

class BrandLogoInverted extends Vue {
  @Inject() $appConfig: any;
  public $store = useStore()

  get imgSrc() {
    return this.$store.state.locale === "fr" ?  `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/logo-inverted-fr.png` :  `${this.$appConfig.getUrl()}assets/tenants/${this.$appConfig.code}/images/logo-inverted-en.png`;
  }
};
export default toNative(BrandLogoInverted);
</script>