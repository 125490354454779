<template>
  <div v-if="showPopup" id="consent-popup" class="hidden" :class="{ 'inverted': (isRMG || isEclipse) }">
    <h2 v-html="popupTitle" class="titleText"></h2>
    <p v-html="moreInfoContent" class="contentText"></p>
    <div class="button-row center">
      <button @click="acceptConsent($event)"
        :class="{ 'button button--secondary': (isRMG || isEclipse), 'button button--primary': (isRFA), 'button button--ghost--white': (!isRMG && !isEclipse && !isRFA) }">
        {{ $t("AcceptAll") }}
      </button>
      <button @click="rejectConsent($event)"
        :class="{ 'button button--secondary': (isRMG || isEclipse), 'button button--primary': (isRFA), 'button button--ghost--white': (!isRMG && !isEclipse && !isRFA) }">
        {{ $t("RejectAll") }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import config from "@/services/configService";
import { Component, Vue, Inject, toNative } from "vue-facing-decorator"
import { useStore } from "vuex"
import { Content } from "@/store/types/content";
import Spinner from "@/components/Spinner.vue";
import userService from "@/services/userService";
import contentService from "@/services/contentService";
import { useGtm } from "@gtm-support/vue-gtm";
import { CookieService } from "@/services/cookieService";
import moment from "moment";
import { brand } from "@/assets/branding";

import {
  enableBodyScrolling,
  disableBodyScrolling,
} from "@/services/utilService";
@Component({
  components: {
    Spinner,
  },
})


class ConsentPopup extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  public language = "";
  private showPopup = false;
  private moreInfoContent = "";
  private loading = true;
  private popupTitle = "";
  private isRMG: boolean = false;
  private isRFA: boolean = false;
  private isEclipse: boolean = false;
  private gtm = useGtm();

  private created() {
    this.language = this.$store.state.locale ? this.$store.state.locale : "en";
    this.loadContent();
    this.isRMG = this.$store.state.tenantName.toLowerCase().includes("rmg");
    this.isRFA = this.$store.state.tenantName.toLowerCase().includes("rfa");
    this.isEclipse = this.$store.state.tenantName.toLowerCase().includes("eclipse");
    this.showPopup = !CookieService.getCookie(CookieService.Cookies.Consent.Name) ? true : false;
  }

  private beforeUnmount() {
    enableBodyScrolling();
  }

  private popUpClose(e: Event) {
    this.showPopup = false;
    this.moreInfoContent = "";
    this.popupTitle = "";
  }

  private acceptConsent(e: Event) {
    CookieService.setCookie(CookieService.Cookies.Consent.Name, CookieService.Cookies.Consent.Values.Accepted, { expires: moment().add(config.cookieConsentExpiryInDays , "days").toDate() })
    try {
      this.gtm.enable(true);
    } catch (error) {
      console.error(error);
    }
    const windowAny = (window as any);
    if (config.facebookPixelId && windowAny && windowAny.fbq) {
      windowAny.fbq("init", config.facebookPixelId);
      windowAny.fbq("track", "PageView");
    }
    this.popUpClose(e);
  }

  private rejectConsent(e: Event) {
    CookieService.setCookie(CookieService.Cookies.Consent.Name, CookieService.Cookies.Consent.Values.Rejected, { expires: moment().add(config.cookieConsentExpiryInDays, "days").toDate() })

    try {
      this.gtm.enable(false);
    } catch (error) {
      console.error(error);
    }
    this.popUpClose(e);
  }


  private async loadContent() {
    const response = await contentService.getConsentPopUp(config.code);
    if (response.isSuccess) {
      this.popupTitle =
        this.language === "fr"
          ? response.value.frenchContent
          : response.value.englishContent;
    } else {
      this.moreInfoContent = "";
    }

    const contentResponse = await contentService.getConsentContent(config.code);
    if (response.isSuccess) {
      this.moreInfoContent =
        this.language === "fr"
          ? contentResponse.value.frenchContent
          : contentResponse.value.englishContent;
    } else {
      this.moreInfoContent = "";
    }
    this.loading = false;
  }
}
export default toNative(ConsentPopup)
</script>

<style lang="scss">


#consent-popup {
  &.inverted {
    color: var(--color--primary);
    background: white;

    .titleText,
    .contentText {
      color: var(--color--primary);

      p,
      a,
      h2 {
        color: var(--color--primary);
      }
    }
  }

  position: sticky;
  width: 100%;
  background: var(--color--primary);
  bottom: 0;
  left: 0;
  padding: 20px;
  z-index: 2;

  .titleText {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: white;
    margin-bottom: 0.5rem;

    @media (max-width: 768px) {
      font-size: 14px;

    }

    p,
    a {
      color: white;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

    }
  }

  .contentText {
    text-align: center;
    color: white;
    max-width: 1200px;
    margin: 0 auto;

    p,
    a {
      color: white;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0px;
      }

      @media (max-width: 768px) {
        font-size: 12px;

      }

    }
  }

  .button-row {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;

    .button {
      margin-right: 30px;

      &:last-child {
        margin-right: 0px;
      }
    }

    @media (max-width: 768px) {
      font-size: 12px;

    }

    @media (max-width: $media--iphone-plus) {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      .button {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;

        &:first-child {
          margin-bottom: 10px;
        }
      }

    }

    .button--secondary {
      background: var(--color--button--secondary-background);
      border: 1px solid var(--color--button--secondary-background);
      color: var(--color--button--secondary-text);

      &:hover {
        background: var(--color--button--secondary-text);
        border: 1px solid var(--color--button--secondary-border);
        color: var(--color--button--secondary-background);
      }
    }
  }

}
</style>
