import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon")!
  const _component_ClockIcon = _resolveComponent("ClockIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notifications", {open: _ctx.isOpen}])
  }, [
    _createElementVNode("h2", null, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleNotifications && _ctx.toggleNotifications(...args)))
      }, [
        _createVNode(_component_ArrowLeftIcon)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("Notifications")), 1)
    ]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (n, i) => {
        return (_openBlock(), _createElementBlock("li", { key: i }, [
          _createElementVNode("span", null, [
            _createVNode(_component_ClockIcon, { class: "dark" }),
            _createTextVNode(" " + _toDisplayString(n.date), 1)
          ]),
          _createElementVNode("p", null, _toDisplayString(n.title), 1),
          (n.content)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: n.content,
                class: "content"
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 2))
}