<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.625" height="19" viewBox="0 0 16.625 19">
        <path id="Path_570" data-name="Path 570" d="M16.105-3.043a4.719,4.719,0,0,1-.854-1,5.967,5.967,0,0,1-.705-1.744,13.866,13.866,0,0,1-.3-3.117,5.835,5.835,0,0,0-.649-2.709,5.957,5.957,0,0,0-1.781-2.1A5.722,5.722,0,0,0,9.277-14.77a1.085,1.085,0,0,0,.223-.668,1.141,1.141,0,0,0-.353-.835,1.141,1.141,0,0,0-.835-.353,1.141,1.141,0,0,0-.835.353,1.141,1.141,0,0,0-.353.835,1.085,1.085,0,0,0,.223.668,5.722,5.722,0,0,0-2.542,1.058,5.957,5.957,0,0,0-1.781,2.1,5.834,5.834,0,0,0-.649,2.709,13.866,13.866,0,0,1-.3,3.117,5.967,5.967,0,0,1-.705,1.744,5.188,5.188,0,0,1-.816,1A1.76,1.76,0,0,0,0-1.763,1.728,1.728,0,0,0,.5-.52,1.7,1.7,0,0,0,1.781,0H5.938a2.282,2.282,0,0,0,.705,1.67,2.33,2.33,0,0,0,3.34,0A2.282,2.282,0,0,0,10.688,0h4.156a1.7,1.7,0,0,0,1.28-.52,1.728,1.728,0,0,0,.5-1.243A1.822,1.822,0,0,0,16.105-3.043ZM8.313,1.188A1.141,1.141,0,0,1,7.478.835,1.141,1.141,0,0,1,7.125,0H9.5a1.141,1.141,0,0,1-.353.835A1.141,1.141,0,0,1,8.313,1.188Zm6.531-2.375H1.781a.551.551,0,0,1-.557-.371.515.515,0,0,1,.148-.631A6.374,6.374,0,0,0,2.932-4.564a12.115,12.115,0,0,0,.631-4.342A4.71,4.71,0,0,1,4.193-11.3a4.621,4.621,0,0,1,1.726-1.726,4.71,4.71,0,0,1,2.394-.631,4.71,4.71,0,0,1,2.394.631A4.621,4.621,0,0,1,12.432-11.3a4.71,4.71,0,0,1,.631,2.394,12.811,12.811,0,0,0,.594,4.342,6.815,6.815,0,0,0,1.6,2.375.515.515,0,0,1,.148.631A.551.551,0,0,1,14.844-1.187Z" transform="translate(0 16.625)" fill="#1d2675"/>
    </svg>
</template>

<script>
export default {
  name: "BellIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        &.color-primary {
            path {
                fill: var(--color--primary);
            }
        }
        &.color-danger {
            path {
                fill: var(--color--error-text);
            }
        }
        path {
            fill: var(--color--menu-icons);
        }
        &.hasNotification {
            path {
                fill: var(--color--notification);
            }
        }
    }
</style>