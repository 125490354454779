<template >
  <span class="glossary-tip">

    <InforIconPrimary v-tooltip.top-center="description" :class="{ white: white }" style="outline:none"
      id="product-info-icon" />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import InforIconPrimary from "@/components/Icons/InfoIconPrimary.vue";
import { Action } from "vuex-facing-decorator";

@Component({
  components: {
    InforIconPrimary,
  },
})
class ToolTip extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Action("getGlossary") private getGlossary!: () => Promise<any>;

  @Prop() private glossaryTerm!: string;
  @Prop() private override!: string;
  @Prop() private white!: string;



  get description(): string {
    if (this.override) {
      return this.override;
    }
    if (!this.$store.state.glossary) {
      return "";
    }

    const glossaryObj = this.$store.state.glossary.find((termObj: any) => termObj.term.trim().toLowerCase() === this.glossaryTerm.trim().toLowerCase());
    return glossaryObj ? glossaryObj.description : "";
  }


}
export default toNative(ToolTip)
</script>

<style  lang="scss" >


.glossary-tip {
  font-weight: var(--font--medium);
  color: black;
  display: flex;

  svg {
    transform: scale(1.35);
    margin-left: 10px;
    cursor: pointer;
  }
}

.tooltip {
  display: block !important;
  z-index: 90000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 5px;
    max-width: 250px;
    padding: 10px 10px;
    font-size: 13px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    --color: #f9f9f9;

    .popover-inner {
      background: var(--color);
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: var(--color);
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>