import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#609cf5" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#1d2675" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
  ],
  metaImageUrl: "assets/tenants/MS/images/McapMetadataImage.jpg",
  logoSize: "110px",
  logoSizeFr:"110px",
  FAQCovidDirectLink: true,
  showCampaingBanner: true,
  showWelcomePopup: true,
  showEducationalVideosPopup: true,
  showMortgageRenewalPopup: false,
  hasHomeSystem: true,
  hasHomeAppliance: true,
  showSalesBanners: false,
  showIAConvertedMessage: true,
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  MortgageLifeInsuranceLabel: "MortgageLifeInsurance",
  showVideoLibrary: true,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: true,
      homeSystem: true,
      privacy: "https://www.mcap.com/Privacy",
      security: "https://www.mcap.com/Security",
      forms: "https://mcap.entrez.ca/Home/ContentPage/Forms",
      legal: "https://www.mcap.com/Legal",
      licences: "https://www.mcap.com/Licences",
      access: null,
      terms: null,
      moreInfoSAP: "https://www.mcap.com/covid-19",
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: "https://mcap.mtg-app.com",
    },
    fr: {
      homeAppliance: true,
      homeSystem: true,
      privacy: "https://www.mcap.com/fr/protection-des-renseignements-personnels",
      security: "https://www.mcap.com/fr/securite",
      forms: "https://mcap.entrez.ca/Home/ContentPage/Forms",
      legal: "https://www.mcap.com/fr/conditions-juridiques",
      licences: " https://www.mcap.com/fr/nombres-de-permis",
      access: null,
      terms: null,
      moreInfoSAP: "https://www.mcap.com/fr/covid-19",
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: "https://mcap-fr.mtg-app.com",
    },
  },

  footerbottom: "",
};
