import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#609cf5" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#1d2675" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: false,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: null,
      privacy: "https://www.peoplestrust.com/en/legal/privacy-security/privacy/",
      security: null,
      forms: null,
      licences: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: null,
    },
    fr: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: null,
      privacy: "https://www.peoplestrust.com/fr/legale/protection-des-renseignements-personnels-et-securite/protection-des-renseignements-personnels/",
      security: null,
      forms: null,
      licences: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: null,
    },
  },

  footerbottom: "",
};
