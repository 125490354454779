import { Vue, Inject , toNative } from "vue-facing-decorator"
import { createStore } from "vuex"
import Vuex, { StoreOptions } from "vuex";
import Cookies from "universal-cookie";
import moment from "moment";

import { LoginResult, User, UpdateUserResponse } from "./types/user";
import { RootState } from "./types/index";
import { OfferSigningStatus, MortgageSummaryResponse, MortgageActivityResponse, Document, Mortgage, PropertyTaxPayment, PropertyTaxes, PropertyTaxPaymentsResponse, DocumentResponse, PropertyTaxCollectionInfo, ServiceFeesDetails, FormsDetails, PropertyCommunicationPreference } from "./types/mortgage";
import { getOfferTypeName, getTenants as utilServiceGetTenants } from "@/services/utilService";


import config from "@/services/configService";
import VueI18n from "@/plugins/i18n";

import http from "@/services/httpService";
import { setLanguage as currencyServiceSetLanguage } from "@/services/currencyService";
import { Content } from "./types/content";
import { Tab } from "./types/viewModels";
import { AmortizationDetails, AmortizationPayment } from "./types/amortization";
import { PaymentFrequencyChangeDetails, PaymentDetails, NewPaymentDetails, PaymentDateChangeDetails, ArrearsPaymentDetails, LineOfCreditDetails, OneTimeLumpSumPaymentRequestDetails, OnGoingLumpSumPaymentRequestDetails, LumpSumPaymentResponse } from "./types/paymentChanges";

// Vue.use(Vuex);
// Vue.config.devtools = true;
import { loginModule } from "@/store/modules/loginModule";
import { TenantList } from "@/store/types/tenant";
import { Question } from "./types/login";
import { CodeValue } from "./types/codeValue";
import { CookieService } from "@/services/cookieService";
import errorService from "@/services/errorService";
import i18n from "@/plugins/i18n";
import userService from "@/services/userService";
import mortgageService from "@/services/mortgageService";
import contentService from "@/services/contentService";
import { off } from "process";
import apiService from "@/services/apiService";
import { user } from "./mockData/user";
import { QuizData } from "@/store/types/quizData";
import { OfferProduct, LockIn, Renewal, OfferCreateRequest, OfferCancelRequest, OfferESignNotificationRequest } from "@/store/types/offer";
import offerService from "@/services/offerService";
import lookupService from "@/services/lookupService";
import videoService from "@/services/videoService";
import safeguardService from "@/services/safeguardService";
import { CreateVideoAnalyticsRequest } from "./types/video";
import { SafeguardCancelAnalyticsRequest } from "./types/safeguard";
const { t, te, locale } = i18n.global;

const cookies = new Cookies();
const allTabs: Tab[] = [
  { id: "mortgage__tabs__tab--details", name: "mortgage-details", textKey: "MortgageDetails" },
  { id: "mortgage__tabs__tab--creditdetails", name: "mortgage-details", textKey: "LineCreditDetails" },
  { id: "mortgage__tabs__tab--activity", name: "activity", textKey: "Activity" },
  { id: "mortgage__tabs__tab--tax", name: "property-tax-details", textKey: "PropertyTaxDetails" },
  { id: "mortgage__tabs__tab--documents", name: "documents", textKey: "Documents" },
  { id: "mortgage__tabs__tab--schedule", name: "amortization-schedule", textKey: "AmortizationSchedule" },
  { id: "mortgage__tabs__tab--forms", name: "forms", textKey: "Forms" },
  { id: "mortgage__tabs__tab--servicefees", name: "service-fees", textKey: "ServiceFees" },
  { id: "mortgage__tabs__tab--calculator", name: "mortgage-calculator", textKey: "Calculator", hide: true }, // hide calc :comment out this
  { id: "mortgage__tabs__tab--lockin", name: "mortgage-lockin", textKey: "LockIn", hide: true }, // hide calc :comment out this
  { id: "mortgage__tabs__tab--renewal", name: "mortgage-renewal", textKey: "Renewal", hide: true }, // hide calc :comment out this
  { id: "mortgage__tabs__tab--skippayment", name: "skip-paymentv2", textKey: "PaymentAssistance", hide: true }, // hide calc :comment out this

];
const getDefaultState = (): RootState => {
  return {
    locale: i18n.global.locale,
    tenantName: i18n.global.locale === "fr" ? config.nameFr || config.name : config.name,
    tenantNamePrefix: i18n.global.locale === "fr" ? config.prefixFr : config.prefix,
    screenSize: 0,
    mediaSizes: {
      ipad: 768,
      iphone: 375,
      iphonePlus: 510,
    },
    loading: false,
    user: CookieService.getCookie("user") || config.currentUser || null,
    mortgageSummaries: [],
    mortgageDetails: null,
    config,
    mortgageActivities: [],
    documents: [],
    offerAds: [],
    offerInfo: [[]],
    propertyTaxInfo: null,
    propertyTaxPayments: null,
    mortgageDetailTabs: allTabs.slice(),
    amortizationDetails: null,
    paymentFrequencyChangeDetails: null,
    prospectivePaymentFrequencyDetails: null,
    paymentDateChangeDetails: null,
    prospectivePaymentDateDetails: null,
    arrearsPaymentDetails: null,
    lineOfCreditDetails: null,
    requestRefreshActivities: 0,
    oneTimeLumpSumRequest: null,
    onGoingLumpSumRequest: null,
    lumpSumPayments: [],
    tenants: [],
    securityQuestions: [],
    userSecurityQuestions: [],
    faqQuestion: -1,
    faq: [],
    messageTopics: [],
    isContactUsOpen: false,
    MessageTopic: "",
    MessageMortgageNumber: 0,
    MessageAttachmentMandatory: false,
    MessageIsMortgageNumberFix: false,
    MessageIsTopicFix: false,
    glossary: null,
    serviceFees: null,
    forms: null,
    isMarsDown: false,
    campaignBanner: null,
    campaignBannerMobile: null,
    AdBanners: null,
    quizTandC: null,
    quizData: null,
    LSPPCalcScenarioOne: {},
    LSPPCalcScenarioOneParameters: {},
    receivedFireInsurance: null,
    LSPPCalcBaseline: {},
    videoModalData: null,
    showVideoModal: false,
    showReminderResendPopup: false,
    signingReminderInfo: null,
    lockIn: {
      loading: false,
      isSuccess: false,
      value: null,
    },
    renewal: {
      loading: false,
      isSuccess: false,
      value: null,
    },
    lockInCancel: {
      loading: false,
    },
    renewalCancel: {
      loading: false,
    },
    showMortgageRenewalPopup: false,
    dismissedRenewalNotifications: null,
    dismissRenewalNotificationsForThisSession: false,
    lastRenewalDueDate: "",
    lockInSigningInfo: null,
    renewalSigningInfo: null,
    licenceInfoFromCMS : null,
  };
};

const state = getDefaultState();
export const mutations = {
  UPDATE_SCREEN_SIZE(rState: RootState, size: number) {
    rState.screenSize = size;
  },
  UPDATE_LSPP_CALC_SCENARIO_ONE(rState: RootState, scenario: any) {
    rState.LSPPCalcScenarioOne = scenario.output;
    rState.LSPPCalcScenarioOneParameters = scenario.parameters;
  },
  UPDATE_LSPP_CALC_BASELINE(rState: RootState, baseline: any) {
    rState.LSPPCalcBaseline = baseline;
  },
  UPDATE_FIRE_INSURANCE(rState: RootState, response: boolean) {
    rState.receivedFireInsurance = response;
  },
  UPDATE_LOCALE(rState: RootState, lang: string) {
    rState.locale = lang;
    rState.tenantName = lang === "fr" ? config.nameFr || config.name : config.name;
    rState.tenantNamePrefix = lang === "fr" ? config.prefixFr : config.prefix;
  },
  SET_USER(rState: RootState, result: LoginResult) {
    rState.user = result.user!;
    rState.dismissRenewalNotificationsForThisSession = false;
    rState.dismissedRenewalNotifications = CookieService.getCookie("_dsrlns") || null;
  },
  LOGOUT(rState: RootState) {
    const carryOver = { screenSize: rState.screenSize, locale: rState.locale, user: null };
    const newState = { ...getDefaultState(), ...carryOver };
    Object.assign(state, newState);
  },
  UPDATE_MORTGAGE_SUMMARIES(rState: RootState, response: MortgageSummaryResponse) {
    rState.mortgageSummaries = response.mortgages;
  },
  UPDATE_MORTGAGE_OFFER_ADS(rState: RootState, offers: Content[]) {
    rState.offerAds = offers;
  },
  UPDATE_MORTGAGE_ACTIVITIES(rState: RootState, response: MortgageActivityResponse) {
    rState.mortgageActivities = response.items;
  },
  UPDATE_MORTGAGE_PROPERTYTAX_PAYMENTS(rState: RootState, response: PropertyTaxPayment[]) {
    rState.propertyTaxPayments = response;
  },
  UPDATE_MORTGAGE_PROPERTYTAX(rState: RootState, response: PropertyTaxes) {
    rState.propertyTaxInfo = { info: response, collected: !!response };
  },
  UPDATE_MORTGAGE(rState: RootState, response: Mortgage) {
    rState.mortgageDetails = response;
  },
  CLEAR_MORTGAGE(rState: RootState) {
    rState.mortgageDetails = null;
  },
  UPDATE_MORTGAGE_TABS(rState: RootState, response: Tab[]) {
    if (rState.config.name.toLowerCase().includes("eclipse")) {
      const index = response.findIndex((x) => x.name === "service-fees");
      if (index !== undefined) {
        response.splice(index, 1);
      }
    }
    rState.mortgageDetailTabs = response;
  },
  UPDATE_DOCUMENTS(rState: RootState, response: Document[]) {
    rState.documents = response;
  },
  UPDATE_SERVICEFEES(rState: RootState, response: ServiceFeesDetails) {
    rState.serviceFees = response;
  },
  UPDATE_FORMS(rState: RootState, response: FormsDetails) {
    rState.forms = response;
  },
  UPDATE_AMORTIZATION(rState: RootState, response: AmortizationDetails) {
    rState.amortizationDetails = response;
  },
  UPDATE_PAYMENTFREQUENCY_DETAILS(rState: RootState, response: PaymentFrequencyChangeDetails) {
    rState.paymentFrequencyChangeDetails = response;
  },
  UPDATE_PAYMENTFREQUENCY_PROSPECTIVE_DETAILS(rState: RootState, response: NewPaymentDetails) {
    rState.prospectivePaymentFrequencyDetails = response;
  },
  RESET_PAYMENTFREQUENCY(rState: RootState) {
    rState.paymentFrequencyChangeDetails = null;
    rState.prospectivePaymentFrequencyDetails = null;
  },
  UPDATE_PAYMENTDATE_DETAILS(rState: RootState, response: PaymentDateChangeDetails) {
    rState.paymentDateChangeDetails = response;
  },
  UPDATE_PAYMENTDATE_PROSPECTIVE_DETAILS(rState: RootState, response: NewPaymentDetails) {
    rState.prospectivePaymentDateDetails = response;
  },
  RESET_PAYMENTDATE(rState: RootState) {
    rState.prospectivePaymentDateDetails = null;
    rState.paymentDateChangeDetails = null;
  },
  UPDATE_ARREARS_PAYMENT_DETAILS(rState: RootState, response: ArrearsPaymentDetails) {
    rState.arrearsPaymentDetails = response;
  },
  RESET_ARREARS_PAYMENT(rState: RootState) {
    rState.arrearsPaymentDetails = null;
  },
  UPDATE_USER_NEEDS_CASL(rState: RootState) {
    rState.user.needsCaslPreferences = false;
    if (config.currentUser) {
      config.currentUser.needsCaslPreferences = false;
    }
    CookieService.setCookie("user", rState.user, { path: config.publicPath, sameSite: true, secure : true });
  },
  UPDATE_USERNAME(rState: RootState, username: string) {
    rState.user.username = username;
    CookieService.setCookie("user", rState.user, { path: config.publicPath, sameSite: true, secure : true });
  },
  UPDATE_EMAIL(rState: RootState, email: string) {
    rState.user.email = email;
    if (config.currentUser) {
      config.currentUser.email = email;
    }
    CookieService.setCookie("user", rState.user, { path: config.publicPath, sameSite: true, secure : true });
  },
  UPDATE_USER_NEEDS_PAPERLESS(rState: RootState) {
    rState.user.needsPaperlessPreferences = false;
    if (config.currentUser) {
      config.currentUser.needsPaperlessPreferences = false;
    }
    CookieService.setCookie("user", rState.user, { path: config.publicPath, sameSite: true, secure : true });
  },
  UPDATE_LINE_OF_CREDIT_DETAILS(rState: RootState, details: LineOfCreditDetails) {
    rState.lineOfCreditDetails = details;
  },
  REQUEST_ACTIVITIES_REFRESH(rState: RootState) {
    rState.requestRefreshActivities++;
  },
  UPDATE_TENANTS(rState: RootState, tenants: TenantList) {
    rState.tenants = tenants.tenants;
  },
  UPDATE_USER_AGREEMENT(rState: RootState, Agreement: string) {
    rState.user.userAgreement = Agreement;
  },
  UPDATE_QUIZ_TandC(rState: RootState, Agreement: string) {
    rState.quizTandC = Agreement;
  },
  UPDATE_SECURITY_QUESTIONS(rState: RootState, securityQuestion: Question[]) {
    rState.securityQuestions = securityQuestion;
  },
  UPDATE_USER_SECURITY_QUESTIONS(rState: RootState, securityQuestion: Question[]) {
    rState.userSecurityQuestions = securityQuestion;
  },
  UPDATE_LUMPSUM_ONETIME_REQUEST(rState: RootState, request: OneTimeLumpSumPaymentRequestDetails) {
    rState.oneTimeLumpSumRequest = request;
  },
  UPDATE_LUMPSUM_ONGOING_REQUEST(rState: RootState, request: OnGoingLumpSumPaymentRequestDetails) {
    rState.onGoingLumpSumRequest = request;
  },
  UPDATE_LUMPSUM_PAYMENTS(rState: RootState, payments: LumpSumPaymentResponse) {
    rState.lumpSumPayments = payments.payments;
  },
  CLEAR_LUMPSUM_REQUESTS(rState: RootState) {
    rState.onGoingLumpSumRequest = null;
    rState.oneTimeLumpSumRequest = null;
  },
  UPDATE_FAQ_QUESTION(rState: RootState, index: number) {
    rState.faqQuestion = index;
  },
  HIDE_EDUCATIONAL_VIDEOS_FLAG(rState: RootState) {
    rState.user.showEducationalVideosPopup = false;
    CookieService.setCookie("user", rState.user, { path: config.publicPath, sameSite: true, secure : true });
  },
  DISMISS_RENEWAL_NOTIFICATION(rState: RootState) {
    CookieService.setCookie("_dsrlns", true, { expires: new Date(rState.lastRenewalDueDate), path: config.publicPath, sameSite: true, secure : true });
  },
  CHECK_NOTIFICATION_DISMISS_DATE(rState: RootState, renewDate: string) {
    rState.lastRenewalDueDate = renewDate;
    rState.showMortgageRenewalPopup = !rState.dismissedRenewalNotifications;
  },
  UPDATE_FAQS(rState: RootState, content: any) {
    rState.faq = rState.locale === "fr" ? JSON.parse(content.frenchContent) : JSON.parse(content.englishContent);
  },
  UPDATE_GLOSSARY(rState: RootState, content: any) {
    rState.glossary = rState.locale === "fr" ? JSON.parse(content.frenchContent) : JSON.parse(content.englishContent);
  },
  UPDATE_TOPICS(rState: RootState, topics: CodeValue[]) {
    rState.messageTopics = topics;
  },
  TOGGLE_CONTACTUS(rState: RootState, contactInfo: any) {
    rState.MessageTopic = contactInfo.topic  !== undefined ? contactInfo.topic : "";
    rState.MessageMortgageNumber = contactInfo.mortgageNumber !== undefined ? contactInfo.mortgageNumber : 0;
    rState.isContactUsOpen = contactInfo.isOpen;
    rState.MessageIsTopicFix = contactInfo.messageIsTopicFix !== undefined ? contactInfo.messageIsTopicFix : false;
    rState.MessageIsMortgageNumberFix = contactInfo.messageIsMortgageNumberFix !== undefined ? contactInfo.messageIsMortgageNumberFix : false;
    rState.MessageAttachmentMandatory = contactInfo.messageAttachmentMandatory !== undefined ? contactInfo.messageAttachmentMandatory : false;
  },
  SET_MARS_STATUS(rState: RootState, isMarsDown: boolean) {
    rState.isMarsDown = isMarsDown;
  },
  SET_QUIZ_DATA(rState: RootState, quizData: QuizData) {
    rState.quizData = quizData;
  },
  UPDATE_CAMPAIGNBANNER(rState: RootState, content: any) {
    rState.campaignBanner =  rState.locale === "fr" ? content.frenchContent : content.englishContent;
  },
  UPDATE_CAMPAIGNBANNER_MOBILE(rState: RootState, content: any) {
    rState.campaignBannerMobile =  rState.locale === "fr" ? content.frenchContent : content.englishContent;
  },
  UPDATE_OFFERINFO(rState: RootState, content: any) {
    rState.offerInfo[content.offerType] = rState.locale === "fr" ?  content.response.frenchContent : content.response.englishContent;
  },
  UPDATE_ADBANNER(rState: RootState, content: any) {
    rState.AdBanners =  content;
  },
  TOGGLE_VIDOE_MODAL(rState: RootState, data: any) {
    rState.showVideoModal = !rState.showVideoModal;
    rState.videoModalData = data;
  },
  TOGGLE_REMINDER_RESEND_POPUP(rState: RootState, info: any) {
    rState.showReminderResendPopup = !rState.showReminderResendPopup;
    rState.signingReminderInfo = info;
  },
  FETCH_LOCKIN_VIRTUAL(rState: RootState, value: boolean) {
    rState.lockIn = {
      loading: value,
      isSuccess: true,
      value: {
        ...rState.lockIn.value,
        submitRequestStatus: "NOT_STARTED",
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  FETCH_RENEWAL_VIRTUAL(rState: RootState, value: boolean) {
    rState.renewal = {
      loading: value,
      isSuccess: true,
      value: {
        ...rState.renewal.value,
        submitRequestStatus: "NOT_STARTED",
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  SET_LOCKIN_SIGNING_STATUS(rState: RootState, signingStatus: OfferSigningStatus[]) {
    rState.lockInSigningInfo = signingStatus;
  },
  SET_RENEWAL_SIGNING_STATUS(rState: RootState, signingStatus: OfferSigningStatus[]) {
    rState.renewalSigningInfo = signingStatus;
  },
  CLEAR_LOCKIN_SIGNING_STATUS(rState: RootState) {
    rState.lockInSigningInfo = null;
  },
  CLEAR_RENEWAL_SIGNING_STATUS(rState: RootState) {
    rState.renewalSigningInfo = null;
  },
  REMIND_SIGNATORY_DONE(rState: RootState) {
    rState.showReminderResendPopup = false;
  },
  FETCH_LOCKIN_VIRTUAL_ERROR(rState: RootState, value: boolean) {
    rState.lockIn = {
      loading: false,
      isSuccess: value,
      value: {
        ...rState.lockIn.value,
        submitRequestStatus: "NOT_STARTED",
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  FETCH_RENEWAL_VIRTUAL_ERROR(rState: RootState, value: boolean) {
    rState.renewal = {
      loading: false,
      isSuccess: value,
      value: {
        ...rState.renewal.value,
        submitRequestStatus: "NOT_STARTED",
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  UPDATE_LOCKIN_VIRTUAL(rState: RootState, lockin: LockIn) {
    rState.lockIn = {
      loading: lockin.loading,
      isSuccess: lockin.isSuccess,
      value: lockin.value,
    };
  },
  UPDATE_RENEWAL_VIRTUAL(rState: RootState, renewal: Renewal) {
    rState.renewal = {
      loading: renewal.loading,
      isSuccess: renewal.isSuccess,
      value: renewal.value,
    };
  },
  CLEAR_LOCKIN_VIRTUAL_PRODUCT_SELECTION(rState: RootState) {
    if (typeof rState.lockIn.value.products !== "undefined") {
      rState.lockIn.value.products = [...rState.lockIn.value.products.map((p) => ({ ...p, productChosen: false }))];
    }
  },
  CLEAR_RENEWAL_VIRTUAL_PRODUCT_SELECTION(rState: RootState) {
    if (typeof rState.renewal.value.products !== "undefined") {
      rState.renewal.value.products = [...rState.renewal.value.products.map((p) => ({ ...p, productChosen: false }))];
    }
  },
  TOGGLE_LOCKIN_VIRTUAL_PRODUCT(rState: RootState, product: OfferProduct) {
    const newProduct = { ...product, productChosen: !product.productChosen };
    rState.lockIn.value.products = [...rState.lockIn.value.products.map((p) => p.product !== product.product ? { ...p, productChosen: false } : { ...p, ...newProduct })];
  },
  TOGGLE_RENEWAL_VIRTUAL_PRODUCT(rState: RootState, product: OfferProduct) {
    product.productChosen = !product.productChosen;
    rState.renewal.value.products = rState.renewal.value.products.slice();
  },
  SUBMIT_LOCKIN_VIRTUAL(rState: RootState, value: boolean) {
    rState.lockIn = {
      loading: value,
      isSuccess: true,
      value: {
        ...rState.lockIn.value,
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  SUBMIT_RENEWAL_VIRTUAL(rState: RootState, value: boolean) {
    rState.renewal = {
      loading: value,
      isSuccess: true,
      value: {
        ...rState.renewal.value,
        returnCode: -1,
        errorCode: "",
      },
    };
  },
  CREATE_LOCKIN_VIRTUAL_PRODUCT_SUCCESS(rState: RootState, payload: any) {
    rState.lockIn = { loading: false, isSuccess: true, value: { ...rState.lockIn.value, submitRequestStatus: "SUCCESS", errorCode: payload.errorCode, returnCode: payload.returnCode } };
  },
  CREATE_LOCKIN_VIRTUAL_PRODUCT_FAILURE(rState: RootState, payload: any) {
    rState.lockIn = { loading: false, isSuccess: true, value: { ...rState.lockIn.value, submitRequestStatus: "ERROR" } };
  },
  CREATE_RENEWAL_VIRTUAL_PRODUCT_SUCCESS(rState: RootState, payload: any) {
    rState.renewal = { loading: false, isSuccess: true, value: { ...rState.renewal.value, submitRequestStatus: "SUCCESS", errorCode: payload.errorCode, returnCode: payload.returnCode } };
  },
  CREATE_RENEWAL_VIRTUAL_PRODUCT_FAILURE(rState: RootState, payload: any) {
    rState.renewal = { loading: false, isSuccess: true, value: { ...rState.renewal.value, submitRequestStatus: "ERROR" } };
  },
  CREATE_LOCKIN_VALUE(rState: RootState) {
    rState.lockIn = { loading: false, value: { errorCode: "", returnCode: 0, submitRequestStatus: "NOT_STARTED", products: [] } };
  },
  CREATE_RENEWAL_VALUE(rState: RootState) {
    rState.renewal = { loading: false, value: { errorCode: "", returnCode: 0, submitRequestStatus: "NOT_STARTED", products: [] } };
  },
  SUBMIT_LOCKIN_CANCEL(rState: RootState, value: boolean) {
    rState.lockInCancel = {
      loading: value,
    };
  },
  CREATE_LOCKIN_CANCEL_SUCCESS(rState: RootState, payload: any) {
    rState.lockInCancel = { loading: false, isSuccess: true };
  },
  CREATE_LOCKIN_CANCEL_FAILURE(rState: RootState, payload: any) {
    rState.lockInCancel = { loading: false, isSuccess: false, returnCode: -1 };
  },
  CLEAR_LOCKIN_CANCEL_STATE(rState: RootState) {
    rState.lockInCancel = { loading: false, isSuccess: null, returnCode: null };
  },
  SUBMIT_RENEWAL_CANCEL(rState: RootState, value: boolean) {
    rState.renewalCancel = {
      loading: value,
    };
  },
  CREATE_RENEWAL_CANCEL_SUCCESS(rState: RootState, payload: any) {
    rState.renewalCancel = { loading: false, isSuccess: true };
  },
  CREATE_RENEWAL_CANCEL_FAILURE(rState: RootState, payload: any) {
    rState.renewalCancel = { loading: false, isSuccess: false, returnCode: -1 };
  },
  CLEAR_RENEWAL_CANCEL_STATE(rState: RootState) {
    rState.renewalCancel = { loading: false, isSuccess: null, returnCode: null };
  },
  UPDATE_LICENCEINFO(rState: RootState, content: any) {
    rState.licenceInfoFromCMS = rState.locale === "fr" ? JSON.parse(content.frenchContent) : JSON.parse(content.englishContent);
  },
};
export const actions = {
  setScreenSize({ commit }: any, size: number) {
    commit("UPDATE_SCREEN_SIZE", size);
  },
  updateLSPPCalcScenarioOne({ commit }: any, scenario: any) {
    commit("UPDATE_LSPP_CALC_SCENARIO_ONE", scenario);
  },
  updateLSPPCalcBaseline({ commit }: any, scenario: any) {
    commit("UPDATE_LSPP_CALC_BASELINE", scenario);
  },
  setFireInsuruacne({ commit }: any, response: boolean) {
    commit("UPDATE_FIRE_INSURANCE", response);
  },
  updateLocale({ commit }: any, lang: string) {
    if (lang === "en" || lang === "fr") {
      commit("UPDATE_LOCALE", lang);
      i18n.global.locale = lang;
      currencyServiceSetLanguage();
      moment.locale(lang);
    }
  },
  updateFaqQuestion({ commit }: any, index: number) {
    commit("UPDATE_FAQ_QUESTION", index);
  },
  logout({ commit }: any) {
    userService.logout();
    commit("LOGOUT");
    CookieService.setCookie("user", "", { path: config.publicPath });
  },
  async getMortgageSummaries({ commit }: any) {
    const response = await mortgageService.getMortgageSummaries(i18n.global.locale.toUpperCase());
    if (response.isSuccess) {
      commit("CLEAR_MORTGAGE");
      commit("UPDATE_MORTGAGE_SUMMARIES", response.value);
      commit("UPDATE_MORTGAGE_OFFER_ADS", response.value.allOffers);
    }
    return response;

  },
  async getMortgageDetails({ commit }: any, mortgageNumber: string) {
    commit("UPDATE_MORTGAGE_TABS", allTabs.slice());

    const response = await mortgageService.getMortgageDetails(mortgageNumber, i18n.global.locale);
    if (response.isSuccess) {
      const tabs = allTabs.slice();

      if (response.value.mortgageType === "R" && config.isThinkHELOCEnded === true) {
        tabs.splice(tabs.findIndex((c) => c.textKey === "MortgageDetails"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "LineCreditDetails"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "Activity"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "PropertyTaxDetails"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "AmortizationSchedule"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "Forms"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "ServiceFees"), 1);
        tabs.splice(tabs.findIndex((c) => c.textKey === "Calculator"), 1);
      } else {
        if (response.value.mortgageType === "R") {
          tabs.splice(tabs.findIndex((c) => c.textKey === "MortgageDetails"), 1);
        } else {
          tabs.splice(tabs.findIndex((c) => c.textKey === "LineCreditDetails"), 1);
        }
        if (response.value.chargeType === "2") {
          tabs.splice(tabs.findIndex((c) => c.name === "property-tax-details"), 1);
        }
        if (response.value && ((response.value.chargeType === "2" && response.value.mortgageType === "R") || response.value.chargeType === "8")) {
          tabs.splice(tabs.findIndex((c) => c.name === "amortization-schedule"), 1);
        }
      }
      commit("UPDATE_MORTGAGE_TABS", tabs);
      commit("UPDATE_MORTGAGE", response.value);
    }
    return response;
  },
  async getMortgageActivities({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getMortgageActivities(mortgageNumber, i18n.global.locale);
    if (response.isSuccess) {
      response.value.items.forEach((item: any, index: any) => item.id = index);
      commit("UPDATE_MORTGAGE_ACTIVITIES", response.value);
    }
  },
  async getMortgageActivityHistory({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getMortgageActivities(mortgageNumber, i18n.global.locale, ["historical"]);
    if (response.isSuccess) {
      response.value.items.forEach((item: any, index: any) => item.id = index);
      commit("UPDATE_MORTGAGE_ACTIVITIES", response.value);
    }
  },
  async getMortgageActivityHistoryRecent({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getMortgageActivities(mortgageNumber, i18n.global.locale, ["historical", "recent"]);
    if (response.isSuccess) {
      response.value.items.forEach((item: any, index: any) => item.id = index);
      commit("UPDATE_MORTGAGE_ACTIVITIES", response.value);
    }
  },
  hideEducationalVideosFlagInUserData({ commit }: any) {
    commit("HIDE_EDUCATIONAL_VIDEOS_FLAG");
  },
  dismissRenewalNotificationInCache({ commit }: any) {
    commit("DISMISS_RENEWAL_NOTIFICATION");
  },
  checkNotificationDismissDate({ commit }: any, renewDate: string) {
    commit("CHECK_NOTIFICATION_DISMISS_DATE", renewDate);
  },
  async getPropertyTaxInfo({ commit }: any, mortgageNumber: string) {
    const response = await apiService.GET("propertytaxes", [mortgageNumber, i18n.global.locale]);
    if (response.isCatchError && response.errorValue  && response.errorValue.response.status === 404) {
      commit("UPDATE_MORTGAGE_PROPERTYTAX", null);
    } else if (response.isCatchError) {
      throw response.errorValue;
    } else {
      commit("UPDATE_MORTGAGE_PROPERTYTAX", response.value);
    }
  },
  async getPropertyTaxPayments({ commit }: any, mortgageNumber: string) {
    // is this used???
    const { data } = await http.get<PropertyTaxPaymentsResponse>(`/api/v1/propertytaxes/${mortgageNumber}/payments/${i18n.global.locale}-CA`);
    commit("UPDATE_MORTGAGE_PROPERTYTAX_PAYMENTS", data.items);
  },
  async getPropertyTaxPaymentHistoryAll({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getPropertyTaxPayment(mortgageNumber, i18n.global.locale, ["historical"] );
    commit("UPDATE_MORTGAGE_PROPERTYTAX_PAYMENTS", response.value.items);

  },
  async getPropertyTaxPaymentHistoryRecent({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getPropertyTaxPayment(mortgageNumber, i18n.global.locale, ["historical", "recent"] );
    commit("UPDATE_MORTGAGE_PROPERTYTAX_PAYMENTS", response.value.items);
  },
  async getAllDocuments({ commit }: any, mortgageNumber: string) {
    const response = await apiService.GET("documents", [mortgageNumber, `${i18n.global.locale}-CA`]);
    commit("UPDATE_DOCUMENTS", response.value.items);
  },
  async getServiceFees({ commit }: any, mortgage: Mortgage) {
    const response = await apiService.GET("servicefees", [mortgage.lender, `${i18n.global.locale}-CA`,mortgage.lineOfBusiness]);
    commit("UPDATE_SERVICEFEES", response.value);
  },
  async getForms({ commit }: any, lender: string) {
    const response = await apiService.GET("forms", [lender, `${i18n.global.locale}-CA`]);
    commit("UPDATE_FORMS", response.value);
  },
  async getAmortizationDetails({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getAmortizationDetails(mortgageNumber, `${i18n.global.locale}-CA`);
    commit("UPDATE_AMORTIZATION", response.value);
  },
  getPaymentFrequencyChangeDetails({ commit }: any, details: any) {
    commit("UPDATE_PAYMENTFREQUENCY_DETAILS", details);
  },
  getProspectivePaymentFrequencyChangeDetails({ commit }: any, data: any) {
    commit("UPDATE_PAYMENTFREQUENCY_PROSPECTIVE_DETAILS", data);
  },
  resetPaymentFrequencyDetails({ commit }: any) {
    commit("RESET_PAYMENTFREQUENCY");
  },
  getPaymentDateChangeDetails({ commit }: any, data: any) {
    commit("UPDATE_PAYMENTDATE_DETAILS", data);
  },
  getProspectivePaymentDateChangeDetails({ commit }: any, data: any) {
    commit("UPDATE_PAYMENTDATE_PROSPECTIVE_DETAILS", data);
  },
  resetPaymentDateChangeDetails({ commit }: any) {
    commit("RESET_PAYMENTDATE");
  },

  getArrearsPaymentDetails({ commit }: any, data: any) {
    commit("UPDATE_ARREARS_PAYMENT_DETAILS", data);
  },
  resetArrearsPaymentDetails({ commit }: any) {
    commit("RESET_ARREARS_PAYMENT");
  },
  async updateUserNeedsCasl({ commit }: any, allowMarketing: any) {
    const response = await userService.updateCASL(allowMarketing);
    if (response.isSuccess) {
      commit("UPDATE_USER_NEEDS_CASL");
    }
    return response;
  },
  async updateUserName({ commit }: any, username: string) {
    const updated = { username: username.toUpperCase() };
    const data = await userService.ChangeUsername(updated, i18n.global.locale);
    if (data.isSuccess) {
      commit("UPDATE_USERNAME", username);
    }
    return data;

  },
  async updateEmail({ commit }: any, email: string) {
    const updated = { email };
    const response = await userService.changeEmail(updated);
    if (!response.isSuccess && response.isCatchError) {
      return {isSuccess: false , error:  errorService.getErrorTextByResponse(response.errorValue)};
    } else if (!response.isSuccess) {
      return {isSuccess: false, error: t("ChangeEmail.ErrorNotValidEmail") };
    }
    commit("UPDATE_EMAIL", email);
    return {isSuccess: true};

  },
  async updateUserNeedsPaperless({ commit }: any, isPaperless: PropertyCommunicationPreference[]) {
    const response = await userService.updatePaperless(i18n.global.locale, isPaperless);
    if (response.isSuccess) {
      commit("UPDATE_USER_NEEDS_PAPERLESS");
    }
    return response;
  },
  resetLineOfCreditDetails({ commit }: any) {
    commit("UPDATE_LINE_OF_CREDIT_DETAILS", null);
  },
  async getSecurityQuestions({ commit }: any) {
    const response = await userService.getSecurityQuestions(i18n.global.locale);
    if (response.isSuccess) {
      commit("UPDATE_SECURITY_QUESTIONS", response!.value.questions);
    }
  },
  async getUserSecurityQuestions({ commit }: any) {
    const response = await userService.getUserSecurityQuestions(i18n.global.locale);
    if (response.isSuccess) {
      commit("UPDATE_USER_SECURITY_QUESTIONS", response!.value.questions);
    }
  },
  async updateUserSecurityQuestions({ commit }: any, request: any) {
    return await userService.updateUserSecurityQuestions( request);
  },
  async getLineOfCreditDetails({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getLineOfCreditDetails(mortgageNumber, i18n.global.locale);
    commit("UPDATE_LINE_OF_CREDIT_DETAILS", response.value);
    return response;
  },
  refreshActivities({ commit }: any) {
    commit("REQUEST_ACTIVITIES_REFRESH");
  },
  async getTenants({ commit }: any) {
    const response = await utilServiceGetTenants();
    commit("UPDATE_TENANTS", response!.value);

  },
  async getUserAgreement({ commit }: any) {
    const response = await userService.getUserAgreement(i18n.global.locale);
    commit("UPDATE_USER_AGREEMENT", response!.value);
  },
  async getQuizTandC({ commit }: any, request: any) {
    const response = await contentService.getQuizTandC(request.type, request.version, config.code);
    commit("UPDATE_QUIZ_TandC", response!.value);
  },
  async getLumpSumOneTimeDetails({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getLumpSumOneTimeDetails(mortgageNumber);
    commit("UPDATE_LUMPSUM_ONETIME_REQUEST", response!.value);
    return response;
  },
  async getLumpSumOnGoingDetails({ commit }: any, request: any) {
    const mortgageNumber: string = request.mortgageNumber;
    const startDate: string = request.startDate ? moment(request.startDate,"YYYY-MM-DD").format("YYYY-MM-DD")?.toString() : "All";
    const lang: string = i18n.global.locale;

    const response = await mortgageService.getLumpSumOnGoingDetails(mortgageNumber , startDate, lang);
    commit("UPDATE_LUMPSUM_ONGOING_REQUEST", response!.value);
    return response;
  },
  async getCurrentLumpSumPayments({ commit }: any, mortgageNumber: string) {
    const response = await mortgageService.getCurrentLumpSumPayments(mortgageNumber);
    commit("UPDATE_LUMPSUM_PAYMENTS", response!.value);
    return response;
  },
  async getFAQs({ commit }: any) {
    const response = await  contentService.getFAQs(config.code);
    commit("UPDATE_FAQS", response!.value);
    return response;
  },
  async getTopics({ commit }: any) {
    const response = await  contentService.getTopics(config.code);
    commit("UPDATE_TOPICS", response!.value);
    return response;
  },
  async getGlossary({ commit }: any) {
    const response = await  contentService.getGlossary(config.code);
    commit("UPDATE_GLOSSARY", response!.value);
    return response;
  },
  async getCampaignBanner({ commit }: any) {
    const mobile = await contentService.getCampaignBannerMobile(config.code);
    commit("UPDATE_CAMPAIGNBANNER_MOBILE", mobile!.value);
    const desktop = await contentService.getCampaignBanner(config.code);
    commit("UPDATE_CAMPAIGNBANNER", desktop!.value);
  },
  async getOfferAdsBanners({ commit }: any, offerAds: any) {
    const adBanners: { desktop: any; mobile: any; name: any; }[] = [];
    for await (const offer of offerAds) {
      if (["offerSkipsLimitOver", "offerSkipPayment", "offerPrivilegePayment", "offerLOCDraw", "receivedFireInsurance", "lapsedFireInsurance", "offerLockIn", "offerHomeMortgageProtection", "offerHomeWarranty"].indexOf(offer.notificationText) === -1) {
        const offerShortName = getOfferTypeName(offer.notificationText);
        if (["MLI", "HW"].indexOf(offerShortName) !== -1 ) {
          const quizAvailableResponse = await userService.isQuizAvailable(offerShortName);
          if (!quizAvailableResponse.isSuccess) {
            continue;
          }
          if (quizAvailableResponse.value.hasUserCompletedLead === 1 && quizAvailableResponse.value.hasUserCompletedQuiz === 1) {
            continue;
          }
        }

        const name = "Offers_" + offer.notificationText;
        const desktop = await contentService.getAdBanner(config.code, name);
        const mobile = await contentService.getAdBannerMobile(config.code, name);
        if (desktop.isSuccess || mobile.isSuccess) {
          const banner = {
            desktop:  i18n.global.locale === "fr" ? desktop.value.frenchContent : desktop.value.englishContent,
            mobile:  i18n.global.locale === "fr" ? mobile.value.frenchContent : mobile.value.englishContent,
            name: offerShortName,
            toolTipName: name.replace("offer", "").replace("Offers_", ""),
          };
          adBanners.push(banner);
        }
      }
    }

    const desktopBanner = await contentService.getHomeBanner();
    const mobileBanner = await contentService.getHomeBannerMobile();

    if (desktopBanner.isSuccess && mobileBanner.isSuccess) {
      const desktopEnJson = JSON.parse(desktopBanner.value.englishContent);
      const desktopFrJson = JSON.parse(desktopBanner.value.frenchContent);
      const mobileEnJson = JSON.parse(mobileBanner.value.englishContent);
      const mobileFrJson = JSON.parse(mobileBanner.value.frenchContent);
      const banner = {
        desktop: `<img src="data:image/jpeg;base64,${i18n.global.locale === "fr" ? desktopFrJson.imgBase64 : desktopEnJson.imgBase64}" />` ,
        mobile: `<img src="data:image/jpeg;base64,${i18n.global.locale === "fr" ? mobileFrJson.imgBase64 : mobileEnJson.imgBase64}" />`,
        name: "HomeBanner",
        toolTipName: i18n.global.locale === "fr" ? desktopFrJson.tooltip : desktopEnJson.tooltip,
        href: i18n.global.locale === "fr" ? desktopFrJson.href : desktopEnJson.href,
      };
      adBanners.push(banner);
    }
    adBanners.reverse();
    commit("UPDATE_ADBANNER", adBanners);
  },
  async getOfferInfo({commit}: any, offerType: any) {
    const response = await contentService.getOfferInfo(offerType);
    if (response.isSuccess) {
      commit("UPDATE_OFFERINFO", { response: response!.value, offerType });
    }
  },

  clearLumpSumRequests({ commit }: any) {
    commit("CLEAR_LUMPSUM_REQUESTS");
  },
  setUser({ commit }: any, userState: User) {
    commit("SET_USER", userState);
  },
  toggleContactUs({ commit }: any, payload: any) {
    commit("TOGGLE_CONTACTUS", payload);
  },
  updateMarsStatus({ commit }: any, isMarsDown: boolean) {
    commit("SET_MARS_STATUS", isMarsDown);
  },
  updateQuizData({ commit }: any, quizData: any ) {
    commit("SET_QUIZ_DATA", quizData);
  },
  toggleVideoModal({ commit }: any, data: any) {
    commit("TOGGLE_VIDOE_MODAL", data);
  },
  toggleReminderResendPopup({ commit }: any, info: any) {
    commit("TOGGLE_REMINDER_RESEND_POPUP", info);
  },
  async getLockInOfferSigningStatus({ commit }: any, info: any) {
    const signingStatus = await offerService.getLockInSigningStatus(info.packageId);
    const { isSuccess, value } = signingStatus;
    if (isSuccess) {
      commit("SET_LOCKIN_SIGNING_STATUS", value);
    }
  },
  async getRenewalOfferSigningStatus({ commit }: any, info: any) {
    const signingStatus = await offerService.getRenewalSigningStatus(info.packageId, info.brandCode);
    const { isSuccess, value } = signingStatus;
    if (isSuccess) {
      commit("SET_RENEWAL_SIGNING_STATUS", value);
    }
  },
  clearRenewalOfferSigningStatusValue({ commit }: any) {
    commit("CLEAR_RENEWAL_SIGNING_STATUS");
  },
  clearLockInOfferSigningStatusValue({ commit }: any) {
    commit("CLEAR_LOCKIN_SIGNING_STATUS");
  },
  async postLockInSigningReminder({ commit }: any, info: any) {
    const reminderStatus = await offerService.postLockInSigningReminder(info.offerId, info.newInfo);
    const { isSuccess, value } = reminderStatus;
    if (isSuccess) {
      commit("REMIND_SIGNATORY_DONE");
    }
  },
  async postRenewalSigningReminder({ commit }: any, info: any) {
    const reminderStatus = await offerService.postRenewalSigningReminder(info.offerId, info.newInfo);
    const { isSuccess, value } = reminderStatus;
    if (isSuccess) {
      commit("REMIND_SIGNATORY_DONE");
    }
  },
  async getLockIn({ commit }: any, mortgageNumber: string) {
    commit("FETCH_LOCKIN_VIRTUAL", true);
    const lockinResponse = await offerService.getLockIn(mortgageNumber, i18n.global.locale);
    const { isSuccess, value } = lockinResponse;
    if (isSuccess) {
      commit("UPDATE_LOCKIN_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_LOCKIN_VIRTUAL", false);
      commit("FETCH_LOCKIN_VIRTUAL_ERROR", false);
    }
  },
  async getRenewal({ commit }: any, mortgageNumber: string) {
    commit("FETCH_RENEWAL_VIRTUAL", true);
    const renewalResponse = await offerService.getRenewal(mortgageNumber, i18n.global.locale);
    const { isSuccess, value } = renewalResponse;
    if (isSuccess) {
      commit("UPDATE_RENEWAL_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_RENEWAL_VIRTUAL", false);
      commit("FETCH_RENEWAL_VIRTUAL_ERROR", false);
    }
  },
  async getLockInVirtual({ commit }: any, mortgageNumber: string) {
    commit("FETCH_LOCKIN_VIRTUAL", true);
    const lockinResponse = await offerService.getInitialLockIn(mortgageNumber, i18n.global.locale);
    const { isSuccess, value } = lockinResponse;
    if (isSuccess) {
      commit("UPDATE_LOCKIN_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_LOCKIN_VIRTUAL", false);
      commit("FETCH_LOCKIN_VIRTUAL_ERROR", false);
    }
  },
  async getRenewalVirtual({ commit }: any, mortgageNumber: string) {
    commit("FETCH_RENEWAL_VIRTUAL", true);
    const renewalResponse = await offerService.getInitialRenewal(mortgageNumber, i18n.global.locale);
    const { isSuccess, value } = renewalResponse;
    if (isSuccess) {
      commit("UPDATE_RENEWAL_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_RENEWAL_VIRTUAL", false);
      commit("FETCH_RENEWAL_VIRTUAL_ERROR", false);
    }
  },
  async getLockInRevisedVirtual({ commit }: any, detail: any) {
    commit("FETCH_LOCKIN_VIRTUAL", true);
    commit("CLEAR_LOCKIN_VIRTUAL_PRODUCT_SELECTION");
    const lockinResponse = await offerService.getRevisedInitialLockIn(detail.mortgageNumber, detail.effectiveDate, detail.lang);
    const { isSuccess, value } = lockinResponse;
    if (isSuccess) {
      commit("UPDATE_LOCKIN_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_LOCKIN_VIRTUAL", false);
      commit("FETCH_LOCKIN_VIRTUAL_ERROR", false);
    }
  },
  async getRenewalRevisedVirtual({ commit }: any, detail: any) {
    commit("FETCH_RENEWAL_VIRTUAL", true);
    commit("CLEAR_RENEWAL_VIRTUAL_PRODUCT_SELECTION");
    const renewalResponse = await offerService.getRevisedInitialRenewal(detail.mortgageNumber, detail.effectiveDate, detail.lang);
    const { isSuccess, value } = renewalResponse;
    if (isSuccess) {
      commit("UPDATE_RENEWAL_VIRTUAL", { loading: false, isSuccess: true, value });
    } else {
      commit("FETCH_RENEWAL_VIRTUAL", false);
      commit("FETCH_RENEWAL_VIRTUAL_ERROR", false);
    }
  },
  toggleLockInChosenProduct({ commit }: any, chosenProduct: OfferProduct) {
    commit("CLEAR_LOCKIN_VIRTUAL_PRODUCT_SELECTION");
    commit("TOGGLE_LOCKIN_VIRTUAL_PRODUCT", chosenProduct);
  },
  toggleRenewalChosenProduct({ commit }: any, chosenProduct: OfferProduct) {
    commit("TOGGLE_RENEWAL_VIRTUAL_PRODUCT", chosenProduct);
  },
  clearLockInChosenProduct({ commit }: any, chosenProduct: OfferProduct) {
    commit("CLEAR_LOCKIN_VIRTUAL_PRODUCT_SELECTION");
  },
  clearRenewalChosenProduct({ commit }: any, chosenProduct: OfferProduct) {
    commit("CLEAR_RENEWAL_VIRTUAL_PRODUCT_SELECTION");
  },
  async postLockInVirtual({ commit }: any, request: OfferCreateRequest) {
    commit("SUBMIT_LOCKIN_VIRTUAL", true);
    const lockinResponse = await offerService.postLockIn(request.mortgageNumber, i18n.global.locale, request);
    const { isSuccess, value } = lockinResponse;
    commit("SUBMIT_LOCKIN_VIRTUAL", false);
    if (isSuccess) {
      commit("CREATE_LOCKIN_VIRTUAL_PRODUCT_SUCCESS", { loading: false, returnCode: value.returnCode, errorCode: null });
    } else {
      commit("CREATE_LOCKIN_VIRTUAL_PRODUCT_FAILURE", { loading: false });
    }
  },
  async postRenewalVirtual({ commit }: any, request: OfferCreateRequest) {
    commit("SUBMIT_RENEWAL_VIRTUAL", true);
    const responseResponse = await offerService.postRenewal(request.mortgageNumber, i18n.global.locale, request);
    const { isSuccess, value } = responseResponse;
    commit("SUBMIT_RENEWAL_VIRTUAL", false);
    if (isSuccess) {
      commit("CREATE_RENEWAL_VIRTUAL_PRODUCT_SUCCESS", { loading: false, returnCode: value.returnCode, errorCode: null });
    } else {
      commit("CREATE_RENEWAL_VIRTUAL_PRODUCT_FAILURE", { loading: false });
    }
  },
  async postLockInAnalytics({ commit }: any, payload: any) {
    await offerService.postLockInAnalytics(payload.mortgageNumber, i18n.global.locale, payload.logType);
  },
  async postRenewalAnalytics({ commit }: any, payload: any) {
    await offerService.postRenewalAnalytics(payload.mortgageNumber, i18n.global.locale, payload.logType);
  },
  async lookupPhoneNumber({ commit }: any, mobilePhone: string) {
    const phoneLookupResponse = await lookupService.lookupPhoneNumber(mobilePhone);
    const { isSuccess, value } = phoneLookupResponse;
    return (isSuccess ? value : null);
  },
  clearLockInValue({ commit }: any) {
    commit("CREATE_LOCKIN_VALUE");
  },
  clearRenewalValue({ commit }: any) {
    commit("CREATE_RENEWAL_VALUE");
  },
  async postLockInCancel({ commit }: any, request: OfferCancelRequest) {
    commit("SUBMIT_LOCKIN_CANCEL", true);
    const lockinCancelResponse = await offerService.postLockInCancel(request);
    const { isSuccess, value } = lockinCancelResponse;
    if (isSuccess) {
      commit("CREATE_LOCKIN_CANCEL_SUCCESS", { loading: false, returnCode: value.returnCode, errorCode: null });
    } else {
      commit("CREATE_LOCKIN_CANCEL_FAILURE", { loading: false });
    }
  },
  clearLockInCancel({ commit }: any) {
    commit("CLEAR_LOCKIN_CANCEL_STATE");
  },
  async postRenewalCancel({ commit }: any, request: OfferCancelRequest) {
    commit("SUBMIT_RENEWAL_CANCEL", true);
    const renewalCancelResponse = await offerService.postRenewalCancel(request);
    const { isSuccess, value } = renewalCancelResponse;
    if (isSuccess) {
      commit("CREATE_RENEWAL_CANCEL_SUCCESS", { loading: false, returnCode: value.returnCode, errorCode: null });
    } else {
      commit("CREATE_RENEWAL_CANCEL_FAILURE", { loading: false });
    }
  },
  clearRenewalCancel({ commit }: any) {
    commit("CLEAR_RENEWAL_CANCEL_STATE");
  },
  async postVideoAnalytics({ commit }: any, payload: CreateVideoAnalyticsRequest) {
    await videoService.postAnalytics(payload);
  },
  async addSafeguardCancelAnalytics({ commit }: any, payload: SafeguardCancelAnalyticsRequest) {
    const newRequestBody = {
      cancelType: payload.cancelType,
      brand: payload.brand,
    };

    await safeguardService.postCancelAnalytics(payload.mortgageNumber, i18n.global.locale, newRequestBody);
  },
  async postSafeguardAnalytics({ commit }: any, payload: any) {
    const newRequestBody = {
      options: payload.options,
      brand: payload.brand,
      chargeType: payload.chargeType,
    };

    await safeguardService.postSafeguardAnalytics(payload.mortgageNumber, i18n.global.locale, newRequestBody);
  },
  async getLicenceInfo({ commit }: any) {
    const response = await contentService.getLicenceInfo(config.code);
    const { isSuccess } = response;
    if (isSuccess) {
      commit("UPDATE_LICENCEINFO", response!.value);
    }   
  },
};

export default createStore({
  state,
  modules: {
    login: loginModule,
  },
  mutations,
  actions,
})
