import apiService from "./apiService";
import config from "./configService";
import i18n from "@/plugins/i18n"


class ContentService {
  public getSalesBanners = (): any => {
    return apiService.GET("content/Sales_Banners/Brand", [config.code, `${i18n.global.locale}-CA`]);
  }
  public getWelcomePopUp = (): any => {
    return apiService.GET("content/Welcome_Popup_Title", [`${i18n.global.locale}-CA`]);
  }
  public getWelcomePopUpContent = (): any => {
    return apiService.GET("content/Welcome_Popup_Content", [`${i18n.global.locale}-CA`]);
  }
  public getDocuments = (mortgageNumber: string, docId: string): any => {
    return apiService.GET("documents", [mortgageNumber, "content", docId], { responseType: "blob" });
  }
  public getIEBanner = (): any => {
    return apiService.GET("Content/IE_Banner/Brand", [`${i18n.global.locale}-CA`]);
  }
  public getFAQs = (brandCode: string): any => {
    return apiService.GET("Content/FAQ/Brand", [brandCode, `${i18n.global.locale}-CA`]);
  }
  public getTopics = (brandCode: string): any => {
    return apiService.GET("Contact/topics", [brandCode, `${i18n.global.locale}-CA`]);
  }
  public getGlossary = (brandCode: string): any => {
    return apiService.GET("Content/Glossary/Brand", [brandCode, `${i18n.global.locale}-CA`]);
  }
  public getCampaignBannerMobile = (brandCode: string): any => {
    return apiService.GET("Content/campaign_banner_mobile/Brand", [brandCode, `${i18n.global.locale}-CA`]);
  }
  public getCampaignBanner = (brandCode: string): any => {
    return apiService.GET("Content/campaign_banner/Brand", [brandCode, `${i18n.global.locale}-CA`]);
  }
  public getAdBanner = (brandCode: string, adName: string): any => {
    return apiService.GET("Content", [adName, "Brand", brandCode, `${i18n.global.locale}-CA`]);
  }
  public getAdBannerMobile = (brandCode: string, adName: string): any => {
    return apiService.GET("Content", [`${adName}_mobile`, "Brand", brandCode, `${i18n.global.locale}-CA`]);
  }
  public getOfferInfo = (offerType: string): any => {
    return apiService.GET("content", [`Offers_${offerType}_info`, `${i18n.global.locale}-CA`]);
  }
  public getOffer = (offerType: string): any => {
    return apiService.GET("content", [`Offers_${offerType}`]);
  }
  public getLoginAlert = (brand: string): any => {
    return apiService.GET("Content/Login_Alert/Brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getEligibleCorrespondence = (brand: string): any => {
    return apiService.GET("Content/Eligible_Correspondence/Brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getQuizQueAns = (campaignName: string): any => {
    return apiService.GET("Campaign", ["campaignQuestions", `${campaignName}`, `${i18n.global.locale}-CA`]);
  }
  public getQuizTandC = (type: string, version: number, brandCode: string): any => {
    return apiService.GET(`Content/${type}_Terms_Conditions_0${version}`, [`${i18n.global.locale}-CA`]);
  }
  public GetMLIContactTimes = (): any => {
    return apiService.GET("Campaign", ["MLILeadContactTimes"]);
  }
  public getHomeBanner = (): any => {
    return apiService.GET("content/Offers_HomeBanner/Brand", [config.code, `${i18n.global.locale}-CA`]);
  }
  public getHomeBannerMobile = (): any => {
    return apiService.GET("content/Offers_HomeBanner_mobile/Brand", [config.code, `${i18n.global.locale}-CA`]);
  }
  public getConsentPopUp = (brand: string): any => {
    return apiService.GET("content/Consent_Popup_Title/brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getConsentContent = (brand: string): any => {
    return apiService.GET("content/Consent_Popup_Content/brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getPaymentAssistanceNeedtoKnow = (brand: string): any => {
    return apiService.GET("content/Payment_Assistance_Need_to_Know/brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getPaymentAssistanceAcknowledgement = (brand: string): any => {
    return apiService.GET("content/Payment_Assistance_Acknowledgement/brand", [brand, `${i18n.global.locale}-CA`]);
  }
  public getLicenceInfo = (brand: string): any => {
    return apiService.GET("content/Licence_Info/brand", [brand, `${i18n.global.locale}-CA`]);
  }
}
export default new ContentService();
