<template>
    <svg id="linkedin-logo-button" xmlns="http://www.w3.org/2000/svg" width="25.65" height="25.65" viewBox="0 0 25.65 25.65">
        <g id="Group_11" data-name="Group 11">
            <g id="Group_10" data-name="Group 10">
            <path id="Path_16" data-name="Path 16" d="M25.673,21.3a2.05,2.05,0,0,0-1.862,1.027v-.88H21.743c.027.583,0,6.22,0,6.22H23.81V24.19a1.393,1.393,0,0,1,.068-.5,1.132,1.132,0,0,1,1.061-.757c.748,0,1.047.57,1.047,1.407v3.328h2.068V24.1C28.053,22.187,27.033,21.3,25.673,21.3Zm-1.864,1.049H23.8a.155.155,0,0,0,.013-.021Z" transform="translate(-10.511 -10.296)" fill="#fff"/>
            <rect id="Rectangle_22" data-name="Rectangle 22" width="2.067" height="6.22" transform="translate(8.019 11.149)" fill="#fff"/>
            <path id="Path_17" data-name="Path 17" d="M12.825,0A12.825,12.825,0,1,0,25.65,12.825,12.84,12.84,0,0,0,12.825,0Zm6.8,18.626a1,1,0,0,1-1.012.987H6.949a1,1,0,0,1-1.011-.987V6.825a1,1,0,0,1,1.011-.987H18.614a1,1,0,0,1,1.012.987v11.8Z" fill="#fff"/>
            <path id="Path_18" data-name="Path 18" d="M16.457,15.777a1.077,1.077,0,1,0-.027,2.149h.013a1.078,1.078,0,1,0,.014-2.149Z" transform="translate(-7.39 -7.627)" fill="#fff"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: "LinkedinIcon",
};
</script>

<style lang="scss" scoped>

</style>