import store from "@/store/index";
import i18n from "@/plugins/i18n";
import config, { Config } from "../services/configService";
import router from "@/router";
import { brand } from "@/assets/branding";

const { t, te } = i18n.global;

const getTranslation = (key: string, params: any) => {
  return te(key) ? t(key, params) : undefined;
};

function getMetaInfo() {
  const tenantName = store.state.tenantName || "";
  const tenantNamePrefix = store.state.locale === "fr" ? config.prefixFr : config.prefix;
  const route = router.currentRoute.value.name?.toString();

  const metaTitle =
    getTranslation(`MetaData.${route}.Title-${config.code}`, {tenant: tenantName, prefix: tenantNamePrefix}) ||
    getTranslation(`MetaData.${route}.Title`, {tenant: tenantName, prefix: tenantNamePrefix}) ||
    `${tenantNamePrefix}${tenantName}`;
  const metaDescription =
    getTranslation(`MetaData.${route}.Description-${config.code}`, {tenant: tenantName, prefix: tenantNamePrefix}) ||
    getTranslation(`MetaData.${route}.Description`, {tenant: tenantName, prefix: tenantNamePrefix}) ||
    "";
  const metaImageURL = brand.metaImageUrl ? config.getUrl() + brand.metaImageUrl : "";

  const metaTagsData = [
    { name: "title", content: metaTitle },
    { name: "description", content: metaDescription },

    { name: "og:title", content: metaTitle },
    { name: "og:description", content: metaDescription },
    { name: "og:image", content: metaImageURL },

    { name: "twitter:title", content: metaTitle },
    { name: "twitter:description", content: metaDescription },
    { name: "twitter:image", content: metaImageURL },

    { name: "og:type", content: "website" },
    { name: "robots", content: "index,follow" },
  ];

  return {
    title: metaTitle,
    meta: metaTagsData,
    link: [
      { href: `${config.publicPath}assets/tenants/${config.code}/styles/variables.css`, rel: "stylesheet", type:"text/css"},
      { href: `${config.publicPath}assets/tenants/${config.code}/styles/fontface.css`, rel: "stylesheet", type:"text/css"},
      { href: `${config.publicPath}assets/tenants/${config.code}/images/favicons/favicon-32x32.png`, rel: "icon", type:"image/png", sizes:"32x32"},
      { href: `${config.publicPath}assets/tenants/${config.code}/images/favicons/favicon-16x16.png`, rel: "icon", type:"image/png", sizes:"16x16"},
      { href: `${config.publicPath}assets/tenants/${config.code}/images/favicons/favicon.ico`, rel: "icon" }
    ]
  };
}

export default getMetaInfo;
