import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "application-body" }
const _hoisted_2 = {
  key: 0,
  id: "app",
  class: "app--login"
}
const _hoisted_3 = {
  key: 1,
  id: "app",
  class: "app--login"
}
const _hoisted_4 = {
  key: 2,
  id: "app"
}
const _hoisted_5 = {
  id: "container--main",
  class: "container"
}
const _hoisted_6 = {
  key: 5,
  id: "app"
}
const _hoisted_7 = {
  key: 0,
  id: "container--main",
  class: "container container--main"
}
const _hoisted_8 = {
  key: 1,
  id: "container--main",
  class: "container container--main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IEBannerMessage = _resolveComponent("IEBannerMessage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ConsentPopup = _resolveComponent("ConsentPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name === 'Unavailable')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isIE)
            ? (_openBlock(), _createBlock(_component_IEBannerMessage, {
                key: 0,
                class: "bannerTop",
                closeBanner: () => { }
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ]))
      : (_ctx.$route.name === 'Login' || _ctx.$route.name === 'PasswordReset')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.isIE)
              ? (_openBlock(), _createBlock(_component_IEBannerMessage, {
                  key: 0,
                  class: "bannerTop",
                  closeBanner: () => { }
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_router_view)
          ]))
        : (_ctx.$route.name === 'Impersonation')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.isIE)
                ? (_openBlock(), _createBlock(_component_IEBannerMessage, {
                    key: 0,
                    closeBanner: () => { }
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_router_view)
            ]))
          : (_ctx.$route.name === 'Help' || _ctx.$route.name === 'ContactUs' || _ctx.$route.name === 'SessionExpired' || _ctx.$route.name === 'VideoLibrary' || _ctx.$route.name === 'Video')
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                id: "app",
                class: _normalizeClass(["app--help", { anonymous: !_ctx.$store.state.user }])
              }, [
                _cache[0] || (_cache[0] = _createElementVNode("a", {
                  href: "#container--main",
                  class: "skip-link"
                }, "Skip to main content", -1)),
                _createVNode(_component_Header),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_router_view)
                ]),
                _createVNode(_component_Footer, { isFAQ: true })
              ], 2))
            : (_ctx.$route.name === 'Glossary' || _ctx.$route.name === 'Sitemap' || _ctx.$route.name === 'MLILeadGenerationForm')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  id: "app",
                  class: _normalizeClass(["app--help", { anonymous: !_ctx.$store.state.user }])
                }, [
                  _createVNode(_component_Header),
                  _createVNode(_component_router_view),
                  _createVNode(_component_Footer)
                ], 2))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[1] || (_cache[1] = _createElementVNode("a", {
                    href: "#container--main",
                    class: "skip-link"
                  }, "Skip to main content", -1)),
                  _createVNode(_component_Header),
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_Spinner)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_router_view)
                      ])),
                  _createVNode(_component_Footer)
                ])),
    _createVNode(_component_ConsentPopup)
  ]))
}