<template>
    <div class="overlay">
        <div class="wrapper">
            <div class="spinner" />
        </div>
    </div>
</template>

<script>
export default {
  name: "Spinner",
};
</script>

<style lang="scss" scoped>


    .spinner {
        border: 6px solid #ccc;
        border-top: 6px solid var(--color--primary);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1.5s linear infinite;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255,255,255,.85);
        z-index: 3;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

</style>