import { LoginState, LoginSteps } from "./types/types";
import Vuex, { Module } from "vuex";
import { Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex"
import http from "@/services/httpService";
import config from "@/services/configService";
import { RootState } from "@/store/types";
import { LoginResult } from "@/store/types/user";
import { Answer } from "@/store/types/login";
import { Content } from "../types/content";
import errorService from "@/services/errorService";
import { CookieService } from "@/services/cookieService";
import contentService from "@/services/contentService";

// Vue.use(Vuex);
const logState = {
  currentStep: null,
  loginResult: null,
  loginRequest: null,
  loginAlert: null,
};
export const mutations = {
  INITIALIZE(loginModState: LoginState) {
    loginModState.currentStep = LoginSteps.None;
    loginModState.loginRequest = { username: "", password: "", brand: config.code, language: `${CookieService.getCookie("lang") || "en"}-CA` };
    loginModState.loginResult = null;
  },
  INITIALIZE_REGISTRATION(loginModState: LoginState) {
    loginModState.currentStep = LoginSteps.MortgageVerification;
    loginModState.loginRequest = { username: "", password: "", brand: config.code, language: `${CookieService.getCookie("lang") || "en"}-CA` };
    loginModState.loginResult = null;
  },
  UPDATE_CURRENT_STEP(loginModState: LoginState, step: LoginSteps | null) {
    loginModState.currentStep = step;
  },
  UPDATE_LOGIN_RESULT(loginModState: LoginState, result: LoginResult) {
    loginModState.loginResult = result;
  },
  UPDATE_USERNAME(loginModState: LoginState, username: string) {
    loginModState.loginRequest.username = username;
  },
  UPDATE_PASSWORD(loginModState: LoginState, password: string) {
    loginModState.loginRequest.password = password;
  },
  UPDATE_ANSWERS(loginModState: LoginState, answers: Answer[]) {
    loginModState.loginRequest.securityQuestionAnswers = answers;
  },
  UPDATE_VERIFICATION_CODE(loginModState: LoginState, code: string) {
    loginModState.loginRequest.activationCode = code;
  },
  UPDATE_ACCEPTED_USER_AGREEMENT(loginModState: LoginState) {
    loginModState.loginRequest.acceptedAgreement = true;
  },
  UPDATE_NEW_PASSWORD(loginModState: LoginState, newPassword: string) {
    loginModState.loginRequest.newPassword = newPassword;
  },
  COMPLETE(loginModState: LoginState, result: LoginResult) {
    loginModState.currentStep = LoginSteps.Complete;
    loginModState.loginResult = result;
  },
  UPDATE_LOGIN_ALERT(loginModState: LoginState, content: Content) {
    loginModState.loginAlert = {
      englishContent: content?.englishContent ? content.englishContent.replace(/"/g, "`")  : "",
      frenchContent: content?.frenchContent ? content.frenchContent.replace(/"/g, "`")  : "",
    };
  },
};
export const actions = {
  reset({ commit }: any) {
    commit("UPDATE_CURRENT_STEP", null);
  },
  startRegistration({ commit }: any) {
    commit("INITIALIZE_REGISTRATION");
  },
  async initialize({ commit }: any) {
    const response = await contentService.getLoginAlert(config.code);
    commit("UPDATE_LOGIN_ALERT", response!.value);
    commit("INITIALIZE");
  },
  setUsernameAndPassword({ commit }: any, data: { username: string, password: string }) {
    commit("UPDATE_USERNAME", data.username);
    commit("UPDATE_PASSWORD", data.password);
  },
  setSecurityAnswers({ commit }: any, answers: Answer[]) {
    commit("UPDATE_ANSWERS", answers);
  },
  setVerificationCode({ commit }: any, code: string) {
    commit("UPDATE_VERIFICATION_CODE", code);
  },
  setAcceptedUserAgreement({ commit }: any) {
    commit("UPDATE_ACCEPTED_USER_AGREEMENT");
  },
  setNewPassword({ commit }: any, newPassword: string) {
    commit("UPDATE_NEW_PASSWORD", newPassword);
  },
  async login({ commit, state }: any) {
    if ( state.loginRequest && (state.loginRequest.username.trim().length === 0 ) ) {
      commit("UPDATE_LOGIN_RESULT",  { isSuccess: false, error: "BPE3008", failedLoginAttempts: 0, securityQuestions: [], allSecurityQuestions: [] });
      commit("UPDATE_CURRENT_STEP", LoginSteps.None);
      return;
    }
    if (state.loginRequest && (state.loginRequest.password.trim().length === 0) ) {
      commit("UPDATE_LOGIN_RESULT",  { isSuccess: false, error: "BPE3027", failedLoginAttempts: 0, securityQuestions: [], allSecurityQuestions: [] });
      commit("UPDATE_CURRENT_STEP", LoginSteps.None);
      return;
    }
    await http.post("/api/account", state.loginRequest, { handle403s: true } as any).then((res) => {
      if (res.data.isSuccess) {
        commit("COMPLETE", res.data);
        CookieService.setCookie("user", JSON.stringify(res.data.user), { path: config.publicPath, sameSite: true, secure : true });
        commit("SET_USER", res.data, { root: true });

      }
    }).catch((err) => {
      if (err.response.status === 403 || err.response.status === 422) {
        commit("UPDATE_LOGIN_RESULT", err.response.data);
        commit("UPDATE_CURRENT_STEP", getStepFromError(err.response.data.error));
      } else if (err.response.status === 400) {
        const firstError = err.response.data[Object.keys(err.response.data)[0]];
        if (!state.loginResult) {
          commit("UPDATE_LOGIN_RESULT", { isSuccess: false, error: firstError, failedLoginAttempts: 0, securityQuestions: [], allSecurityQuestions: [] });
        } else {
          const newResult = { ...state.loginResult };
          newResult.error = firstError;
          commit("UPDATE_LOGIN_RESULT", newResult);
        }
      } else {
        const newResult: any = { ...state.loginResult };
        newResult.error = errorService.UnknownError;
        commit("UPDATE_LOGIN_RESULT", newResult);
        commit("UPDATE_CURRENT_STEP", LoginSteps.UnknownError);
      }
    });
  },
};

export const loginModule: Module<LoginState, RootState> = {
  namespaced: true,
  state: logState,
  mutations ,
  actions,
};

export const getStepFromError = (errorCode: string) => {
  switch (errorCode) {
    case "BPE8008":
      return LoginSteps.ValidationCode;
    case "BPE8010":
      return LoginSteps.ValidationCodeExpired;
    case "BPE3046":
      return LoginSteps.ValidationCodeIncorrect;
    case "BPE8001":
      return LoginSteps.UserAgreement;
    case "BPE8002":
      return LoginSteps.SecurityAnswers;
    case "BPE8003":
    case "BPE8020":
      return LoginSteps.SecurityQuestions;
    case "BPE8004":
      return LoginSteps.SecurityAnswersIncorrect;
    case "BPE3042":
      return LoginSteps.InvalidUsernamePassword;
    case "BPE3013":
      return LoginSteps.LockedOut;
    case "BPE8005":
      return LoginSteps.LastAttempt; // Security questions specific
    case "BPE7044":
    case "BPE7045":
      return LoginSteps.LastAttempt;
    case "BPE8006":
    case "BPE3041":
    case "BPE8007":
      return LoginSteps.NewPasswordRequired;
  }


  return LoginSteps.UnknownError;
};
