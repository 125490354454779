import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "left" }
const _hoisted_2 = {
  key: 0,
  class: "menu"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "header__menu__item__icon" }
const _hoisted_5 = {
  key: 2,
  id: "header__menu__item--question"
}
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = {
  key: 0,
  class: "header__menu__item--question__badge"
}
const _hoisted_8 = { style: {"display":"none"} }
const _hoisted_9 = { id: "header__menu__item--gear" }
const _hoisted_10 = { class: "icon" }
const _hoisted_11 = { style: {"display":"none"} }
const _hoisted_12 = { class: "profile__top" }
const _hoisted_13 = {
  key: 0,
  class: "profile__image"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "profile__text" }
const _hoisted_16 = {
  class: "header__profile",
  id: "header__profile"
}
const _hoisted_17 = { class: "profile__top" }
const _hoisted_18 = {
  key: 0,
  class: "profile__image"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "profile__text" }
const _hoisted_21 = { class: "home" }
const _hoisted_22 = {
  class: "active-item",
  href: "",
  id: "header__menu--mobile__item--messages"
}
const _hoisted_23 = {
  key: 0,
  style: {"justify-content":"left"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IEBannerMessage = _resolveComponent("IEBannerMessage")!
  const _component_BrandLogoInverted = _resolveComponent("BrandLogoInverted")!
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CsrSearch = _resolveComponent("CsrSearch")!
  const _component_LanguageToggle = _resolveComponent("LanguageToggle")!
  const _component_MailIcon = _resolveComponent("MailIcon")!
  const _component_QuestionIcon = _resolveComponent("QuestionIcon")!
  const _component_GearIcon = _resolveComponent("GearIcon")!
  const _component_DropdownIcon = _resolveComponent("DropdownIcon")!
  const _component_MenuToggleIcon = _resolveComponent("MenuToggleIcon")!
  const _component_LeftArrowIcon = _resolveComponent("LeftArrowIcon")!
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_LogoutIcon = _resolveComponent("LogoutIcon")!
  const _component_Message = _resolveComponent("Message")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isIE)
      ? (_openBlock(), _createBlock(_component_IEBannerMessage, {
          key: 0,
          closeBanner: _ctx.closeBanner,
          class: _normalizeClass({
      bannerTop:
        _ctx.$route.name === 'Help' ||
        _ctx.$route.name === 'Sitemap' ||
        _ctx.$route.name === 'ContactUs',
    })
        }, null, 8, ["closeBanner", "class"]))
      : _createCommentVNode("", true),
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("header", {
          key: 1,
          class: _normalizeClass(["header", { moveDown: _ctx.isIEBannerShowing && _ctx.isIE }])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: `/`,
              class: _normalizeClass(["logo", { width90: _ctx.isEclipse || _ctx.isRMG }]),
              id: "header__logo"
            }, {
              default: _withCtx(() => [
                (_ctx.isRFA)
                  ? (_openBlock(), _createBlock(_component_BrandLogoInverted, {
                      key: 0,
                      style: _normalizeStyle({ minWidth: _ctx.logoSize })
                    }, null, 8, ["style"]))
                  : (_openBlock(), _createBlock(_component_BrandLogo, {
                      key: 1,
                      style: _normalizeStyle({ minWidth: _ctx.isFrench? _ctx.logoSizeFr : _ctx.logoSize })
                    }, null, 8, ["style"]))
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.isCsr)
              ? (_openBlock(), _createBlock(_component_CsrSearch, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.screenSize > _ctx.mediaSizes.ipad && _ctx.user)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_ctx.isBilingual)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                      _createVNode(_component_LanguageToggle)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.isCsr)
                  ? _withDirectives((_openBlock(), _createElementBlock("li", {
                      key: 1,
                      id: "header__menu__item--messages",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMessaging && _ctx.toggleMessaging(...args)))
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_MailIcon)
                      ])
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('ContactUs'),
                        void 0,
                        { "top-center": true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                (!_ctx.isCsr || _ctx.$appConfig.code == 'MS' || _ctx.$appConfig.code == 'RM')
                  ? _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_router_link, {
                          to: `/help`,
                          alt: "help center"
                        }, {
                          default: _withCtx(() => [
                            (_ctx.showNewVideoBadge)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("EducationalVideos.SupportPage.New")), 1))
                              : _createCommentVNode("", true),
                            _createVNode(_component_QuestionIcon, { style: {"transform":"scale(1.3)"} }),
                            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("HowCanWeHelp")), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ])), [
                      [
                        _directive_tooltip,
                        _ctx.$t('Assistance'),
                        void 0,
                        { "top-center": true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                _withDirectives((_openBlock(), _createElementBlock("li", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_router_link, {
                      to: `/settings`,
                      alt: "$t('Settings')"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_GearIcon, { style: {"transform":"scale(1.25)"} }),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("Settings")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.$t('Settings'),
                    void 0,
                    { "top-center": true }
                  ]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.screenSize > _ctx.mediaSizes.ipad && _ctx.user)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([{ open: _ctx.profileIsOpen }, 'profile']),
                id: "header__profile",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleProfileDropdown()))
              }, [
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.user.profileImage)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("img", {
                          "aria-hidden": "true",
                          src: _ctx.user.profileImage,
                          alt: "Your Profile Picture"
                        }, null, 8, _hoisted_14)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.user.firstName) + " " + _toDisplayString(_ctx.user.lastName), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.user.type), 1)
                  ]),
                  _createVNode(_component_DropdownIcon, {
                    class: _normalizeClass({'dropdown__icon': !_ctx.isRFA && !_ctx.isICICI})
                  }, null, 8, ["class"])
                ]),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", {
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                    style: {"cursor":"pointer"}
                  }, _toDisplayString(_ctx.$t("SignOut")), 1)
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.screenSize <= _ctx.mediaSizes.ipad)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                id: "menu__toggle",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleMobileMenu()))
              }, [
                _createVNode(_component_MenuToggleIcon)
              ]))
            : _createCommentVNode("", true),
          (_ctx.screenSize <= _ctx.mediaSizes.ipad && _ctx.user)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass(["menu menu--mobile", { open: _ctx.mobileMenuIsOpen }])
              }, [
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleMobileMenu())),
                  class: "header__menu--mobile__close",
                  id: "header__menu--mobile__close"
                }, [
                  _createVNode(_component_LeftArrowIcon)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    (_ctx.user.profileImage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createElementVNode("img", {
                            "aria-hidden": "true",
                            src: _ctx.user.profileImage,
                            alt: "Your Profile Picture"
                          }, null, 8, _hoisted_19)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.user.firstName) + " " + _toDisplayString(_ctx.user.lastName), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.user.type), 1)
                    ])
                  ])
                ]),
                _createElementVNode("ul", null, [
                  _createElementVNode("li", {
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleMobileMenu && _ctx.toggleMobileMenu(...args)))
                  }, [
                    _createVNode(_component_router_link, { to: `/` }, {
                      default: _withCtx(() => [
                        _createVNode(_component_HomeIcon, {
                          class: "mobile--menu",
                          style: {"top":"-3px","margin-right":"27px"}
                        }),
                        _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.$t("Home")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", {
                    onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openMessaging && _ctx.openMessaging(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("a", _hoisted_22, [
                      _createVNode(_component_MailIcon, {
                        class: "mobile--menu",
                        style: {"transform":"scale(1.2)","margin-right":"23px"}
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("ContactUs")), 1)
                    ])
                  ]),
                  _createElementVNode("li", {
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleMobileMenu && _ctx.toggleMobileMenu(...args)))
                  }, [
                    _createVNode(_component_router_link, {
                      to: `/settings`,
                      class: "active-item",
                      id: "header__menu--mobile__item--gear"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_GearIcon, {
                          class: "mobile--menu",
                          style: {"transform":"scale(1.4)"}
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("Settings")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", {
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.toggleMobileMenu && _ctx.toggleMobileMenu(...args)))
                  }, [
                    _createVNode(_component_router_link, {
                      to: `/help`,
                      class: "active-item",
                      id: "header__menu--mobile__item--question"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_QuestionIcon, {
                          class: "mobile--menu",
                          style: {"transform":"scale(1.4)"}
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("Help")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args))),
                      class: "active-item",
                      id: "header__menu--mobile__item--"
                    }, [
                      _createVNode(_component_LogoutIcon, { class: "mobile--menu" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("Logout")), 1)
                    ])
                  ]),
                  (_ctx.isBilingual)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_23, [
                        _createVNode(_component_LanguageToggle)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_Message, {
      isOpen: _ctx.messageIsOpen,
      toggleMessage: _ctx.toggleMessaging
    }, null, 8, ["isOpen", "toggleMessage"])
  ]))
}