import moment, { Moment } from "moment";
import store from "@/store/index";
import VueI18n from "@/plugins/i18n";
import { useI18n } from "vue-i18n";
import i18n from "@/plugins/i18n"

const { t, te } = i18n.global;
type DateParam = Date | Moment | null;

export class DateService {

  public static englishFormat = "ddd, MMM D, YYYY";
  public static frenchFormat = "ddd D MMM YYYY";
  public static englishDateTimeFormat = "ddd, MMM D, YYYY h:mm:ss a";
  public static frenchDateTimeFormat = "ddd D MMM YYYY HH:mm:ss";

  public static months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  public static monthsAndYearsTextFromTotalMonths = (totalMonths: number) => {
    const years = Math.floor(totalMonths / 12);
    const months = Math.ceil(totalMonths % 12);
    return DateService.monthsAndYearsText(years, months);
  }

  public static monthsAndYearsText = (years: number, months: number) => {
    if (months === 0 && years === 1) {
      return t("NumberOfYear", { years, months });
    } else if (months === 0 && years > 1) {
      return t("NumberOfYears", { years, months });
    } else if (months === 1 && years === 1) {
      return t("NumberOfYearAndMonth", { years, months });
    } else if (months === 1 && years === 0) {
      return t("NumberOfMonth", { years, months });
    } else if (months === 1 && years > 1) {
      return t("NumberOfYearsAndMonth", { years, months });
    } else if (months > 1 && years === 1) {
      return t("NumberOfYearAndMonths", { years, months });
    } else if (months > 1 && years > 1) {
      return t("NumberOfYearsAndMonths", { years, months });
    } else if (months > 1 && years === 0) {
      return t("NumberOfMonths", { years, months });
    } else {
      return "-";
    }
  }

  public static yearsDiff = (start: DateParam, end: DateParam) => {
    if (start === null || end === null) {
      return 0;
    }
    end = moment(end);
    start = moment(start);
    const duration = moment.duration(end.diff(start));
    const years = duration.asYears();
    return Math.ceil(years);
  }

  public static daysDiff = (start: DateParam, end: DateParam) => {
    if (start === null || end === null) {
      return 0;
    }
    end = moment(end);
    start = moment(start);
    const duration = moment.duration(end.diff(start));
    const days = duration.asDays();
    return Math.ceil(days);
  }

  public static monthsDiff = (d1: any, d2: any) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const years = DateService.yearsDiff(d1, d2);
    return (years * 12) + (date2.getMonth() - date1.getMonth());
  }

  public static effectiveDateFr = (value: string) => {
    // Note: value is of the form "YYYY-MM-DD"
    let day = value.slice(-2);
    if (day[0] === "0") {
      day = day.slice(-1);
    }
    let monthNum = value.substring(5, 7);
    if (monthNum[0] === "0") {
      monthNum = monthNum.slice(-1);
    }
    const monthWord = DateService.months[monthNum];
    const year = value.substring(0, 4);
    return (day + " " + monthWord + " " + year);
  }

  public static formatDate = (value: any, format?: string) => {
    return moment(value).format(format || (store.state.locale === "fr" ? DateService.frenchFormat : DateService.englishFormat));
  }

  public static formatDateTime = (value: any, format?: string, isLocalTime?: boolean) => {
    const date = isLocalTime
      ? moment(value)
      : moment.utc(value).local();
    return date.format(format || (store.state.locale === "fr" ? DateService.frenchDateTimeFormat : DateService.englishDateTimeFormat));
  }
}
