<template>
  <div class="application-body">
    <div id="app" class="app--login" v-if="$route.name === 'Unavailable'">
      <IEBannerMessage v-if="isIE" class="bannerTop" :closeBanner="() => { }" />
      <router-view />
    </div>
    <div id="app" class="app--login" v-else-if="$route.name === 'Login' || $route.name === 'PasswordReset'">
      <IEBannerMessage v-if="isIE" class="bannerTop" :closeBanner="() => { }" />
      <router-view />
    </div>
    <div id="app" v-else-if="$route.name === 'Impersonation'">
      <IEBannerMessage v-if="isIE" :closeBanner="() => { }" />
      <router-view />
    </div>
    <div id="app" class="app--help" :class="{ anonymous: !$store.state.user }"
      v-else-if="$route.name === 'Help' || $route.name === 'ContactUs' || $route.name === 'SessionExpired' || $route.name === 'VideoLibrary' || $route.name === 'Video'">
      <a href="#container--main" class="skip-link">Skip to main content</a>
      <Header />
      <div id="container--main" class="container">
        <router-view />
      </div>
      <Footer :isFAQ="true" />
    </div>
    <div id="app" class="app--help" :class="{ anonymous: !$store.state.user }"
      v-else-if="$route.name === 'Glossary' || $route.name === 'Sitemap' || $route.name === 'MLILeadGenerationForm'">
      <Header />
      <router-view />
      <Footer />
    </div>
    <div id="app" v-else>
      <a href="#container--main" class="skip-link">Skip to main content</a>
      <Header />

      <div id="container--main" class="container container--main" v-if="loading">
        <Spinner />
      </div>
      <div id="container--main" class="container container--main" v-else>
        <router-view />
      </div>
      <Footer />
    </div>
    <ConsentPopup />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Inject, toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { mapActions } from "vuex";
import IEBannerMessage from "@/components/IEBannerMessage.vue";
import { MediaSizes } from "@/store/types/media";
import VueI18n from "./plugins/i18n";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer.vue";
import Spinner from "@/components/Spinner.vue";
import { CookieService } from "@/services/cookieService";
import ConsentPopup from "@/views/ConsentPop.vue";

@Component({
  components: {
    Header,
    Footer,
    IEBannerMessage,
    ConsentPopup,
    Spinner,
  },
  methods: {
    ...mapActions(["setScreenSize", "updateLocale"]),
  },
})

class App extends Vue {
  @Inject() $appConfig: any

  public $store = useStore()
  private langCookie: string = "";
  private loading: boolean = true;
  private path: string = "";
  private mediaSizes: MediaSizes = {} as any;
  private setScreenSize!: (size: number) => void;
  private updateLocale!: (lang: string) => void;

  get screenSize(): number {
    return this.$store.state.screenSize;
  }


  private created() {
    // await this.$router.isReady();

    this.loading = this.$store.state.loading;
    this.mediaSizes = this.$store.state.mediaSizes;
    this.setScreenSize(window.innerWidth);

    const lang = this.$route.query.lang || CookieService.getCookie("lang");

    if (!lang &&
      this.$appConfig?.frenchUrl === ("https://" + location.host) ||
      this.$appConfig?.frenchUrl === ("http://" + location.host)) {

      this.langCookie = "fr";
    } else {
      this.langCookie = this.$appConfig?.isBilingual && lang ? lang : "en";
    }

    if (this.langCookie) {
      this.updateLocale(this.langCookie);
      CookieService.setCookie("lang", this.langCookie);
    }
  }

  get isIE() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE "); // IE 10 or older => return version number
    const trident = ua.indexOf("Trident/"); // IE 11 => return version number
    if (msie > 0 || trident > 0) {
      return true;
    }
    // other browser
    return false;
  }

  private mounted() {
    window.addEventListener("resize", () => {
      this.setScreenSize(window.innerWidth);
    });
  }

}
export default toNative(App)
</script>

<style lang="scss">
@import "./assets/variables";
@import "./assets/new";
</style>

<style lang="scss" scoped>
#app {
  padding-top: 80px;

  &.app {
    &--login {
      padding-top: 0px;
    }

    &--help {
      &.anonymous {
        padding-top: 0px;
      }

      & .container {
        padding-top: 0px;
      }
    }
  }
}

.container {
  padding-top: 25px;
}

.container--main {
  min-height: calc(100vh - 80px - 77px); //Header - Footer
  padding: 15px;
  padding-top: 25px;
  padding-bottom: 100px;
}

.skip-link {
  position: fixed;
  left: -9999px;
}

.bannerTop {
  position: unset;
}
</style>