import { CreateVideoAnalyticsRequest } from "@/store/types/video";
import apiService from "./apiService";

class VideoService {
  public postAnalytics = (request: CreateVideoAnalyticsRequest): any => {
    return apiService.POST("video/analytics", [], request);
  }
}

export default new VideoService();
