import axios from "axios";
import store from "@/store/";
import router from "@/router";
import config from "@/services/configService";
import { CookieService } from "@/services/cookieService";
import path from "path";

const http = axios.create();

http.interceptors.request.use((request) => {
  if (request.method && request.method.toUpperCase() === "POST") {
    const antiforgery = CookieService.getCookie("XSRF-TOKEN") || window.sessionStorage.getItem("XSRF-TOKEN");
    if (antiforgery) {
      request.headers["X-XSRF-TOKEN"] = antiforgery;
    } else {
      console.error("Missing XSRF-TOKEN");
    }
  }
  request.headers["X-Requested-With"] = "XMLHttpRequest";
  if (config.publicPath !== "/" && request.url && request.url.startsWith("/")) {
    request.url = path.join(config.publicPath, request.url);
  }
  return request;
});

function checkForExceptions(url: string) {
  const exceptions = ["content" , "servicefees" , "forms"];
  let exception = false;
  exceptions.forEach((e) => {
    if (!exception) {
      exception = url.toLowerCase().includes(e);
    }
  });
  return exception;
}

http.interceptors.response.use((response) => {
  if (response.headers["XSRF-TOKEN"] != null) {
    window.sessionStorage.setItem("XSRF-TOKEN", response.headers["XSRF-TOKEN"]);
  } else if (response.headers["xsrf-token"] != null) {
    window.sessionStorage.setItem("XSRF-TOKEN", response.headers["xsrf-token"]);
  }

  if (response.config && response.config.url && !checkForExceptions(response.config.url)) {
    store.dispatch("updateMarsStatus", response.data && response.data.isMarsDown);
  }
  return response;
}, (error) => {
  if (error.response.status === 400) {
    // Most likely anti-forgery token issue
    console.error(error);
  }
  if ((error.response.status === 403 || error.response.status === 401) && !error.response.config.handle403s) {
    // Login and registration will handle their own auth issues
    store.dispatch("logout").then(() => router.push("/login"));
  }
  return Promise.reject(error);
});
export default http;
