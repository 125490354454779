<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
        <path id="info_1_" data-name="info (1)" d="M6.75,0A6.75,6.75,0,1,0,13.5,6.75,6.758,6.758,0,0,0,6.75,0Zm.439,10.755c-.321.053-.959.187-1.283.214a.79.79,0,0,1-.691-.36.844.844,0,0,1-.1-.772L6.389,6.328H5.062A1.586,1.586,0,0,1,6.311,4.853a4.885,4.885,0,0,1,1.283-.213A1.017,1.017,0,0,1,8.285,5a.844.844,0,0,1,.1.772L7.111,9.281H8.437a1.494,1.494,0,0,1-1.248,1.474Zm.4-6.536a.844.844,0,1,1,.844-.844.844.844,0,0,1-.844.844Z" fill="#1d2675"/>
    </svg>
</template>

<script>
export default {
  name: "InfoIconPrimary",
};
</script>

<style lang="scss" scoped>

    svg {
        padding: 1px;

        path {
            fill: var(--color--tooltip-icon-background);
        }

        &.white {
            path {
                fill: white !important;
            }
        }
    }
</style>