import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt", "data-tentant"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _ctx.imgSrc,
    height: "600",
    alt: `${_ctx.$store.state.tenantName} Logo`,
    "data-tentant": _ctx.$store.state.tenantName
  }, null, 8, _hoisted_1))
}