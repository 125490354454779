import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "topBanner" }
const _hoisted_2 = { class: "middleSection" }
const _hoisted_3 = { class: "searchContainer" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["index"]
const _hoisted_6 = {
  key: 0,
  class: "noResults"
}
const _hoisted_7 = { class: "leave" }
const _hoisted_8 = { class: "subText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftIcon = _resolveComponent("ArrowLeftIcon")!
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_ArrowRightIcon = _resolveComponent("ArrowRightIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider", {open: _ctx.isOpen}])
  }, [
    _createElementVNode("h2", null, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleContactUs && _ctx.toggleContactUs(...args)))
      }, [
        _createVNode(_component_ArrowLeftIcon)
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t("ContactUsDrawer.newCoversation")), 1)
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("ContactUsDrawer.QuickAnswers")), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SearchIcon, { class: "black" }),
            _withDirectives(_createElementVNode("input", {
              placeholder: _ctx.searchTextPlaceHolder,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searched) = $event)),
              onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
            }, null, 40, _hoisted_4), [
              [_vModelText, _ctx.searched]
            ]),
            _createElementVNode("div", {
              class: "arrow",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, [
              _createVNode(_component_ArrowRightIcon)
            ])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("ContactUsDrawer.SearchResults")) + "\"" + _toDisplayString(_ctx.searched) + "\"", 1)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTopics, (topic, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "bottomSection",
            key: i,
            index: i
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("h4", null, _toDisplayString(topic.header), 1),
              _createElementVNode("p", null, _toDisplayString(topic.description), 1)
            ])
          ], 8, _hoisted_5))
        }), 128)),
        (_ctx.filteredTopics.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("ContactUsDrawer.NoResults")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("ContactUsDrawer.LeaveUs")), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("ContactUsDrawer.TypicalReplies")), 1),
        _createElementVNode("div", {
          class: "button button--primary--white newMessage",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleMessage && _ctx.toggleMessage(...args)))
        }, _toDisplayString(_ctx.$t("ContactUsDrawer.NewMessage")), 1)
      ])
    ])
  ], 2))
}