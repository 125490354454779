import { OfferCreateRequest, OfferCancelRequest, OfferESignNotificationRequest } from "@/store/types/offer";
import apiService from "./apiService";
import config from "./configService";

class OfferService {

  public getLockIn = (mortgageNumber: string, lang: string): any => {
    return apiService.GET("lockin", [mortgageNumber, lang]);
  }

  public getLockInSigningStatus = (packageId: string): any => {
    return apiService.GET("lockin/users/package", [packageId, "signers", "signingStatus"]);
  }

  public postLockInSigningReminder = (offerId: string, info: OfferESignNotificationRequest): any => {
    return apiService.POST("lockin/users/offer", [offerId, "reminder"], info);
  }

  public getInitialLockIn = (mortgageNumber: string, lang: string): any => {
    return apiService.GET("lockin/initial", [mortgageNumber, lang]);
  }

  public getRevisedInitialLockIn = (mortgageNumber: string, effectiveDate: string, lang: string): any => {
    return apiService.POST("lockin/revised", [mortgageNumber, lang], { effectiveDate });
  }

  public postLockIn = (mortgageNumber: string, lang: string, request: OfferCreateRequest): any => {
    return apiService.POST("lockin/create", [mortgageNumber, lang], request);
  }

  public postLockInAnalytics = (mortgageNumber: string, lang: string, logType: string): any => {
    return apiService.POST("lockin/analytics", [config.code, mortgageNumber, lang, logType], {});
  }

  public postLockInCancel = (request: OfferCancelRequest): any => {
    return apiService.POST("lockin/cancel", [], request);
  }

  public getRenewal = (mortgageNumber: string, lang: string): any => {
    return apiService.GET("renewal", [mortgageNumber, lang]);
  }

  public getRenewalSigningStatus = (packageId: string, brandCode: string): any => {
    return apiService.GET("renewal/package", [brandCode, packageId, "signers", "signingStatus"]);
  }

  public postRenewalSigningReminder = (offerId: string, info: OfferESignNotificationRequest): any => {
    return apiService.POST("renewal/offer", [offerId, "reminder"], info);
  }

  public getInitialRenewal = (mortgageNumber: string, lang: string): any => {
    return apiService.GET("renewal/initial", [mortgageNumber, lang]);
  }

  public getRevisedInitialRenewal = (mortgageNumber: string, effectiveDate: string, lang: string): any => {
    return apiService.POST("renewal/revised", [mortgageNumber, lang], { effectiveDate });
  }

  public postRenewal = (mortgageNumber: string, lang: string, request: OfferCreateRequest): any => {
    return apiService.POST("renewal/create", [mortgageNumber, lang], request);
  }

  public postRenewalAnalytics = (mortgageNumber: string, lang: string, logType: string): any => {
    return apiService.POST("renewal/analytics", [config.code, mortgageNumber, lang, logType], {});
  }

  public postRenewalCancel = (request: OfferCancelRequest): any => {
    return apiService.POST("renewal/cancel", [], request);
  }
}

export default new OfferService();
