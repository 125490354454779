import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "menu-search" }
const _hoisted_3 = {
  key: 0,
  class: "menu"
}
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = {
  for: "username",
  id: "username__label"
}
const _hoisted_7 = {
  for: "webClientId",
  id: "webClientId__label"
}
const _hoisted_8 = { class: "shadowing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchIcon = _resolveComponent("SearchIcon")!
  const _component_DownArrowIcon = _resolveComponent("DownArrowIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "row",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
      }, [
        _createVNode(_component_SearchIcon, { class: "black" }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("CsrSearchHeader")), 1),
        _createVNode(_component_DownArrowIcon, { class: "black" })
      ]),
      (_ctx.showDropdown)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "top",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
            }, [
              _createVNode(_component_SearchIcon, { class: "black" }),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("CsrSearchHeader")), 1),
              _createVNode(_component_DownArrowIcon, { class: "black" })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: _normalizeClass(["input-container", { hasValue: _ctx.username.length > 0 }])
                }, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Username")), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "username",
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addActiveInput($event))),
                    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeActiveInput($event))),
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event))
                  }, null, 544), [
                    [
                      _vModelText,
                      _ctx.username,
                      void 0,
                      { trim: true }
                    ]
                  ])
                ], 2)
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["input-container", { hasValue: _ctx.webClientId.length > 0 }])
              }, [
                _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("WebClientId")), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  id: "webClientId",
                  onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addActiveInput($event))),
                  onBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removeActiveInput($event))),
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.webClientId) = $event))
                }, null, 544), [
                  [
                    _vModelText,
                    _ctx.webClientId,
                    void 0,
                    { trim: true }
                  ]
                ])
              ], 2),
              _createElementVNode("button", {
                class: "button button--primary",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.impersonate()))
              }, _toDisplayString(_ctx.$t("Continue")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("Shadowing", _ctx.user)), 1),
      _createElementVNode("button", {
        class: "button button--delete",
        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.cancel()))
      }, _toDisplayString(_ctx.$t("Cancel")), 1)
    ])
  ]))
}