import { LoginResult, LoginRequest } from "@/store/types/user";
import { Content } from "@/store/types/content";

export enum LoginSteps {
  None,
  Complete,
  ValidationCode,
  ValidationCodeIncorrect,
  ValidationCodeExpired,
  SecurityAnswers,
  SecurityQuestions,
  UserAgreement,
  LockedOut,
  InvalidUsernamePassword,
  LastAttempt,
  UnknownError,
  MortgageVerification,
  SecurityAnswersIncorrect,
  NewPasswordRequired,
}

export interface LoginState {
  currentStep: LoginSteps | null;
  loginResult: LoginResult | null;
  loginRequest: LoginRequest | null;
  loginAlert: Content | null;
}
