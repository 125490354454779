import apiService from "./apiService";

export interface Response {
  isSuccess: boolean;
  error?: any;
  value?: any;
  errorValue?: any;
  isCatchError?: boolean;
}

class LookupService {

  public lookupPhoneNumber = (mobilePhone: string): any => {
    return apiService.GET("phone/lookup", [mobilePhone]);
  }
}

export default new LookupService();
