import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#0072CE" },
    { name: "Green", value: "#079559" },
    { name: "Primary", value: "#002554" },
    { name: "Red", value: "#D93759" },
    { name: "Orange", value: "#002554" },
    { name: "Grey", value: "#B3B9BC" },
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: true,
  showCampaingBanner: true,
  showWelcomePopup: true,
  showEducationalVideosPopup: true,
  showMortgageRenewalPopup: false,
  hasHomeSystem: true,
  hasHomeAppliance: true,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  HomeMortgageProtectionLabel: "Secure Start",
  MortgageLifeInsuranceLabel: "Secure Start",
  showVideoLibrary: true,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: true,
      homeSystem: true,
      privacy: "https://www.rmgmortgages.ca/privacy-policy-rmg",
      security: "https://www.rmgmortgages.ca/security-rmg",
      forms: "https://rmg.entrez.ca/Home/ContentPage/Forms",
      legal: null,
      licences: "https://www.rmgmortgages.ca/licences",
      terms: "https://www.rmgmortgages.ca/terms-of-use-rmg",
      access: "https://www.rmgmortgages.ca/accessibility-rmg",
      moreInfoSAP: "https://www.rmgmortgages.ca/covid-19",
      clickHereSAP: "help/special-payment-arrangements/special-payment-arrangements-info",
      finmo: "https://rmg-mortgages.mtg-app.com",
    },
    fr: {
      homeAppliance: true,
      homeSystem: true,
      privacy: null,
      security: null,
      forms: null,
      legal: null,
      licences: null,
      access: null,
      terms: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
    },
  },

  footerbottom: "",
};
