import Cookies from "universal-cookie";
import moment from "moment";

const cookies = new Cookies();

export class CookieService {
  public static setCookie = (name: string, value: any, options?: any) => {
    if (name === "lang") {
      // Remove the previously saved cookie, so duplicated cookies are removed.
      cookies.remove("lang", { path: (window as any).mcap_config.publicPath });

      if (!options) {
        options = {};
      }
      options.path = "/";
      options.expires = moment().add(90, "days").toDate();
      options.sameSite = true;
      options.secure = true;
    }

    cookies.set(name, value, options);
  };

  public static getCookie = (name: string) => {
    return cookies.get(name);
  };

  public static removeCookie = (name: string) => {
    return cookies.remove(name);
  };

  public static Cookies = {
    Consent: {
      Name: "HOPConsent",
      Values: {
        Accepted: "accept",
        Rejected: "reject",
      },
    },
  };
}
