<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.625" height="14.547" viewBox="0 0 16.625 14.547">
     <path id="Path_4439" data-name="Path 4439" d="M.594-11.727a.578.578,0,0,1-.427-.167A.578.578,0,0,1,0-12.32V-13.8a.578.578,0,0,1,.167-.427A.578.578,0,0,1,.594-14.4H16.031a.578.578,0,0,1,.427.167.578.578,0,0,1,.167.427v1.484a.578.578,0,0,1-.167.427.578.578,0,0,1-.427.167Zm0,5.937a.578.578,0,0,1-.427-.167A.578.578,0,0,1,0-6.383V-7.867a.578.578,0,0,1,.167-.427.578.578,0,0,1,.427-.167H16.031a.578.578,0,0,1,.427.167.578.578,0,0,1,.167.427v1.484a.578.578,0,0,1-.167.427.578.578,0,0,1-.427.167Zm0,5.937A.578.578,0,0,1,.167-.019.578.578,0,0,1,0-.445V-1.93a.578.578,0,0,1,.167-.427.578.578,0,0,1,.427-.167H16.031a.578.578,0,0,1,.427.167.578.578,0,0,1,.167.427V-.445a.578.578,0,0,1-.167.427.578.578,0,0,1-.427.167Z" transform="translate(0 14.398)" fill="#333436" opacity="1"/>
    </svg>
</template>

<script>
export default {
  name: "MenuToggleIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: var(--color--menu-toggle);
        }
    }
</style>