import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#609cf5" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#061F6F" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
  ],
  metaImageUrl: "",
  logoSize: "180px",
  logoSizeFr:"180px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: false,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: null,
      homeSystem: null,
      terms: null,
      privacy: "https://www.lsm.ca/privacy/",
      security: null,
      forms: "https://mylsm.lsm.ca/Home/ContentPage/Forms",
      legal: "https://www.lsm.ca/legal/",
      licences: null,
      access: null,
      moreInfoSAP: "https://mylsm.entrez.ca/Home/ContactUsForm",
      clickHereSAP: "help/special-payment-arrangements/sap-info",
      complaintsLink: "https://lsm.ca/resolving/",
      finmo: null,
    },
    fr: {
      homeAppliance: null,
      homeSystem: null,
      privacy: null,
      security: null,
      forms: null,
      legal: null,
      licences: null,
      access: null,
      terms: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
    },
  },

  footerbottom: "",
};
