import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#609cf5" },
    { name: "Green", value: "#66cc66" },
    { name: "Primary", value: "#1d2675" },
    { name: "Red", value: "#E27575" },
    { name: "Orange", value: "#F2A765" },
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: false,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: "https://www.firstwestcu.ca/terms-of-use/",
      privacy: "https://www.firstwestcu.ca/privacy-policy/",
      security: null,
      forms: null,
      licences: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: "https://www.ppcalculators.com/Default.aspx?id=691819",
      complaintsLink: "https://www.firstwestcu.ca/contact-us/",
    },
    fr: {
      homeAppliance: false,
      homeSystem: false,
      terms: null,
      legal: null,
      privacy: null,
      security: null,
      forms: null,
      licences: null,
      access: null,
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: null,
      complaintsLink: null,
    },
  },

  footerbottom: "",
};
