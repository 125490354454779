import {format as currencyFormat} from "@/services/currencyService";
import { DateService } from "@/services/dateService";

// Filters modified due to a breaking change in the migration, also need to add to global properties before app mount hence imported it in main.ts
// https://v3-migration.vuejs.org/breaking-changes/filters.html

const filters = {
  toCurrency(value: any, zeroReplacement: string) {
    if (typeof value !== "number" && !isNaN(value)) {
      return value;
    }
    if ((value === 0 || isNaN(value)) && zeroReplacement) {
      return zeroReplacement;
    }
    return currencyFormat(value);
  },
  dateFormat(value: any, format: string){
    return DateService.formatDate(value, format);
  }
}

export default filters