<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18.746" height="23.26" viewBox="0 0 18.746 23.26">
        <g id="logout_2_" data-name="logout (2)" transform="translate(-49.677)">
            <g id="Group_3284" data-name="Group 3284" transform="translate(49.677)">
            <g id="Group_3283" data-name="Group 3283" transform="translate(0)">
                <path id="Path_4437" data-name="Path 4437" d="M61.126,22.348H51.544a.977.977,0,0,1-.955-.939V1.789a.925.925,0,0,1,.955-.877h9.582a.456.456,0,0,0,0-.912H51.544a1.837,1.837,0,0,0-1.867,1.789V21.409a1.89,1.89,0,0,0,1.867,1.851h9.582a.456.456,0,0,0,0-.912Z" transform="translate(-49.677)"/>
            </g>
            </g>
            <g id="Group_3286" data-name="Group 3286" transform="translate(56.109 6.354)">
            <g id="Group_3285" data-name="Group 3285" transform="translate(0)">
                <path id="Path_4438" data-name="Path 4438" d="M203.462,144.582l-4.1-4.561a.456.456,0,0,0-.644-.034.475.475,0,0,0-.034.66l3.42,3.815H191.721a.456.456,0,1,0,0,.912h10.3l-3.326,3.311a.448.448,0,0,0,.322.767.456.456,0,0,0,.322-.136l4.1-4.106A.457.457,0,0,0,203.462,144.582Z" transform="translate(-191.265 -139.87)"/>
            </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        path {
            fill: black;
        }
        &.mobile--menu {
            path {
                fill: var(--color--menu-icons--mobile) !important;
            }
        }
    }
</style>