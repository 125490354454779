import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "button-row center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPopup)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "consent-popup",
        class: _normalizeClass(["hidden", { 'inverted': (_ctx.isRMG || _ctx.isEclipse) }])
      }, [
        _createElementVNode("h2", {
          innerHTML: _ctx.popupTitle,
          class: "titleText"
        }, null, 8, _hoisted_1),
        _createElementVNode("p", {
          innerHTML: _ctx.moreInfoContent,
          class: "contentText"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.acceptConsent($event))),
            class: _normalizeClass({ 'button button--secondary': (_ctx.isRMG || _ctx.isEclipse), 'button button--primary': (_ctx.isRFA), 'button button--ghost--white': (!_ctx.isRMG && !_ctx.isEclipse && !_ctx.isRFA) })
          }, _toDisplayString(_ctx.$t("AcceptAll")), 3),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rejectConsent($event))),
            class: _normalizeClass({ 'button button--secondary': (_ctx.isRMG || _ctx.isEclipse), 'button button--primary': (_ctx.isRFA), 'button button--ghost--white': (!_ctx.isRMG && !_ctx.isEclipse && !_ctx.isRFA) })
          }, _toDisplayString(_ctx.$t("RejectAll")), 3)
        ])
      ], 2))
    : _createCommentVNode("", true)
}