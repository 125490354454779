import apiService from "./apiService";
import { PropertyCommunicationPreference } from "@/store/types/mortgage";
import config from "./configService";

export interface ChangePasswordDetails {
  newpassword: string;
  oldPassword: string;
}
class UserService {

  public ChangePassword = (passwords: ChangePasswordDetails): any => {
    return apiService.PUT("Users/password", [], passwords);
  }

  public updateCASL = (allowMarketing: any): any => {
    return apiService.POST("users/casl", [], allowMarketing);
  }

  public getCASL = (): any => {
    return apiService.GET("users/casl", []);
  }

  public updatePaperless = (lang: string, isPaperless: PropertyCommunicationPreference[]): any => {
    return apiService.PUT("mortgages/documentPreferences", [lang], isPaperless);
  }

  public getPaperless = (lang: string): any => {
    return apiService.GET("mortgages/documentPreferences", [lang]);
  }

  public linkMortgage = (mortgageDetails: any): any => {
    return apiService.PUT("Users/linkMortgage", [], mortgageDetails);
  }

  public ChangeUsername = (username: any, lang: string): any => {
    return apiService.PUT("Users/username", [lang], username);
  }

  public changeEmail = (email: any): any => {
    return apiService.PUT("Users/emailAddress", [], email);
  }

  public getSweepStakes = (lang: string): any => {
    return apiService.GET("Users/sweepstakesURL", [lang]);
  }

  public acknowledgedWelcomePopup = (): any => {
    return apiService.PUT("Users/hideWelcomePopup", [], null);
  }

  public acknowledgedEducationalVideosPopup = (): any => {
    return apiService.PUT("Users/hideEducationalVideosPopup", [], null);
  }

  public logFeedbackOnEducationalVideo = (videoId: string, feedback: string): any => {
    return apiService.POST(`Users/analytics/videos/${videoId}/${feedback}`, [], null);
  }

  public accountRecoveryPasswordReset = (lang: string, body: any): any => {
    return apiService.POST("accountRecovery/resetPassword", [lang], body);
  }

  public restPassword  = (body: any, extra: any): any => {
    return apiService.PUT("accountRecovery/resetPassword", [], body, extra);
  }

  public accountRecoverySendUsername = (lang: string, body: any): any => {
    return apiService.POST("accountRecovery/sendUsername", [config.code, lang], body);
  }

  public accountRecoverySendUsernameAndPassword = (lang: string, body: any): any => {
    return apiService.POST("accountRecovery/sendUsernameAndPasswordReset", [config.code, lang], body);
  }

  public registration = (mortgageNumber: any, body: any): any => {
    return apiService.POST("registration", [mortgageNumber, config.code], body);
  }

  public validateRegistration = (mortgageNumber: any, body: any): any => {
    return apiService.POST("registration/validate", [mortgageNumber, config.code], body);
  }

  public sendNewVerificationCode = ( body: any, extras: any): any => {
    return apiService.POST("users/resendVerification", [], body, extras);
  }

  public impersonate = (body: any): any => {
    return apiService.customPOST("/api/account/impersonate", [], body);
  }

  public sendMessage = ( mortgageNumber: string, lang: string, body: any): any => {
    return apiService.POST("contact", [config.code, mortgageNumber, `${lang}-CA`], body);
  }

  public getSecurityQuestions = (lang: string): any => {
    return apiService.GET("AccountRecovery", ["securityQuestions", "all", `${lang}-CA`]);
  }

  public getUserSecurityQuestions = (lang: string): any => {
    return apiService.GET("AccountRecovery", ["securityQuestions", "my", `${lang}-CA`]);
  }

  public getUserAgreement = (lang: string): any => {
    return apiService.GET("Users", ["agreement", `${lang}-CA`]);
  }

  public updateUserSecurityQuestions = ( request: any): any => {
    return apiService.PUT("AccountRecovery", ["securityQuestions"], request);
  }

  public getSweepstakesURL = (lang: string): any => {
    return apiService.GET("Users/sweepstakesURL", [lang]);
  }

  public submitMLILead = ( mortgageNumber: string, lang: string, data: any ): any => {
    return apiService.POST("Campaign", ["campaignLead", "MLI", mortgageNumber, `${lang}-CA`], data);
  }

  public submitEnrollment = ( enrollmentType: string, mortgageNumber: string, lang: string, data: any ): any => {
    return apiService.POST("Campaign", ["enrollment", enrollmentType, mortgageNumber, `${lang}-CA`], data);
  }

  public submitQuiz = (campaignName: string, mortgageNumber: string, lang: string, body: any): any => {
    return apiService.POST("Campaign", ["campaignQuestions", `${campaignName}`, `${mortgageNumber}`, `${lang}-CA`], body);
  }

  public isQuizAvailable = (type: any): any => {
    return apiService.GET("Campaign", ["isQuizAvailable", type]);
  }

  public logout = async ( ): Promise<any> => {
    const response =  await apiService.customPOST("/api/account/logout", []);
    if (response.isCatchError) {
      console.error(response.errorValue);
    }
    return response;
  }

  public submitConversionForm = (mortgageNumber: string, lang: string, body: any): any => {
    return apiService.POST("contact/conversion", [config.code, mortgageNumber, `${lang}-CA`], body);
  }

  public lockInClickedAnalytics = (mortgageNumber: string, lang: string): any => {
    return apiService.POST("contact/LockInClickedAnalytics", [config.code, mortgageNumber, `${lang}-CA`], null);
  }

}

export default new UserService();
