<template>
    <div class="EI-banner" v-if="visible">
        <div class="text" v-html="text" />
        <div class="close" @click="close" >
            <CloseIcon class="white" />
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Inject , toNative } from "vue-facing-decorator"
import { useStore } from "vuex";
import { Content } from "@/store/types/content";

import CloseIcon from "@/components/Icons/CloseIcon.vue";
import contentService from "@/services/contentService";

@Component({
  components: {
    CloseIcon,

  },
})
class IEBannerMessage extends Vue {

  @Inject() $appConfig: any


  public $store = useStore()
  @Prop() private closeBanner!: () => void;
  private visible: boolean = true;
  private text: string = "";


  private close() {
    this.visible = false;
    this.closeBanner();
  }

  private async created() {
    this.visible = true;
    const response = await contentService.getIEBanner();
    if (response.isSuccess) {
      this.text = this.$store.state.locale === "fr" ? response.value.frenchContent : response.value.englishContent;
    }
  }
}
export default toNative(IEBannerMessage)
</script>
<style lang="scss" scoped>


    .EI-banner{
        background: red;
        width: 100%;
        position: fixed;
        z-index: 10000;
        top:0;
        .text{
            max-width: 100%;
            text-align: center;
            padding:5px;
            color: white;
        }
        .close {
            position: absolute;
            right: 15px;
            top: 5px;
            cursor: pointer;
        }
    }
</style>
