<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.635" height="8.899" viewBox="0 0 16.635 8.899">
        <g id="down-arrow" transform="translate(-6.4 -33.4)">
            <path id="Path_113" data-name="Path 113" d="M22.865,33.572a.588.588,0,0,0-.831,0l-7.308,7.323L7.4,33.572a.588.588,0,0,0-.831.831L14.3,42.127a.574.574,0,0,0,.416.172.6.6,0,0,0,.416-.172L22.851,34.4A.576.576,0,0,0,22.865,33.572Z" fill="#707070"/>
        </g>
    </svg>
</template>

<script>
export default {
  name: "DownArrowIcon",
};
</script>

<style lang="scss" scoped>

    svg {
        &.blue {
            path {
                fill: var(--color--blue);
            }
        }
    }
</style>