import { Branding } from "@/assets/brandingModel";

export const branding: Branding = {
  chartColors: [
    { name: "Blue", value: "#8F9194" },
    { name: "Green", value: "#262e31" },
    { name: "Primary", value: "#003057" },
    { name: "Red", value: "#D93759" },
    { name: "Orange", value: "#F2A765" },
    { name: "Grey", value: "#8F9194" },
  ],
  metaImageUrl: "",
  logoSize: "90px",
  logoSizeFr:"90px",
  FAQCovidDirectLink: false,
  showCampaingBanner: false,
  showWelcomePopup: true,
  showEducationalVideosPopup: false,
  showMortgageRenewalPopup: false,
  hasHomeSystem: false,
  hasHomeAppliance: false,
  showSalesBanners: false,
  showIAConvertedMessage: false,
  MortgageLifeInsuranceLabel: "",
  HomeMortgageProtectionLabel: "HomeMortgageProtection",
  showVideoLibrary: true,
  showLicenceInfoFromCMS: false,
  usefulLink: {
    en: {
      homeAppliance: null,
      homeSystem: null,
      terms: null,
      legal: null,
      privacy: "https://www.merixfinancial.com/privacy-policy",
      security: null,
      forms: null,
      licences: "https://www.merixfinancial.com/about/brokerage-licenses/",
      access: "https://www.merixfinancial.com/accessibility",
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: "https://www.ppcalculators.com/Default.aspx?id=987951",
    },
    fr: {
      homeAppliance: null,
      homeSystem: null,
      terms: null,
      legal: null,
      privacy: "https://www.merixfinancial.com/fr/privacy-policy",
      security: null,
      forms: null,
      licences: "https://www.merixfinancial.com/fr/a-propos/licences-de-courtage/",
      access: "https://www.merixfinancial.com/fr/accessibilite/",
      moreInfoSAP: null,
      clickHereSAP: null,
      finmo: null,
      mortgageCalculators: "https://www.ppcalculators.com/Default.aspx?id=987951",
    },
  },

  footerbottom: "",
};
